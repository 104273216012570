import React from 'react';
import { useSelector } from 'react-redux';
import { ContContainer } from './MainContentElements';
import MainSlide from '../MainSlide';
import MainSessionContent from '../MainSession';
import MainSchedule from '../MainSchedule';
import NewsLetter from '../NewsLetter';
import MainCodeLab from '../MainCodeLab';
import MainIntro3 from '../MainIntro3';
import MainTechSquare from '../MainTechSquare';
import MainOnsiteEvent from '../MainOnsiteEvent';

const MainContent = () => {
  const phase = useSelector((state) => state.meta.data.phase);

  return (
    <ContContainer>
      {phase === 'phase4' ? null : <MainIntro3 />}
      <MainSlide />
      <MainSessionContent />
      <MainTechSquare />
      {phase === 'phase4' ? null : <MainSchedule />}
      <MainOnsiteEvent />
      <MainCodeLab />
      {/*
      <MainBlog />
      <MainEngagement />
      <MainJoinus />
      <MainHighlights />
       */}
      <NewsLetter />
    </ContContainer>
  );
};

export default MainContent;
