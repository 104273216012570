import styled from 'styled-components';

export const RegiContainer = styled.div`
  min-height: calc(100vh - 65px - 166px);
  // margin-bottom: 128px;

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 56px - 216px);
    // margin-bottom: 60px;
  }
`;
