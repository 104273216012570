import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { ActivityMyPointContainer, HowToUsePopup } from './ActivityMyPoint';

const ActivityMyPoint = ({ isLink, point }) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modal]);

  // const cameraRef = useRef(null);
  // const { qrFileHandler } = useActivityQRDetect();

  return (
    <>
      <ActivityMyPointContainer>
        <p>My current point</p>
        <p>{point} P</p>
        <Button color="white" size="medium" onClick={() => setModal(true)}>
          How to use points
        </Button>
        {/* <input
        ref={cameraRef}
        id="points-camera"
        name="points-camera"
        type="file"
        accept="image/*"
        capture="environment"
        className="hidden"
        onChange={qrFileHandler}
      />
      <Button
        color="white"
        size="medium"
        onClick={() => cameraRef.current?.click()}
      >
        Use points
      </Button>
      <p>Scan QR through camera</p> */}
      </ActivityMyPointContainer>
      {isLink != null ? (
        <Link to="/activity/point" className="btn-link" />
      ) : null}
      {modal ? (
        <HowToUsePopup>
          <div className="inner-wrap">
            <div className="popup-cont">
              <img
                src={`${process.env.PUBLIC_URL}/images/activity-bingo-howtoplay-3.svg`}
                alt="Scan QR code"
              />
              <p>Scan QR code</p>
              <p>
                Turn on the camera app <br />
                and take a picture of the <br />
                QR code provided by our staff
              </p>
              <Button color="black" onClick={() => setModal(false)}>
                Okay
              </Button>
            </div>
          </div>
        </HowToUsePopup>
      ) : null}
    </>
  );
};

export default ActivityMyPoint;
