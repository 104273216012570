import axios from 'axios';

export function Post({ url, headers, body }) {
  return axios({
    method: 'post',
    headers,
    url,
    data: body,
  })
    .then((data) => data)
    .catch((err) => ({
      error: (err.response && err.response.data) || err,
    }));
}

export function Get({ url, headers }) {
  return axios({
    method: 'get',
    headers,
    url,
  })
    .then((data) => data)
    .catch((err) => ({
      error: (err.response && err.response.data) || err,
      status: err.status,
    }));
}

export function Delete({ url, headers, body }) {
  const opt = {
    method: 'delete',
    headers,
    url,
  };

  if (body) {
    opt.data = body;
  }

  return axios(opt)
    .then((data) => data)
    .catch((err) => ({
      error: (err.response && err.response.data) || err,
    }));
}
