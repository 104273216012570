import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import CookieEu from '../../components/CookieEu';
import CookieLatin from '../../components/CookieLatin';
import withRouter from '../../components/Router/withRouter';

const Cookie = (props) => {
  const { location } = props;
  const country = new URLSearchParams(location.search).get('country');

  return (
    <>
      <DetailContainer>
        {country ? (
          {
            eu: <CookieEu />,
            latin_america: <CookieLatin />,
          }[country]
        ) : (
          <CookieEu />
        )}
      </DetailContainer>
    </>
  );
};

export default withRouter(Cookie);
