import styled from 'styled-components';

export const ActivityHistoryContainer = styled.div`
  margin-top: 176px;
`;

export const ActivityHistory = styled.div`
  border-top: 10px solid #f6f6f6;
  padding: 24px;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-family: 'SamsungSharpSans-bold';
      font-size: 16px;
      line-height: 1;
    }
    .control {
      button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-family: 'SamsungOne-700';
        font-size: 14px;
        line-height: 1;
        color: #979797;
        padding: 8px 12px;
        background-color: #f6f6f6;
        border-radius: 15px;
        overflow: hidden;

        &.on {
          color: #2088fd;
          background: rgba(32, 136, 253, 0.12);
        }
      }
      button + button {
        margin-left: 8px;
      }
    }
  }
  ul {
    margin-top: 4px;
  }
  li {
    padding: 16px 0;
    p {
      display: flex;
      justify-content: space-between;
      align-items: end;
      font-family: 'SamsungOne-700';
      font-size: 20px;
      line-height: 28px;

      span {
        padding-right: 8px;
      }
      em {
        white-space: nowrap;
      }
    }
    p + p {
      margin-top: 2px;
      font-family: 'SamsungOne-600';
      font-size: 14px;
      line-height: 20px;
      color: #7c7c7c;

      span {
        padding: 0;
      }
    }
    button {
      display: inline-flex;
      font-family: 'SamsungOne-600';
      font-size: 14px;
      line-height: 20px;
      color: #2088fd;
      align-items: center;
      &:after {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-blue.svg`});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
    &.save {
      em {
        color: #2088fd;
      }
    }
  }
  li + li {
    border-top: 1px solid #eaeaea;
  }
`;
