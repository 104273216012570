import * as types from '../ActionTypes';

const initialState = {
  isLiked: false,
  motion: null,
};

const LikedReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIKED_SESSION: {
      return { ...state, isLiked: action.isLiked, motion: null };
    }
    case types.LIKE_SESSION_REQUEST: {
      return { ...state, error: null, motion: null };
    }
    case types.LIKE_SESSION_SUCCESS: {
      return {
        ...state,
        isLiked: true,
        motion: 'like',
      };
    }
    case types.LIKE_SESSION_ERROR: {
      return { ...state, error: action.error };
    }
    case types.UNLIKE_SESSION_REQUEST: {
      return { ...state, error: null, motion: null };
    }
    case types.UNLIKE_SESSION_SUCCESS: {
      return { ...state, isLiked: false, motion: 'unlike' };
    }
    case types.UNLIKE_SESSION_ERROR: {
      return { ...state, error: action.error };
    }
    case types.RESET_LIKE_MOTION: {
      return { ...state, motion: null };
    }
    default:
      return state;
  }
};

export default LikedReducer;
