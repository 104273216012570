import React from 'react';
import ActivityMsgCont from '../../components/ActivityMsgCont';

const ActivityPickupComplete = () => {
  return (
    <>
      <ActivityMsgCont
        type="pickup"
        msgTitle={`Thank you for participating. <br />
          Please pick up the prize!`}
        buttonLink="/activity/point"
        buttonText="Go to Point history"
      />
    </>
  );
};

export default ActivityPickupComplete;
