import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import CodeLabCont from '../../components/CodeLabCont';
import { GET_CODELAB_REQUEST } from '../../redux/ActionTypes';

const CodeLab = () => {
  // get code lab data
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_CODELAB_REQUEST });
  }, [dispatch]);

  return (
    <>
      <DetailContainer>
        <DetailHero
          bgPc1="bg-codelab-banner-pc-1.svg"
          bgPc2="bg-codelab-banner-pc-2.svg"
          bgMo1="bg-codelab-banner-mo-1.png"
          bgMo2="bg-codelab-banner-mo-2.svg"
          title="Code Lab"
          bgColor="#CA6CEB"
          path="Code Lab"
        />
        <CodeLabCont />
      </DetailContainer>
    </>
  );
};

export default CodeLab;
