import React from 'react';
import { Link } from 'react-router-dom';
import {
  ActivityContainer,
  ActivityTab,
} from '../ActivityBingoMain/ActivityBingoElements';
import { ActivityBingoMapCont } from './ActivityBingoMapElements';

const ActivityBingoMapContent = () => {
  return (
    <ActivityContainer type="bingo">
      <ActivityTab>
        <Link to="/activity/bingo">Activities</Link>
        <Link to="/activity/bingo/map" className="active">
          Map
        </Link>
        <Link to="/activity/bingo/prizes">Prizes</Link>
      </ActivityTab>
      <ActivityBingoMapCont>
        <p>Bingo Map</p>
        <img
          src={`${process.env.PUBLIC_URL}/images/bingo-map.v3.png`}
          alt="map image"
        />
        <ul>
          <li>Open Stage</li>
          <li>Networking Lounge</li>
          <li>AI for Platforms</li>
          <li>Enterprise & Ecosystem</li>
          <li>AI for Devices</li>
          <li>Session Rooms</li>
          <li>Message Wall</li>
          <li>Gamer’s Lounge</li>
          <li>Info/Giveaway Desk</li>
        </ul>
      </ActivityBingoMapCont>
    </ActivityContainer>
  );
};

export default ActivityBingoMapContent;
