import { put, takeLatest, select } from 'redux-saga/effects';
import * as types from '../ActionTypes';

export function* getSessionFilter(action) {
  try {
    const {
      sessionList,
      checkedTypes,
      checkedMainCategories,
      checkedTopics,
      checkedLocations,
    } = action;
    const clearFilter = yield select((state) => state.sessionFilter.isClear);

    let filterList = sessionList;
    if (filterList) {
      if (checkedTypes && checkedTypes.length > 0) {
        filterList = filterList.filter((x) =>
          checkedTypes.includes(x.SessionType)
        );
      }

      if (checkedMainCategories.length > 0) {
        filterList = filterList.filter((x) =>
          checkedMainCategories.includes(x.CustomPresField65)
        );
      }

      if (checkedTopics.length > 0) {
        filterList = filterList.filter((x) => {
          return checkedTopics.some((element) =>
            x.CustomPresField2.includes(element)
          );
        });
      }

      if (checkedLocations && checkedLocations.length > 0) {
        filterList = filterList.filter((x) =>
          checkedLocations.includes(x.PresentationRoom)
        );
      }
    }

    const result = {
      filterData: filterList.length !== 0 ? filterList : [],
      filterChecked: !!(
        (checkedTypes && checkedTypes.length > 0) ||
        checkedMainCategories.length > 0 ||
        checkedTopics.length > 0 ||
        checkedLocations.length > 0
      ),
      clearFilter,
      sessionList: [...sessionList],
    };

    yield put({ type: types.GET_SESSION_FILTER_SUCCESS, data: result });
  } catch (error) {
    if (error) {
      console.error('SessionFilterSaga', error);
      yield put({ type: types.GET_SESSION_FILTER_ERROR, error });
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_SESSION_FILTER_REQUEST, getSessionFilter);
}
