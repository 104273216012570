import styled from 'styled-components';

export const ActivityBingoPrizesContainer = styled.div`
  position: relative;
  background-color: #2088fd;
  margin-top: 48px;
  padding: 16px 0 60px;
`;
export const ActivityPrizesCont = styled.div`
  position: relative;
  margin: 0 24px;
`;
export const ActivityPrizesTitle = styled.div`
  position: relative;
  margin-top: 40px;
  // background-image: url(${`${process.env.PUBLIC_URL}/images/activity-prizes-title-bg.svg`});
  // background-position: top center;
  // background-size: 312px 110px;
  // background-repeat: no-repeat;
  text-align: center;
  color: #fff;
  p:first-of-type {
    font-family: 'samsungsharpsans-bold';
    font-size: 18px;
    line-height: 24px;
  }
  p:first-of-type + p {
    font-family: 'samsungsharpsans-bold';
    font-size: 32px;
    line-height: 42px;
  }
  p:last-of-type {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
  }
  .bg {
    position: absolute;
    left: 50%;
    top: 0;
    width: 312px;
    height: 110px;
    transform: translateX(-50%);
  }
`;
export const ActivityPrizesList = styled.div`
  position: relative;
  z-index: 2;
  margin-top: 40px;
  ul {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    li {
      position: relative;
      padding: 12px;
      background-color: #fff;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 26px;
        height: 32px;
        background-image: url(${`${process.env.PUBLIC_URL}/images/activity-prizes-label.svg`});
        font-family: 'samsungsharpsans-bold';
        font-size: 10px;
        line-height: 26px;
        text-align: center;
        color: #fff;
        &.no1 {
          background-image: url(${`${process.env.PUBLIC_URL}/images/activity-prizes-label-no1.svg`});
        }
      }
      img {
        display: block;
        width: 100px;
        height: 100px;
      }
      p {
        margin-top: 12px;
        font-family: 'samsungsharpsans-bold';
        font-size: 12px;
        line-height: 16px;
      }
      &.col-2 {
        grid-column: span 2;
        span {
          width: 39px;
          height: 48px;
          background-image: url(${`${process.env.PUBLIC_URL}/images/activity-prizes-label.l.svg`});
          font-size: 15px;
          line-height: 39px;
        }
      }
    }
  }
`;
export const ActivityPrizesSchedule = styled.div`
  position: relative;
  margin: 98px 0 80px;
  padding: 24px 16px;
  background-color: #fff;
  border-radius: 12px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 24px;
    top: -83px;
    width: 190px;
    height: 83px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-prizes-schedule-bg-1.png`});
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -24px;
    top: -119px;
    width: 154px;
    height: 100px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-prizes-schedule-bg-2.svg`});
    background-size: contain;
    background-repeat: no-repeat;
    .codelab & {
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-prizes-schedule-bg-3.svg`});
    }
  }
  p {
    font-family: 'samsungsharpsans-bold';
    font-size: 20px;
    line-height: 28px;
  }
  ul {
    margin-top: 8px;
    li {
      padding: 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: 'SamsungOne-700';
      font-size: 14px;
      height: 28px;
      span:first-of-type {
        flex: 1;
        padding: 0 10px;
        font-family: 'samsungsharpsans-bold';
        font-size: 16px;
        line-height: 20px;
      }
      span + span {
        display: flex;
        align-items: center;
        color: #979797;
        font-family: 'SamsungOne-400';
        &:before {
          content: '';
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 6px;
          background-image: url(${`${process.env.PUBLIC_URL}/images/ico-clock.svg`});
        }
      }
      em {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 28px;
        border-radius: 6px;
        text-align: center;
        font-family: 'SamsungOne-700';
        font-size: 12px;
        line-height: 100%;
        color: #fff;
        background-color: #2088fd;
      }
    }
    li + li {
      border-top: 1px solid rgba(0, 0, 0, 0.08);
    }
    li:last-of-type {
      padding-bottom: 0;
    }
  }
`;
export const ActivityPrizesRandom = styled.div`
  overflow: hidden;
  padding-top: 82px;
  .slick-slider {
    .slick-list {
      overflow: visible;
    }
    .slick-slide {
      width: 200px;
      margin: 0;
      padding: 0 5px;
      transition: transform 0.3s;
      &.slick-active {
        transform: translateY(-42px);
      }
    }
    .slick-dots {
      bottom: 0;
    }
    .slick-dots li {
      width: 6px;
      height: 6px;
      margin: 0 12px;

      + li {
        margin-left: 0;
      }
    }
    .slick-dots li button:before {
      width: 6px;
      height: 6px;
      font-size: 0;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 100%;
      transition: width 0.3s;
    }
    .slick-dots li.slick-active button:before {
      background-color: #fff;
    }
  }
  .random-item {
    width: 200px;
    height: 240px;
    background-color: #fff;
    border-radius: 14px;
    overflow: hidden;
    .img {
      padding: 14px;
      background-color: #f5f5f5;
      img {
        display: block;
        width: 140px;
        height: 140px;
        margin: auto;
      }
    }
    p {
      padding: 12px 16px 0;
      font-family: 'SamsungOne-700';
      font-size: 16px;
      line-height: 20px;
    }
    p + p {
      padding: 4px 16px 16px;
      font-size: 14px;
      line-height: 20px;
      color: #2088fd;
      &:before {
        content: 'P';
        display: inline-block;
        width: 15px;
        height: 15px;
        border: 1px solid #2088fd;
        border-radius: 50%;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin-right: 6px;
      }
    }
  }
`;
