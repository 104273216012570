import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import * as types from '../ActionTypes';
import { Get, Post, Delete } from '../../utils/Requester';
// eslint-disable-next-line import/no-cycle
import { getUserRegisterType } from './UserSessionSaga';
import { sessionData as sampleSession } from '../../sample/SessionData';

export function* getReservationList() {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      return [];
    }
    const { data, error } = yield call(Get, {
      url: `/api/sdc/reservation`,
    });

    if (error || typeof data === 'string') {
      console.error('ReservationSaga', error);
      return [];
    }

    return data.reservations;
  } catch (error) {
    if (error) {
      console.error('ReservationSaga', error);
      return [];
    }
  }
}

// register type + reservation data
export function* getReservation({ sessionId }) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      return { type: null, isReservation: false };
    }

    const registerType = yield call(getUserRegisterType);
    if (registerType) {
      const { data, error } = yield call(Get, {
        url: `/api/sdc/reservation/${sessionId}`,
      });

      if (error || typeof data === 'string') {
        console.error('Reservation Saga', error);
        return { type: registerType, isReservation: false };
      }
      // 404
      if (typeof data.data === 'object') {
        return { type: registerType, isReservation: false };
      }

      const reservationInfo = JSON.parse(data.data);
      return {
        type: registerType,
        isReservation:
          registerType === 'online'
            ? reservationInfo.PresentationID === sessionId &&
              reservationInfo.SessionType.toLowerCase() === 'mega'
            : reservationInfo.PresentationID === sessionId,
      };
    }
    return { type: registerType, isReservation: false };
  } catch (error) {
    console.error('getReservation', error);
    return { type: null, isReservation: false };
  }
}

function* addReservation({ sessionData }) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({
        type: types.ADD_RESERVATION_SUCCESS,
        isReservation: true,
      });
      return;
    }

    const tag = sessionData.CustomPresField2 || sessionData.category || '';

    const reservationData = {
      PresentationID: sessionData.PresentationID,
      PresentationTitle: sessionData.PresentationTitle,
      PresentationDateTimeStart: sessionData.PresentationDateTimeStart,
      PresentationDateTimeEnd: sessionData.PresentationDateTimeEnd,
      AbstractTextShort: sessionData.AbstractTextShort,
      SessionType: sessionData.SessionType,
      PresentationImage:
        sessionData.CustomPresField1 || sessionData.image || '',
      PresentationTag: typeof tag === 'string' && tag ? tag.split(',') : tag,
      MainCategory: sessionData.mainCategory,
    };

    const { data, error } = yield call(Post, {
      url: `/api/sdc/reservation/${sessionData.PresentationID}`,
      body: { data: reservationData },
    });

    if (error || typeof data === 'string') {
      console.error('ReservationSaga', error);
      yield put({
        type: types.ADD_RESERVATION_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
      return;
    }

    yield put({
      type: types.ADD_RESERVATION_SUCCESS,
      isReservation: true,
    });
  } catch (error) {
    if (error) {
      console.error('ReservationSaga', error);
      yield put({
        type: types.ADD_RESERVATION_ERROR,
        error,
      });
    }
  }
}

function* deleteReservation({ sessionId, actionPage }) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({
        type: types.DELETE_RESERVATION_SUCCESS,
        isReservation: false,
        data: actionPage && actionPage === 'mypage' ? sampleSession : [],
      });
      return;
    }
    const { data, error } = yield call(Delete, {
      url: `/api/sdc/reservation/${sessionId}`,
    });

    if (error || typeof data === 'string') {
      console.error('ReservationSaga', error);
      yield put({
        type: types.DELETE_RESERVATION_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
      return;
    }

    let list = [];
    if (actionPage && actionPage === 'mypage') {
      const stateReservation = yield select(
        (state) => state.reservationData.data
      );
      list = stateReservation.filter((x) => x.PresentationID !== sessionId);
    }
    yield put({
      type: types.DELETE_RESERVATION_SUCCESS,
      isReservation: false,
      data: actionPage && actionPage === 'mypage' ? list : [],
    });
  } catch (error) {
    if (error) {
      console.error('ReservationSaga', error);
      yield put({
        type: types.DELETE_RESERVATION_ERROR,
        error,
      });
    }
  }
}

export function* notiReservation({ date }) {
  // date timezone is UTC
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({
        type: types.OPEN_RESERVATION_NOTI_DATA,
        notiSessions: [],
      });
      return;
    }
    const gapTime = yield select((state) => state.meta.gapTime);
    const realTime = gapTime === 0 ? date : date - gapTime;

    const registerType = yield call(getUserRegisterType); // get register type
    let reservationList = [];
    if (registerType) {
      // get reservation list
      const { data, error } = yield call(Get, {
        url: `/api/sdc/reservation`,
      });

      if (error || typeof data === 'string') {
        console.error('ReservationSaga', error);
        return [];
      }

      // reservation list filter according to register type
      reservationList = [...data.reservations].filter((x) => {
        return registerType === 'online'
          ? x.SessionType.toLowerCase() === 'mega'
          : x;
      });
    }

    const startTimes = [
      ...new Set(reservationList.map((x) => x.PresentationDateTimeStart)),
    ]
      // filter data for If it is earlier than the current time
      .filter(
        (x) =>
          moment.tz(x, 'America/Los_Angeles').utc().valueOf() -
            moment.utc(realTime).valueOf() >
          0
      )
      .sort((a, b) => new Date(a) - new Date(b));

    const diffArray = [];
    if (startTimes.length > 0) {
      startTimes.forEach((x) => {
        // Check the time 30 minutes or 5 minutes ago
        const currentTime = moment.utc(realTime);
        const beforeThirty = moment
          .tz(x, 'America/Los_Angeles')
          .utc()
          .subtract(30, 'minute');
        const beforeFive = moment
          .tz(x, 'America/Los_Angeles')
          .utc()
          .subtract(5, 'minute');

        // Only add time that has not yet passed
        if (beforeThirty.isAfter(currentTime)) {
          diffArray.push(beforeThirty.diff(currentTime));
        }

        if (beforeFive.isAfter(currentTime)) {
          diffArray.push(beforeFive.diff(currentTime));
        }
      });
    }

    if (diffArray.length > 0) {
      // delay saga
      yield delay(Math.min(...diffArray));

      const compareDate = moment.utc(realTime).add(Math.min(...diffArray));

      // get notification session data
      const notiSessions = reservationList.filter((x) => {
        // 9/25 Noti popup provided only when 5 minutes remain
        // const beforeThirty = moment
        //   .tz(x.PresentationDateTimeStart, 'America/Los_Angeles')
        //   .utc()
        //   .subtract(30, 'minute');
        const beforeFive = moment
          .tz(x.PresentationDateTimeStart, 'America/Los_Angeles')
          .utc()
          .subtract(5, 'minute');
        // if (beforeThirty.isSame(compareDate)) {
        //   x.leftTime = '30min';
        // }

        // if (beforeFive.isSame(compareDate)) {
        //   x.leftTime = '5min';
        // }
        return beforeFive.isSame(compareDate);
      });

      // show notification popup + save to notification session data in redux
      yield put({
        type: types.OPEN_RESERVATION_NOTI_DATA,
        notiSessions,
      });

      // re-call functions
      yield call(notiReservation, {
        date: moment.utc().valueOf(),
      });
    }
  } catch (error) {
    if (error) {
      console.error(
        '[ReservationSaga.js] Reservation Notification Function',
        error
      );
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(types.ADD_RESERVATION_REQUEST, addReservation);
  yield takeLatest(types.DELETE_RESERVATION_REQUEST, deleteReservation);
}
