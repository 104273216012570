import styled from 'styled-components';

export const FilterContainer = styled.div`
  border-radius: 24px;
  background-color: #fff;
  position: relative;
  z-index: 1;

  &.schedule {
    background-color: #f6f6f6;
  }

  @media screen and (max-width: 1024px) {
    border-radius: 0;
    border-bottom: solid 1px #dddddd;
    &.schedule {
      background-color: #ffffff;
      width: 100%;
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 49;
      margin-bottom: 0;
    }
  }
`;

export const FilterDim = styled.div`
  position: relative;
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }
`;

export const FilterInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 30px 36px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 30px 0;
  }
`;

export const FilterBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // z-index: 1;
`;

export const FilterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  padding: 0 4px;

  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 20px 24px;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
  }
`;

export const FilterTitle = styled.div`
  font-family: 'samsungSharpSans-bold';
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: normal;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
    line-height: 1.2;
  }
`;

export const FilterClose = styled.button`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-close.png`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const FilterCont = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  height: 52px;
  width: calc(100% - 116px);

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 0 24px;
    width: 100%;
    height: 188px;
  }
`;

export const FilterItemBox = styled.div`
  width: 100%;
  height: 100%;
  &.main-categories {
    width: 265px;
    flex: none;
  }
  @media screen and (max-width: 1024px) {
    &.main-categories {
      width: 100%;
      height: 52px;
    }
  }
`;

export const FilterItemWrap = styled.div`
  position: relative;
`;

export const FilterItem = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 28px;
  border: 1.4px solid #dddddd;
  background-color: #ffffff;
  box-sizing: content-box;
  width: 100%;
  max-height: 52px;
  transition: max-height 0.5s cubic-bezier(0.24, 0.96, 0.46, 1);

  &.transition {
    z-index: 1;
  }

  .empty-block {
    height: 24px;
  }

  &.main-categories {
    width: 100%;
    flex: none;
  }

  &.open {
    border-radius: 28px;
    max-height: 452px;

    &.main-categories {
      max-height: 272px;
    }
  }
  @media screen and (max-width: 1024px) {
    min-height: 52px;
    max-height: 52px;

    &.main-categories {
      width: 100%;
      z-index: 3;
    }

    &.location-categories {
      z-index: 2;
    }
  }
`;

export const FilterItemTitle = styled.div`
  position: relative;
  font-family: 'SamsungOne-700';
  font-weight: bold;
  font-size: 18px;
  color: #363636;
  padding: 14px 28px;

  .count {
    padding-left: 6px;
    font-family: 'SamsungOne-600';
    color: #adadad;
    font-weight: 600;
    em {
      color: #2088fd;
      &.empty {
        color: #adadad;
      }
    }
  }

  .more-btn {
    position: absolute;
    top: 14px;
    right: 28px;
    height: 20px;
    &.close {
      img {
        transform: rotate(0deg);
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    transform: rotate(180deg);
  }

  @media screen and (max-width: 768px) {
    .more-btn {
      height: 14px;
      top: 18px;
    }

    img {
      width: 14px;
      height: 14px;
    }
  }
`;

export const FilterItemCont = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 24px 0 24px;
  overflow: scroll;

  &.topic-filter {
    max-height: 416px;
    overflow: auto;
  }

  &.close {
    visibility: hidden;
  }

  &::-webkit-scrollbar {
    width: 20px;
    max-height: 132px;
  }

  &::-webkit-scrollbar-thumb {
    background: #dddddd;
    border-radius: 20px;
    height: 50%;
    width: 4px;
    border: 8px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &.main-categories {
    overflow: hidden;
  }

  @media screen and (min-width: 769px) {
    &.topic-filter {
      overflow: hidden;
    }
  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    &.topic-filter {
      overflow: auto;
      & > div {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 769px) {
    padding: 16px 24px 0 28px;
  }
`;

export const FilterClearWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.4px #ddd solid;
  background-color: #ffffff;
  height: 52px;
  border-radius: 999px;
  padding: 16px 18px;
  white-space: nowrap;
  &:not(&.schedule):hover {
    background-color: #f6f6f6;
    border: 1.4px solid #f6f6f6;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const FilterClear = styled.button`
  font-family: 'SamsungOne-700';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: normal;
  color: #1e1e1e;
  box-sizing: border-box;
  transition: 0.3s;
  margin-right: 8px;

  @media screen and (max-width: 1024px) {
    min-width: 144px;
  }
`;

export const FilterCount = styled.div`
  color: #2088fd;
  font-family: 'SamsungOne-700';
  font-weight: 700;
  font-size: 16px;

  &.zero {
    color: #979797;
  }
`;
