import * as types from '../ActionTypes';

const initialState = {
  isLoading: false,
  data: {},
  error: null,
  bookmarkCount: 0,
  likeCount: 0,
};

const SessionDetail = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SESSION_DETAIL_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
        bookmarkCount: 0,
        likeCount: 0,
      };
    case types.GET_SESSION_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: { ...action.data },
      };
    case types.GET_SESSION_DETAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case types.UPDATE_BOOKMARK_COUNT:
      return {
        ...state,
        bookmarkCount: action.bookmarkCount,
      };
    case types.UPDATE_LIKE_COUNT:
      return {
        ...state,
        likeCount: action.likeCount,
      };
    default:
      return state;
  }
};

export default SessionDetail;
