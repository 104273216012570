import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import moment from 'moment-timezone';
import * as types from '../ActionTypes';
import { Get } from '../../utils/Requester';
import { markEventVersion, diffTime } from '../../utils/Functions';
import { getBookmarkCount } from './UserBookmarkSaga';
import { getMetaLive } from './MetaSaga';
import { KeynoteData } from '../../sample/KeynoteData';

function* getKeynote() {
  try {
    yield call(getMetaLive);

    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield delay(3000);
      yield put({
        type: types.GET_KEYNOTE_SUCCESS,
        data: { keynoteData: KeynoteData, keynoteCount: 10000 },
      });
      return;
    }

    let keynoteData = yield select((state) => state.sessionList.keynoteData);

    if (keynoteData === null || Object.keys(keynoteData).length === 0) {
      const { data, headers, error } = yield call(Get, {
        url: `/api/event/keynote`,
      });

      if (error || typeof data === 'string') {
        console.error('KeynoteDataSaga', error || data);
        yield put({
          type: types.GET_KEYNOTE_ERROR,
          error: error || 'UNKNOWN_ERROR',
        });
        return;
      }
      if (headers['mark-event-version']) {
        markEventVersion(headers['mark-event-version']);
      }
      // const keynoteData =data.keynote[0]
      keynoteData = data.keynote[0];
    }
    const gapTime = yield select((state) => state.meta.gapTime);
    const { phase, keynote } = yield select((state) => state.meta.data);
    const d =
      gapTime === 0 ? moment.utc().valueOf() : moment.utc().valueOf() - gapTime;

    let diffValue = '';
    const currentTime = moment
      .tz(d, 'America/Los_Angeles')
      .format('YYYY-MM-DD[T]HH:mm:ss');
    if (
      phase === 'phase3' &&
      keynote?.liveBadgeStart &&
      keynote?.liveBadgeEnd
    ) {
      const { status, diff } = diffTime(
        currentTime,
        keynote?.liveBadgeStart,
        keynote?.liveBadgeEnd
      );
      keynoteData.status = status;
      diffValue = diff;
    }
    const result = {
      keynoteData: keynoteData && keynoteData.length !== 0 ? keynoteData : null,
      keynoteCount: 0,
    };

    const keynoteId = keynoteData ? keynoteData.PresentationID : null;

    if (keynoteId) {
      const keynoteBookmarkCount = yield call(getBookmarkCount, {
        sessionId: keynoteId,
      });

      result.keynoteCount = keynoteBookmarkCount;
    }

    yield put({ type: types.GET_KEYNOTE_SUCCESS, data: { ...result } });

    if (diffValue && diffValue !== -1) {
      yield delay(diffValue);
      yield call(getKeynote);
    }
  } catch (error) {
    if (error) {
      console.error('KeynoteDataSaga', 'error', error);
      yield put({ type: types.GET_KEYNOTE_ERROR, error });
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_KEYNOTE_REQUEST, getKeynote);
}
