import React from 'react';
import { Link } from 'react-router-dom';
import { useActivityData } from '../../hooks/useActivityData';
import { useActivityProfile } from '../../hooks/useActivityProfile';
import ActivityMyPoint from '../ActivityMyPoint';
import MyProfile from '../Myprofile';
import {
  ActivityMainContainer,
  ActivityMainGate,
  ActivityMainHero,
  ActivityMainHeroBg,
  ActivityMainInfo,
} from './ActivityMainElements';

const ActivityMain = () => {
  const { points } = useActivityData();
  const { name, email, profileImage } = useActivityProfile();

  return (
    <ActivityMainContainer>
      <ActivityMainHero>
        <ActivityMainHeroBg>
          <span />
          <span />
          <span />
          <span />
          <img
            src={`${process.env.PUBLIC_URL}/images/activity-hero-bg-breadman.png`}
            alt="SDC24 bread man"
          />
        </ActivityMainHeroBg>
        <div className="text-area">
          <p>Let’s Play with</p>
          <img
            src={`${process.env.PUBLIC_URL}/images/txt-sdc24-white.svg`}
            alt="SDC24"
          />
        </div>
      </ActivityMainHero>
      <ActivityMainInfo>
        <MyProfile name={name} mail={email} url={profileImage} />
        <ActivityMyPoint point={points} isLink />
      </ActivityMainInfo>
      <ActivityMainGate>
        <h3>SDC24 Activities</h3>
        <Link to="/activity/bingo">
          <p>SDC24 Bingo</p>
          <div className="more-arrow">
            <img
              src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
              alt="ic"
            />
          </div>
        </Link>
        <Link to="/activity/codelab">
          <p>SDC24 Code Lab</p>
          <div className="more-arrow">
            <img
              src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
              alt="ic"
            />
          </div>
        </Link>
      </ActivityMainGate>
    </ActivityMainContainer>
  );
};

export default ActivityMain;
