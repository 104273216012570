import * as types from '../ActionTypes';

const initialState = {
  isLoading: false,
  error: null,
  unRegisterLoading: false,
  registerState: null,
  unRegisterError: null,
};

const myPageData = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MY_PAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        data: null,
        likeData: null,
      };
    case types.GET_MY_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: { ...action.data },
        likeData: [...action.likeData],
      };
    case types.GET_MY_PAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case types.UPDATE_LIKE_LIST: {
      return {
        ...state,
        likeData: [...action.data],
      };
    }
    case types.ONLINE_UNREGISTER_REQUEST:
      return {
        ...state,
        unRegisterLoading: true,
        registerState: null,
        unRegisterError: null,
      };
    case types.ONLINE_UNREGISTER_SUCCESS:
      return {
        ...state,
        registerState: 'unregister',
        unRegisterLoading: false,
      };
    case types.ONLINE_UNREGISTER_ERROR:
      return {
        ...state,
        unRegisterLoading: false,
        unRegisterError: action.error,
      };
    case types.ONLINE_UNREGISTER_RESET:
      return {
        ...state,
        registerState: null,
        unRegisterLoading: false,
      };
    default:
      return state;
  }
};

export default myPageData;
