import styled from 'styled-components';

export const SectionContainer = styled.div`
  width: 100%;
  & + & {
    margin-top: 100px;
  }
  @media screen and (max-width: 768px) {
    & + & {
      margin-top: 60px;
    }
  }
`;

export const SectionTitle = styled.div`
  margin-bottom: 44px;
  font-family: 'samsungSharpSans-bold';
  font-size: 32px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #000;

  &.isMegaSession {
    font-size: 24px;
    line-height: 32px;
  }
  &.relatedSession {
    margin-bottom: 32px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 28px;

    &.relatedSession {
      margin-bottom: 20px;
    }

    &.isMegaSession {
      font-size: 18px;
      line-height: 24px;
    }

    &.bookmark {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
`;

export const Sectioncont = styled.div``;
