import React from 'react';
import { Link } from 'react-router-dom';
import {
  FooterContent,
  FooterInner,
  FooterTop,
  FooterLogoBox,
  FooterSns,
  FooterBottom,
  FooterFamily,
  FooterCopy,
} from './FooterElement';

const Footer = ({ cookieCountry }) => {
  return (
    <FooterContent>
      <FooterInner>
        <FooterTop>
          <FooterLogoBox>
            Samsung Developer Conference {new Date().getFullYear()}
          </FooterLogoBox>
          <FooterSns>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/samsungdev"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Facebook.svg`}
                    alt="facebook"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://instagram.com/samsung_dev"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Instagram.svg`}
                    alt="instagram"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/samsungdevelopers"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Linkedin.svg`}
                    alt="linkedin"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/samsung_dev"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/sdc24-Twitter.svg`}
                    alt="twitter"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/samsungdevelopers"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Youtube.svg`}
                    alt="youtube"
                  />
                </a>
              </li>
              {/* <li>
                <a
                  href="https://samsungdev.buzzsprout.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Broadcast.svg`}
                    alt="broadcast"
                  />
                </a>
              </li> */}
            </ul>
          </FooterSns>
        </FooterTop>
        <FooterBottom>
          <FooterFamily>
            <ul>
              <li>
                <a
                  href="https://developer.samsung.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Samsung Developer
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/FooterFamilyLink.svg`}
                      alt="link"
                    />
                  </span>
                </a>
              </li>
              <li>
                <a href="/terms">Terms</a>
              </li>
              <li>
                {/* <Link to="/privacy">Privacy policy</Link> */}
                <a href="/privacy">Privacy Policy</a>
              </li>
              {cookieCountry && (
                <li>
                  {/* <Link to="/cookie/lang=eu">Cookie Policy</Link> */}
                  <a href="/cookie">Cookie Policy</a>
                </li>
              )}
            </ul>
          </FooterFamily>
          <FooterCopy>
            Copyright © {new Date().getFullYear()} SAMSUNG. All rights reserved.
          </FooterCopy>
        </FooterBottom>
      </FooterInner>
    </FooterContent>
  );
};

export default Footer;
