import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { decodeQRId, getQR } from '.';
import { ActivityMsgContContainer } from '../../components/ActivityMsgCont/ActivityMsgContElements';
import Loading from '../../components/Loading';
import withRouter from '../../components/Router/withRouter';
import { Post } from '../../utils/Requester';

/**
 * @typedef {(
 *  "not-enough-points" | "already-claimed" | "internal-error" | "not-found"
 * )} ActivityErrorType
 */

/**
 * @typedef {{
 *  type: ActivityErrorType;
 *  message: string;
 * }} ActivityError
 */

/** @type {ActivityError} */
const GENERIC_ERROR = {
  type: 'internal-error',
  message: 'An error occurred while trying to claim points.',
};

const ClaimQR = (props) => {
  const { params } = props;
  const { qrId = '' } = params;

  const navigate = useNavigate();
  const decodedQRId = useMemo(() => decodeQRId(qrId), [qrId]);

  const claimPoints = async () => {
    if (!decodedQRId) return;
    let activityType = 'Bingo';
    /** @type {ActivityError | null} */
    let error = null;

    try {
      const result = await getQR(decodedQRId);

      if (!result) {
        error = GENERIC_ERROR;
        return;
      }

      activityType = result.activity.type;

      const claimResponse = await Post({ url: `/api/sdc/qr/${decodedQRId}` });

      if ('error' in claimResponse) {
        const { type, message } = claimResponse.error;
        console.error(message);

        error = {
          type,
          message,
        };
      }
    } catch (err) {
      error = GENERIC_ERROR;
      console.error(err);
    } finally {
      if (activityType === 'Bingo') {
        navigate('/activity/bingo-complete');
      } else if (activityType === 'Code Lab') {
        navigate('/activity/codelab-complete');
      } else if (activityType === 'Prize') {
        if (error != null) {
          if (error.type === 'already-claimed') {
            navigate('/activity/overlap-error');
          } else {
            navigate('/activity/point-error');
          }
        } else {
          navigate('/activity/pickup-complete');
        }
      } else if (activityType === 'Raffle') {
        if (error != null) {
          navigate('/activity/ticket-error');
        } else {
          navigate('/activity/ticket-complete');
        }
      } else if (error != null) {
        navigate('/activity/scan-error');
      } else {
        navigate('/activity');
      }
    }
  };

  useEffect(() => {
    claimPoints();
  }, [decodedQRId]);

  return (
    <ActivityMsgContContainer>
      <Loading />
    </ActivityMsgContContainer>
  );
};

export default withRouter(ClaimQR);
