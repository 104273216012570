import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MypageCont from '../../components/MypageCont';
import Loading from '../../components/Loading';
import { GET_MY_PAGE_REQUEST } from '../../redux/ActionTypes';
import withRouter from '../../components/Router/withRouter';

const Mypage = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_MY_PAGE_REQUEST });
  }, [dispatch]);

  const {
    isLoading,
    data: userData,
    likeData,
    error,
  } = useSelector((state) => state.myPageData);

  useEffect(() => {
    const { navigate } = props;
    if (error && navigate) {
      navigate('/internal_server_error');
    }
  }, [error]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <MypageCont userData={userData && userData} likeData={likeData} />
    </>
  );
};

export default withRouter(Mypage);
