import styled from 'styled-components';

export const PopupContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

export const PopupDim = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const ContentWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  padding: 40px 0 40px 40px;
  min-width: 720px;
  max-width: 720px;
  max-height: 700px;
  height: 700px;
  border-radius: 24px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  font-size: 0;
  box-sizing: border-box;

  &.more-content {
    padding-bottom: 0;

    .border-box {
      padding-bottom: 40px;
    }
  }

  @media screen and (max-width: 768px) {
    height: 600px;
    max-height: 600px;
    min-width: calc(100% - 48px);
    padding: 32px 0px 0px 24px;
    border-radius: 16px;

    &.more-content {
      .border-box {
        padding-bottom: 0;
      }
    }
  }
`;

export const PopupInner = styled.div`
  width: 100%;
  padding-right: 40px;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  @media screen and (max-width: 768px) {
    padding-right: 24px;
  }
`;

export const PopupTitle = styled.div`
  margin-bottom: 12px;
  font-family: 'SamsungOne-700';
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: -0.24px;
  color: #000;
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    font-size: 20px;
    letter-spacing: -0.2px;
    line-height: 1.3;
  }
`;

export const PopupDesc = styled.div`
  .text {
    margin-bottom: 32px;
    font-family: 'SamsungOne-400';
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.24px;
    color: #535353;
    white-space: pre-wrap;
  }

  &.is-more {
    height: 148px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  &.is-appended {
    .text {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .text {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 1.42;
      letter-spacing: -0.2px;
    }

    &.is-more {
      height: 100px;
      -webkit-line-clamp: 5;
    }
  }
`;

export const PopupCont = styled.div`
  &.border-box {
    border-top: 1px solid #efefef;
  }

  @media screen and (max-width: 768px) {
  }
`;

export const InnerContent = styled.div`
  height: auto;

  @media screen and (max-width: 768px) {
    max-height: none;
  }
`;

export const PopupClose = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  background-image: url(${`${process.env.PUBLIC_URL}/images/ico-close.png`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 768px) {
    right: 14px;
    top: 14px;
    width: 20px;
    height: 20px;
  }
`;

export const LinkContWrap = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: none;

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

export const LinkContent = styled.div`
  width: calc(50% - 12px);
  padding: 20px 0;
  border-bottom: 1px solid #efefef;

  &:nth-child(5) {
    padding-bottom: 0;
    border-bottom: none;
  }
  :nth-child(6) {
    padding-bottom: 0;
    border-bottom: none;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 16px 0;

    &:nth-child(1),
    :nth-child(2) {
      padding-top: 16px;
    }
    &:nth-child(5) {
      padding-bottom: 16px;
      border-bottom: 1px solid #efefef;
    }
    &:nth-child(6) {
      padding-bottom: 32px;
      border-bottom: none;
    }
  }
`;

export const LinkItemLink = styled.a`
  display: inline-block;
  position: relative;
  color: #2088fd;
  font-family: 'SamsungOne-700';
  font-size: 14px;
  line-height: 1.42;
  margin-top: 4px;
  padding-right: 18px;

  &:after {
    content: '';
    position: absolute;
    top: 2.5px;
    right: 0;
    width: 14px !important;
    height: 14px !important;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-blue.svg`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &:hover {
    color: #0f42cf;
    text-decoration: underline;

    &:after {
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-navy.svg`});
    }
  }
  @media screen and (max-width: 768px) {
  }
`;

export const LinkTitle = styled.div`
  color: #535353;
  font-family: 'SamsungOne-700';
  font-size: 20px;
  line-height: 1.3;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 1.42;
  }
`;

export const LinkDesc = styled.div`
  color: #535353;
  font-family: 'SamsungOne-400';
  font-size: 14px;
  line-height: 1.42;
  margin-top: 4px;

  @media screen and (max-width: 768px) {
  }
`;

export const ListContent = styled.div`
  width: 100%;
  height: auto;
  text-align: left;

  dl {
    padding: 36px 0;
    border-top: 1px solid #efefef;

    &:last-child {
      padding-bottom: 0;
    }

    dt {
      color: #535353;
      font-family: 'SamsungOne-700';
      font-size: 20px;
      line-height: 1.3;
    }

    dd {
      color: #535353;
      font-family: 'SamsungOne-400';
      font-size: 14px;
      line-height: 1.42;
      margin-top: 4px;
    }
  }

  @media screen and (max-width: 768px) {
    height: 148px;

    dl {
      padding: 16px 0;

      &:last-child {
        padding-bottom: 32px;
      }

      dt {
        font-size: 14px;
        line-height: 1.42;
      }
      dt + dt {
        margin-top: 12px;
      }
    }
  }
`;

export const PopupDetailMoreWrap = styled.div`
  display: none;
  margin-top: 12px;
  margin-bottom: 40px;

  &.block {
    display: block;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

export const PopupDetailMore = styled.button`
  position: relative;
  order: 4;
  margin: 0 auto;
  padding-right: 26px;
  font-family: 'SamsungOne-700';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-more.svg`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
  }
  &.is-appended:after {
    transform: translate(0, -50%) rotate(180deg);
  }
  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.42;
    padding-right: 20px;
    margin: 0;

    &:after {
      width: 16px;
      height: 16px;
    }
  }
`;
