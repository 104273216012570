import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  MapContainer,
  MapDesc,
  MapImage,
  MapInner,
  MapLink,
  MapText,
  MapTitle,
  MapWrap,
} from './EventJoinElement';
import Button from '../Button';
import withRouter from '../Router/withRouter';
import ButtonLink from '../ButtonLink';

const EventJoin = withRouter(({ className, navigate, hasArrow }) => {
  const phase = useSelector((state) => state.meta.data.phase);
  const locationObj = useLocation();
  return (
    <MapContainer className={className}>
      <MapInner>
        <MapWrap>
          <MapImage>
            <img
              className="diagram"
              src={`${process.env.PUBLIC_URL}/images/bg-event-banner-join-2.svg`}
              alt="map"
            />
            <img
              className="breadman"
              src={`${process.env.PUBLIC_URL}/images/bg-event-banner-join-1.png`}
              alt="map"
            />
          </MapImage>
          <MapText>
            <MapTitle>Activities</MapTitle>
            <MapDesc>
              Play SDC24 Bingo, complete missions, and tackle Code Lab tasks to
              unlock a variety of exciting prizes.
            </MapDesc>
            {locationObj.pathname !== '/event/activity' && (
              <MapLink>
                <div
                  className="btn-arrow"
                  onClick={() => navigate('/event/activity')}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                    alt="ico"
                  />
                </div>
              </MapLink>
            )}
          </MapText>
          {/* sdc24 - phase3.5 button */}
          {phase === 'phase3' && (
            <div className="btn-link">
              <ButtonLink color="black" size="medium" url="/activity">
                Play Now
              </ButtonLink>
            </div>
          )}
        </MapWrap>
      </MapInner>
    </MapContainer>
  );
});

export default EventJoin;
