import styled from 'styled-components';

export const Support = styled.div`
  position: relative;

  .react-tabs__tab-list {
    display: flex;
    align-items: flex-end;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
  }

  .react-tabs__tab {
    width: 50%;
    padding: 20px 0;
    font-family: 'samsungsharpsans-Medium';
    font-size: 18px;
    line-height: 1.44;
    color: #9e9e9e;
    border-bottom: 1px solid #ddd;
    text-align: center;
    cursor: pointer;
    &--selected {
      font-family: 'samsungsharpsans-bold';
      color: #2088fd;
      border-bottom: 2px solid #2088fd;
    }
  }

  @media screen and (max-width: 768px) {
    .react-tabs__tab-list {
      padding: 0 24px;
    }
    .react-tabs__tab {
      padding: 14px 0 13px;
      font-size: 16px;
      &--selected {
        color: #2088fd;
        border-bottom: 2px solid #2088fd;
      }
    }
  }
`;

export const SupportWrap = styled.div`
  position: relative;
  // padding-top: 60px;
  padding-top: 32px;
  @media screen and (max-width: 768px) {
    padding-top: 20px;
  }
`;

export const SupportInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;

  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const SupportTitle = styled.div`
  max-width: 1200px;
  margin: 100px auto 0;
  padding: 0 40px;
  font-size: 32px;
  font-family: 'samsungsharpsans-bold';

  @media screen and (max-width: 768px) {
    margin: 60px 0 0 0;
    padding: 0 24px;
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const SupportBanner = styled.div`
  .moreLink {
    display: none;
  }
`;

export const SupportBannerTitle = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 40px 0;
  font-size: 32px;
  font-family: 'samsungsharpsans-bold';

  @media screen and (max-width: 768px) {
    padding: 40px 20px 0;
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 24px;
  }
`;

export const SupportBannerInner = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px 40px 60px;

  .banner-btn {
    position: absolute;
    .aLink {
      padding-top: 1em;
      padding-bottom: 1em;
      display: block;
      text-align: center;
      min-width: 0 !important;
      width: fit-content;
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 960px) {
    .banner-btn {
      position: relative;
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
    margin: 24px auto 0;
    padding: 0 24px 0;
  }
`;

export const SupportItem = styled.div`
  & + & {
    margin-top: 60px;
  }
  &:last-of-type {
    margin-bottom: 120px;
  }
  @media screen and (max-width: 768px) {
    & + & {
      margin-top: 40px;
    }

    &:last-of-type {
      margin-bottom: 60px;
    }
  }
`;

export const SupportItemTitle = styled.div`
  margin-bottom: 24px;
  font-family: 'SamsungOne-700';
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.38;
  }
`;

export const SectionWrap = styled.div`
  padding: 100px 0 120px 0;
  background-color: #f6f6f6;

  @media (max-width: 768px) {
    padding: 60px 0 60px 0;
  }
`;

export const SectionInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  font-size: 48px;
  font-family: 'samsungsharpsans-bold';

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    padding: 0 24px;
    font-size: 24px;
    line-height: 1.4;
  }
`;

export const SectionCont = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin-right: -24px;

  .no-video {
    .card_img.has-dim:before {
      background-color: transparent;
    }

    .video-icon {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
    gap: 0;
  }
`;
