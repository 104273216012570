import styled from 'styled-components';

export const ActivityMainContainer = styled.div`
  background-color: #fff;
  margin-top: 48px;
`;

export const ActivityMainHero = styled.div`
  position: relative;
  height: 342px;

  .text-area {
    position: relative;
    padding-top: 44px;
    text-align: center;
    p {
      font-family: 'samsungsharpsans-bold';
      font-size: 32px;
      line-height: 42px;
      color: #fff;
    }
    p + img {
      display: block;
      width: 103px;
      margin: 2px auto 0;
    }
  }
`;

export const ActivityMainHeroBg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0f42cf;
  span,
  img {
    display: block;
    position: absolute;
    background-repeat: no-repeat;
  }
  span:nth-of-type(1) {
    left: 0;
    top: 120px;
    width: 55px;
    height: 108px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-hero-bg-ico-1.svg`});
  }
  span:nth-of-type(2) {
    right: 70px;
    top: 0;
    width: 48px;
    height: 42px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-hero-bg-ico-2.svg`});
  }
  span:nth-of-type(3) {
    right: 0;
    top: 0;
    width: 58px;
    height: 51px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-hero-bg-ico-3.svg`});
  }
  span:nth-of-type(4) {
    right: 0;
    top: 247px;
    width: 69px;
    height: 50px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-hero-bg-ico-4-1.svg`});
  }
  img {
    left: 50%;
    top: 59px;
    width: 309px;
    height: 283px;
    transform: translateX(-50%);
    margin-left: -3px;
  }
`;

export const ActivityMainInfo = styled.div`
  position: relative;
  padding-bottom: 36px;
  .profile {
    margin-top: -56px;
    padding-top: 0;
    > div > div:first-of-type {
      display: none;
    }
  }
`;

export const ActivityMainGate = styled.div`
  border-top: 10px solid #f6f6f6;
  padding: 40px 24px 36px;
  background: #fff;

  h3 {
    font-family: 'samsungsharpsans-bold';
    font-size: 20px;
    line-height: 28px;
  }
  a {
    display: block;
    position: relative;
    margin-top: 20px;
    padding: 20px;
    border-radius: 12px;
    height: 180px;
    box-sizing: border-box;
    color: #fff;
    overflow: hidden;

    p {
      font-family: 'SamsungOne-700';
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
    }
    p + p {
      margin-top: 8px;
      font-family: 'SamsungOne-700';
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      opacity: 0.8;
    }

    .more-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 20px;
      bottom: 20px;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.2);
    }

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right bottom;
    }
    &:first-of-type {
      background-color: #2088fd;
      &:before {
        width: 181px;
        height: 124px;
        background-image: url(${`${process.env.PUBLIC_URL}/images/activity-main-gate-img-1-2.png`});
      }
    }
    &:last-of-type {
      background-color: #ca6ceb;
      &:before {
        width: 185px;
        height: 116px;
        background-image: url(${`${process.env.PUBLIC_URL}/images/activity-main-gate-img-2-2.png`});
      }
    }
  }
`;
