import React from 'react';
import { Link } from 'react-router-dom';
import {
  DetailHeroContainer,
  DetailHeroInner,
  DetailHeroText,
  DetailHeroTitle,
  DetailHeroSamsung,
  DetailHeroBg,
} from './DetailHeroElements';

const DetailHero = ({
  bgPc,
  bgMo,
  bgColor,
  title,
  desc,
  path,
  depth,
  depth2,
  is2Depth,
  bgPc1,
  bgPc2,
  bgMo1,
  bgMo2,
  type,
}) => {
  return (
    <DetailHeroContainer bgColor={bgColor} bgPc={bgPc} bgMo={bgMo}>
      <DetailHeroBg bgPc1={bgPc1} bgPc2={bgPc2} bgMo1={bgMo1} bgMo2={bgMo2}>
        <div className={`breadman ${type}`} />
        <div className={`diagram ${type}`} />
      </DetailHeroBg>
      <DetailHeroInner>
        <DetailHeroText>
          <DetailHeroTitle>{title}</DetailHeroTitle>
          {is2Depth ? (
            <DetailHeroSamsung className="is-2depth">
              <Link to="/">Home</Link>
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-breadcrumb.svg`}
                alt="breadcrumb"
              />
              <Link to="/sessions">Session</Link>
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-breadcrumb.svg`}
                alt="breadcrumb"
              />
              <Link to="#" className="current">
                {depth2}
              </Link>
            </DetailHeroSamsung>
          ) : (
            <DetailHeroSamsung>
              <Link to="/">Home</Link>
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-breadcrumb.svg`}
                alt="breadcrumb"
              />
              <Link to="#" className="current">
                {path}
              </Link>
            </DetailHeroSamsung>
          )}
        </DetailHeroText>
      </DetailHeroInner>
    </DetailHeroContainer>
  );
};

export default DetailHero;
