import React from 'react';
import ActivityHeader from '../../components/ActivityHeader';
import ActivityCodeLabMapContent from '../../components/ActivityCodeLabMapContent';

const ActivityCodeLabMap = () => {
  return (
    <>
      <ActivityHeader isDropdown />
      <ActivityCodeLabMapContent />
    </>
  );
};

export default ActivityCodeLabMap;
