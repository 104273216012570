import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { Get } from '../../utils/Requester';
import * as types from '../ActionTypes';
import { CodeLabData } from '../../sample/CodeLabData';

export function* getCodelabData() {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({ type: types.GET_CODELAB_SUCCESS, data: CodeLabData });
      return;
    }
    const { data, error } = yield call(Get, {
      url: '/api/event/codelab',
    });

    if (error || typeof data === 'string') {
      console.log('error', error);
      yield put({ type: types.GET_CODELAB_ERROR, error });
      return;
    }

    let checkedItems = [];
    const filterVal = ['SmartThings', 'Automotive', 'Health', 'Samsung Wallet'];

    // get filter item
    if (window.location.search) {
      const query = new URLSearchParams(window.location.search).getAll(
        'filter'
      );

      const searchValue = query[0].split(',');
      if (searchValue.length === filterVal.length) {
        const sortSearch = searchValue.slice().sort();
        const sortFilter = filterVal.slice().sort();
        if (JSON.stringify(sortSearch) === JSON.stringify(sortFilter)) {
          checkedItems = ['All'];
        } else {
          checkedItems = checkedItems.concat(searchValue);
        }
      } else {
        checkedItems = checkedItems.concat(searchValue);
      }
    }

    yield put({
      type: types.GET_CODELAB_SUCCESS,
      data: data.codelab,
      checkedItems,
    });
  } catch (error) {
    console.log('error', error);
    yield put({ type: types.GET_CODELAB_ERROR, error });
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_CODELAB_REQUEST, getCodelabData);
}
