/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import {
  TechSquareCard,
  TechSquareCardImg,
  TechSquareCardText,
  VideoIcon,
  LoadingCard,
  LoadingCardInner,
  LoadingCardTop,
  LoadingCardTopInner,
  LoadingCardIcon,
  LoadingCardBottom,
  EventTechSquarePopup,
  EventTechSquarePopupInner,
  EventTechSquarePopupCon,
  EventTechSquarePopupInfo,
  EventTechSquarePopupSlide,
  LinkBox,
} from './EventTechCardElement';
import './style.scss';

function SlideNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

function SlidePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

// images : 이미지 URL 여러개인 경우
// image : 이미지 URL 하나인 경우
// title : 제목
// desc : 내용
// slide : slide 인 경우
// video : 비디오 썸네일이 있는경우
// listYn : 리스트 페이지인 경우
// loading : 로딩 유무
// videoUrl : 모바일 팝업에서 사용하는 유투브링크
const EventTechSquareCard = forwardRef(
  (
    {
      images,
      image,
      title,
      subtitle,
      desc,
      slide,
      video,
      listYn,
      loading,
      videoUrl,
      link,
      hideCard,
    },
    ref
  ) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
      setIsPopupOpen(true);
    };

    const closePopup = () => {
      setIsPopupOpen(false);
    };

    const handleDimClick = (e) => {
      if (e.target === e.currentTarget) {
        setIsPopupOpen(false);
      }
    };

    useEffect(() => {
      if (isPopupOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }

      return () => {
        document.body.style.overflow = 'auto';
      };
    }, [isPopupOpen]);

    // Slide setting
    const settings = {
      dots: true,
      speed: 800,
      nextArrow: <SlideNextArrow />,
      prevArrow: <SlidePrevArrow />,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    };

    return (
      <>
        <TechSquareCard
          ref={ref}
          className={`Card ${loading ? 'loading' : ''} ${
            !loading && hideCard ? 'none' : ''
          }`}
          listYn={listYn}
          border
        >
          {loading ? (
            <LoadingCard>
              <LoadingCardInner>
                <LoadingCardTop>
                  <LoadingCardTopInner>
                    <LoadingCardIcon>
                      <span className="dot" />
                      <span className="dot" />
                      <span className="dot" />
                    </LoadingCardIcon>
                  </LoadingCardTopInner>
                </LoadingCardTop>
                <LoadingCardBottom>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </LoadingCardBottom>
              </LoadingCardInner>
            </LoadingCard>
          ) : (
            <>
              {/* 이미지 클릭 시 팝업 열기 */}
              <div>
                {!slide ? (
                  <TechSquareCardImg
                    className={video ? 'card_img has-dim' : 'card_img'}
                    onClick={openPopup}
                  >
                    {video ? <VideoIcon /> : null}
                    <img src={image} alt={desc} />
                  </TechSquareCardImg>
                ) : (
                  <TechSquareCardImg
                    className={`TechSquareSlide ${
                      video ? 'card_img' : 'card_img'
                    }`}
                  >
                    <Slider {...settings}>
                      {images.map((imgSource, imageIndex) => (
                        <div
                          key={imageIndex}
                          className={
                            video && imageIndex < videoUrl.length
                              ? 'first-video-el'
                              : ''
                          }
                          onClick={openPopup}
                        >
                          {video && imageIndex < videoUrl.length ? (
                            <>
                              <div className="video-thumbnail">
                                <img src={imgSource} alt={desc} />
                                <VideoIcon />
                              </div>
                            </>
                          ) : (
                            <div className="image-thumbnail">
                              <img src={imgSource} alt={desc} />
                            </div>
                          )}
                        </div>
                      ))}
                    </Slider>
                  </TechSquareCardImg>
                )}
              </div>

              <TechSquareCardText
                onClick={(event) => {
                  // openPopup
                  openPopup();
                }}
              >
                <div className="title">{title}</div>
                {subtitle ? (
                  <>
                    <p className="subtitle">{subtitle}</p>
                    <LinkBox
                      className="link"
                      href={link}
                      target="_blank"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      Link
                    </LinkBox>
                  </>
                ) : (
                  <p className="desc">{desc}</p>
                )}
              </TechSquareCardText>
            </>
          )}
        </TechSquareCard>
        {isPopupOpen && (
          <EventTechSquarePopup>
            <EventTechSquarePopupInner onClick={handleDimClick}>
              {slide ? (
                // Slide인 경우
                <>
                  <div className="scroll">
                    <EventTechSquarePopupCon
                      className={link ? 'has-link-popup' : ''}
                    >
                      <EventTechSquarePopupSlide className="TechSquareSlide is-popup">
                        <Slider {...settings}>
                          {images.map((imgSource, imageIndex) => (
                            <div
                              key={imageIndex}
                              className={
                                video && imageIndex < videoUrl.length
                                  ? 'first-video-el'
                                  : ''
                              }
                            >
                              {imageIndex < videoUrl.length &&
                              videoUrl[imageIndex] ? (
                                <>
                                  <div className="video-thumbnail">
                                    <iframe
                                      id="ytplayer"
                                      type="text/html"
                                      src={videoUrl[imageIndex]} // 동영상 URL 사용
                                      title="video"
                                      frameBorder="0"
                                      allowFullScreen
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="image-thumbnail">
                                  <img src={imgSource} alt={desc} />
                                </div>
                              )}
                            </div>
                          ))}
                        </Slider>
                      </EventTechSquarePopupSlide>
                      <EventTechSquarePopupInfo>
                        <div className="title">{title}</div>
                        {subtitle ? (
                          <>
                            <p className="subtitle">{subtitle}</p>
                            <LinkBox
                              className="link"
                              href={link}
                              target="_blank"
                            >
                              Link
                            </LinkBox>
                          </>
                        ) : null}
                        <p
                          className="desc"
                          dangerouslySetInnerHTML={{
                            __html: desc.replace(/\n/g, '<br />'),
                          }}
                        >
                          {/* {desc.split('\n').map((line, i) => (
                            <React.Fragment key={i}>
                              {line}
                              {i < desc.split('\n').length - 1 && <br />}
                            </React.Fragment>
                          ))} */}
                        </p>
                      </EventTechSquarePopupInfo>
                    </EventTechSquarePopupCon>
                    <button className="close-btn" onClick={closePopup} />
                  </div>
                </>
              ) : (
                // Slide가 아닌 경우
                <>
                  <div className="scroll">
                    <EventTechSquarePopupCon
                      className={link ? 'has-link-popup' : ''}
                    >
                      <img className="single-img" src={image} alt={desc} />
                      <EventTechSquarePopupInfo className="single-desc">
                        <div className="title">{title}</div>
                        {subtitle ? (
                          <>
                            <p className="subtitle">{subtitle}</p>
                            <LinkBox
                              className="link"
                              href={link}
                              target="_blank"
                            >
                              Link
                            </LinkBox>
                          </>
                        ) : null}
                        <p className="desc">
                          {desc.split('\n').map((line, i) => (
                            <React.Fragment key={i}>
                              {line}
                              {i < desc.split('\n').length - 1 && <br />}
                            </React.Fragment>
                          ))}
                        </p>
                      </EventTechSquarePopupInfo>
                    </EventTechSquarePopupCon>
                    <button className="close-btn" onClick={closePopup} />
                  </div>
                </>
              )}
            </EventTechSquarePopupInner>
          </EventTechSquarePopup>
        )}
      </>
    );
  }
);

export default EventTechSquareCard;
