import React from 'react';
import ActivityMsgCont from '../../components/ActivityMsgCont';

const ActivityBingoComplete = () => {
  return (
    <>
      <ActivityMsgCont
        type="bingo"
        msgTitle="Bingo Mission Complete!"
        msgDesc="Continue exploring more Bingo missions for a chance to complete a line
          and enter the lucky draw to win exciting prizes!"
        buttonLink="/activity/bingo"
        buttonText="Go to Bingo"
      />
    </>
  );
};

export default ActivityBingoComplete;
