import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import RegisterErrorCont from '../../components/RegisterErrorCont';

const RegistrationClosed = () => {
  return (
    <>
      <DetailContainer noMargin>
        <RegisterErrorCont
          errorTitle="Registration has ended."
          errorDesc="Visit the SDC site to discover the SDC Keynote and Sessions."
        />
      </DetailContainer>
    </>
  );
};

export default RegistrationClosed;
