/* eslint-disable */

import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useActivityQRDetect } from '../../hooks/useActivityQRDetect';
import Arrow from '../Navbar/NavArrow';
import {
  ActivityHeaderContainer,
  ActivityHeaderInner,
  ActivityHeaderRight,
  ActivityMenu,
  ActivityMenuArrow,
  ActivityMenuDropdown,
} from './ActivityHeaderElements';

const ActivityHeader = ({ type, isDropdown }) => {
  const locationObj = useLocation();
  const { qrFileHandler } = useActivityQRDetect();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownHandler = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const dropdownCloseHandler = () => {
    setDropdownOpen(false);
  };

  const navigate = useNavigate();
  const goBack = () => {
    // navigate('/activity');
    navigate(-1);
  };

  return (
    <ActivityHeaderContainer>
      <ActivityHeaderInner>
        <ActivityMenu>
          <Link to="/activity" className="btn-activity">
            Activity
          </Link>
          {isDropdown && (
            <button
              type="button"
              className="btn-dropdown"
              onClick={dropdownHandler}
            >
              {locationObj.pathname.includes('/bingo')
                ? 'Bingo'
                : locationObj.pathname.includes('/codelab')
                ? 'Code Lab'
                : 'Activity'}
              <ActivityMenuArrow open={dropdownOpen}>
                <Arrow fill="#000" opacity="0.8" />
              </ActivityMenuArrow>
              <ActivityMenuDropdown open={dropdownOpen}>
                <div className="inner">
                  <Link
                    to="/activity/bingo"
                    onClick={dropdownCloseHandler}
                    className={
                      locationObj.pathname === '/activity/bingo' ||
                      locationObj.pathname.includes('/bingo')
                        ? 'is-selected'
                        : ''
                    }
                  >
                    Bingo
                  </Link>
                  <Link
                    to="/activity/codelab"
                    onClick={dropdownCloseHandler}
                    className={
                      locationObj.pathname === '/activity/codelab' ||
                      locationObj.pathname.includes('/codelab')
                        ? 'is-selected'
                        : ''
                    }
                  >
                    Code Lab
                  </Link>
                </div>
              </ActivityMenuDropdown>
            </button>
          )}
        </ActivityMenu>
        <ActivityHeaderRight>
          <Link to="/activity/point">
            <img
              src={`${process.env.PUBLIC_URL}/images/activity-header-ico-point${
                locationObj.pathname === '/activity/point' ||
                locationObj.pathname.includes('/point')
                  ? '.on'
                  : ''
              }.svg`}
              alt="point"
            />
          </Link>
          <Link to="/activity/ticket">
            <img
              src={`${
                process.env.PUBLIC_URL
              }/images/activity-header-ico-ticket${
                locationObj.pathname === '/activity/ticket' ||
                locationObj.pathname.includes('/ticket')
                  ? '.on'
                  : ''
              }.svg`}
              alt="ticket"
            />
          </Link>
          {/* <label htmlFor="header-camera">
            <img
              src={`${process.env.PUBLIC_URL}/images/activity-header-ico-qr.svg`}
              alt="qr"
            />
          </label>
          <input
            id="header-camera"
            name="header-camera"
            type="file"
            accept="image/*"
            capture="environment"
            className="hidden"
            onChange={qrFileHandler}
          /> */}
        </ActivityHeaderRight>
      </ActivityHeaderInner>
      {type === 'back' && (
        <ActivityHeaderInner>
          <ActivityMenu>
            <button type="button" className="btn-prev" onClick={goBack}>
              Back
            </button>
          </ActivityMenu>
        </ActivityHeaderInner>
      )}
    </ActivityHeaderContainer>
  );
};

export default ActivityHeader;
