import React from 'react';

const Chevron = (props) => {
  const { className, height, width } = props;
  return (
    // <svg
    //   className={className}
    //   height={height || '24'}
    //   width={width || '24'}
    //   viewBox="0 0 16 10"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     id="Line"
    //     d="M14 8L8 2L2 8"
    //     stroke="#9E9E9E"
    //     strokeWidth="1.6"
    //     strokeLinecap="square"
    //   />
    // </svg>
    <svg
      className={className}
      height={height || '24'}
      width={width || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ic_line_arrow_24px">
        <path
          id="Line"
          d="M18 15L12 9L6 15"
          stroke="#9E9E9E"
          strokeWidth="1.6"
          strokeLinecap="square"
        />
      </g>
    </svg>
  );
};

export default Chevron;
