import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MainHero from '../../components/MainHero';
import MainContent from '../../components/MainContent';
import {
  GET_KEYNOTE_REQUEST,
  GET_MAIN_STATUS_REQUEST,
} from '../../redux/ActionTypes';
import withRouter from '../../components/Router/withRouter';

const Main = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_KEYNOTE_REQUEST });
    dispatch({ type: GET_MAIN_STATUS_REQUEST });
  }, [dispatch]);

  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <MainHero />
      <MainContent />
    </>
  );
};

export default withRouter(Main);
