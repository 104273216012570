import styled from 'styled-components';

export const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: ${(props) =>
    props.color === 'black' ? '1px solid #626262' : '1px solid #C9D2DD'};
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  z-index: 99;
  transition: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  @media (min-width: 769px) {
    background-color: ${(props) =>
      props.color === 'black'
        ? 'rgba(0, 0, 0, 0.2)'
        : 'rgba(255, 255, 255, 0.8)'};
  }
`;

export const NavInner = styled.div`
  position: relative;
  max-width: 1248px;
  margin: 0 auto;
  padding: 24px 24px 4px;
  box-sizing: border-box;
  transition: background-color 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  @media (max-width: 768px) {
    position: relative;
    padding: 20px;
    height: 56px;
    box-sizing: border-box;
    background-color: ${(props) =>
      props.color === 'black'
        ? 'rgba(0, 0, 0, 0.2)'
        : 'rgba(255, 255, 255, 0.8)'};
    z-index: 90;
    &.active {
      background-color: #fff;

      .logo {
        svg {
          .path {
            fill: #000;
          }
        }
      }

      .toggleLine {
        &::before,
        &:after {
          background-color: #000;
        }
      }
    }
  }
`;

export const NavTitle = styled.h1`
  width: 74px;
  height: 20px;

  @media (max-width: 768px) {
    width: 60px;
    height: 16px;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-left: -14px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavLink = styled.div`
  position: relative;
  transition: background-color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

  a,
  button {
    display: inline-block;
    padding: 12px 14px;
    opacity: ${(props) => (props.color === 'black' ? '0.8' : '1')};
    font-family: 'samsungsharpsans-bold';
    font-size: 14px;
    color: ${(props) => (props.color === 'black' ? '#C4C4C4' : '#535353')};
    vertical-align: middle;
    white-space: nowrap;

    &:after {
      content: '';
      position: absolute;
      left: 14px;
      right: 14px;
      bottom: -4px;
      margin: auto;
      height: 2px;
    }
  }
  button {
    position: relative;
    padding: 12px 32px 12px 14px;
  }
  & + & {
    margin-left: 12px;
  }
  &:hover:not(.is-selected, .is-active) {
    > a,
    button {
      color: ${(props) => (props.color === 'black' ? '#fff' : '#000')};
      &:after {
        background-color: ${(props) =>
          props.color === 'black' ? '#fff' : '#000'};
      }
    }
  }
  &.is-selected,
  &.is-active {
    > a,
    button {
      color: #2088fd;
      &:after {
        background-color: #2088fd;
      }
    }
  }
  &.my-page,
  &.prev-sdc,
  &.my-page,
  &.sign-out,
  &.signin {
    > a,
    button {
      font-family: 'samsungsharpsans-bold';
    }
    a:after {
      display: none;
    }
  }

  @media (max-width: 768px) {
    a,
    button {
      padding: 10px 10px;
    }
  }
  @media (max-width: 810px) {
    & + & {
      margin-left: 2px;
    }
  }
`;

export const NavDropdown = styled.div`
  display: ${({ open }) => (open ? 'inline-block' : 'none')};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 150px;
  transition: 0.3s;
  padding-top: 48px;

  .inner {
    min-width: 124px;
    border-radius: 8px;
    border: ${(props) =>
      props.color === 'black' ? '1px solid #626262' : '1px solid #E3E5E9'};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
    background-color: ${(props) =>
      props.color === 'black' ? 'rgba(0, 0, 0, 0.4)' : '#ffffff'};
    text-align: center;
    overflow: hidden;
  }

  a,
  button {
    color: ${(props) => (props.color === 'black' ? '#ffffff' : '#535353')};
  }
`;

export const NavDropdownLink = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  transition: background-color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  a,
  button {
    opacity: 1;
    padding: 10px 14px;
    font-family: 'SamsungOne-500';
    display: inline-block;
    width: 100%;
  }
  &:hover {
    background-color: ${(props) =>
      props.color === 'black' ? 'rgba(255, 255, 255, 0.1)' : '#ECF1F7'};
  }
  &.is-selected {
    a,
    button {
      font-family: 'SamsungOne-700';
      color: #000;
    }

    &:hover {
      a,
      button {
        font-family: 'SamsungOne-700';
      }
    }
  }
`;

export const NavArrow = styled.span`
  position: absolute;
  top: 13px;
  right: 14px;
  width: 14px;
  height: 14px;
  transform: rotate(${({ open }) => (open ? '180deg' : 'none')});
`;

export const NavRight = styled.span`
  position: absolute;
  right: 24px;
  top: 24px;
  display: flex;
  height: 24px;
  > div {
    display: inline-block;
    vertical-align: top;
    padding: 2px 0;
    a,
    button {
      padding: 0;
    }
  }
  &:nth-child(2) {
    margin-right: 10px;
  }
  .sign-out {
    position: relative;
    margin-left: 10px;
    padding-left: 10px;
    > button {
      padding: 0 !important;
    }
    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 1px;
      height: 14px;
      background-color: ${(props) =>
        props.color === 'black' ? '#ffffff' : '#dcdcdc'};
      opacity: ${(props) => (props.color === 'black' ? '0.2 !important' : '1')};
    }
  }

  @media (max-width: 768px) {
    top: 19px;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    > div {
      display: none;
    }
    &:nth-child(2) {
      margin-right: 0;
    }
  }
`;

export const NavButton = styled.button`
  display: none;
  width: 20px;
  height: 20px;
  margin-right: 16px;

  @media (max-width: 768px) {
    display: inline-block;
    position: relative;
    margin-right: 0;

    i,
    i::before,
    i::after {
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: ${(props) =>
        props.color === 'black' ? '#ffffff' : '#000'};
      border-radius: 1px;
      content: '';
      -webkit-transition: -webkit-transform 0.2s ease-in-out;
      transition: -webkit-transform 0.2s ease-in-out;
    }

    i {
      top: 8px;
      &:before {
        top: -7px;
        left: 0;
      }

      &:after {
        bottom: -7px;
        left: 0;
      }
    }

    &.active {
      i {
        background-color: transparent;
      }
      i {
        &::before {
          transform: scale(1);
          top: 50%;
          transform: rotate(45deg) scale(1.1);
        }

        &:after {
          transform: scale(1);
          top: 50%;
          transform: rotate(-45deg) scale(1.1);
        }
      }
    }
  }
`;

export const NavLogin = styled.div`
  position: relative;
`;
