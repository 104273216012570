import styled from 'styled-components';

export const DetailHeroContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.bgColor};
  background-image: url(${(props) =>
    props.bgPc ? `${process.env.PUBLIC_URL}/images/${props.bgPc}` : ''});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
  @media screen and (max-width: 768px) {
    background-image: url(${(props) =>
      props.bgMo ? `${process.env.PUBLIC_URL}/images/${props.bgMo}` : ''});
  }
`;

export const DetailHeroBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .breadman {
    position: absolute;
    left: 1040px;
    bottom: -2px;
    z-index: 1;
    background-image: url(${(props) =>
      `${process.env.PUBLIC_URL}/images/${props.bgPc1}`});
    background-position: center;
    background-repeat: no-repeat;
    width: 573px;
    height: 240px;

    &.tech-square {
      bottom: -10px;
    }
  }
  .diagram {
    position: absolute;
    right: -4px;
    top: -2px;
    bottom: -2px;
    background-image: url(${(props) =>
      `${process.env.PUBLIC_URL}/images/${props.bgPc2}`});
    background-position: center;
    background-repeat: no-repeat;
    width: 456px;
    height: 102%;
  }
  @media (min-width: 769px) and (max-width: 1440px) {
    .breadman {
      left: initial;
      right: -97px;
      bottom: -2px;
      &.session {
        right: -29px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .breadman {
      left: initial;
      right: -36px;
      bottom: -2px;
      width: 240px;
      height: 140px;
      background-image: url(${(props) =>
        `${process.env.PUBLIC_URL}/images/${props.bgMo1}`});
      background-position: center;
      background-size: contain;

      &.session,
      &.speakers {
        right: -20px;
      }

      &.schedule {
        width: 200px;
        height: 120px;
        right: -10px;
        bottom: 0;
      }
    }
    .diagram {
      right: -6px;
      top: -1px;
      bottom: -1px;
      width: 194px;
      height: 102%;
      background-image: url(${(props) =>
        `${process.env.PUBLIC_URL}/images/${props.bgMo2}`});
    }
  }
`;

export const DetailHeroInner = styled.div`
  position: relative;
  height: 240px;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    height: 140px;
    padding: 0 24px;
  }
`;

export const DetailHeroText = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  z-index: 1;
  @media screen and (max-width: 768px) {
    left: 0;
    transform: translate(0, -50%);
    width: calc(100% - 48px);
    margin: 0 24px;
    padding: 0;
  }
`;

export const DetailHeroTitle = styled.div`
  margin-bottom: 12px;
  font-family: 'samsungSharpSans-bold';
  font-size: 54px;
  line-height: 70px;
  letter-spacing: normal;
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  @media screen and (max-width: 768px) {
    margin-bottom: 6px;
    font-size: 24px;
    line-height: 1.3;
  }
`;

export const DetailHeroSamsung = styled.div`
	display: flex;
	align-items: center;
	font-family: 'SamsungOne-700';
	font-size: 16px;
  color: rgba(255,255,255,0.6);
	line-height: 20px
	font-feature-settings: 'clig' off, 'liga' off;
	a {
		color: rgba(255,255,255,0.6);
	}

	.current {
		color: rgba(255,255,255,1);
	}

	img {
		display: block;
		margin: 0 4px;
	}

	&.is-2depth {
		img:first-of-type {

				opacity: 0.6;
		}
	}
  @media screen and (max-width: 768px){
		font-size: 14px;
		line-height: 20px;

		img {
			width: 12px;
			height: 12px;
			margin: 0 2px;
		}
	}
`;
