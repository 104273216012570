import styled from 'styled-components';

export const PopupContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const PopupInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  min-width: 600px;
  max-width: 600px;
  max-height: calc(100vh - 80px);
  padding: 40px;
  border-radius: 24px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  text-align: center;
  font-size: 0;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    min-width: calc(100% - 48px);
    padding: 30px;
    border-radius: 16px;
  }
`;

export const PopupTitle = styled.div`
  margin-bottom: 12px;
  font-family: 'SamsungOne-700';
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.24px;
  text-align: center;
  color: #000;
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    font-size: 20px;
    letter-spacing: -0.2px;
  }
`;

export const PopupCont = styled.div`
  max-height: calc(100vh - 526px);
  text-align: center;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    max-height: none;
  }
`;

export const PopupText = styled.div`
  font-family: 'SamsungOne-400';
  font-size: 20px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #535353;
  a {
    color: #1259aa;
    &:hover {
      color: #2088fd;
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 768px) {
    max-height: 206px;
    overflow-y: auto;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const PopupBottom = styled.div`
  margin-top: 40px;
  text-align: center;
  @media screen and (max-width: 768px) {
    margin-top: 24px;
  }
`;

export const PopupTextBtn = styled.button`
  margin-top: 40px;
  font-family: 'SamsungOne-700';
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  letter-spacing: normal;
  color: #535353;
  text-decoration: underline;
  &:hover {
    color: #000;
  }
  @media screen and (max-width: 768px) {
    margin-top: 12px;
  }
`;

export const PopupButton = styled.div`
  .button {
    display: inline-block;
    min-width: 172px;
    & + .button {
      margin-left: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    .button {
      min-width: 10px;
      width: calc(50% - 4px);

      & + .button {
        margin-left: 8px;
      }
    }
  }
`;
