import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import RegisterErrorCont from '../../components/RegisterErrorCont';

const LimitError = () => {
  return (
    <>
      <DetailContainer noMargin>
        <RegisterErrorCont
          errorTitle={`Sorry, it looks like
          this event is already full.
          `}
          errorDesc="Live sessions fill up fast, try to come early!"
        />
      </DetailContainer>
    </>
  );
};

export default LimitError;
