import html2canvas from 'html2canvas';
import _ from 'lodash';
import React, { useLayoutEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useNavigate } from 'react-router-dom';
import { useActivityData } from '../../hooks/useActivityData';
import Button from '../Button';
import Ticket from '../Ticket';
import {
  DownloadTitle,
  InfoPopuBtn,
  InfoPopup,
  InfoPopupClose,
  InfoPopupInner,
  InfoPopupTitle,
  MyDownloadDetail,
  MyDownloadInner,
  MyDownloadWrap,
  PointContainer,
  PopupDesc,
  ScrollArea,
} from './MyIDownloadElement';

const MyDownload = (props) => {
  const { title, isOnline, userData, isPoint } = props;
  const { points, data } = useActivityData();

  const [showPopup, setShowPopup] = useState(false);

  const activityData = _.filter(data, (e, k) => {
    return !e.point;
  });

  const getCurrntNum = () => {
    return activityData.filter((e) => e.status === 'open').length;
  };

  const getUseNum = () => {
    return activityData
      .filter((e) => e.amount)
      .reduce((acc, curr) => {
        return acc + curr.amount;
      }, 0);
  };

  const handleShowPopup = () => {
    setShowPopup(true);
    document.body.style.overflow = 'hidden';
  };

  const navigate = useNavigate();

  const handleHidePopup = () => {
    setShowPopup(false);
    document.body.style.overflow = 'auto';
  };

  const [ticketData, setTicketData] = useState([]);

  useLayoutEffect(() => {
    if (isOnline) {
      return;
    }

    if (!userData) {
      return;
    }

    if (userData.formData.length === 1) {
      setTicketData(userData.formData);
    } else {
      // both form data > offline data
      setTicketData(userData.formData.filter((x) => x.type === 'offline'));
    }
  }, [userData]);

  const onSaveAs = (uri, fileName) => {
    const a = document.createElement('a');
    a.href = uri;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(uri);
    }, 60000);
    a.remove();
  };

  return (
    <>
      <MyDownloadWrap>
        <MyDownloadInner>
          <MyDownloadDetail>
            <DownloadTitle>{title}</DownloadTitle>
            {isPoint && (
              <PointContainer>
                <div className="point-wrap">
                  <div className="point-title">Point</div>
                  <div className="point-box">
                    <div className="point-text">{points} P</div>
                    <Link to="/activity/point">
                      <img
                        className="point-ico"
                        src={`${process.env.PUBLIC_URL}/images/ico-point.svg`}
                        alt="ico"
                      />
                    </Link>
                  </div>
                </div>
                <div className="point-wrap">
                  <div className="point-title">Bingo Lucky Draw Ticket</div>
                  <div className="point-box">
                    <div className="point-text">
                      {(getCurrntNum() || 0) + (getUseNum() || 0)}
                    </div>
                    <Link to="/activity/ticket">
                      <img
                        className="point-ico"
                        src={`${process.env.PUBLIC_URL}/images/ico-point.svg`}
                        alt="ico"
                      />
                    </Link>
                  </div>
                </div>
              </PointContainer>
            )}
            <Button
              className="download-btn"
              onClick={(e) => {
                e.preventDefault();
                if (isPoint) {
                  navigate('/activity');
                } else {
                  handleShowPopup();
                }
              }}
              color="black"
              size="medium"
            >
              {isPoint ? 'Play Now' : 'Download'}
            </Button>
          </MyDownloadDetail>
        </MyDownloadInner>
      </MyDownloadWrap>

      {showPopup &&
        (isOnline ? (
          <InfoPopup>
            <InfoPopupInner>
              <InfoPopupTitle>Wallpaper</InfoPopupTitle>
              <div className="SaverImageWrap">
                <img
                  className="SaverImg"
                  src={`${process.env.PUBLIC_URL}/images/img-wallpaper.png`}
                  alt="broadcast"
                />
              </div>
              <InfoPopuBtn>
                <Button
                  type="button"
                  color="black"
                  size="medium"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      `${process.env.PUBLIC_URL}/images/img-wallpaper.png`,
                      '_blank'
                    );
                  }}
                >
                  Download Image
                </Button>
              </InfoPopuBtn>
              <InfoPopupClose onClick={handleHidePopup} />
            </InfoPopupInner>
          </InfoPopup>
        ) : (
          <InfoPopup>
            <InfoPopupInner className="scrollInner TicketImageWrap">
              <ScrollArea>
                <InfoPopupTitle className="ticket">
                  Commemorative Ticket
                </InfoPopupTitle>
                <PopupDesc>
                  These tickets are designed to commemorate SDC24 and are not
                  physical tickets that you must bring to participate in the
                  event.
                </PopupDesc>
                <div className="TicketImageWrap">
                  <Ticket
                    registerFirstName={ticketData[0].firstName}
                    registerLastName={ticketData[0].lastName}
                    type={ticketData[0].registrationType}
                    country={ticketData[0].country}
                    company={ticketData[0].company}
                    occupation={ticketData[0].occupation}
                    date="24.10.03"
                    location="San Francisco and Online"
                  />
                </div>
                <InfoPopuBtn>
                  <Button
                    type="button"
                    color="black"
                    size="medium"
                    onClick={(e) => {
                      e.preventDefault();
                      html2canvas(document.getElementById('on-site-ticket'), {
                        logging: false,
                        onclone: (element) => {
                          if (element) {
                            const ids = [
                              element.getElementById('on-site-ticket'),
                              element.getElementById('ticket'),
                            ];
                            ids.forEach((x, idx) => {
                              if (idx === 0) {
                                x.style.borderRadius = 0;
                                x.style.backgroundImage =
                                  'url(/images/commTicket-bg.png)';
                              } else if (idx === 1) {
                                x.style.backgroundImage = isMobile
                                  ? 'url(/images/commTicket_mo.png)'
                                  : 'url(/images/sdc24-commTicket.png)';
                              }
                            });
                          }
                        },
                      }).then((canvas) => {
                        const objURL = canvas.toDataURL('image/*');
                        onSaveAs(objURL, 'ticket.png');
                      });
                    }}
                  >
                    Download Image
                  </Button>
                </InfoPopuBtn>
              </ScrollArea>
              <InfoPopupClose onClick={handleHidePopup} />
            </InfoPopupInner>
          </InfoPopup>
        ))}
    </>
  );
};

export default MyDownload;
