import styled from 'styled-components';

export const Card = styled.div`
  position: relative;
  opacity: ${(props) => (props.listYn ? '0' : '1')};
  transform: ${(props) => (props.listYn ? 'translateY(50px)' : 'none')};
  width: ${(props) => (props.mainslide ? '100%' : 'calc(25% - 24px)')};
  margin: ${(props) => (props.mainslide ? 0 : '12px')};
  display: inline-block;
  text-align: left;
  border-radius: 24px;
  background-color: #f6f6f6;
  font-size: 0;
  border: ${(props) => (props.border ? '1px solid #efefef;' : 'none')};
  box-sizing: border-box;
  transition: 0.3s ease;
  aspect-ratio: 1/1.375;
  overflow: hidden;
  &.is-active {
    opacity: 1;
    transform: translateY(0);
  }
  // &.more-link:active {
  //   background-color: rgba(0, 0, 0, 0.4);
  // }

  @media screen and (min-width: 769px) {
    &:hover:not(.loading) {
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.06);
      .card_img img {
        transform: translateY(10px);
      }
    }

    /* hoverClass 가진 element*/
    &.hover {
      position: relative;
      .more-link {
        svg {
          width: 14px;
        }
      }

      /* hoverClass 가진 element에 hover시*/
      &:hover {
        .cardHover {
          opacity: 1;
        }
        .Text {
          opacity: 0;
        }
        .card_img {
          width: 100px;
          padding-top: 100px;
          bottom: 32px;
          right: 32px;
          border-radius: 50%;
          transition: width 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
            padding-top 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
            bottom 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) 0.05s,
            right 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) 0.05s,
            border-radius 0.05s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        .more-link {
          background-color: ${(props) =>
            props.blBg
              ? 'background: rgba(255, 255, 255, 0.20);'
              : 'rgba(255, 255, 255, 0.2)'};
        }

        .icon-static {
          display: none;
        }
      }

      .Text {
        padding: 28px 32px 0;

        div:first-of-type {
          margin-top: 0;
          margin-bottom: 8px;
        }
        div:last-of-type {
          font-size: 14px;
        }
      }

      /* hover card style */
      .cardHover {
        position: absolute;
        width: calc(100% - 64px);
        height: calc(100% - 56px);
        top: 0;
        left: 0;
        border-radius: 24px;
        padding: 28px 32px;
        opacity: 0;

        .subject {
          font-size: 16px;
        }

        .text {
          margin: 4px 0 16px 0;
        }

        .name {
          font-family: 'SamsungOne-400';
          margin-top: 2px;
          font-size: 16px;
          color: #fff;
          opacity: 0.8;
        }
      }
      /* .hover card style */
    }
  }
  @media screen and (min-width: 820px) {
    /* long name style*/

    .Text {
      div:first-of-type {
        font-size: 22px;
      }
    }
  }
  @media screen and (min-width: 1681px) {
    /* long name style*/

    .Text {
      div:first-of-type {
        font-size: 20px;
      }
    }
  }
  @media screen and (min-width: 1141px) {
    /* long name style*/

    .Text {
      div:first-of-type {
        font-size: 20px;
      }
    }
  }
  @media screen and (min-width: 1742px) {
    /* long name style*/

    .Text {
      div:first-of-type {
        font-size: 24px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .cardHover {
      .text {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: ${(props) => (props.listYn ? 'calc(50% - 10px)' : '100%')};
    margin: ${(props) => (props.listYn ? '5px' : '8px')};
    text-align: center;
    border-radius: 16px;

    &.hover {
      .icon-static {
        display: none;
      }
    }

    .icon-static {
      img {
        width: 7px;
      }
    }

    &.is-slide {
      width: 100%;
      margin: 0;
      .card_img {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .Text {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        // div:first-of-type {
        //   font-size: 14px;
        // }
        div:last-of-type {
          margin-top: 2px;
        }
        .Text-desc {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
`;

export const CardImage = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  padding-top: 104%;
  background-color: #f6f6f6;
  overflow: hidden;
  border-radius: 24px;
  transition: all 0.2s ease-out;
  transform-origin: center center;

  img {
    position: absolute;
    right: 0;
    bottom: 0;
    //transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    transition: 0.3s;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    height: ${(props) => (props.listYn ? '60vw' : '50vw')};
    margin-top: 0;
    background-color: #f6f6f6;
    border-radius: 16px;
    padding-top: 0;
    height: 100%;
    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 91%;
      margin: 0 auto;
      width: auto;
      bottom: 0;
      top: auto;
      border-radius: none;
    }
  }
`;

export const CardInfo = styled.div`
  position: relative;
  display: block;
  padding: 28px 32px 0px;
  word-break: break-word;
  @media screen and (max-width: 1768px) {
    text-align: left;
  }
  @media screen and (max-width: 768px) {
    padding: ${(props) => (props.listYn ? '16px' : '28px')};
    width: calc(100% - 32px);
  }
`;

export const CardTitle = styled.div`
  display: block;
  font-family: 'SamsungOne-700';
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  @media screen and (max-width: 768px) {
    font-size: ${(props) => (props.listYn ? '14px' : '24px')};
    line-height: 32px;
  }
`;

export const CardText = styled.div`
  position: relative;
  overflow: hidden;
  text-overflow: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 6px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  white-space: pre-line;

  @media screen and (max-width: 768px) {
    margin-top: ${(props) => (props.listYn ? '4px' : '2px')};
    font-size: ${(props) => (props.listYn ? '12px' : '1em')};

    &.Text-desc {
      margin-top: 0px;
      margin-bottom: 2px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const CardHover = styled.div`
  position: absolute;
  background-size: cover;
  background-image: ${(props) => `url(${props.hoverBg})`};
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const HoverTitle = styled.div`
  display: block;
  font-family: 'SamsungOne-700';
  font-size: 24px;
  color: #fff;
  white-space: pre-line;
`;
export const HoverSubject = styled.div`
  display: block;
  font-family: 'SamsungOne-400';
  font-size: 14px;
  color: #fff;
  opacity: 0.8;
`;

export const Button = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 32px;
  bottom: 32px;
  width: 48px;
  height: 48px;
  background-color: ${(props) =>
    props.blBg ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)'};
  border-radius: 100%;
  backdrop-filter: blur(9px);
  transition: 0.3s;
  z-index: 4;

  svg {
    display: block;
    width: 18px !important;
    height: 18px !important;
  }

  &:active {
    background-color: ${(props) =>
      props.blBg ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)'};
  }

  @media screen and (max-width: 768px) {
    left: 20px;
    bottom: 20px;
    width: 32px;
    height: 32px;

    .icon-static-img {
      width: 7px;
    }
  }
`;

export const LoadingCard = styled.div`
  border-radius: 24px;
  background-color: #fff;
`;

export const LoadingCardInner = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const LoadingCardTop = styled.div`
  position: relative;
  background-color: #f6f6f6;
  border-radius: 24px;
  &:after {
    content: '';
    display: block;
    padding-bottom: 137.599%;
  }
  @media screen and (max-width: 768px) {
    height: 57.6vw;
    border-radius: 16px;
  }
`;

export const LoadingCardEmptyBox = styled.div`
  position: relative;
  padding: 32px 32px 0;

  div {
    width: 53.555%;
    height: 32px;
    background-color: #efefef;
    border-radius: 4px;

    + div {
      margin-top: 4px;
      width: 100%;
      height: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 16px 16px 0;

    div {
      width: 50%;
      height: 20px;

      + div {
        margin-top: 2px;
        width: 100%;
        height: 16px;
      }
    }
  }
`;

export const LoadingCardTopInner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  transition: 0.3s;
  object-fit: cover;
  border-radius: 14px 14px 0 0;
`;

export const LoadingCardIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    animation-name: blink;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    & + .dot {
      margin-left: 10px;
    }
    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.08;
    }
    25% {
      opacity: 0.14;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.08;
    }
  }

  @media screen and (max-width: 768px) {
    .dot {
      width: 6px;
      height: 6px;
      & + .dot {
        margin-left: 8px;
      }
    }
  }
`;

export const LoadingCardBottom = styled.div`
  width: 100%;
  padding: 24px;
  background-color: #f6f6f6;
  border-radius: 0 0 14px 14px;
  box-sizing: border-box;
  div {
    width: 100%;
    height: 26px;
    border-radius: 4px;
    background-color: #f6f6f6;
    & + div {
      margin-top: 6px;
    }
    &:first-of-type {
      width: 50%;
    }
    &:last-of-type {
      height: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: ${(props) => (props.listYn ? '16px' : '24px')};
    div {
      height: 20px;
      & + div {
        margin-top: 4px;
      }
      &:last-of-type {
        height: 16px;
      }
    }
  }
`;
