import React, { useEffect } from 'react';

import Lottie from 'lottie-react';
import prizeLottie from '../../lottie/reward-luckydraw.json';
import Button from '../Button';
import {
  PopupButton,
  PopupContainer,
  PopupInner,
  PopupText,
  PopupTitle,
} from './PopupRaffleTicketElements';

const PopupRaffleTicket = ({ setOpen }) => {
  useEffect(() => {
    if (setOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [setOpen]);
  return (
    <>
      <PopupContainer>
        <PopupInner>
          <Lottie className="lottie" animationData={prizeLottie} loop={false} />
          <PopupTitle>
            You’ve joined the <br />
            Lucky Draw !
          </PopupTitle>
          <PopupText>
            The Lucky Draw you entered will take place at 3:45 PM and 6:45 PM on
            the Open Stage.
          </PopupText>
          <PopupButton>
            <span className="button">
              <Button
                color="black"
                size="medium"
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
              >
                Okay
              </Button>
            </span>
          </PopupButton>
        </PopupInner>
      </PopupContainer>
    </>
  );
};

export default PopupRaffleTicket;
