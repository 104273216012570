import { call, put, takeLatest } from 'redux-saga/effects';
import { Post } from '../../utils/Requester';
import * as types from '../ActionTypes';

export function* postSubscribe(action) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({ type: types.SUBSCRIBE_SUCCESS, data: '' });
      return;
    }
    const { data, error } = yield call(Post, {
      url: '/api/sdc/newsletter/subscribe',
      body: action.data,
    });

    if (error) {
      console.log('error', error);
      yield put({ type: types.SUBSCRIBE_ERROR, error });
      return;
    }

    yield put({ type: types.SUBSCRIBE_SUCCESS, data });
  } catch (error) {
    console.log('error', error);
    yield put({ type: types.SUBSCRIBE_ERROR, error });
  }
}

export default function* rootSaga() {
  yield takeLatest(types.SUBSCRIBE_REQUEST, postSubscribe);
}
