import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RegisterWrap } from './RegisterContElement';
import Banner from '../Banner';
import { getGaClientId } from '../../utils/Functions';

const RegisterCon = () => {
  const [index, setIndex] = useState(0);
  const { isLogin } = useSelector((state) => state.userSession);
  // const gaCid = getGaClientId();
  const [queryParams, setQuery] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem('sdc-utm-data')) {
      const data = sessionStorage.getItem('sdc-utm-data');
      const queryData = Object.entries(JSON.parse(data))
        .map((e) => e.join('='))
        .join('&');

      setQuery(queryData);
    }
  }, []);

  return (
    <RegisterWrap>
      <Banner
        title="In-Person"
        bannerDate="Oct. 03, San Jose"
        desc="Join us on-site in California to experience SDC24 firsthand."
        buttonLengthOne
        buttonUrl={`/registration?fd=c2RwOlNEUDIwMjM=${
          queryParams ? `&${queryParams}` : ''
        }`}
        buttonText="Register Now"
      />
      <Banner
        title="Virtual"
        bannerDate="Oct. 03, Online"
        desc="Remotely view the keynote, sessions, and more."
        buttonLengthOne
        buttonUrl={
          isLogin
            ? `/registration/online/index.html${
                queryParams ? `?${queryParams}` : ''
              }`
            : `/login?redirectURL=${encodeURIComponent(
                `/registration/online/index.html${
                  queryParams ? `?${queryParams}` : ''
                }`
              )}`
        }
        buttonText="Register Now"
      />
    </RegisterWrap>
  );
};

export default RegisterCon;
