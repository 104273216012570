import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_CODELAB_FILTER } from '../../redux/ActionTypes';
import CardCodeLab from '../CardCodeLab';
import CheckBox from '../Checkbox';
import withRouter from '../Router/withRouter';
import {
  CardInner,
  CardWrap,
  CodeLabFilter,
  TechContainer,
  TechFilterBtn,
  TechInner,
} from './CodeLabContElements';

const CodeLabCateFilter = {
  All: 'All',
  'Samsung Wallet': 'Samsung Wallet',
  SmartThings: 'SmartThings',
  Automotive: 'Automotive',
  Health: 'Health',
};

const ButtonFilter = withRouter(
  ({
    className,
    label,
    value,
    searchParams,
    navigate,
    isClickedAll,
    onClickAll,
    location,
  }) => {
    const [isCheck, setIsChecked] = useState(false);
    const dispatch = useDispatch();

    const { checkedItems } = useSelector((state) => state.codelab);

    // checked items
    useEffect(() => {
      if (checkedItems.length > 0 && checkedItems.includes(value)) {
        setIsChecked(true);
      } else if (checkedItems.length === 0) {
        setIsChecked(label === 'All');
      } else {
        setIsChecked(false);
      }
    }, [checkedItems]);

    const clickHandler = (e) => {
      if (e.target.id === 'All') {
        onClickAll(true);
      } else {
        onClickAll(false);
      }
      const prevQuery = searchParams[0].getAll('filter');
      if (
        (prevQuery.toString() === '' || prevQuery.toString() === 'All') &&
        e.target.id === 'All'
      ) {
        return;
      }
      // unchecked
      if (prevQuery.toString().split(',').includes(e.target.id)) {
        const newQuery = prevQuery
          .toString()
          .split(',')
          .filter((query) => query !== e.target.id);
        navigate(
          `${
            newQuery.length > 0 ? `?filter=${encodeURIComponent(newQuery)}` : ''
          }`
        );
      } else {
        // checked
        const prevEncode = encodeURIComponent(...prevQuery);
        if (e.target.id === 'All') {
          navigate(`?filter=All`);
        } else {
          navigate(
            `${
              prevEncode === 'undefined' || prevEncode === 'All'
                ? `?filter=${encodeURIComponent(e.target.id)}`
                : `?filter=${[prevEncode, encodeURIComponent(e.target.id)]}`
            }`
          );
          if (prevEncode === 'All') {
            onClickAll(false);
          }
        }
      }
      setIsChecked(!isCheck);
      dispatch({ type: UPDATE_CODELAB_FILTER, checkedItem: e.target.id });
    };

    useEffect(() => {
      if (isClickedAll) {
        setIsChecked(label === 'All');
      }
      if (!isClickedAll && label === 'All') {
        setIsChecked(false);
      }
    }, [isClickedAll, label]);

    // filter all item handling
    useEffect(() => {
      const filter = searchParams[0].getAll('filter');
      const splitFilter = filter.toString().split(',');
      const compareFilter = Object.keys(CodeLabCateFilter)
        .filter((x) => x !== 'All')
        .slice()
        .sort();
      const sortSearch = splitFilter.slice().sort();
      if (
        filter &&
        splitFilter.length > 0 &&
        JSON.stringify(sortSearch) === JSON.stringify(compareFilter)
      ) {
        navigate({
          pathname: location.pathname,
          search: `?filter=All`,
        });
        onClickAll(true);
      }
    }, [location, navigate]);

    return (
      <CheckBox
        className={className}
        id={label}
        label={label}
        onChange={clickHandler}
        checked={isCheck}
        value={value}
      />
    );
  }
);

const CodeLab = () => {
  // PC, MO 체크
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const phase = useSelector((state) => state.meta.data.phase);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
  };

  const { isLoading, data, error, checkedItems } = useSelector(
    (state) => state.codelab
  );

  const [filterData, setFilterData] = useState(null);

  useEffect(() => {
    if (data && checkedItems.length > 0 && !checkedItems.includes('All')) {
      setFilterData(data.filter((x) => checkedItems.includes(x.tag[0])));
    } else if (data) {
      setFilterData(data);
    }
  }, [data, checkedItems]);

  // card interaction
  const tl = useRef();
  const cardRef = useRef();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      '(min-width: 768px)': function () {
        const cardArray = gsap.utils.toArray(cardRef.current.children);
        cardArray.forEach((el) => {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: '0 95%',
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
      '(max-width: 767px)': function () {
        const cardArray = gsap.utils.toArray(cardRef.current.children);
        cardArray.forEach((el) => {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: '0 100%',
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
      tl.current.kill();

      ScrollTrigger.clearMatchMedia();
    };
  }, [filterData]);

  const [modal, setModal] = useState(false);
  const [modalFalse, setModalFalse] = useState(false);
  const [isClickedAll, setIsClickedAll] = useState(true);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const handleClickAll = (flag) => {
    setIsClickedAll(flag);
  };

  return (
    <TechContainer>
      <TechInner>
        <TechFilterBtn
          onClick={() => setIsOpenFilter(!isOpenFilter)}
          className={isOpenFilter && 'is-checked'}
        >
          Filter
        </TechFilterBtn>
        <CodeLabFilter className={isOpenFilter ? null : 'close'}>
          {Object.entries(CodeLabCateFilter).map(([key, value]) => (
            <ButtonFilter
              className="codelab"
              key={key}
              label={key}
              value={value}
              isClickedAll={isClickedAll}
              onClickAll={handleClickAll}
              defaultCheck={value === 'All'}
            />
          ))}
        </CodeLabFilter>
        <CardWrap>
          <CardInner ref={cardRef}>
            {(filterData || [{}, {}, {}, {}, {}, {}])
              .sort((a, b) =>
                !isLoading && filterData ? a.title.localeCompare(b.title) : ''
              )
              .map((item, idx) => (
                <CardCodeLab
                  key={item.id}
                  id={item.id}
                  listYn
                  col="col-3"
                  bookmark
                  title={item.title}
                  url={item.url}
                  // description={item.description}
                  timeTaken={item.timeTaken}
                  image={item.image}
                  tag={item.tag}
                  // type={item.type}
                  // live={item.live}
                  setModal={setModal}
                  setModalFalse={setModalFalse}
                  hover={phase !== 'phase2' && item.url && item.url.length > 0}
                  // 로딩중일 경우 true로 전송
                  loading={isLoading}
                />
              ))}
          </CardInner>
        </CardWrap>
      </TechInner>
    </TechContainer>
  );
};

export default CodeLab;
