import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import TermsCont from '../../components/TermsCont';

const Terms = () => {
  return (
    <>
      <DetailContainer>
        <TermsCont />
      </DetailContainer>
    </>
  );
};

export default Terms;
