import React from 'react';
import {
  ErrorWrap,
  ErrorWrapInner,
  ErrorTitle,
  ErrorDesc,
  ErrorLogo,
  ErrorCopyright,
} from './RegisterErrorContElement';
import ButtonLink from '../ButtonLink';

const RegisterErrorCont = (props) => {
  const { errorTitle, errorDesc, buttonLink, buttonText } = props;
  return (
    <ErrorWrap className="only-content">
      <ErrorWrapInner>
        {/* <ErrorCharacter /> */}
        <ErrorLogo />
        <ErrorTitle>{errorTitle}</ErrorTitle>
        <ErrorDesc dangerouslySetInnerHTML={{ __html: errorDesc }} />
        <ButtonLink url={buttonLink || '/'} color="black" size="xsmall">
          {buttonText || 'Go to SDC'}
        </ButtonLink>
      </ErrorWrapInner>
      <ErrorCopyright>Samsung Developer Conference 2024</ErrorCopyright>
    </ErrorWrap>
  );
};

export default RegisterErrorCont;
