import React, { useEffect, useRef, useState } from 'react';
import { useActivityQRDetect } from '../../hooks/useActivityQRDetect';
import { HowToUsePopup } from '../ActivityMyPoint/ActivityMyPoint';
import Button from '../Button';
import { ActivityMyRaffleTicketContainer } from './ActivityMyRaffleTicket';

const ActivityMyRaffleTicket = ({ current, use }) => {
  const cameraRef = useRef(null);
  const { qrFileHandler } = useActivityQRDetect();

  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modal]);

  return (
    <>
      <ActivityMyRaffleTicketContainer>
        <p>Bingo lucky draw ticket</p>
        <div className="status">
          <div>
            <span>Current</span>
            <em>{current}</em>
          </div>
          <div>
            <span>Used</span>
            <p>{use}</p>
          </div>
        </div>
        <Button color="white" size="medium" onClick={() => setModal(true)}>
          How to get Lucky Draw tickets
        </Button>
        {/* <input
        ref={cameraRef}
        id="raffle-camera"
        name="raffle-camera"
        type="file"
        accept="image/*"
        capture="environment"
        className="hidden"
        onChange={qrFileHandler}
      />
      <Button
        color="white"
        size="medium"
        onClick={() => cameraRef.current?.click()}
      >
        Use lucky draw ticket
      </Button>
      <p>Scan QR through camera</p> */}
      </ActivityMyRaffleTicketContainer>
      {modal ? (
        <HowToUsePopup>
          <div className="inner-wrap">
            <div className="popup-cont">
              <img
                src={`${process.env.PUBLIC_URL}/images/activity-bingo-howtoplay-3.svg`}
                alt="Scan QR code"
              />
              <p>Scan QR code</p>
              <p>
                Turn on the camera app <br />
                and take a picture of the <br />
                QR code provided by our staff
              </p>
              <Button color="black" onClick={() => setModal(false)}>
                Okay
              </Button>
            </div>
          </div>
        </HowToUsePopup>
      ) : null}
    </>
  );
};

export default ActivityMyRaffleTicket;
