import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  PopupContainer,
  PopupInner,
  PopupTitle,
  PopupDesc,
  PopupCont,
  PopupClose,
  LinkContWrap,
  LinkContent,
  ListContent,
  LinkItemLink,
  LinkTitle,
  LinkDesc,
  InnerContent,
  PopupDetailMoreWrap,
  PopupDetailMore,
  ContentWrap,
  PopupDim,
} from './PopupTechSquareElements';
import Button from '../Button';

const SamsungCLabData = [
  {
    id: 1,
    title: 'Dr.Tail',
    desc: 'Online veterinary pet telehealth service',
    url: 'https://drtail.us',
  },
  {
    id: 2,
    title: 'TAGHIVE',
    desc: 'Clicker based smart classroom solution',
    url: 'https://tag-hive.com/',
  },
  {
    id: 3,
    title: 'WELT',
    desc: 'Digital therapeutics and digital biomarkers',
    url: 'https://www.weltcorp.com/',
  },
  {
    id: 4,
    title: 'DATUMO',
    desc: 'All-in-one data Platform',
    url: 'https://datumo.com/',
  },
  {
    id: 5,
    title: 'VESSL AI',
    desc: 'End-to-End MLOps platform',
    url: 'https://vessl.ai/',
  },
  {
    id: 6,
    title: 'CLIKA',
    desc: 'Tiny AI technology',
    url: 'https://www.clika.io/',
  },
];

const PopupTechSquare = ({ setOpen, title, des, isLinkCont }) => {
  // 팝업 노출 시 스크롤 방지
  useEffect(() => {
    document.body.style.cssText = `
			position: fixed; 
			top: -${window.scrollY}px;
			overflow-y: scroll;
			width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      // eslint-disable-next-line radix
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);

  // more 클릭시 텍스트 노출
  // const [isLinkCont, setIsLinkCont] = useState(true);
  const [more, setMore] = useState(false);
  const textHandler = () => {
    setMore(!more);
  };

  const descRef = useRef(null);
  const [btnBlock, setBtnBlock] = useState(false);

  const descHandler = useCallback(() => {
    if (descRef.current) {
      setBtnBlock(false);
      if (window.innerWidth > 769) {
        if (descRef.current.clientHeight > 65) {
          // 3줄 넘길 경우
          setBtnBlock(true); // 말줄임, more 버튼 활성화
        }
      } else if (descRef.current.clientHeight > 64) {
        // 3줄 넘길 경우
        setBtnBlock(true); // 말줄임, more 버튼 활성화
      }
    }
  }, [descRef, btnBlock]);

  useEffect(() => {
    descHandler();

    window.addEventListener('resize', descHandler);
    return () => window.removeEventListener('resize', descHandler);
  }, []);

  const closePopup = () => {
    setOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      {isLinkCont ? (
        <PopupContainer>
          <ContentWrap className={more ? 'more-content' : ''}>
            <PopupInner>
              <PopupTitle>Samsung C-Lab</PopupTitle>
              <InnerContent>
                <PopupDesc
                  className={more ? 'is-appended' : btnBlock ? 'is-more' : ''}
                >
                  <div ref={descRef} className="text">
                    C-Lab inside is a corporate venture program that encourages
                    and nurtures innovative ideas from Samsung employees.
                    <br />
                    It was established in 2012, to discover new ideas and to
                    disperse a creative corporate culture throughout the
                    company.
                    <br />
                    C-Lab inside projects that have proven marketability will
                    either transfer to an in-house division or become spin-off
                    companies that will be supported by Samsung Electronics.{' '}
                    <br />
                    <br />
                    C-Lab Outside is a startup acceleration program designed to
                    encourage competent and prospective entrepreneurs outside
                    Samsung Electronics.
                    <br />
                    C-Lab Outside program helps startups to grow their
                    businesses by providing financial aid, mentoring,
                    consultation, business co-operation, and infrastructure.
                  </div>
                </PopupDesc>

                <PopupDetailMoreWrap className={btnBlock ? 'block' : ''}>
                  <PopupDetailMore
                    onClick={textHandler}
                    className={more ? 'is-appended' : ''}
                  >
                    {more ? 'Less' : 'More'}
                  </PopupDetailMore>
                </PopupDetailMoreWrap>

                <PopupCont className="border-box">
                  <LinkContWrap>
                    {SamsungCLabData.map((item, idx) => (
                      // <TechCardItem key={idx} item={item} idx={idx} setModal={setModal} setModalFalse={setModalFalse}/>
                      <LinkContent key={idx}>
                        <LinkTitle>{item.title}</LinkTitle>
                        <LinkDesc>{item.desc}</LinkDesc>
                        <LinkItemLink
                          href={item.url}
                          className="link"
                          target="_blank"
                        >
                          Link
                        </LinkItemLink>
                      </LinkContent>
                    ))}
                  </LinkContWrap>
                </PopupCont>
              </InnerContent>

              <PopupClose onClick={closePopup} />
            </PopupInner>
          </ContentWrap>
          <PopupDim onClick={closePopup} />
        </PopupContainer>
      ) : (
        <PopupContainer>
          <ContentWrap>
            <PopupInner>
              <PopupTitle>Tech Square</PopupTitle>
              <InnerContent>
                <PopupDesc>
                  <div className="text">
                    Products and services using our new technology are displayed
                    in the space by each field, and you can find details in the
                    keynote speech and sessions.
                  </div>
                </PopupDesc>
                <PopupCont>
                  <ListContent>
                    <dl>
                      <dt>Platform : Bixby, SmartThings, Knox, Tizen</dt>
                      <dd>
                        SmartThings Matter/Hub, Home View, Calm Onboarding,
                        SmartThings Ecosystem Wall and more
                      </dd>
                    </dl>
                    <dl>
                      <dt>Mobile & Screen Experience</dt>
                      <dd>
                        Samsung Wallet, Tizen TV, Smart Edge Blending and more
                      </dd>
                    </dl>
                    <dl>
                      <dt>Home & Health Experience</dt>
                      <dd>
                        Samsung Food, Vision AI Pro cooking camera oven, Samsung
                        Health and more
                      </dd>
                    </dl>
                    <dl>
                      <dt>Sustainability</dt>
                      <dd>
                        Resource Circulation Gallery, Circular Factory, Relumino
                        Mode and more
                      </dd>
                    </dl>
                  </ListContent>
                </PopupCont>
              </InnerContent>
              <PopupClose onClick={closePopup} />
            </PopupInner>
          </ContentWrap>
          <PopupDim onClick={closePopup} />
        </PopupContainer>
      )}
    </>
  );
};

export default PopupTechSquare;
