import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PopupContainer,
  PopupInner,
  PopupTitle,
  PopupDesc,
  PopupBottom,
  PopupBtnLeft,
  PopupBtnRight,
  PopupBtn,
  PopupOnlyNoti,
} from './PopupElements';
import Button from '../Button';

const Popup = ({
  modal,
  setModal,
  version,
  title,
  desc,
  CloseText,
  btnText,
  linkText,
  linkText2,
  link,
  linkBlock,
  notify,
  children,
  multilink,
  externalLink,
  reload,
  onClick,
  linkData,
}) => {
  const PopupOff = () => {
    setModal(false);
    if (reload) {
      window.location.reload();
    }
  };

  const navigate = useNavigate();

  const goToSignin = () => {
    window.open('https://developer.samsung.com/dashboard/support');
  };

  const goToUnregisterComplete = () => {
    navigate('/registration/canceled');
  };

  return (
    <>
      <PopupContainer>
        <PopupInner>
          <PopupTitle>{title}</PopupTitle>
          <PopupDesc>
            {desc}
            {link ? (
              linkBlock ? (
                multilink ? (
                  <>
                    {linkData.map((x) => (
                      <a
                        href={x.url}
                        className="linkBlock"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Go to {x.title}
                      </a>
                    ))}
                  </>
                ) : externalLink ? (
                  <a
                    href={link}
                    className="linkBlock"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {linkText}
                  </a>
                ) : (
                  <a href={link} className="linkBlock">
                    {linkText}
                  </a>
                )
              ) : (
                <a href={link} className="link">
                  {linkText}
                </a>
              )
            ) : null}
          </PopupDesc>
          {notify ? (
            <PopupOnlyNoti
              className="noti"
              dangerouslySetInnerHTML={{ __html: notify }}
            />
          ) : null}
          {
            version === 'alert' ? ( // Alert popup case
              <PopupBottom>
                <PopupBtnLeft>
                  <Button
                    color="white"
                    outline="outline"
                    size="medium"
                    fullWidth="fullWidth"
                    onClick={PopupOff}
                  >
                    {CloseText}
                  </Button>
                </PopupBtnLeft>
                <PopupBtnRight>
                  <Button
                    color="black"
                    size="medium"
                    fullWidth="fullWidth"
                    onClick={onClick || goToUnregisterComplete}
                  >
                    {btnText}
                  </Button>
                </PopupBtnRight>
              </PopupBottom>
            ) : (
              <PopupBtn>
                <Button
                  color="black"
                  size="medium"
                  fullWidth="fullWidth"
                  onClick={onClick || PopupOff}
                >
                  {btnText}
                </Button>
              </PopupBtn>
            ) // confirm popup case
          }
        </PopupInner>
      </PopupContainer>
    </>
  );
};

export default Popup;
