import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  ModalContainer,
  ModalInner,
  ModalWrap,
  ModalText,
  ModalTitle,
  Modaldesc,
  ModalBtn,
  ModalClose,
} from './ModalCookieElements';
import Button from '../Button';

import {
  COOKIE_GDPR_MODAL_CLOSE,
  UPDATE_COOKIES_GDPR_REQUEST,
} from '../../redux/ActionTypes';

const ModalCookie = ({ setOpen, setOpenManage, openManage }) => {
  const dispatch = useDispatch();

  const closeModal = (accept) => {
    // setOpen(false);
    document.body.style.overflow = 'auto';
    if (accept) {
      dispatch({
        type: UPDATE_COOKIES_GDPR_REQUEST,
        accept,
      });
    } else {
      // click close button
      dispatch({
        type: COOKIE_GDPR_MODAL_CLOSE,
      });
    }
  };

  return (
    <ModalContainer className={openManage && 'hide'}>
      <ModalInner>
        <ModalWrap>
          <ModalText>
            <ModalTitle>SAMSUNG and Cookies</ModalTitle>
            <Modaldesc>
              Our site uses cookies, including optional cookies to provide you
              with the best experience on our website and relevant advertising
              based on your use of our site, You can manage your preferences or
              accept all cookies. You can also read our{' '}
              <a href="/privacy" target="_blank">
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href="/cookie" target="_blank">
                Cookie Policy
              </a>
              .
            </Modaldesc>
          </ModalText>
          <ModalBtn>
            <div className="button">
              <Button
                color="borderWhite"
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  setOpenManage(true);
                }}
              >
                I Want to Manage
              </Button>
            </div>
            <div className="button">
              <Button
                color="white"
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  closeModal('all');
                }}
              >
                OK, Let's Go!
              </Button>
            </div>
          </ModalBtn>
        </ModalWrap>
        <ModalClose
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        />
      </ModalInner>
    </ModalContainer>
  );
};

export default ModalCookie;
