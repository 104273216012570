import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import EventJoin from '../EventJoin';
import withRouter from '../Router/withRouter';
import {
  ActivityPointItem,
  ActivityPrizeBox,
  ActivityPrizeItem,
  MeetupBanner,
  MeetupContBottom,
  MeetupContContainer,
  MeetupContTop,
  MeetupDesc,
  MeetupIntroduce,
  MeetupTitle,
  TabPanelBox,
  TabPanelTitle,
} from './ActivityContElement';
import './style.scss';

const missionList = [
  {
    title: 'Sign-in first',
    desc: 'Please sign-in SDC24 to participate.',
    img: `${process.env.PUBLIC_URL}/images/ico-activity-mission-1.svg`,
  },
  {
    title: 'Visit the mission spot',
    desc: 'Visit the designated mission spot to fulfill the mission.',
    img: `${process.env.PUBLIC_URL}/images/ico-activity-mission-2-1.svg`,
  },
  {
    title: 'Scan QR code',
    desc: 'Scan QR code from our promoter after completing the mission to accumulate points.',
    img: `${process.env.PUBLIC_URL}/images/ico-activity-mission-3.svg`,
  },
  {
    title: 'Redeem for Gifts',
    desc: 'Exchange your points and lines for gifts at the Giveaway desk.',
    img: `${process.env.PUBLIC_URL}/images/ico-activity-mission-4.svg`,
  },
];

const codelabList = [
  {
    title: 'Sign-in first',
    desc: 'Please sign-in SDC24 to participate.',
    img: `${process.env.PUBLIC_URL}/images/ico-activity-mission-1.svg`,
  },
  {
    title: 'View task details',
    desc: 'Finish the tasks to accumulate points.',
    img: `${process.env.PUBLIC_URL}/images/ico-activity-codelab-1.svg`,
  },
  {
    title: 'Scan QR code',
    desc: 'Scan QR code from our promoter after completing a task to accumulate points and receive a Code Lab lucky draw ticket.',
    img: `${process.env.PUBLIC_URL}/images/ico-activity-codelab-2-1.svg`,
  },
  {
    title: 'Redeem for Gifts',
    desc: 'Exchange your points and lines at the Giveaway desk.',
    img: `${process.env.PUBLIC_URL}/images/ico-activity-codelab-3.svg`,
  },
];

const prizeList = [
  {
    title: 'Galaxy Z Fold6',
    img: `${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-zfold.png`,
  },
  {
    title: 'Galaxy Z Fold6',
    img: `${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-zflip.png`,
  },
  {
    title: 'Galaxy Watch Ultra',
    img: `${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-watch7u.png`,
  },
  {
    title: 'Galaxy Ring',
    img: `${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-ring.png`,
  },
  {
    title: 'Galaxy Watch7',
    img: `${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-watch7.png`,
  },
  {
    title: 'Gaming Monitor',
    img: `${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-monitor.png`,
  },
  {
    title: 'Batterypack',
    img: `${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-battery.png`,
  },
];

const pointList = [
  {
    title: 'Keychain',
    point: '1,000',
    img: `${process.env.PUBLIC_URL}/images/img-prize-6-1.png`,
  },
  {
    title: 'Tumbler',
    point: '1,500',
    img: `${process.env.PUBLIC_URL}/images/img-prize-7-1.png`,
  },
  {
    title: 'Charging Tray',
    point: '2,500',
    img: `${process.env.PUBLIC_URL}/images/img-prize-8-1.png`,
  },
];

const ActivityCont = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const navigate = useNavigate();
  const phase = useSelector((state) => state.meta.data.phase);

  const settings = {
    dots: true,
    speed: 800,
    centerMode: false,
    infinite: false,
    centerPadding: '0px',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    cssEase: 'ease-in-out',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          dots: true,
          infinite: false,
        },
      },
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      setDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <MeetupContContainer>
      <MeetupContTop>
        <button className="btn-back" type="button" onClick={() => navigate(-1)}>
          Back
        </button>
        <MeetupBanner>
          <img
            className="assets-1"
            src={`${process.env.PUBLIC_URL}/images/bg-session-assets-1.svg`}
            alt="assets-1"
          />
          <img
            className="assets-2"
            src={`${process.env.PUBLIC_URL}/images/bg-session-assets-2.svg`}
            alt="assets-2"
          />
          <img
            className="assets-3"
            src={`${process.env.PUBLIC_URL}/images/bg-session-assets-3.png`}
            alt="assets-3"
          />
          <img
            className="assets-4"
            src={`${process.env.PUBLIC_URL}/images/bg-session-assets-4.svg`}
            alt="assets-4"
          />
          <img
            className="assets-5"
            src={`${process.env.PUBLIC_URL}/images/bg-session-assets-5.svg`}
            alt="assets-5"
          />
          <div className="banner-box">
            <div className="banner-text">Join Activities</div>
            <div className="banner-time">{`${moment(
              '2024-10-03T11:00:00'
            ).format('ddd, MMM D, YYYY hh:mm A')} PT`}</div>
          </div>
        </MeetupBanner>
        <MeetupTitle>SDC24 Activities</MeetupTitle>
        <MeetupDesc>
          Enjoy the excitement of our SDC24 Bingo and Code Lab missions. Earn
          points to redeem for gifts and complete Bingo lines or Code Lab tasks
          to receive lucky draw tickets and join our Lucky Draw.
        </MeetupDesc>
      </MeetupContTop>
      <MeetupContBottom>
        <Tabs>
          <TabList>
            <Tab>SDC24 Bingo</Tab>
            <Tab>Code Lab</Tab>
            <Tab>Reward</Tab>
          </TabList>
          <TabPanelBox>
            <TabPanel>
              <TabPanelTitle>SDC24 Bingo</TabPanelTitle>
              <MeetupDesc className="bottom">
                Complete SDC24 Bingo missions to earn points, which you can
                redeem for gifts. For each Bingo line you complete, you’ll
                receive lucky draw tickets to enter our lucky draw.
              </MeetupDesc>
              <MeetupIntroduce>
                <div className="introduce-info">
                  <div className="introduce-name">
                    How to play SDC24 Bingo missions
                  </div>
                  {isDesktop ? (
                    <div className="introduce-mission-box">
                      {missionList.map((mission, idx) => (
                        <div className="introduce-mission" key={idx}>
                          <div className="mission-img-box">
                            <div className="mission-ico">{idx + 1}</div>
                            <img src={mission.img} alt="mission-img" />
                          </div>
                          <div className="mission-title">{mission.title}</div>
                          <div className="mission-desc">{mission.desc}</div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="activity-slide">
                      <Slider {...settings}>
                        {missionList.map((mission, idx) => (
                          <div className="introduce-mission" key={idx}>
                            <div className="mission-img-box">
                              <img src={mission.img} alt="mission-img" />
                            </div>
                            <div className="mission-title">{mission.title}</div>
                            <div className="mission-desc">{mission.desc}</div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  )}
                </div>
              </MeetupIntroduce>
            </TabPanel>
          </TabPanelBox>
          <TabPanelBox>
            <TabPanel>
              <TabPanelTitle>Code Lab</TabPanelTitle>
              <MeetupDesc className="bottom">
                Complete Code Lab tasks to earn points, which can be redeemed
                for gifts at Giveaway desk. For each task completed, earn Code
                Lab Lucky Draw tickets to enter our Lucky Draw exclusive for
                Code Lab.
              </MeetupDesc>
              <MeetupIntroduce>
                <div className="introduce-info">
                  <div className="introduce-name">How to join?</div>
                  {isDesktop ? (
                    <div className="introduce-mission-box">
                      {codelabList.map((mission, idx) => (
                        <div className="introduce-mission" key={idx}>
                          <div className="mission-img-box">
                            <div className="mission-ico">{idx + 1}</div>
                            <img src={mission.img} alt="mission-img" />
                          </div>
                          <div className="mission-title">{mission.title}</div>
                          <div className="mission-desc">{mission.desc}</div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="activity-slide">
                      <Slider {...settings}>
                        {codelabList.map((mission, idx) => (
                          <div className="introduce-mission" key={idx}>
                            <div className="mission-img-box">
                              <img src={mission.img} alt="mission-img" />
                            </div>
                            <div className="mission-title">{mission.title}</div>
                            <div className="mission-desc">{mission.desc}</div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  )}
                </div>
              </MeetupIntroduce>
            </TabPanel>
          </TabPanelBox>
          <TabPanelBox>
            <TabPanel>
              <TabPanelTitle>Reward</TabPanelTitle>
              <MeetupDesc className="bottom">
                Collect tickets and points through onsite activities. You can
                win prizes using tickets and points.
              </MeetupDesc>
              <MeetupIntroduce>
                <div className="introduce-info">
                  <div className="introduce-name">
                    Lucky Draw Prizes
                    <div className="introduce-desc">
                      Apply for Lucky Draw with your lucky draw ticket. General
                      Lucky Draw will be held at 4:00pm, 5:45pm and 6:45pm. Code
                      Lab Lucky Draw will be held at 6:00pm.
                    </div>
                  </div>
                  <ActivityPrizeBox>
                    {prizeList.map((prize, idx) => (
                      <ActivityPrizeItem
                        className={idx ? '' : 'first'}
                        key={idx}
                      >
                        <span className="ico-rank">{idx + 1}</span>
                        <img
                          className="img-prize"
                          src={prize.img}
                          alt="prize"
                        />
                        <div className="prize-title">{prize.title}</div>
                      </ActivityPrizeItem>
                    ))}
                  </ActivityPrizeBox>
                </div>
                <div className="introduce-info">
                  <div className="introduce-name">
                    Point Redeem Prizes
                    <div className="introduce-desc">
                      Earn points through activities and exchange them for gifts
                      at the Giveaway desk. Each person can receive one gift
                      each.
                    </div>
                  </div>
                  {isDesktop ? (
                    <div className="introduce-mission-box">
                      {pointList.map((point, idx) => (
                        <ActivityPointItem key={idx}>
                          <img
                            src={point.img}
                            alt="img"
                            className="img-point"
                          />
                          <div className="point-info">
                            <div className="point-title">{point.title}</div>
                            <div className="point-price">
                              <img
                                src={`${process.env.PUBLIC_URL}/images/ico-price.svg`}
                                alt="point"
                                className="ico-point"
                              />
                              {point.point}
                            </div>
                          </div>
                        </ActivityPointItem>
                      ))}
                    </div>
                  ) : (
                    <div className="activity-slide">
                      <Slider {...settings}>
                        {pointList.map((point, idx) => (
                          <ActivityPointItem key={idx}>
                            <ActivityPointItem>
                              <img
                                src={point.img}
                                alt="img"
                                className="img-point"
                              />
                              <div className="point-info">
                                <div className="point-title">{point.title}</div>
                                <div className="point-price">
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/ico-price.svg`}
                                    alt="point"
                                    className="ico-point"
                                  />
                                  {point.point}
                                </div>
                              </div>
                            </ActivityPointItem>
                          </ActivityPointItem>
                        ))}
                      </Slider>
                    </div>
                  )}
                </div>
              </MeetupIntroduce>
            </TabPanel>
          </TabPanelBox>
        </Tabs>
        {!isDesktop && phase === 'phase3' && (
          <EventJoin className="activity-join" />
        )}
      </MeetupContBottom>
    </MeetupContContainer>
  );
};

export default withRouter(ActivityCont);
