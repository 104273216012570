import * as types from '../ActionTypes';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  checkedMainCategories: [],
  checkedTopics: [],
  checkedLocations: [],
  filterData: {},
};

const ScheduleDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SCHEDULE_SESSION_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };

    case types.GET_SCHEDULE_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // data: action.data,
        openStage: action.openStage,
        timeSchedule: action.timeSchedule,
        roomSchedule: action.roomSchedule,
        checkedMainCategories: action.checkedMainCategories,
        checkedTopics: action.checkedTopics,
        checkedLocations: action.checkedLocations,
        filterData: action.filterData,
        error: null,
      };

    case types.GET_SCHEDULE_SESSION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.CLEAR_SCHEDULE_FILTER: {
      return {
        ...state,
        checkedTypes: [],
        checkedTopics: [],
        checkedMainCategories: [],
        checkedLocations: [],
      };
    }

    case types.UPDATE_SCHEDULE_TOPIC_FILTER: {
      let newTopics = [...state.checkedTopics];
      if (newTopics.indexOf(action.checkedTopic) === -1) {
        if (typeof action.checkedTopic === 'object') {
          newTopics = [];
          if (action.checkedTopic.length > 0) {
            const checkedTopic = action.checkedTopic.toString().split(',');
            newTopics.push(...checkedTopic);
          }
        } else {
          newTopics.push(action.checkedTopic);
        }
      } else {
        newTopics.splice(newTopics.indexOf(action.checkedTopic), 1);
      }
      return {
        ...state,
        checkedTopics: newTopics,
      };
    }

    case types.UPDATE_SCHEDULE_CATEGORY_FILTER: {
      let newMainCategories = [...state.checkedMainCategories];
      if (newMainCategories.indexOf(action.checkedMainCategory) === -1) {
        if (typeof action.checkedMainCategory === 'object') {
          newMainCategories = [];
          if (action.checkedMainCategory.length > 0) {
            const checkedMainCategory = action.checkedMainCategory
              .toString()
              .split(',');
            newMainCategories.push(...checkedMainCategory);
          }
        } else {
          newMainCategories.push(action.checkedMainCategory);
        }
      } else {
        newMainCategories.splice(
          newMainCategories.indexOf(action.checkedMainCategory),
          1
        );
      }
      return {
        ...state,
        checkedMainCategories: newMainCategories,
      };
    }

    case types.UPDATE_SCHEDULE_LOCATION_FILTER: {
      let newLocations = [...state.checkedLocations];
      if (newLocations.indexOf(action.checkedLocations) === -1) {
        if (typeof action.checkedLocations === 'object') {
          newLocations = [];
          if (action.checkedLocations.length > 0) {
            const checkedLocations = action.checkedLocations
              .toString()
              .split(',');
            newLocations.push(...checkedLocations);
          }
        } else {
          newLocations.push(action.checkedLocations);
        }
      } else {
        newLocations.splice(newLocations.indexOf(action.checkedLocations), 1);
      }
      return {
        ...state,
        checkedLocations: newLocations,
        isClear: false,
      };
    }

    default:
      return state;
  }
};

export default ScheduleDataReducer;
