import styled from 'styled-components';

export const ActivityMyRaffleTicketContainer = styled.div`
  position: relative;
  margin: 12px 24px 0;
  padding: 18px 24px 24px;
  border-radius: 16px;
  background-color: #f6f6f6;
  text-align: center;

  p:first-of-type {
    font-family: 'samsungsharpsans-bold';
    font-size: 16px;
    line-height: 20px;
  }
  .status {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    > div {
      text-align: center;
      color: #9e9e9e;
      width: 35px;
      span {
        font-family: 'SamsungOne-600';
        font-size: 10px;
        line-height: 16px;
      }
      p,
      em {
        display: block;
        margin-top: 2px;
        font-family: 'samsungsharpsans-bold';
        font-size: 36px;
        line-height: normal;
      }
      em {
        color: #1e1e1e;
      }
      + div {
        position: relative;
        padding-left: 46px;
        margin-left: 45px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 1px;
          height: 50px;
          background-color: #eaeaea;
          transform: translateY(-50%);
        }
      }
    }
  }
  .Button {
    margin-top: 20px;
    padding: 0;
    width: 264px;
    color: #fff;
    background-color: #2088fd;
  }
  .Button + p {
    margin-top: 14px;
    font-size: 12px;
    line-height: 16px;
    color: #7c7c7c;
  }
  .btn-link {
    display: block;
    position: absolute;
    right: 14px;
    top: 14px;
    width: 24px;
    height: 24px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-mypoint-arrow.svg`});
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
