import React from 'react';
import ActivityHeader from '../../components/ActivityHeader';
import ActivityBingoPrizesContent from '../../components/ActivityBingoPrizesContent';

const ActivityBingoPrizes = () => {
  return (
    <>
      <ActivityHeader isDropdown />
      <ActivityBingoPrizesContent />
    </>
  );
};

export default ActivityBingoPrizes;
