import styled from 'styled-components';

export const MeetupContContainer = styled.div`
  margin: 102px auto 0;
  max-width: 1280px;

  @media (max-width: 768px) {
    margin: 56px auto 0;
  }
`;
export const MeetupContTop = styled.div`
  padding: 80px 40px 60px;
  .btn-back {
    position: relative;
    padding-left: 32px;
    font-family: 'samsungOne-700';
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 24px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-previous.svg`});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  @media (max-width: 768px) {
    padding: 24px 24px 40px 24px;
    .btn-back {
      padding-left: 24px;
      margin-bottom: 12px;
      font-size: 16px;
      &:before {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
export const MeetupBanner = styled.div`
  background-color: #0c35a6;
  border-radius: 36px;
  position: relative;
  max-height: 380px;
  overflow: hidden;
  margin-bottom: 56px;
  &:after {
    content: '';
    display: block;
    padding-bottom: 49.14%;
  }
  .banner-box {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .banner-text {
      font-size: 54px;
      font-family: 'samsungsharpsans-bold';
      color: #ffffff;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .banner-time {
      font-size: 20px;
      font-family: 'SamsungOne-400';
      color: #ffffff;
      font-weight: 400;
    }
  }
  img {
    position: absolute;
  }
  .assets-1 {
    left: 0;
    top: 80px;
    width: 140px;
  }
  .assets-2 {
    left: 145px;
    top: 280px;
    width: 158px;
  }
  .assets-3 {
    right: -20px;
    bottom: 0;
    width: 400px;
    z-index: 1;
  }
  .assets-4 {
    left: 772px;
    top: 0;
  }
  .assets-5 {
    left: 1050px;
    top: 220px;
  }

  @media (max-width: 768px) {
    margin-bottom: 24px;
    border-radius: 12px;
    &:after {
      padding-bottom: 70.51%;
    }
    .banner-box {
      .banner-text {
        font-size: 20px;
        margin-bottom: 2px;
      }
      .banner-time {
        font-size: 14px;
      }
    }
    .assets-1 {
      left: 0;
      top: 23%;
      width: 13%;
    }
    .assets-2 {
      left: 13%;
      top: 77%;
      width: 14%;
    }
    .assets-3 {
      right: -1%;
      bottom: 0;
      width: 44%;
      z-index: 1;
    }
    .assets-4 {
      right: 0;
      top: 0;
      left: initial;
      bottom: initial;
      width: 48%;
    }
    .assets-5 {
      right: 5%;
      bottom: 33%;
      top: initial;
      left: initial;
      width: 11%;
    }
  }
`;
export const MeetupTitle = styled.div`
  font-size: 42px;
  font-family: 'samsungsharpsans-bold';
  font-weight: 600;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 12px;
  }
`;
export const MeetupDesc = styled.div`
  font-size: 16px;
  color: #1e1e1e;
  font-family: 'SamsungOne-400';
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 14px;
    &.bottom {
      padding: 0 24px;
    }
  }
`;
export const MeetupContBottom = styled.div`
  padding: 40px 40px 120px;
  .react-tabs__tab-list {
    display: flex;
    width: 100%;
    margin-bottom: 48px;
    overflow: auto;
  }
  .react-tabs__tab {
    width: 50%;
    padding: 20px 0;
    font-family: 'samsungsharpsans-medium';
    font-size: 18px;
    line-height: 1.44;
    color: #9e9e9e;
    border-bottom: 2px solid #efefef;
    text-align: center;
    cursor: pointer;
    &--selected {
      font-family: 'samsungsharpsans-bold';
      color: #2088fd;
      border-bottom: 2px solid #2088fd;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    .react-tabs__tab {
      width: 50%;
      padding: 14px 13px 13px;
      font-size: 16px;
      white-space: nowrap;
    }
    .react-tabs__tab-list {
      width: calc(100% - 24px);
      padding-left: 24px;
      margin-bottom: 36px;
      ::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
`;
export const TabPanelBox = styled.div`
  @media screen and (max-width: 768px) {
    margin-top: 36px;
  }
`;

export const MeetupIntroduce = styled.div`
  padding: 50px 40px;
  border-radius: 24px;
  background-color: #f6f6f6;
  margin-top: 48px;
  display: flex;
  gap: 60px;
  flex-direction: column;
  overflow: hidden;

  .img-introduce {
    width: 282px;
    height: 362px;
    border-radius: 21px;
    background-color: #ffffff;
  }
  .introduce-info {
    width: 100%;
    .introduce-name {
      font-size: 32px;
      color: #000000;
      font-weight: 600;
      margin-bottom: 40px;
      font-family: 'samsungsharpsans-bold';
    }
    .introduce-desc {
      font-size: 14px;
      color: #1e1e1e;
      font-family: 'SamsungOne-400';
      margin-top: 14px;
      line-height: 20px;
    }
    .introduce-mission-box {
      display: flex;
      gap: 20px;
    }
    .introduce-mission {
      flex: 1;
      color: #1e1e1e;
      text-align: center;
      width: 280px;
      .mission-img-box {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1/1;
        position: relative;
        background-color: #fff;
        border-radius: 16px;
        margin-bottom: 24px;
        .mission-ico {
          position: absolute;
          padding: 3px 9px;
          color: #ffffff;
          background-color: #2088fd;
          border-radius: 6px;
          top: 16px;
          left: 16px;
          font-size: 18px;
          font-family: 'samsungOne-700';
          font-weight: 700;
        }
        img {
          display: block;
          width: 160px;
          height: 160px;
        }
      }
      .mission-title {
        font-size: 20px;
        font-family: 'samsungsharpsans-bold';
        font-weight: 600;
        margin-bottom: 4px;
      }
      .mission-desc {
        font-size: 16px;
        line-height: 24px;
        padding: 0 16px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    border-radius: 0;
    margin-top: 32px;
    padding: 40px 24px 90px 24px;
    overflow: hidden;
    .img-introduce {
      width: 100%;
      height: 100%;
    }
    .introduce-info {
      .introduce-name {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .introduce-desc {
        margin: 14px 0 20px 0;
      }
      .introduce-mission {
        text-align: left;
        .mission-title {
          font-size: 18px;
        }
        .mission-desc {
          font-size: 14px;
          line-height: 20px;
          padding: 0;
          margin: 14px 0 40px 0;
        }
      }
    }
  }
`;

export const EventMapWrap = styled.div`
  background-color: #f6f6f6;
  padding: 52px 40px 40px 40px;
  border-radius: 24px;
  margin: 24px 0 0 0;
  & > div {
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    background-color: #ffffff;
    padding: 0;
    margin: 24px 0 60px 0;
    & > div {
      padding: 48px 24px 60px;
    }
  }
`;

export const MeetupBottomBanner = styled.div`
  background-color: #10b77b;
  border-radius: 36px;
  position: relative;
  max-height: 340px;
  overflow: hidden;
  margin-top: 72px;
  &:after {
    content: '';
    display: block;
    padding-bottom: 120.83%;
  }
  &.join {
    background-color: #1259aa;
    max-height: 214px;
    .bottom-banner-box {
      padding: 54px 48px;
    }
  }
  img {
    position: absolute;
  }
  .assets-6 {
    right: 0;
  }
  .assets-7 {
    bottom: 0;
    right: 320px;
  }
  .assets-8 {
    width: 400px;
    bottom: 0;
    right: 130px;
  }
  .assets-11 {
    width: 300px;
    bottom: 0;
    right: 100px;
  }
  .assets-12 {
    right: 0;
    bottom: 0;
  }
  .bottom-banner-box {
    padding: 71px 56px;
    height: 100%;
    .bottom-banner-title {
      font-size: 32px;
      font-family: 'samsungsharpsans-bold';
      color: #ffffff;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .bottom-banner-desc {
      font-size: 16px;
      font-weight: 500;
      font-family: 'SamsungOne-500';
      opacity: 0.8;
      color: #ffffff;
      max-width: 570px;
    }
    .btn-register {
      padding: 16px 36px;
      background-color: #1e1e1e;
      color: #ffffff;
      font-family: 'samsungOne-700';
      font-weight: 700;
      border-radius: 36px;
      font-size: 18px;
    }
  }
  @media screen and (max-width: 768px) {
    position: relative;
    margin: 60px 24px;
    max-height: 377px;
    border-radius: 16px;
    &.join {
      border-radius: 16px;
      max-height: 275px;
      margin: 62px 24px 60px;
      &:after {
        content: '';
        display: block;
        padding-bottom: 113.4%;
      }
      .bottom-banner-box {
        padding: 24px;
      }
    }
    .assets-6 {
      width: 126px;
      top: 117px;
    }
    .assets-7 {
      bottom: 40px;
      left: 0;
    }
    .assets-8 {
      width: 200px;
      bottom: 70px;
      right: 70px;
    }
    .assets-11 {
      width: 250px;
      right: 116px;
      // left: -50px;
    }
    .assets-12 {
      width: 128px;
    }
    .bottom-banner-box {
      padding: 30px 24px;
      .bottom-banner-title {
        font-size: 20px;
        margin-bottom: 10px;
      }
      .bottom-banner-desc {
        font-size: 14px;
      }
    }
  }
`;

export const TabPanelTitle = styled.div`
  font-size: 32px;
  font-family: 'samsungsharpsans-bold';
  font-weight: 600;
  margin-bottom: 14px;

  @media screen and (max-width: 768px) {
    padding: 0 24px;
    font-size: 20px;
    margin-bottom: 12px;
  }
`;

export const TabPanelButton = styled.button`
  padding: 16px 36px;
  background-color: #1e1e1e;
  color: #ffffff;
  font-family: 'samsungOne-700';
  font-weight: 700;
  border-radius: 36px;
  font-size: 18px;
  margin-top: 36px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin: 40px 24px 0 24px;

    &.register {
      position: absolute;
      bottom: 24px;
      left: 0;
      width: calc(100% - 48px);
    }
  }
`;

export const MeetupTheme = styled.div`
  margin-top: 32px;
  .introduce-theme {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px rgba(0, 0, 0, 0.08) solid;
    padding-bottom: 26px;
    margin-bottom: 26px;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .introduce-theme-time {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 18px;
      color: #7c7c7c;
      font-weight: 600;
      font-family: 'SamsungOne-600';
      margin-bottom: 8px;
      img {
        width: 14px;
        height: 14px;
      }
    }
    .introduce-theme-title {
      max-width: 600px;
      font-size: 24px;
      font-weight: 600;
      font-family: 'samsungsharpsans-bold';
    }
    .introduce-theme-cate {
      width: 200px;
      .introduce-theme-badge {
        padding: 2px 8px;
        color: #ffffff;
        font-family: 'samsungOne-700';
        font-weight: 700;
        border-radius: 6px;
        &.cate-1 {
          background-color: #10b77b;
        }
        &.cate-2 {
          background-color: #2088fd;
        }
        &.cate-3 {
          background-color: #756bf1;
        }
        &.cate-4 {
          background-color: #ca6ceb;
        }
      }
      .introduce-theme-text {
        margin-top: 10px;
        font-size: 18px;
        font-family: 'samsungOne-700';
        font-weight: 700;
        &.cate-1 {
          color: #10b77b;
        }
        &.cate-2 {
          color: #2088fd;
        }
        &.cate-3 {
          color: #756bf1;
        }
        &.cate-4 {
          color: #ca6ceb;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 24px;
    background-color: #ffffff;
    padding: 32px;
    border-radius: 12px;
    .introduce-theme {
      padding-bottom: 32px;
      margin-bottom: 32px;
      flex-direction: column;
      .introduce-theme-time {
        font-size: 14px;
        margin-bottom: 4px;
      }
      .introduce-theme-title {
        font-size: 18px;
        margin-bottom: 16px;
      }
      .introduce-theme-cate {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        .introduce-theme-text {
          font-size: 16px;
          margin-top: 0;
        }
      }
    }
  }
`;

export const ActivityPrizeBox = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(5, 1fr);
  @media screen and (max-width: 1024px) {
    grid-gap: 8px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
export const ActivityPrizeItem = styled.div`
  width: 100%;
  min-height: 211px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 16px;
  background-color: #ffffff;
  &.first {
    grid-column: span 2;
    grid-row: span 2;
    .ico-rank {
      left: 23px;
      top: 23px;
      width: 60px;
      height: 75px;
      font-size: 22px;
      line-height: 60px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-prizes-label-no1.svg`});
    }
    .img-prize {
      width: 212px;
      margin-top: 90px;
    }
    .prize-title {
      font-size: 32px;
      line-height: 42px;
      bottom: 48px;
    }
  }
  .ico-rank {
    position: absolute;
    left: 17px;
    top: 17px;
    width: 30px;
    height: 38px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-prizes-label.svg`});
    background-size: contain;
    background-repeat: no-repeat;
    font-family: 'samsungsharpsans-bold';
    font-size: 11px;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }
  .img-prize {
    width: 122px;
    margin-top: 28px;
  }
  .prize-title {
    font-family: 'samsungsharpsans-bold';
    font-size: 18px;
    line-height: 24px;
    color: #1e1e1e;
    position: absolute;
    bottom: 20px;
    text-align: center;
  }

  @media screen and (max-width: 1024px) {
    &.first {
      grid-column: span 3;
      grid-row: span 1;
      min-height: 300px;
      .ico-rank {
        left: 10px;
        top: 10px;
        width: 39px;
        height: 48px;
        font-size: 14px;
        line-height: 39px;
      }
      .img-prize {
        width: 212px;
        margin-top: 20px;
      }
      .prize-title {
        font-size: 12px;
        line-height: 16px;
        bottom: 12px;
      }
    }
    .ico-rank {
      left: 10px;
      top: 10px;
      width: 26px;
      height: 32px;
      font-size: 10px;
      line-height: 26px;
    }
    .prize-title {
      font-size: 12px;
      line-height: 16px;
      bottom: 12px;
    }
    .img-prize {
    }
  }

  @media screen and (max-width: 768px) {
    min-height: 152px;
    &.first {
      grid-column: span 2;
      grid-row: span 1;
      min-height: 152px;
      .img-prize {
        width: 102px;
        margin-top: 12px;
      }
    }
    .img-prize {
      width: 92px;
      margin-top: 20px;
    }
  }
`;

export const ActivityPointItem = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  background-color: #ffffff;
  aspect-ratio: 1 / 1;
  .img-point {
    margin-top: 49px;
    width: 246px;
  }
  .point-info {
    position: absolute;
    bottom: 40px;
    .point-title {
      color: #1e1e1e;
      font-family: 'samsungsharpsans-bold';
      font-weight: 600;
      font-size: 20px;
    }
    .point-price {
      display: flex;
      align-items: center;
      margin-top: 6px;
      gap: 6px;
      color: #2088fd;
      font-size: 20px;
      font-family: 'samsungOne-700';
      font-weight: 700;
      justify-content: center;
    }
  }
  @media screen and (max-width: 1024px) {
    .img-point {
      margin-top: 35px;
      width: 220px;
    }
    .point-info {
      left: 24px;
      bottom: 24px;
      .point-title {
        font-size: 16px;
      }
      .point-price {
        justify-content: flex-start;
        font-size: 14px;
        .ico-point {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
  }
`;
