import React, { useState } from 'react';
import {
  DetailBottomContainer,
  DetailBottomInner,
  SectionWrap,
} from './SpeakerDetailBottomElement';
import Section from '../Section';
import CardSession from '../CardSession';
import ToastPopup from '../ToastPopup';
import { colorMapping } from '../../utils/SessionColor';

const SpeakerDetailBottom = (props) => {
  const { sessionData } = props;

  const [modal, setModal] = useState(false);
  const [modalFalse, setModalFalse] = useState(false);

  return (
    <DetailBottomContainer>
      <DetailBottomInner>
        <Section title="Related Sessions" className="relatedSession">
          <SectionWrap>
            {sessionData.map((item, idx) => {
              const type =
                item.SessionType && item.SessionType.toLowerCase() === 'keynote'
                  ? 'keynote'
                  : item.mainCategory && item.mainCategory !== '0'
                  ? item.mainCategory
                  : '-';
              return (
                <CardSession
                  key={idx}
                  id={item.PresentationID}
                  col="col-3"
                  title={item.PresentationTitle}
                  date={item.PresentationDate}
                  description={item.AbstractTextShort?.replace(/\u00a0/gi, ' ')}
                  image={item.image}
                  bookmark
                  // tag={
                  //   item.category && item.mainCategory
                  //     ? [item.mainCategory, ...item.category]
                  //     : item.mainCategory
                  //     ? [item.mainCategory]
                  //     : item.category
                  //     ? [...item.category]
                  //     : null
                  // }
                  tag={item.category ? [...item.category] : null}
                  // SDC23 TODO: keynote card UI
                  // type={
                  //   item.SessionType &&
                  //   item.SessionType.toLowerCase() === 'keynote'
                  //     ? ''
                  //     : item.SessionType
                  // }
                  type={type}
                  color={type !== '-' ? colorMapping[type] : ''}
                  sessionData={item}
                  setModal={setModal}
                  setModalFalse={setModalFalse}
                  live={item.status === 'live' && 'on'}
                />
              );
            })}
          </SectionWrap>
        </Section>
      </DetailBottomInner>
      {/* toast popup */}
      {/* 북마크 설정 팝업 */}
      <ToastPopup
        modal={modal}
        setModal={setModal}
        state="success"
        text="This session is kept as a bookmark."
      />

      {/* 북마크 해제 팝업 */}
      <ToastPopup
        modal={modalFalse}
        setModal={setModalFalse}
        state="success"
        text="This session is no longer bookmarked."
      />
    </DetailBottomContainer>
  );
};

export default SpeakerDetailBottom;
