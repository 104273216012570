import React from 'react';
import styled from 'styled-components';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import RegisterCon from '../../components/RegisterCont';

const RegisterWrap = styled.div`
  min-height: calc(100vh - 65px - 166px);
  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 56px - 214px);
  }
`;

// TODO: When a non-login user, access to the page must be blocked.
const Registration = () => {
  return (
    <>
      <RegisterWrap>
        <DetailContainer>
          <DetailHero
            bgPc="reg-header-bg.png"
            bgMo="reg-header-bg.s.png"
            title="Registration"
            path="Registration"
            bgColor="#2088FD"
          />
          <RegisterCon />
        </DetailContainer>
      </RegisterWrap>
    </>
  );
};

export default Registration;
