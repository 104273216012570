import React from 'react';
import ActivityHeader from '../../components/ActivityHeader';
import ActivityBingoMapContent from '../../components/ActivityBingoMapContent';

const ActivityBingoMap = () => {
  return (
    <>
      <ActivityHeader isDropdown />
      <ActivityBingoMapContent />
    </>
  );
};

export default ActivityBingoMap;
