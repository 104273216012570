import React from 'react';
import { Link } from 'react-router-dom';
import {
  ActivityContainer,
  ActivityTab,
} from '../ActivityBingoMain/ActivityBingoElements';
import { ActivityBingoMapCont } from '../ActivityBingoMapContent/ActivityBingoMapElements';

const ActivityCodeLabMapContent = () => {
  return (
    <ActivityContainer type="codelab">
      <ActivityTab>
        <Link to="/activity/codelab">Activities</Link>
        <Link to="/activity/codelab/map" className="active">
          Map
        </Link>
        <Link to="/activity/codelab/prizes">Prizes</Link>
      </ActivityTab>
      <ActivityBingoMapCont>
        <p>Code Lab Map</p>
        <img
          src={`${process.env.PUBLIC_URL}/images/codelab-map.png`}
          alt="map image"
        />
        <ul className="codelab">
          <li>Code Lab</li>
          <li>Info / Giveaway Desk</li>
        </ul>
      </ActivityBingoMapCont>
    </ActivityContainer>
  );
};

export default ActivityCodeLabMapContent;
