import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import {
  MainSectionTitleArea,
  Title,
  Desc,
  Btn,
} from './MainSectionTitleElement';
import ButtonLink from '../ButtonLink';

const MainSectionTitle = (props) => {
  const { title, desc, buttonUrl, linkText } = props;
  const mainRef = useRef();
  const tl = useRef();
  const titleRef = useRef();
  const descRef = useRef();
  const buttonRef = useRef();

  gsap.registerPlugin(ScrollTrigger);
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    setHasRendered(true);
  }, []);

  useEffect(() => {
    if (hasRendered) {
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: mainRef.current,
            scrub: 0.5,
            start: 'top 80%',
            end: 'center 70%',
          },
        })
        .fromTo(titleRef.current, { y: 30, opacity: 0 }, { y: 0, opacity: 1 })
        .fromTo(
          descRef.current,
          { y: 20, opacity: 0 },
          { y: 0, opacity: 1 },
          '-=0.3'
        )
        .fromTo(
          buttonRef.current,
          { y: 20, opacity: 0 },
          { y: 0, opacity: 1 },
          '-=0.3'
        );
    }

    return () => {
      if (tl.current) tl.current.kill();
    };
  }, [hasRendered]);
  return (
    <MainSectionTitleArea className="MainSectionTitleArea" ref={mainRef}>
      <Title ref={titleRef}>{title}</Title>
      <Desc className="desc" ref={descRef}>
        {desc}
      </Desc>
      {buttonUrl && (
        <Btn ref={buttonRef}>
          <ButtonLink url={buttonUrl} color="black" size="medium">
            {linkText}
          </ButtonLink>
        </Btn>
      )}
    </MainSectionTitleArea>
  );
};

export default MainSectionTitle;
