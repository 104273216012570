import React from 'react';
import ActivityHeader from '../../components/ActivityHeader';
import ActivityMain from '../../components/ActivityMain';

const ActivityEvent = () => {
  return (
    <>
      <ActivityHeader />
      <ActivityMain />
    </>
  );
};

export default ActivityEvent;
