import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import KoreaConsent from '../../components/KoreaConsent';

const KoreaConsentPage = () => {
  return (
    <>
      <DetailContainer>
        <KoreaConsent />
      </DetailContainer>
    </>
  );
};

export default KoreaConsentPage;
