import { useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components';
import Button from '../../components/Button';
import DetailContainer from '../../components/DetailContainer';
import Loading from '../../components/Loading';
import withRouter from '../../components/Router/withRouter';
import { Delete, Get } from '../../utils/Requester';

// =============================================== //

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px - 175px);
  gap: 25px;
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 5px;
  margin-bottom: 25px;
`;

export const PointsLabel = styled.div`
  font-size: 24px;
`;

export const DescriptionLabel = styled.div`
  opacity: 0.5;
  max-width: 384px;
`;

export const NameLabel = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

export const PlaceholderQR = styled.div`
  width: 256px;
  height: 256px;
  background-color: gray;
  opacity: 0.5;
`;

// =============================================== //

export const getQR = async (id) => {
  return Get({ url: `/api/sdc/qr/${id}` })
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return null;
    });
};

export const deleteQRClaims = async () => {
  return Delete({ url: `/api/sdc/qr` })
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return null;
    });
};

export const decodeQRId = (qrId) => {
  try {
    return qrId ? atob(qrId) : '';
  } catch (err) {
    return '';
  }
};

// =============================================== //

const QR = (props) => {
  const { params } = props;
  const { qrId = '' } = params;
  const [loading, setLoading] = useState(false);
  const [qr, setQR] = useState(null);

  const decodedQRId = useMemo(() => {
    return decodeQRId(qrId);
  }, [qrId]);

  const deleteClaims = async () => {
    setLoading(true);
    deleteQRClaims().finally(() => {
      setLoading(false);
    });
  };

  const handleRefresh = () => {
    setLoading(true);
    getQR(decodedQRId)
      .then((data) => {
        setQR(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (decodedQRId) handleRefresh();
  }, [decodedQRId]);

  const qrValue = new URL(window.location.href);
  qrValue.pathname = `/qr/claim/${qrId}`;

  const points = qr?.activity.points || 0;
  const description = qr?.activity.description || 'No Description Available';
  const name = qr?.activity.name || 'Invalid QR Code';

  return (
    <DetailContainer>
      <Main>
        <NameLabel>{name}</NameLabel>
        {!loading && (
          <a href={qrValue.toString()} alt="QR" target="_blank">
            <QRCode size={256} value={qrValue.toString()} />
          </a>
        )}
        {loading && <PlaceholderQR />}
        {loading && <Loading />}
        <Labels>
          <PointsLabel>
            {points < 0 ? `- ${points}` : `+ ${points}`} Points
          </PointsLabel>
          <DescriptionLabel>{description}</DescriptionLabel>
        </Labels>
        <Button onClick={deleteClaims}>Delete Claims</Button>
      </Main>
    </DetailContainer>
  );
};

const QRWrapper = (props) => {
  const [authorized, setAuthorized] = useState(false);

  if (!authorized) {
    const pass = prompt('Enter QR Code Password');
    if (pass === 'sdc2024') setAuthorized(true);
    else return <div>Unauthorized</div>;
  }

  return <QR {...props} />;
};

export default withRouter(QRWrapper);
