import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const MainKeynoteArea = styled.div`
  padding: 0 0 148px 0;
  font-size: 14px;

  .MainSectionTitleArea {
    padding-bottom: 80px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0 60px;

    .MainSectionTitleArea {
      padding-bottom: 48px;
    }
  }
`;

export const LinkArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  &.is-DesktopSlide {
    margin-top: 20px;
  }
  @media screen and (max-width: 1024px) {
    margin-top: 40px;
    &.is-DesktopSlide {
      margin-top: 40px;
    }
  }
`;

export const CardWrap = styled.div`
  overflow: hidden;
  @media screen and (min-width: 1420px) {
    padding-top: 50px;
  }
`;

export const CardInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 112px;

  &.is-DesktopSlide {
    display: block;

    .slick-slider {
      padding: 0;
    }
  }

  .slick-list {
    overflow: visible;
  }

  @media screen and (max-width: 768px) {
    display: block;
    padding: 0;
  }

  @media screen and (min-width: 1420px) {
    .slick-slide {
      opacity: 0;
      transform: translateX(500px);
      transition: all 0s ease-out;
    }

    transform: translateX(300px);
    transition: all 0s ease-out;
    &.is-active {
      transform: translateX(0);
      transition-duration: 0.5s;
      .slick-slide {
        opacity: 1;
        transform: translateX(0);
        transition-duration: 0.5s;
        transition-delay: 0s;
      }
      .slick-slide:nth-of-type(2) {
        transition-delay: 0.1s;
      }
      .slick-slide:nth-of-type(3) {
        transition-delay: 0.2s;
      }
      .slick-slide:nth-of-type(4) {
        transition-delay: 0.3s;
      }
      .slick-slide:nth-of-type(5) {
        transition-delay: 0.4s;
      }
      .slick-slide:nth-of-type(6) {
        transition-delay: 0.5s;
      }
      .slick-slide:nth-of-type(7) {
        transition-delay: 0.6s;
      }
    }
    &.is-active.align-card {
      .slick-slide {
        transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-delay: 0s;
        &:nth-of-type(odd) {
          margin-top: -50px;
        }
        &:nth-of-type(even) {
          margin-top: 50px;
        }
      }
    }
  }
`;
