import { call, put, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment-timezone';
import * as types from '../ActionTypes';
import { Get } from '../../utils/Requester';
import meta from '../../sample/p2_meta.json';

export function* getMetaLive() {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      // NOTE: get phase2 data
      // yield put({
      //   type: types.GET_META_LIVE_SUCCESS,
      //   data: meta,
      //   gapTime: 0,
      // });

      // NOTE: get phase3 data
      // yield put({
      //   type: types.GET_META_LIVE_SUCCESS,
      //   data: require('../../sample/p3_meta.json'),
      //   gapTime: 0,
      // });

      // NOTE: get data by local env phase
      yield put({
        type: types.GET_META_LIVE_SUCCESS,
        // eslint-disable-next-line import/no-dynamic-require
        data: require(`../../sample/p${process.env.REACT_APP_PHASE}_meta.json`),
        gapTime: 0,
      });

      return;
    }

    const { data, error } = yield call(Get, {
      url: `/api/meta/live`,
    });
    if (error || typeof data === 'string') {
      console.error('Meta', error);
      yield put({
        type: types.GET_META_LIVE_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
    }

    const gap = moment(moment.utc().valueOf()).diff(data.currentTime);
    const gapMin = Math.floor(gap / 60000);

    delete data.currentTime; // delete to current time

    yield put({
      type: types.GET_META_LIVE_SUCCESS,
      data,
      gapTime: gapMin !== 0 ? gap : 0,
    });
  } catch (error) {
    if (error) {
      console.error('Meta', error);
      yield put({
        type: types.GET_META_LIVE_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_META_LIVE_REQUEST, getMetaLive);
}
