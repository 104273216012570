import styled from 'styled-components';

export const PopupContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const PopupInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 312px;
  height: 450px;
  padding: 264px 24px 0;
  border-radius: 24px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;

  .lottie {
    position: absolute;
    left: 0;
    top: 0;
    width: 312px;
    height: 180px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/reward-prize-bg.svg`});
  }
  .prod {
    position: absolute;
    left: 50%;
    top: 58px;
    transform: translateX(-50%);
    padding: 26px;
    border-radius: 16px;
    background-color: #f6f6f6;
    img {
      display: block;
      width: 130px;
      height: 130px;
    }
  }
`;

export const PopupTitle = styled.div`
  font-family: 'samsungsharpsans-bold';
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

export const PopupText = styled.div`
  margin-top: 8px;
`;

export const PopupButton = styled.div`
  margin-top: 32px;
  display: inline-block;
`;
