import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  DropdownWrap,
  DropdownBtn,
  DropdownList,
  DropdownListItem,
} from './DropdownElement';
import Chevron from './Chevron';

const Dropdown = (props) => {
  const { url, btnText, itemText, onClick } = props;
  const dropdownItemText = itemText;

  const [isActive, setActive] = useState(false);
  const toggleActive = () => {
    setActive(!isActive);
  };

  return (
    <DropdownWrap
      className={`dropdown ${isActive ? 'active' : ''}`}
      onClick={toggleActive}
    >
      <DropdownBtn>
        {btnText}
        <Chevron className="arr" />
      </DropdownBtn>
      <DropdownList className="list">
        {dropdownItemText &&
          dropdownItemText.map((item, idx) => (
            <DropdownListItem key={idx} onClick={onClick}>
              <Link to={url[idx]}>{item}</Link>
            </DropdownListItem>
          ))}
      </DropdownList>
    </DropdownWrap>
  );
};

export default Dropdown;
