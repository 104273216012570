import React, { useEffect, useState } from 'react';

const NavLogo = ({ fill }) => {
  // 👇️ paste SVG into a component
  // take fill and stroke colors as props
  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  // Handle window resize event
  useEffect(() => {
    const handleResize = () => {
      setDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <svg
      width={isDesktop ? 76 : 62}
      height={isDesktop ? 20 : 16}
      viewBox="0 0 76 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 15.6472L2.86609 13.3719C3.72103 14.8348 5.03828 15.6707 6.56451 15.6707C8.22915 15.6707 9.13023 14.5797 9.13023 13.3954C9.13023 11.956 7.41945 11.5145 5.61638 10.9573C3.35192 10.2371 0.832325 9.35503 0.832325 6.12796C0.832325 3.41115 3.18997 1.27515 6.42608 1.27515C9.15376 1.27515 10.7252 2.32008 12.0886 3.73684L9.49935 5.70999C8.78283 4.64244 7.78947 4.08424 6.4487 4.08424C4.92338 4.08424 4.09105 4.92019 4.09105 6.01216C4.09105 7.35835 5.70956 7.79985 7.53525 8.40329C9.82323 9.14604 12.4125 10.1674 12.4125 13.4189C12.4125 16.0887 10.2855 18.6897 6.58803 18.6897C3.53647 18.6897 1.52623 17.3897 0 15.6481"
        fill={fill}
      />
      <path
        d="M32.9648 9.98191C32.9648 4.92011 36.7555 1.27417 41.6327 1.27417C44.7992 1.27417 47.5494 2.94606 49.0295 5.47741L46.2793 7.24248C45.3076 5.47741 43.8058 4.38634 41.6327 4.38634C38.4427 4.38634 36.0851 6.8245 36.0851 9.98191C36.0851 13.1393 38.4201 15.5313 41.563 15.5313C43.8981 15.5313 45.5392 14.1851 46.4168 12.3504L49.2829 13.8595C47.8037 16.7391 44.9837 18.6887 41.6318 18.6887C36.4316 18.6887 32.9639 14.8112 32.9639 9.981"
        fill={fill}
      />
      <path
        d="M20.418 3.94032H18.064V16.0226H20.418V18.6887H14.874V1.27417H20.418V3.94032Z"
        fill={fill}
      />
      <path
        d="M31.0109 9.98186C31.0109 5.53888 28.523 1.75362 24.5106 1L23.501 3.97375C26.4295 4.21802 27.8915 7.57355 27.8915 9.98186C27.8915 12.3902 26.4204 15.7303 23.501 15.9484L24.5305 18.9683C28.7419 18.202 31.0118 14.2457 31.0118 9.98186"
        fill={fill}
      />
      <path
        d="M51.5215 15.4364C51.5215 14.7126 51.6509 14.0576 51.9115 13.4713C52.1711 12.886 52.5113 12.3504 52.9319 11.8646C53.3526 11.3788 53.8267 10.9364 54.3541 10.5356C54.8816 10.1348 55.4054 9.75755 55.9256 9.40291C56.3382 9.11069 56.7362 8.82933 57.1189 8.55882C57.5007 8.28922 57.8372 8.01962 58.1286 7.75002C58.419 7.48042 58.6524 7.20358 58.8279 6.91769C59.0034 6.63271 59.0921 6.32783 59.0921 6.00485C59.0921 5.45027 58.8966 4.97621 58.5067 4.58357C58.1168 4.19093 57.5468 3.9937 56.7977 3.9937C56.0486 3.9937 55.4633 4.21716 54.9974 4.66409C54.5306 5.11101 54.1524 5.68911 53.862 6.39749L51.1777 5.03411C51.6979 3.8779 52.428 2.96506 53.368 2.29467C54.3089 1.62429 55.4588 1.28955 56.8203 1.28955C57.6608 1.28955 58.4181 1.41349 59.0912 1.65957C59.7643 1.90656 60.3378 2.2413 60.8119 2.6647C61.2859 3.089 61.6451 3.5893 61.8903 4.1674C62.1346 4.74551 62.2576 5.36613 62.2576 6.02837C62.2576 6.69061 62.1346 7.30762 61.8903 7.83144C61.6451 8.35526 61.3239 8.83656 60.9268 9.27625C60.5287 9.71503 60.0818 10.1203 59.5851 10.4895C59.0875 10.8595 58.5872 11.2141 58.0824 11.5525C57.6382 11.8456 57.2139 12.1342 56.8095 12.4192C56.4042 12.7042 56.0414 13.0009 55.7202 13.3094C55.3991 13.6179 55.1385 13.9418 54.9404 14.2801C54.7414 14.6194 54.6418 14.9894 54.6418 15.3893H62.1888V18.3938H51.5224V15.4354L51.5215 15.4364Z"
        fill={fill}
      />
      <path
        d="M70.311 18.1314V14.2421H63V11.3714L70.1475 1.23145H73.3942V11.3714H75.8934V14.2421H73.3942V18.1314H70.311ZM66.4336 11.3714H70.311V5.93103L66.4336 11.3714Z"
        fill={fill}
      />
    </svg>
  );
};

export default NavLogo;
