import styled from 'styled-components';

export const RegisterWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 140px 40px;

  .inner {
    min-height: 240px;
    height: auto;
  }

  .title {
    font-size: 32px;
    line-height: 42px;
    font-feature-settings: 'clig' off, 'liga' off;
    margin-bottom: 8px;
  }

  .desc {
    font-size: 20px;
  }

  .banner-btn {
    width: auto;
    //position: static;
    max-width: 179px;
    margin-top: 48px;
  }

  @media (max-width: 768px) {
    display: block;
    margin: 0 auto;
    padding: 40px 24px 60px;

    .inner {
      height: auto;
      min-height: auto;
    }

    .title {
      font-size: 20px;
      line-height: 28px;
    }

    .desc {
      font-size: 14px;
      line-height: 20px;
    }

    .banner-btn {
      margin-top: 32px;
      max-width: none;
      > a {
        min-width: 144px;
      }
    }
  }
`;

export const RegisterWrapInner = styled.div``;
