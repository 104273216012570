import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  ScheduleContainer,
  ScheduleInner,
  ScheduleTitle,
  ScheduleBanner,
  ScheduleWrap,
  ScheduleGrid,
  EngagementBox,
  EngagementBoxBg,
  EngagementBoxInner,
  EngagementTitle,
  EngagementText,
  GridInfo,
  GridInfoList,
  GridInfoItem,
  GridInfoMore,
  ScheduleIconBox,
  ScheduleItem,
  ScheduleCardContainer,
  TechFilterBtn,
  ScheduleRoomCard,
} from './ScheduleContElements';
import Button from '../Button';
import Filter from '../Filter/shcedule';
import withRouter from '../Router/withRouter';
import { convertURL } from '../../utils/Functions';

// schedule sample data
import { ScheduleData } from '../../sample/ScheduleData';

const ScheduleCard = ({
  title,
  time,
  speaker,
  desc,
  type,
  room,
  tags,
  filterCount,
  hideCard,
  isMobile,
}) => {
  const className = `${
    type?.toLowerCase().indexOf('tech') > -1 ? 'col-3' : 'col-1'
  } ${desc ? 'desc' : ''} ${filterCount ? 'filter' : ''} ${
    hideCard ? 'hide' : ''
  }`;

  return (
    <ScheduleCardContainer
      className={className}
      onClick={(e) => {
        e.preventDefault();
        if (!desc) {
          window.location = `/sessions/${convertURL(title)}`;
        }
      }}
    >
      {desc ? (
        <div>{desc}</div>
      ) : (
        <>
          <div className="top">
            <div
              className={`location ${
                type.toLowerCase().indexOf('open') > -1 ? 'open' : ''
              }`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-schedule-${
                  type.toLowerCase().indexOf('tech') > -1 ? 'tech' : 'open'
                }.svg`}
                alt="schedule-tech"
              />
              <div className="text-box">
                <div className="room">{room}</div>
                <div className="time">{time}</div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div>
              <div className="title">{title}</div>
              <div className="speaker">{speaker}</div>
            </div>
            <div className="bottom-box">
              <div className="tag-box">
                {type.toLowerCase().indexOf('tech') > -1 || isMobile
                  ? tags
                      .slice(0, 1)
                      .map((tag) => <div className="tag">{tag}</div>)
                  : tags.map((tag) => <div className="tag">{tag}</div>)}
                {tags.length > 1 &&
                  (type.toLowerCase().indexOf('tech') > -1 || isMobile) && (
                    <span>{`+ ${tags.length - 1}`}</span>
                  )}
              </div>
              <div className="link-btn">
                <img
                  src={`${process.env.PUBLIC_URL}/images/ico-schedule-arrow.svg`}
                  alt="schedule-tech"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </ScheduleCardContainer>
  );
};

const ScheduleCont = (props) => {
  // Phase2.5부터 true로 변경
  const [after, setAfter] = useState(true);
  const phase = useSelector((state) => state.meta.data.phase);
  const {
    isLoading,
    openStage,
    timeSchedule,
    roomSchedule,
    filterData,
    checkedMainCategories,
    checkedTopics,
    checkedLocations,
  } = useSelector((state) => state.schedule);

  const [scheduleFilterData, setFilterData] = useState({
    session: [],
    stage: [],
  });

  // make filter data
  useEffect(() => {
    if (Object.keys(filterData).length > 0) {
      // has data
      const { session, stage } = filterData;
      if (session.length > 0 || stage.length > 0) {
        let sessionFilter = session || [];
        let openStageFilter = stage || [];

        // filter to main category
        if (checkedMainCategories && checkedMainCategories.length > 0) {
          sessionFilter = sessionFilter.filter((x) =>
            checkedMainCategories.includes(x.MainCategory)
          );
          openStageFilter = openStageFilter.filter((x) =>
            checkedMainCategories.includes(x.MainCategory)
          );
        }

        // filter to topic
        if (checkedTopics.length > 0) {
          sessionFilter = sessionFilter.filter((x) => {
            return checkedTopics.some((element) =>
              x.PresentationCategory.includes(element)
            );
          });
          openStageFilter = openStageFilter.filter((x) => {
            return checkedTopics.some((element) =>
              x.PresentationCategory.includes(element)
            );
          });
        }

        // filter to location
        if (checkedLocations && checkedLocations.length > 0) {
          sessionFilter = sessionFilter.filter((x) =>
            checkedLocations.includes(x.PresentationRoom)
          );
          openStageFilter = openStageFilter.filter((x) =>
            checkedLocations.includes(x.PresentationRoom)
          );
        }

        setFilterData({
          session: [...sessionFilter],
          stage: [...openStageFilter],
        });
      }
    }
  }, [checkedMainCategories, checkedTopics, checkedLocations]);

  // console.log('scheduleFilterData', scheduleFilterData);

  // mobile more button
  const [roomsMore, setRoomsMore] = useState([true, false, false]);
  const [contentsMore, setContentsMore] = useState([false, false, false]);

  // room more button handling for mobile
  const toggleRoomMore = (index) => {
    setRoomsMore((prev) => prev.map((item, i) => (i === index ? !item : item)));
  };

  const contentsMoreToggle = (index) => {
    setContentsMore((prev) =>
      prev.map((item, i) => (i === index ? !item : item))
    );
  };

  // More button handler - PC
  const [more, setMore] = useState(false);
  const textHandler = () => {
    setMore(!more);
  };

  const [more2, setMore2] = useState(false);
  const textHandler2 = () => {
    setMore2(!more2);
  };

  // AHall More button handler - MO
  const [moreA, setMoreA] = useState(true);
  const moreAHandler = () => {
    setMoreA(!moreA);
  };

  // BHall More button handler - MO
  const [moreB, setMoreB] = useState(false);
  const moreBHandler = () => {
    setMoreB(!moreB);
  };

  // CHall More button handler - MO
  const [moreC, setMoreC] = useState(false);
  const moreCHandler = () => {
    setMoreC(!moreC);
  };

  const [moreTheater, setMoreTheater] = useState(false);
  const moreTheaterHandler = () => {
    setMoreTheater(!moreTheater);
  };

  const [openA, setOpenA] = useState(true);
  const [openB, setOpenB] = useState(false);
  const [openC, setOpenC] = useState(false);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const [isMobile, setMobile] = useState(window.innerWidth < 768);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const calculateDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { hour: '2-digit', minute: '2-digit', hour12: false };
    return date.toLocaleTimeString('ko-KR', options);
  };

  const getPresenters = (presenters) => {
    const presenterList = presenters.map(
      (presenter) =>
        `${presenter.PresenterFirstName} ${presenter.PresenterLastName}`
    );
    return presenterList.join(', ');
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const hoverEnter = (e) => {
    e.target.classList.add('is-active');
  };
  const hoverLeave = (e) => {
    e.target.classList.remove('is-active');
  };

  // gsap interaction
  const tl = useRef();
  // const tabRef = useRef();
  const gridRef = useRef();

  // const [tab, setTab] = useState(false);
  // const tabHandler = () => {
  //   setTab(!tab);
  // };

  // const { location } = props;
  // const { hash } = location;

  // const [tabIdx, setTab] = useState(0);
  // const tabHandler = (index) => {
  //   const { navigate } = props;
  //   setTab(index);
  //   if (index === 1) {
  //     navigate('#online');
  //   } else {
  //     navigate('');
  //   }
  // };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
    setMobile(window.innerWidth < 768);
  };

  // show/hide filter based on window size
  const filterHandler = () => {
    if (!isDesktop) {
      setFilterOpen(false);
    } else {
      setFilterOpen(true);
    }
  };

  // useLayoutEffect(() => {
  //   if (hash === '#online') {
  //     setTab(1);
  //   } else {
  //     setTab(0);
  //   }
  // }, [hash]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    tl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: gridRef.current,
        },
      })
      .fromTo(
        gridRef.current,
        { opacity: 0, y: 40 },
        { opacity: 1, y: 0, duration: 0.3 }
      );

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
      tl.current.kill();
    };
  }, [isLoading]);

  useEffect(() => {
    window.addEventListener('resize', updateMedia, filterHandler);

    return () =>
      window.removeEventListener('resize', updateMedia, filterHandler);
  });

  return (
    <ScheduleContainer>
      {/* <TechFilterBtn
        onClick={toggleFilter}
        className={filterOpen && 'is-checked'}
      >
        Filter
      </TechFilterBtn> */}
      <ScheduleInner>
        {/* {isDesktop ? (
          <Filter
            className="schedule"
            setOpen={setFilterOpen}
            setCount={setFilterCount}
          />
        ) : filterOpen ? (
          <Filter
            className="schedule"
            setOpen={setFilterOpen}
            setCount={setFilterCount}
          />
        ) : null} */}
        <ScheduleIconBox>
          <div className="icon-item">
            <img
              src={`${process.env.PUBLIC_URL}/images/ico-schedule-tech.svg`}
              alt="schedule-tech"
            />
            Tech Sessions
          </div>
          <div className="icon-item">
            <img
              src={`${process.env.PUBLIC_URL}/images/ico-schedule-open.svg`}
              alt="schedule-open"
            />
            Open Stage
          </div>
        </ScheduleIconBox>
        <ScheduleWrap ref={gridRef}>
          {/* ScheduleGrid > p3 props unused at sdc24 */}
          <ScheduleGrid
            className="on-site"
            p3={phase === 'phase3' || phase === 'phase4'}
          >
            <ScheduleItem>
              <div className="index-box item-01">
                <div className="index-text">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ico-watch.svg`}
                    alt="watch"
                  />
                  08:30 - 10:00
                </div>
              </div>
              <div className="item_wrap">
                <div className="box">
                  <div className="title">Registration</div>
                </div>
              </div>
            </ScheduleItem>
            <ScheduleItem>
              <div className="index-box item-02">
                <div className="index-text">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ico-watch.svg`}
                    alt="watch"
                  />
                  10:00 - 11:10
                </div>
              </div>
              <div className="item_wrap">
                <Link
                  to="/keynote"
                  className="box"
                  onMouseEnter={hoverEnter}
                  onMouseLeave={hoverLeave}
                >
                  <div className="title">
                    Keynote
                    <img
                      src={`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`}
                      alt="ico-arrow"
                    />
                  </div>
                </Link>
              </div>
            </ScheduleItem>
            <ScheduleItem>
              <div className="index-box item-03">
                <div className="index-text">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ico-watch.svg`}
                    alt="watch"
                  />
                  11:10 - 18:00
                </div>
              </div>
              <div className="item_wrap">
                <Link
                  to="/tech-square"
                  className="box"
                  onMouseEnter={hoverEnter}
                  onMouseLeave={hoverLeave}
                >
                  <div className="title">
                    Tech Square + Networking
                    <img
                      src={`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`}
                      alt="ico-arrow"
                    />
                  </div>
                </Link>
              </div>
            </ScheduleItem>
            <ScheduleItem>
              <div className="index-box item-04">
                <div className="index-text">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ico-watch.svg`}
                    alt="watch"
                  />
                  11:30 - 18:00
                </div>
              </div>
              <div className="item_wrap">
                <Link
                  to="/sessions"
                  className={`box ${after ? 'after' : null}`}
                  onMouseEnter={hoverEnter}
                  onMouseLeave={hoverLeave}
                >
                  <div className="title">
                    Tech Session
                    <img
                      src={`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`}
                      alt="ico-arrow"
                    />
                  </div>
                </Link>
                {after &&
                  (isDesktop ? (
                    <GridInfo className={more ? 'is-appended' : ''}>
                      <div className="inner has-first">
                        <GridInfoList
                          className={`${moreA ? 'is-appended' : ''}`}
                        >
                          <div className="schedule-card-box">
                            {timeSchedule &&
                              Object.keys(timeSchedule).map((x) => {
                                return timeSchedule[x].map((item) => (
                                  <ScheduleCard
                                    filterCount={filterCount}
                                    key={item.PresentationID}
                                    title={item.PresentationTitle}
                                    time={`${moment(
                                      item.PresentationDateTimeStart
                                    ).format('HH:mm')} - ${moment(
                                      item.PresentationDateTimeEnd
                                    ).format('HH:mm')}`}
                                    speaker={getPresenters(
                                      item.Presenters || []
                                    )}
                                    desc={item.desc}
                                    type={item.SessionType}
                                    room={item.PresentationRoom}
                                    tags={
                                      typeof item.PresentationCategory ===
                                        'object' &&
                                      item.MainCategory &&
                                      item.MainCategory !== '0'
                                        ? [
                                            item.MainCategory,
                                            ...item.PresentationCategory,
                                          ]
                                        : item.MainCategory &&
                                          item.MainCategory !== '0'
                                        ? [item.MainCategory]
                                        : typeof item.PresentationCategory ===
                                          'object'
                                        ? [...item.PresentationCategory]
                                        : null
                                    }
                                  />
                                ));
                              })}
                          </div>
                        </GridInfoList>
                      </div>
                      <GridInfoMore
                        onClick={textHandler}
                        className={more ? 'is-appended' : ''}
                      >
                        {more ? 'Less' : 'More'}
                      </GridInfoMore>
                    </GridInfo>
                  ) : (
                    <>
                      {/* When on mobile, translate the sorting by room */}
                      {roomSchedule &&
                        Object.keys(roomSchedule).map((x, idx) => (
                          <GridInfo
                            key={x}
                            className={`room ${
                              contentsMore[idx] ? 'is-appended' : ''
                            }`}
                          >
                            <div className="inner has-first">
                              <ScheduleRoomCard>
                                <div className="room-title">
                                  {x}
                                  <button
                                    type="button"
                                    onClick={() => toggleRoomMore(idx)}
                                    className={`${
                                      roomsMore[idx] ? '' : 'is-opened'
                                    }`}
                                  >
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/ico-arrow-gray.svg`}
                                      alt="arrow"
                                    />
                                  </button>
                                </div>
                                <GridInfoList
                                  className={`${moreA ? 'is-appended' : ''} ${
                                    roomsMore[idx] ? '' : 'close'
                                  }`}
                                >
                                  <div className="schedule-card-box">
                                    {roomSchedule &&
                                      roomSchedule[x].map((item, index) => (
                                        <ScheduleCard
                                          filterCount={filterCount}
                                          key={item.PresentationID}
                                          title={item.PresentationTitle}
                                          time={`${moment(
                                            item.PresentationDateTimeStart
                                          ).format('HH:mm')} - ${moment(
                                            item.PresentationDateTimeEnd
                                          ).format('HH:mm')}`}
                                          speaker={getPresenters(
                                            item.Presenters || []
                                          )}
                                          desc={item.desc}
                                          type={item.SessionType}
                                          room={item.PresentationRoom}
                                          tags={
                                            typeof item.PresentationCategory ===
                                              'object' &&
                                            item.MainCategory &&
                                            item.MainCategory !== '0'
                                              ? [
                                                  item.MainCategory,
                                                  ...item.PresentationCategory,
                                                ]
                                              : item.MainCategory &&
                                                item.MainCategory !== '0'
                                              ? [item.MainCategory]
                                              : typeof item.PresentationCategory ===
                                                'object'
                                              ? [...item.PresentationCategory]
                                              : null
                                          }
                                          hideCard={
                                            index >= 5 &&
                                            !contentsMore[idx] &&
                                            !isDesktop
                                          }
                                        />
                                      ))}
                                  </div>
                                </GridInfoList>
                              </ScheduleRoomCard>
                            </div>
                            <GridInfoMore
                              key={`${x}-more`}
                              onClick={() => contentsMoreToggle(idx)}
                              className={`${
                                contentsMore[idx] ? 'is-appended' : ''
                              } ${roomsMore[idx] ? '' : 'close'}`}
                            >
                              {contentsMore[idx] ? 'Less' : 'More'}
                            </GridInfoMore>
                          </GridInfo>
                        ))}
                    </>
                  ))}
              </div>
            </ScheduleItem>
            <ScheduleItem>
              <div className="index-box item-01">
                <div className="index-text">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ico-watch.svg`}
                    alt="watch"
                  />
                  11:30 - 17:30
                </div>
              </div>
              <div className="item_wrap">
                <Link
                  to={`/sessions?filter=${encodeURIComponent('Open Stage')}`}
                  className={`box ${after ? 'after' : null}`}
                  onMouseEnter={hoverEnter}
                  onMouseLeave={hoverLeave}
                >
                  <div className="title">
                    Open Stage
                    <img
                      src={`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`}
                      alt="ico-arrow"
                    />
                  </div>
                </Link>
                {after && (
                  <GridInfo className={more2 ? 'is-appended' : ''}>
                    <div className="inner h-auto">
                      <GridInfoList
                        className={`${moreTheater ? 'is-appended' : ''}`}
                      >
                        {/* <div className="grid_session_flex"> */}
                        <div className="schedule-card-box">
                          {/* Areas that should be displayed based on DB data */}
                          {openStage &&
                            openStage.map((item, idx) => (
                              <ScheduleCard
                                filterCount={filterCount}
                                key={item.PresentationID}
                                title={item.PresentationTitle}
                                time={`${moment(
                                  item.PresentationDateTimeStart
                                ).format('HH:mm')} - ${moment(
                                  item.PresentationDateTimeEnd
                                ).format('HH:mm')}`}
                                speaker={getPresenters(item.Presenters || [])}
                                desc={item.desc}
                                type={item.SessionType}
                                room={item.PresentationRoom}
                                tags={
                                  typeof item.PresentationCategory ===
                                    'object' &&
                                  item.MainCategory &&
                                  item.MainCategory !== '0'
                                    ? [
                                        item.MainCategory,
                                        ...item.PresentationCategory,
                                      ]
                                    : item.MainCategory &&
                                      item.MainCategory !== '0'
                                    ? [item.MainCategory]
                                    : typeof item.PresentationCategory ===
                                      'object'
                                    ? [...item.PresentationCategory]
                                    : null
                                }
                                isMobile={isMobile}
                              />
                            ))}
                        </div>
                      </GridInfoList>
                    </div>
                  </GridInfo>
                )}
              </div>
            </ScheduleItem>
            <ScheduleItem>
              <div className="index-box item-05">
                <div className="index-text">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ico-watch.svg`}
                    alt="watch"
                  />
                  11:30 - 17:30
                </div>
              </div>
              <div className="item_wrap">
                <Link
                  to="/codelab"
                  className="box"
                  onMouseEnter={hoverEnter}
                  onMouseLeave={hoverLeave}
                >
                  <div className="title">
                    Code Lab
                    <img
                      src={`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`}
                      alt="ico-arrow"
                    />
                  </div>
                </Link>
              </div>
            </ScheduleItem>
            <ScheduleItem>
              <div className="index-box item-06">
                <div className="index-text">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ico-watch.svg`}
                    alt="watch"
                  />
                  11:30 - 19:00
                </div>
              </div>
              <div className="item_wrap">
                <Link
                  to="/event"
                  className="box"
                  onMouseEnter={hoverEnter}
                  onMouseLeave={hoverLeave}
                >
                  <div className="title">
                    On-site Events
                    <img
                      src={`${process.env.PUBLIC_URL}/images/ico-arr-right.svg`}
                      alt="ico-arrow"
                    />
                  </div>
                </Link>
              </div>
            </ScheduleItem>
          </ScheduleGrid>
        </ScheduleWrap>
      </ScheduleInner>
    </ScheduleContainer>
  );
};

export default withRouter(ScheduleCont);
