import React, { useState } from 'react';
import {
  TicketWrap,
  TicketInner,
  TicketDetail,
  TicketImg,
  TicketInfo,
} from './TicketElement';

const Ticket = (props) => {
  const {
    registerFirstName,
    registerLastName,
    date,
    location,
    type,
    country,
    company,
    occupation,
  } = props;

  return (
    <TicketWrap id="on-site-ticket">
      <TicketInner>
        <TicketDetail>
          <TicketImg id="ticket">
            <div className="qrcode">
              <img src="/images/commTicket-qrcode.svg" alt="QR code" />
            </div>
          </TicketImg>
          <TicketInfo>
            <div className="name">
              {registerFirstName} {registerLastName}
            </div>
            <ul className="info-detail">
              <li>{location}</li>
              <li>{date}</li>
            </ul>
          </TicketInfo>
        </TicketDetail>
      </TicketInner>
    </TicketWrap>
  );
};

export default Ticket;
