import styled from 'styled-components';

export const ScheduleContainer = styled.div`
  padding: 0 40px;
  background-color: #f6f6f6;

  .MainSectionTitleArea {
    br {
      display: block;
    }
  }

  @media (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const ScheduleInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0 148px 0;

  @media (max-width: 768px) {
    padding: 0 0 60px 0;
  }
`;

export const ScheduleList = styled.div``;

export const ScheduleCalendar = styled.div`
  border-radius: 14px;
  overflow: hidden;
  opacity: 0;
  transform: translateY(150px);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &.active {
    opacity: 1;
    transform: translateY(0);
  }
  & + & {
    margin-top: 20px;
  }
  &:nth-of-type(1) .title {
    background-color: #10b77b;
  }
  &:nth-of-type(2) .title {
    background-color: #1259aa;
  }
  &:nth-of-type(3) .title {
    background-color: #756bf1;
  }
  &:nth-of-type(4) .title {
    background-color: #2088fd;
  }
  &:nth-of-type(5) .title {
    background-color: #ca6ceb;
  }
  &:nth-of-type(6) .title {
    background-color: #0f42cf;
  }

  .title {
    display: flex;
    justify-content: space-between;
    padding: 13px 28px 9px;
    font-family: 'SamsungSharpSans-Bold';
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    span + span {
      font-family: 'SamsungOne-700';
    }
  }
  .content {
    position: relative;
    background-color: #fff;
    .cont-li {
      position: relative;
      padding: 24px 28px;
      display: flex;
      justify-content: space-between;
    }
    .cont-li + .cont-li {
      border-top: 1px solid #f6f6f6;
    }

    p {
      font-family: 'SamsungOne-700';
      font-size: 24px;
      line-height: 32px;
    }
    p + p {
      color: #7c7c7c;
    }
  }

  @media (max-width: 768px) {
    border-radius: 12px;
    & + & {
      margin-top: 12px;
    }
    .title {
      padding: 10px 20px 6px;
      font-size: 20px;
      line-height: 28px;
      span + span {
        display: none;
      }
    }
    .content {
      .cont-li {
        padding: 16px 20px;
        display: block;
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }
      p + p {
        margin-top: 4px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;
