import { takeLatest } from 'redux-saga/effects';
import * as types from '../ActionTypes';

function termsDownload({ termsContents, filename }) {
  try {
    const childNodes = [...termsContents.current.childNodes];
    let data = '';
    childNodes.forEach((x, idx) => {
      if (idx === 0) {
        [...x.childNodes].forEach((child, index) => {
          if (index !== x.childNodes.length - 1) {
            if (
              child.childNodes.length === 2 &&
              child.id.indexOf('terms') === -1
            ) {
              [...child.childNodes].forEach((item) => {
                if (item.classList && item.classList.contains('dropdown')) {
                  data += item.firstChild.innerText;
                }
              });
            } else {
              data += child.outerHTML;
            }
          }
        });
      } else {
        data += x.outerHTML;
      }
    });

    const blob = new Blob([...data], {
      type: 'text/html',
    });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const blobURL =
        window.URL && window.URL.createObjectURL
          ? window.URL.createObjectURL(blob)
          : window.webkitURL.createObjectURL(blob);

      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);

      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }

      document.body.appendChild(tempLink);
      tempLink.click();

      setTimeout(() => {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
      }, 200);
    }
  } catch (error) {
    console.log('error', error);
  }
}

export default function* rootSaga() {
  yield takeLatest(types.TERMS_DOWNLOAD_REQUEST, termsDownload);
}
