/* eslint-disable import/named */
import React, { useEffect, useRef, useState } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { MainCodeLabArea, MainCodeLabWrap } from './MainCodeLabElement';
import MainSectionTitle from '../MainSectionTitle';

const MainCodeLab = () => {
  const tl = useRef();
  const mainRef = useRef();
  const imgRef = useRef();

  gsap.registerPlugin(ScrollTrigger);
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    setHasRendered(true);
  }, []);
  useEffect(() => {
    if (hasRendered) {
      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: mainRef.current,
          scrub: true,
          start: 'top 70%',
          end: 'top center',
          onUpdate: (self) => {
            if (self.progress > 0.1) {
              imgRef.current.classList.add('active');
            } else {
              imgRef.current.classList.remove('active');
            }
          },
        },
      });
    }

    return () => {
      if (tl.current) tl.current.kill();
    };
  }, [hasRendered]);

  return (
    <>
      <MainCodeLabWrap ref={mainRef}>
        <MainSectionTitle
          title="Code Lab"
          desc={
            <div>
              Experience Samsung's latest technologies and services through
              hands-on activities.
            </div>
          }
          linkText="Go to Code Lab"
          buttonUrl="/codelab"
        />
        <MainCodeLabArea ref={imgRef}>
          <p>Welcome to Code Lab</p>
          <p>10/3 Start at 11:30</p>
        </MainCodeLabArea>
      </MainCodeLabWrap>
    </>
  );
};

export default MainCodeLab;
