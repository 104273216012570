import styled from 'styled-components';

export const PopupContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
`;

export const PopupInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  min-width: 520px;
  padding: 40px 40px 32px;
  border-radius: 20px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  text-align: center;
  font-size: 0;
  box-sizing: border-box;

  .btn-Confirm {
    margin-top: 32px;
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    min-width: 312px;
    padding: 32px 24px 24px;
    border-radius: 16px;

    .btn-Confirm {
      min-width: 144px;
      font-size: 16px;
    }
  }
`;

export const PopupTitle = styled.div`
  margin-bottom: 40px;
  font-family: 'SamsungOne-700';
  font-size: 24px;
  line-heihgt: 32px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: #000;
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.2px;
  }
`;

export const PopupDesc = styled.div`
  margin-top: -28px;
  margin-bottom: 32px;
  font-size: 20px;
  line-height: 30px;
  color: #535353;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const PopupCont = styled.div`
  margin: 0 auto;
  @media screen and (max-width: 768px) {
  }
`;

export const PopupItem = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 14px 0;
  text-align: center;
  border: solid 1px #1e1e1e;
  border-radius: 36px;
  font-family: 'SamsungOne-700';
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1e1e1e;
  &+& {
    margin-top: 16px;
  }
  &.google {
    border: solid 1px #fd2c25;
    color: #fd2c25;
  }
  &.office {
    border: solid 1px #ea2400;
    color: #ea2400;
  }
  &.outlook {
    border: solid 1px #0072c6;
    color: #0072c6;
  }
  &.yahoo {
    border: solid 1px #571bc9;
    color: #571bc9;
  }
  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 4px;
  }
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 768px){
    padding: 8px 0;
    font-size: 14px;
    line-height: 24px;
    img {
      width: 20px;
      height: 20px;
      margin-top; 1px;
    }
    &+& {
      margin-top: 12px;
    }
  }
`;

export const PopupClose = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 24px;
  height: 24px;
  background-image: url(${`${process.env.PUBLIC_URL}/images/ico-close.png`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 768px) {
    right: 14px;
    top: 14px;
    width: 20px;
    height: 20px;
  }
`;

export const ReserveAgree = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  text-align: left;

  .text {
    margin-left: 8px;
    font-size: 16px;
    color: #7c7c7c;
  }

  @media screen and (max-width: 768px) {
    .text {
      font-size: 14px;
    }
  }
`;
