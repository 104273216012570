import styled from 'styled-components';

export const DetailBottomContainer = styled.div`
  padding: 100px 0 120px 0;
  background-color: #f6f6f6;
  @media screen and (max-width: 768px) {
    padding: 60px 0;
  }
`;

export const DetailBottomInner = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const SectionWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin-right: -24px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
  }
`;
