import * as types from '../ActionTypes';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  checkedItems: [],
};

const CodelabReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CODELAB_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
        checkedItems: [],
      };

    case types.GET_CODELAB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
        checkedItems: action.checkedItems,
      };

    case types.GET_CODELAB_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case types.UPDATE_CODELAB_FILTER: {
      let newItems = [...state.checkedItems];
      if (newItems.indexOf(action.checkedItem) === -1) {
        if (typeof action.checkedItem === 'object') {
          newItems = [];
          if (action.checkedItem.length > 0) {
            const checkedTopic = action.checkedItem.toString().split(',');
            newItems.push(...checkedTopic);
          }
        } else {
          newItems.push(action.checkedItem);
        }
      } else {
        newItems.splice(newItems.indexOf(action.checkedItem), 1);
      }

      if (
        JSON.stringify(newItems.slice().sort()) ===
          JSON.stringify(
            ['SmartThings', 'Automotive', 'Health', 'Samsung Wallet']
              .slice()
              .sort()
          ) ||
        action.checkedItem === 'All'
      ) {
        return {
          ...state,
          checkedItems: ['All'],
        };
      }

      return {
        ...state,
        checkedItems:
          newItems.length === 0 ? ['All'] : newItems.filter((x) => x !== 'All'),
      };
    }

    default:
      return state;
  }
};

export default CodelabReducer;
