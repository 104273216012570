import React from 'react';
import {
  ErrorWrap,
  ErrorWrapInner,
  ErrorNum,
  ErrorTitle,
  ErrorDesc,
} from './ErrorContElement';
import withRouter from '../Router/withRouter';
import Button from '../Button';

const ErrorCont = (props) => {
  const { num, errorTitle, errorDesc, navigate } = props;
  return (
    <ErrorWrap>
      <ErrorWrapInner>
        <ErrorNum>{num}</ErrorNum>
        <ErrorTitle>{errorTitle}</ErrorTitle>
        <ErrorDesc dangerouslySetInnerHTML={{ __html: errorDesc }} />
        <Button
          size="medium"
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            navigate(-2);
          }}
        >
          Back to Previous Page
        </Button>
      </ErrorWrapInner>
    </ErrorWrap>
  );
};

export default withRouter(ErrorCont);
