import React, { useState, useEffect, useRef } from 'react';
import {
  TransformComponent,
  TransformWrapper,
  useControls,
  useTransformEffect,
} from 'react-zoom-pan-pinch';

import './style.scss';

// show reset button only on mobile
const Controls = () => {
  const { resetTransform } = useControls();

  useTransformEffect(({ state, instance }) => {
    // console.log(state.scale); // { previousScale: 1, scale: 1, positionX: 0, positionY: 0 }
    const resetBtn = document.querySelector('.reset-btn');
    if (state.scale > 1) {
      resetBtn.classList.add('is-active');
    } else {
      resetBtn.classList.remove('is-active');
    }
    return () => {};
  });

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button className="reset-btn" onClick={() => resetTransform()} />
    </>
  );
};

const StaticMap = ({ type, title, imgSrc, listItems }) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 769);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <>
      <div
        className={`static-map-section ${type === 'type02' ? 'type02' : ''}`}
      >
        <div className="static-map-container">
          <h2 className="static-map-title">{title}</h2>
          <div className="static-map-area">
            <div className="static-map-img-box">
              {isDesktop ? (
                <img
                  className="map-img"
                  // src={`${process.env.PUBLIC_URL}/images/eventMap02.png`}
                  src={imgSrc}
                  alt=""
                />
              ) : (
                <TransformWrapper initialScale={1}>
                  <TransformComponent>
                    <img className="map-img" src={imgSrc} alt="" />
                  </TransformComponent>
                  <Controls />
                </TransformWrapper>
              )}
            </div>
          </div>

          <div className="static-map-list">
            {listItems.map((item, index) =>
              item.icon ? (
                <div className={item?.border ? 'border-none' : ''}>
                  <span className="list-icon">
                    {item.customIcon ? (
                      <svg
                        width="30"
                        height="31"
                        viewBox="0 0 30 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          y="0.871094"
                          width="30"
                          height="30"
                          rx="15"
                          fill="#CA6CEB"
                        />
                        <path
                          d="M15.8438 20.0117C14.6172 20.0117 13.6641 19.6445 12.9844 18.9102C12.3125 18.168 11.9766 17.1328 11.9766 15.8047C11.9766 14.875 12.1289 14.0664 12.4336 13.3789C12.7461 12.6914 13.1875 12.1641 13.7578 11.7969C14.3359 11.4219 15.0117 11.2344 15.7852 11.2344C16.207 11.2344 16.6172 11.293 17.0156 11.4102C17.4219 11.5195 17.7812 11.6758 18.0938 11.8789L17.6133 12.9688C16.9961 12.6641 16.3789 12.5117 15.7617 12.5117C15.0195 12.5117 14.4453 12.7891 14.0391 13.3438C13.6328 13.8984 13.4297 14.6836 13.4297 15.6992C13.4297 16.6992 13.6367 17.4531 14.0508 17.9609C14.4727 18.4688 15.0938 18.7227 15.9141 18.7227C16.6172 18.7227 17.207 18.5859 17.6836 18.3125L18.1641 19.3789C17.9141 19.5742 17.582 19.7305 17.168 19.8477C16.7617 19.957 16.3203 20.0117 15.8438 20.0117Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                      >
                        <rect width="30" height="30" rx="15" fill="#363636" />
                        <path
                          d="M13.75 9.88239C13.75 9.51005 13.863 9.20918 14.0877 8.97863C14.3137 8.74809 14.6168 8.63281 14.9995 8.63281C15.3823 8.63281 15.697 8.74809 15.9171 8.97863C16.1385 9.20918 16.248 9.51005 16.248 9.88239C16.248 10.2547 16.1385 10.5533 15.9171 10.7792C15.697 11.004 15.3915 11.117 14.9995 11.117C14.6076 11.117 14.2883 11.004 14.0739 10.7792C13.8572 10.5544 13.75 10.2547 13.75 9.88239ZM13.7765 12.7688H16.2203V21.3659H13.7765V12.7688Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </span>
                  <span className="list-text info">{item.text}</span>
                </div>
              ) : (
                <div className={item?.border ? 'border-none' : ''}>
                  <span
                    className="list-label"
                    style={{ backgroundColor: item.color }}
                  >
                    {item.label}
                  </span>
                  <span className="list-text">{item.text}</span>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StaticMap;
