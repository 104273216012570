import React, { useEffect } from 'react';

import Lottie from 'lottie-react';
import prizeLottie from '../../lottie/reward-prize.json';
import Button from '../Button';
import {
  PopupButton,
  PopupContainer,
  PopupInner,
  PopupText,
  PopupTitle,
} from './PopupPrizeElements';

const PopupPrize = ({ setOpen, imgSource }) => {
  useEffect(() => {
    if (setOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [setOpen]);
  return (
    <>
      <PopupContainer>
        <PopupInner>
          <Lottie className="lottie" animationData={prizeLottie} loop={false} />
          <div className="prod">
            <img src={imgSource} alt="" />
          </div>
          <PopupTitle>Prize you received</PopupTitle>
          <PopupText>You can’t receive the same product again.</PopupText>
          <PopupButton>
            <span className="button">
              <Button
                color="black"
                size="medium"
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
              >
                Okay
              </Button>
            </span>
          </PopupButton>
        </PopupInner>
      </PopupContainer>
    </>
  );
};

export default PopupPrize;
