import * as types from '../ActionTypes';

const initialState = {
  isLoading: true,
  isLogin: false,
  isMeetupLoading: false,
  email: '',
  profileImage: '',
  name: '',
};

const SessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_SESSION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.UPDATE_USER_SESSION: {
      const {
        isLogin,
        countryCode,
        showGDPRPopup,
        showGDPRModal,
        isCookiePolicy,
        cookieGDPR,
        email,
        profileImage,
        name,
      } = action;
      return {
        ...state,
        isLogin,
        isLoading: false,
        countryCode,
        showGDPRPopup,
        showGDPRModal,
        isCookiePolicy,
        cookieGDPR,
        email,
        profileImage,
        name,
      };
    }
    case types.UPDATE_USER_SESSION_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    case types.COOKIE_GDPR_MODAL_CLOSE: {
      return {
        ...state,
        showGDPRModal: false,
      };
    }

    case types.UPDATE_COOKIES_GDPR_REQUEST: {
      return {
        ...state,
        showGDPRModal: false,
        showGDPRPopup: false,
      };
    }

    case types.UPDATE_COOKIES_GDPR: {
      return {
        ...state,
        cookieGDPR: action.cookieGDPR,
      };
    }

    // MEETUP data
    case types.UPDATE_USER_MEETUP: {
      return {
        ...state,
        meetup: action.meetup,
        registerType: action.registerType,
        registerId: action.registrationId,
      };
    }

    case types.ADD_MEETUP_ITEM_REQUEST: {
      return {
        ...state,
        isMeetupLoading: true,
        data: null,
        error: null,
      };
    }

    case types.ADD_MEETUP_ITEM_SUCCESS: {
      return {
        ...state,
        isMeetupLoading: false,
        data: action.data,
        error: null,
        meetup: true,
      };
    }

    case types.ADD_MEETUP_ITEM_ERROR: {
      return {
        ...state,
        isMeetupLoading: false,
        data: null,
        error: action.error,
      };
    }

    case types.RESET_MEETUP_ITEM_STATUS: {
      return {
        ...state,
        data: null,
        error: null,
      };
    }

    default:
      return state;
  }
};

export default SessionReducer;
