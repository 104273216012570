/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  .consent {
    padding: 160px 0;
  }
  .consent.ko * {
    /* font-family: 'SamsungOneKorean-400'; */
    font-family: 'SamsungOne-400';
  }
  .consent_inner {
    max-width: 1280px;
    padding: 0 40px;
    margin: 0 auto;
  }
  .consent_top {
    margin-bottom: 60px;
  }
  .consent_title {
    font-family: 'SamsungSharpSans-Bold';
    font-size: 42px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000;
  }
  .consent.ko .consent_title {
    font-family: 'SamsungOne-700';
    font-weight: bold;
  }
  .consent_desc {
    margin-top: 24px;
    font-family: 'SamsungOne-400';
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #535353;
  }
  .consent_desc a {
    font-family: 'SamsungOne-400';
    color: #1259aa;
    text-decoration: none;
  }
  .consent_desc a:hover {
    color: #2088fd;
    text-decoration: underline;
  }
  .consent_agree {
    margin-top: 17px;
    font-size: 14px;
    line-height: 20px;
    color: #535353;
  }
  .consent_item + .consent_item {
    margin-top: 40px;
  }
  .consent_item_title {
    position: relative;
    font-family: 'SamsungOne-700';
    font-size: 20px;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: normal;
    color: #000;
  }
  .consent.ko .consent_item_title {
    font-family: 'SamsungOneKorean-700';
    font-weight: bold;
  }
  .consent_item_desc {
    margin-top: 6px;
    font-family: 'SamsungOne-400';
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #535353;
  }
  .consent_item_warn {
    margin-top: 10px;
    font-family: 'SamsungOne-400';
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #e34a5d;
  }
  .consent_grid {
    margin-top: 20px;
  }
  .consent_grid table {
    padding: 0;
    border: 0;
    border-spacing: 0px;
    border-collapse: collapse;
    width: 100%;
  }
  .consent_grid table colgroup col:nth-of-type(1) {
    width: 31.2%;
  }
  .consent_grid table colgroup col:nth-of-type(2) {
    width: 49.4%;
  }
  .consent_grid table colgroup col:nth-of-type(3) {
    width: 19.4%;
  }
  .consent_grid thead th {
    border-top: 1px solid #000;
    padding: 16px;
    font-family: 'SamsungOne-700';
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: normal;
    color: #4e4e4e;
    vertical-align: top;
  }
  .consent.ko .consent_grid thead th {
    font-family: 'SamsungOneKorean-700';
    font-weight: bold;
  }
  .consent_grid thead th + th {
    border-left: 1px solid #efefef;
  }
  .consent_grid td {
    padding: 16px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    vertical-align: top;
    font-family: 'SamsungOne-400';
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #4e4e4e;
    word-break: keep-all;
  }
  .consent_grid td + td {
    border-left: 1px solid #efefef;
  }
  .consent_grid td a {
    color: #1259aa;
    text-decoration: none;
    word-break: break-all;
  }
  .consent_grid td a:hover {
    color: #2088fd;
    text-decoration: underline;
  }
  .consent_grid td .ex {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
  }
  .consent.ko .consent_grid table td strong {
    font-family: 'SamsungOne-700';
  }

  .checkbox-wrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  .checkbox-item {
  }
  .input-checkbox {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    left: 0;
    pointer-events: none;
    visibility: hidden;
  }
  .input-checkboxlabel {
    display: inline-block;
    height: 24px;
    padding-left: 24px;
    font-size: 16px;
    cursor: pointer;
  }

  .input-checkboxlabel:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 24px;
    height: 24px;
    background-image: url('./images/ico-checkbox.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  .input-checkbox:checked + .input-checkboxlabel:after {
    display: inline-block;
  }
  .input-checkboxlabel:after {
    display: none;
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 24px;
    height: 24px;
    background-image: url('./images/ico-checkbox-active.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  .checkbox-text {
    margin-left: 8px;
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    .consent {
      padding: 60px 0;
    }
    .consent_inner {
      padding: 0 24px;
    }
    .consent_top {
      margin-bottom: 40px;
    }
    .consent_title {
      font-size: 20px;
      line-height: 1.4;
    }
    .consent_desc {
      margin-top: 24px;
      font-size: 14px;
      line-height: 1.43;
    }
    .consent_item_title {
      font-size: 16px;
      line-height: 1.25;
    }
    .consent_grid {
      margin-top: 16px;
    }
    .consent_grid table colgroup col:nth-of-type(1) {
      width: 41.025%;
    }
    .consent_grid table colgroup col:nth-of-type(2) {
      width: 35.897%;
    }
    .consent_grid table colgroup col:nth-of-type(3) {
      width: 22.4%;
    }
    .consent_grid thead th {
      padding: 16px 6px;
      word-break: break-all;
    }
    .consent_grid td {
      padding: 16px 6px;
    }
  }
`;

const KoreaConsent = () => {
  useEffect(() => {
    const scrollToAnchor = () => {
      if (window.location.hash === '#optional') {
        const element = document.querySelector(`${window.location.hash}`);
        if (element) {
          element.scrollIntoView(true);
        }
      }
    };

    const timeoutId = setTimeout(scrollToAnchor, 100);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Container>
      <div className="consent ko">
        <div className="consent_inner">
          <div className="consent_top">
            <div className="consent_title">
              개인정보 수집·이용 동의서 (삼성개발자컨퍼런스 (SDC))
            </div>
            <div className="consent_desc">
              삼성전자 주식회사(이하 ‘회사’라 합니다)는 최초 회원 가입 또는
              서비스 이용시 이용자로부터 아래와 같은 개인정보를 수집하고
              있습니다.
              <br />
              이용자는 본 개인정보 수집·이용 동의서에 따른 동의 시, '필요한
              최소한의 정보 외의 개인정보' 수집·이용에 동의하지 아니할 권리가
              있습니다.
              <br />
              개인정보 처리에 대한 상세한 사항은 SDC 홈페이지(
              <a href="http://www.samsungdeveloperconference.com">
                http://www.samsungdeveloperconference.com
              </a>
              )에 공개한 '개인정보처리방침'을 참조하십시오.
              <br />
              다만, 본 동의서 내용과 상충되는 부분은 본 동의서의 내용이
              우선합니다.
            </div>
          </div>

          <div className="consent_item">
            <div className="consent_item_title">
              개인정보 수집∙이용 동의(필수)
            </div>
            <div className="consent_item_desc">
              ※ 귀하께서는 필수항목 수집·이용에 대한 동의를 거부하실 수 있으나,
              이는 서비스 제공에 필수적으로 제공되어야 하는 정보이므로, 동의를
              거부하실 경우 이벤트 참석, 서비스 이용, 홈페이지 이용을 하실 수
              없습니다.
            </div>
            <div className="consent_grid">
              <table>
                <colgroup>
                  <col style={{ width: '28%' }} />
                  <col style={{ width: '34%' }} />
                  <col style={{ width: '31%' }} />
                </colgroup>

                <thead>
                  <tr>
                    <th>수집항목</th>
                    <th>수집 목적</th>
                    <th>보유기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      □ 이름
                      <br />
                      □ 이메일
                      <br />
                      □ 연령대
                      <br />
                      □ 국가정보
                      <br />
                      □ 삼성계정 ID
                      <br />
                      □ 직급, 소속회사
                      <br />
                      □ 콘텐츠 관련 관심 토픽
                      <br />
                      □ 홈페이지 이용 기록
                      <br />
                      □ 행사장 방문 기록 및 세션, 전시장, 이벤트 참여 기록
                      <br />□ 행사 신청 목적
                    </td>
                    <td>
                      - 컨퍼런스 참석자 관리 및 본인 확인
                      <br />
                      - 공지사항 등 전달
                      <br />
                      - 컨퍼런스 상세 정보 제공
                      <br />
                      - 웹사이트 이용 내역 및 컨퍼런스 방문 이력 내용 확인 기능
                      제공
                      <br />
                      - 이용자 사용 패턴 분석을 통해 필요시 서비스 및 웹사이트에
                      대한 개선 조치 진행
                      <br />
                      - 고객만족 조사 메일 발송
                      <br />
                      - 컨퍼런스 시 촬영한 사진 및 동영상을 이용하여 컨퍼런스
                      홍보물 제작
                      <br />
                      - 상담 접수 및 답변 제공, 문의 내역 관리
                      <br />- 개인정보 이용내역 발송
                      <br />- 삼성개발자포털에서 서비스 이용 이력 확인 기능 제공
                    </td>
                    <td>
                      <strong className="ex">
                        - 삼성개발자포털 (
                        <a href="https://developer.samsung.com/">
                          developer.samsung.com
                        </a>
                        ) 회원 탈퇴시까지
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>□ 쿠키 정보</td>
                    <td>- 웹 사이트 이용 편의 기능 제공</td>
                    <td>
                      <strong className="ex">수집 시점으로부터 1년</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <div className="consent_agree">
                위 개인정보 수집∙이용에 동의합니다. (필수)
              </div>
              <div>
                <div className="checkbox-wrap">
                  <div className="checkbox-item">
                    <input
                      type="checkbox"
                      id="agree"
                      className="input-checkbox"
                    />
                    <label htmlFor="agree" className="input-checkboxlabel" />
                  </div>
                  <div className="checkbox-text">동의함</div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="consent_item" id="optional">
            <div className="consent_item_title">
              개인정보 수집∙이용 동의(선택)
            </div>
            <div className="consent_item_desc">
              ※ 귀하께서는 선택항목 수집·이용에 대한 동의를 거부하실 수 있으며,
              이는 서비스 제공에 필수적으로 제공되어야 하는 정보가 아니므로,
              동의를 거부하시더라도 회원 가입, 서비스 이용, 홈페이지 이용이
              가능합니다.  다만 선택항목 수집·이용에 대하여 동의하지 않으실
              경우, 해당 서비스 사용에 제한이 있을 수 있습니다.
            </div>
            <div className="consent_grid">
              <table>
                <thead>
                  <tr>
                    <th>수집항목</th>
                    <th>수집 목적</th>
                    <th>보유기간</th>
                    {/* <th>동의 여부(선택)</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>□ 휴대폰 번호</td>
                    <td>- 행사 관련 연락 및 본인 확인</td>
                    <td>
                      <strong className="ex">- 수집 시점으로부터 6개월</strong>
                    </td>
                    {/* <td>
                      <div className="checkbox-wrap">
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="agree2"
                            className="input-checkbox"
                          />
                          <label
                            htmlFor="agree2"
                            className="input-checkboxlabel"
                          />
                        </div>
                        <div className="checkbox-text">동의함</div>
                      </div>
                    </td> */}
                  </tr>
                  <tr>
                    <td>□ 삼성 멤버스 닉네임</td>
                    <td>- 삼성멤버스 회원 대상 Samsung Members Badge 발급</td>
                    <td>
                      <strong className="ex">- 수집 시점으로부터 6개월</strong>
                    </td>
                    {/* <td>
                      <div className="checkbox-wrap">
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="agree2"
                            className="input-checkbox"
                          />
                          <label
                            htmlFor="agree2"
                            className="input-checkboxlabel"
                          />
                        </div>
                        <div className="checkbox-text">동의함</div>
                      </div>
                    </td> */}
                  </tr>
                  <tr>
                    <td>□ 개인 URL 정보</td>
                    <td>- eBusiness Card 기능 제공</td>
                    <td>
                      <strong className="ex">- 수집 시점으로부터 6개월</strong>
                    </td>
                    {/* <td>
                      <div className="checkbox-wrap">
                        <div className="checkbox-item">
                          <input
                            type="checkbox"
                            id="agree2"
                            className="input-checkbox"
                          />
                          <label
                            htmlFor="agree2"
                            className="input-checkboxlabel"
                          />
                        </div>
                        <div className="checkbox-text">동의함</div>
                      </div>
                    </td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default KoreaConsent;
