import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../Button';
import { PopupBottom } from '../Popup/PopupElements';
import {
  PopupButton,
  PopupContainer,
  PopupInner,
  PopupLocation,
  PopupText,
  PopupTitle,
} from './PopupBingoActivityElements';

const PopupBingoActivity = ({
  setOpen,
  id,
  type,
  title,
  location,
  linkURL,
  desc,
  point,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (setOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [setOpen]);
  return (
    <>
      <PopupContainer className={type}>
        <PopupInner>
          <div className="point">{point}PT</div>
          <PopupTitle>{title.replaceAll('<br />', '')}</PopupTitle>
          {location && (
            <PopupLocation dangerouslySetInnerHTML={{ __html: location }} />
          )}
          <PopupText dangerouslySetInnerHTML={{ __html: desc }} />
          {(id === 'bingo-3-2' || id === 'bingo-4-2') && (
            <Link
              className="btn-notice"
              target="_blank"
              to="https://www.samsungdeveloperconference.com/privacy"
            >
              Privacy Notice
            </Link>
          )}
          <PopupBottom>
            {type === 'type-2' || type === 'type-3' ? (
              <>
                <PopupButton>
                  <span className="button">
                    <Button
                      color="border"
                      size="medium"
                      fullWidth
                      onClick={(e) => {
                        e.preventDefault();
                        setOpen(false);
                      }}
                    >
                      Close
                    </Button>
                  </span>
                </PopupButton>
                <PopupButton>
                  <span className="button">
                    <Button
                      color="black"
                      size="medium"
                      fullWidth
                      onClick={(e) => {
                        e.preventDefault();
                        if (linkURL) {
                          const a = document.createElement('a');
                          a.href = linkURL;
                          a.target = '_blank';
                          a.click();
                        }
                      }}
                    >
                      {type === 'type-2' ? 'Survey' : 'Subscribe'}
                    </Button>
                  </span>
                </PopupButton>
              </>
            ) : (
              <PopupButton>
                <span className="button">
                  <Button
                    color="black"
                    size="medium"
                    fullWidth
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(false);
                    }}
                  >
                    Okay
                  </Button>
                </span>
              </PopupButton>
            )}
          </PopupBottom>
        </PopupInner>
      </PopupContainer>
    </>
  );
};

export default PopupBingoActivity;
