import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  MeetupContContainer,
  MeetupContTop,
  MeetupBanner,
  MeetupTitle,
  MeetupDesc,
  MeetupContBottom,
  TabPanelBox,
  MeetupIntroduce,
  EventMapWrap,
  MeetupBottomBanner,
  TabPanelTitle,
  TabPanelButton,
  MeetupTheme,
  MeetupModalBox,
  MeetupModalCont,
  MeetupModalReserve,
  MeetupModalConfirm,
  MeetupModalButtonBox,
  MeetupModalButton,
  MeetupBottomBox,
  MeetupBottomBannerWrap,
} from './MeetupContElement';
import StaticMap from '../StaticMap';
import withRouter from '../Router/withRouter';
import EventMap from '../EventMap';
import {
  ADD_MEETUP_ITEM_REQUEST,
  RESET_MEETUP_ITEM_STATUS,
} from '../../redux/ActionTypes';

const themeList = [
  {
    time: '13:00 - 13:20',
    title:
      'Lifestyle Contents Platform: Samsung Daily+ and Daily Borad Ecosystem',
    category: 'AI For Devices',
    type: '2',
  },
  {
    time: '13:30 - 13:50',
    title: 'Technical Discussion on Advanced Professional Video Codec',
    category: 'Enterprise & Ecosystem',
    type: '1',
  },
  {
    time: '14:00 - 14:20',
    title: "Samsung's enterprise mobility and ecosystem strategy",
    category: 'Enterprise & Ecosystem',
    type: '1',
  },
  {
    time: '14:30 - 14:50',
    title: 'AI Cast: Interoperable screen ecperience with external AI',
    category: 'AI For Devices',
    type: '2',
  },
];

const mapItems1 = [
  { label: '1', text: 'Open Stage', color: '#00A067', border: 'false' },
  { label: '2', text: 'Info/Giveaway Desk', icon: 'true', border: 'false' },
];

const mapItems2 = [
  {
    label: '1',
    text: 'Tech Session Meet-up',
    color: '#1259AA',
    border: 'false',
  },
  { label: '2', text: 'Info/Giveaway Desk', icon: 'true', border: 'false' },
];

const mapItems3 = [
  { label: '1', text: 'Enterprise & Ecosystem', color: '#756BF1' },
  { label: '2', text: 'AI for Devices', color: '#756BF1' },
  { label: '3', text: 'Info/Giveaway Desk', icon: 'true', border: 'false' },
];

/* MeetupModal
- type: 'reserve' - checking reservation
        'confirm' - various confirmation
- title: modal title (only confirm type)
- desc: modal desc (only confirm type)
- rightText: modal right text button (only confirm type)
- leftText: modal left text button (only confirm type)
*/

const MeetupCont = ({}) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const [modalParams, setModalParams] = useState({
    isOpened: false,
  });

  const { data, sessionData } = useSelector((state) => state.meetup);
  const dispatch = useDispatch();

  /* reservation fully booked check status */
  const [isFullBooked, setIsFullBooked] = useState(false);

  useEffect(() => {
    if (data) {
      setIsFullBooked(data.isFull);
    }
  }, [data]);

  // is login
  const {
    isLogin,
    meetup,
    registerType,
    registerId,
    isMeetupLoading,
    data: meetupResult,
  } = useSelector((state) => state.userSession);

  useEffect(() => {
    if (!isMeetupLoading && meetupResult) {
      dispatch({ type: RESET_MEETUP_ITEM_STATUS });
      setModalParams({
        isOpened: true,
        title: 'Successfully reserved !',
        desc: 'You can check your reservation history on My page.',
        leftText: 'Cancel',
        rightText: 'Go to My page',
        onClickLeft: () => setModalParams({ isOpened: false }),
        onClickRight: () => {
          window.location = '/mypage';
        },
      });
    }
  }, [isMeetupLoading, meetupResult]);

  const handleClickRegister = () => {
    // click to register button
    // non-login user
    if (isFullBooked) {
      setModalParams({
        isOpened: true,
        type: 'confirm',
        title: 'Fully Booked!',
        desc: 'The pre-reservation is closed. However, there may be additional on-site reservation opportunities. We appreciate your continued interest.',
        rightText: 'Confirm',
        onClickRight: () => setModalParams({ isOpened: false }),
      });
    } else if (!isLogin) {
      setModalParams({
        isOpened: true,
        type: 'confirm',
        title: 'Sign in to access',
        desc: 'Only SDC 24 members can make a reservation.<br/>Please sign in first to join.',
        rightText: 'Sign in',
        leftText: 'Cancel',
        onClickLeft: () => setModalParams({ isOpened: false }),
        onClickRight: () => {
          window.location = `/login?redirectURL=${window.location.pathname}`;
        },
      });
      // login uer / online form user
      // TODO: The popup for that case has not been published yet
    } else if (isLogin && registerType !== 'offline') {
      setModalParams({
        isOpened: true,
        type: 'confirm',
        title: 'Only field participants can participate.',
        desc: "It's a meet-up event run by the site.<br/>Only field participants can participate.",
        rightText: 'Cancel',
        onClickRight: () => setModalParams({ isOpened: false }),
      });
      // offline form + already register user
    } else if (isLogin && meetup && registerType === 'offline') {
      setModalParams({
        isOpened: true,
        type: 'confirm',
        rightText: 'Go to My page',
        leftText: 'Cancel',
        title: 'Already reserved',
        desc: 'You already booked the event.<br/>You can check your reservation history on My page.',
        onClickLeft: () => setModalParams({ isOpened: false }),
        onClickRight: () => {
          window.location = '/mypage';
        },
      });
    } else if (
      isLogin &&
      !meetup &&
      registerType === 'offline' &&
      !data.isFull
    ) {
      setModalParams({
        isOpened: true,
        type: 'reserve',
        rightText: 'register',
        leftText: 'Cancel',
        onClickLeft: () => setModalParams({ isOpened: false }),
        onClickRight: () => {
          dispatch({
            type: ADD_MEETUP_ITEM_REQUEST,
            registrationId: registerId,
          });
        },
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <MeetupContContainer>
      <MeetupContTop>
        <button className="btn-back" type="button" onClick={goBack}>
          Back
        </button>
        <MeetupBanner>
          <img
            className="assets-1"
            src={`${process.env.PUBLIC_URL}/images/bg-meetup-assets-1.svg`}
            alt="assets-1"
          />
          <img
            className="assets-2"
            src={`${process.env.PUBLIC_URL}/images/bg-meetup-assets-2.svg`}
            alt="assets-2"
          />
          <img
            className="assets-3"
            src={`${process.env.PUBLIC_URL}/images/bg-meetup-assets-3.svg`}
            alt="assets-3"
          />
          <img
            className="assets-4"
            src={`${process.env.PUBLIC_URL}/images/bg-meetup-assets-4.svg`}
            alt="assets-4"
          />
          <img
            className="assets-5"
            src={`${process.env.PUBLIC_URL}/images/bg-meetup-assets-5.png`}
            alt="assets-5"
          />
          <div className="banner-box">
            <div className="banner-text">Networking Opportunities</div>
            <div className="banner-time">{`${moment(
              '2024-10-03T11:30:00'
            ).format('ddd, MMM D, YYYY hh:mm A')} PT`}</div>
          </div>
        </MeetupBanner>
        <MeetupTitle>SDC24 Meet-up</MeetupTitle>
        <MeetupDesc>
          Join us in celebrating the 10th anniversary of SDC with an exciting
          array of networking opportunities! Engage with Samsung speakers and
          industry experts at various Meet-ups, where you can dive deeper into
          the topics covered in Open Stage and Tech Sessions. You can also
          connect, share insights, and explore new ideas on the exhibition
          themes with fellow attendees at the casual Meet-up programs in the
          Pocket Lounges.
        </MeetupDesc>
      </MeetupContTop>
      <MeetupContBottom>
        <Tabs>
          <TabList>
            <Tab>Open Stage</Tab>
            <Tab>Tech Session</Tab>
            <Tab>Roundtable</Tab>
          </TabList>
          <TabPanelBox>
            <TabPanel>
              <MeetupDesc className="bottom">
                Attendees can meet with popular developer influencer in
                person for Q&A and have deep dive discussions related to the
                topic. Networking opportunities for attendees with
                similar interests.
              </MeetupDesc>
              <MeetupIntroduce>
                <img
                  className="img-introduce"
                  src={
                    sessionData && sessionData.Presenters.length > 0
                      ? sessionData.Presenters[0].PresenterPhotoFileName
                      : `${process.env.PUBLIC_URL}/images/img-meetup-speaker.png`
                  }
                  alt="speaker"
                />
                <div className="introduce-info">
                  <div className="introduce-title">About Speaker</div>
                  {sessionData && sessionData.Presenters.length > 0 ? (
                    <>
                      <div className="introduce-name">
                        {`${sessionData.Presenters[0].PresenterFirstName} ${sessionData.Presenters[0].PresenterLastName}`}
                      </div>
                      <div className="introduce-job">
                        {sessionData.Presenters[0].PresenterPosition &&
                        sessionData.Presenters[0].PresenterOrganization
                          ? `${sessionData.Presenters[0].PresenterPosition}, ${sessionData.Presenters[0].PresenterOrganization}`
                          : sessionData.Presenters[0].PresenterPosition
                          ? sessionData.Presenters[0].PresenterPosition
                          : sessionData.Presenters[0].PresenterOrganization}
                      </div>
                      <div className="introduce-desc">
                        {`${sessionData.Presenters[0].PresenterBiographyText}`}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="introduce-name">Tiffany Janzen</div>
                      <div className="introduce-job">
                        Content creator & software developer
                      </div>
                      <div className="introduce-desc">
                        Tiffany is the tech founder of platforms Tiffintech and
                        Taekoff, and is a software developer with over 8 years
                        of industry experience. She has worked with some of the
                        world’s biggest tech companies, and will be sharing
                        insight on the latest tech advancements, future tech
                        predictions and practical tech tips.
                      </div>
                    </>
                  )}

                  <div className="introduce-stage">
                    <div className="stage-info">
                      <div className="stage-badge">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/ico-schedule-open.svg`}
                          alt="schedule-open"
                        />
                        {sessionData && sessionData.PresentationRoom}
                      </div>
                      <div className="stage-time">
                        {sessionData &&
                          `${moment(
                            sessionData.PresentationDateTimeStart
                          ).format('HH:mm')} - ${moment(
                            sessionData.PresentationDateTimeEnd
                          ).format('HH:mm')}
                        `}
                      </div>
                    </div>
                    <div className="stage-subject">
                      {sessionData && sessionData.PresentationTitle}
                    </div>
                    <div className="stage-tags">
                      {/* publish category: ['Developer Program', 'Game'] */}
                      {sessionData &&
                        sessionData.category.map((tag) => (
                          <div className="tag" key="tag">
                            {tag}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </MeetupIntroduce>
              <StaticMap
                type="type02"
                title="Event Map"
                imgSrc={`${process.env.PUBLIC_URL}/images/eventMap5.png`}
                listItems={mapItems1}
              />
              <MeetupBottomBannerWrap>
                <MeetupBottomBanner>
                  <img
                    className="assets-6"
                    src={
                      isDesktop
                        ? `${process.env.PUBLIC_URL}/images/bg-meetup-assets-6.svg`
                        : `${process.env.PUBLIC_URL}/images/bg-meetup-assets-9.svg`
                    }
                    alt="assets-6"
                  />
                  <img
                    className="assets-7"
                    src={
                      isDesktop
                        ? `${process.env.PUBLIC_URL}/images/bg-meetup-assets-7.svg`
                        : `${process.env.PUBLIC_URL}/images/bg-meetup-assets-10.svg`
                    }
                    alt="assets-7"
                  />
                  <img
                    className="assets-8"
                    src={`${process.env.PUBLIC_URL}/images/bg-meetup-assets-8.png`}
                    alt="assets-8"
                  />
                  <div className="bottom-banner-box">
                    <div className="bottom-banner-title">Pre-Reservation</div>
                    <div className="bottom-banner-desc">
                      Only the first 15 people will be pre-booked for open
                      stage. Other participation applications will be made at
                      the event site.
                    </div>
                    <TabPanelButton
                      className="register"
                      onClick={handleClickRegister}
                    >
                      Register
                    </TabPanelButton>
                  </div>
                </MeetupBottomBanner>
              </MeetupBottomBannerWrap>
            </TabPanel>
          </TabPanelBox>
          <TabPanelBox>
            <TabPanel>
              <TabPanelTitle>Tech Session</TabPanelTitle>
              <MeetupDesc className="bottom">
                Join our session speakers outside for an extended Q&A or
                discussion after each Tech Session. Don’t miss your chance to
                connect and ask questions!
              </MeetupDesc>
              <TabPanelButton
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `/sessions?filter=${encodeURIComponent(
                    'Tech Session Room 1,Tech Session Room 2,Tech Session Room 3'
                  )}`;
                }}
              >
                Check Session detail
              </TabPanelButton>
              <StaticMap
                type="type02"
                title="Event Map"
                imgSrc={`${process.env.PUBLIC_URL}/images/eventMap2.png`}
                listItems={mapItems2}
              />
              <MeetupBottomBannerWrap>
                <MeetupBottomBanner className="join">
                  <img
                    className="assets-12"
                    src={`${process.env.PUBLIC_URL}/images/bg-meetup-assets-12.svg`}
                    alt="assets-12"
                  />
                  <img
                    className="assets-11"
                    src={`${process.env.PUBLIC_URL}/images/bg-meetup-assets-11.png`}
                    alt="assets-11"
                  />
                  <div className="bottom-banner-box">
                    <div className="bottom-banner-title">How to Join?</div>
                    <div className="bottom-banner-desc">
                      There is no pre-reservation. There will be a notice about
                      Tech Session at the end of each session.
                    </div>
                  </div>
                </MeetupBottomBanner>
              </MeetupBottomBannerWrap>
            </TabPanel>
          </TabPanelBox>
          <TabPanelBox>
            <TabPanel>
              <TabPanelTitle>Roundtable</TabPanelTitle>
              <MeetupDesc className="bottom">
                Offering opportunities for exchanging opinions, sharing ideas,
                and gaining insights through various topics related to
                exhibition content.
              </MeetupDesc>
              <MeetupIntroduce>
                <div className="introduce-info">
                  <div className="introduce-name theme">Themes</div>
                  <MeetupTheme>
                    {themeList.map((theme, idx) => (
                      <>
                        <div className="introduce-theme">
                          <div>
                            <div className="introduce-theme-time">
                              <img
                                src={`${process.env.PUBLIC_URL}/images/ico-watch-gray.svg`}
                                alt="watch"
                              />
                              {theme.time}
                            </div>
                            <div className="introduce-theme-title">
                              {theme.title}
                            </div>
                          </div>
                          <div className="introduce-theme-cate">
                            <span className="introduce-theme-badge">
                              {theme.type}
                            </span>
                            <div className="introduce-theme-text">
                              {theme.category}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </MeetupTheme>
                </div>
              </MeetupIntroduce>
              <StaticMap
                type="type02"
                title="Event Map"
                imgSrc={`${process.env.PUBLIC_URL}/images/eventMap3.png`}
                listItems={mapItems3}
              />
              <MeetupBottomBox>
                <MeetupBottomBannerWrap>
                  <MeetupBottomBanner className="join">
                    <img
                      className="assets-12"
                      src={`${process.env.PUBLIC_URL}/images/bg-meetup-assets-12.svg`}
                      alt="assets-12"
                    />
                    <img
                      className="assets-11"
                      src={`${process.env.PUBLIC_URL}/images/bg-meetup-assets-11.png`}
                      alt="assets-11"
                    />
                    <div className="bottom-banner-box">
                      <div className="bottom-banner-title">How to Join?</div>
                      <div className="bottom-banner-desc">
                        There is no pre-reservation. There will be a notice
                        about Tech Session at the end of each session.
                      </div>
                    </div>
                  </MeetupBottomBanner>
                </MeetupBottomBannerWrap>
              </MeetupBottomBox>
            </TabPanel>
          </TabPanelBox>
        </Tabs>
      </MeetupContBottom>
      {/* modal case */}
      {modalParams.isOpened && (
        <MeetupModalBox>
          <MeetupModalCont>
            {modalParams.type === 'reserve' ? (
              <MeetupModalReserve>
                <div className="modal-title">Make a reservation</div>
                <div className="modal-reserve-box">
                  <div className="modal-reserve-item">
                    <div className="modal-reserve-title">Event</div>
                    <div className="modal-reserve-text line">Open Stage</div>
                  </div>
                  <div className="modal-reserve-item">
                    <div className="modal-reserve-title">Date</div>
                    <div className="modal-reserve-text line">
                      Thur, Oct 03, 2024
                    </div>
                  </div>
                  <div className="modal-reserve-item">
                    <div className="modal-reserve-title">Time & Date</div>
                    <div className="modal-reserve-text">11:50-12:20</div>
                  </div>
                  <div className="modal-reserve-item">
                    <div className="modal-reserve-title">Location</div>
                    <div className="modal-reserve-text last">
                      Private Meeting Room
                    </div>
                  </div>
                </div>
              </MeetupModalReserve>
            ) : (
              <MeetupModalConfirm>
                <div className="modal-title">{modalParams.title}</div>
                <div
                  className="modal-desc"
                  dangerouslySetInnerHTML={{ __html: modalParams.desc }}
                />
              </MeetupModalConfirm>
            )}
            <MeetupModalButtonBox>
              {modalParams.leftText && modalParams.onClickLeft && (
                <MeetupModalButton
                  className="cancel"
                  onClick={modalParams.onClickLeft}
                >
                  {modalParams.leftText}
                </MeetupModalButton>
              )}
              {modalParams.rightText && modalParams.onClickRight && (
                <MeetupModalButton onClick={modalParams.onClickRight}>
                  {modalParams.rightText}
                </MeetupModalButton>
              )}
            </MeetupModalButtonBox>
          </MeetupModalCont>
        </MeetupModalBox>
      )}
    </MeetupContContainer>
  );
};

export default withRouter(MeetupCont);
