import React from 'react';

const Chevron = (props) => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 11.5 10 7 5.212 2.5"
        stroke="#1259AA"
        fill="none"
        strokeWidth="1.4"
        fillRule="evenodd"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default Chevron;
