import React from 'react';
import moment from 'moment-timezone';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import {
  PopupContainer,
  PopupInner,
  PopupTitle,
  PopupCont,
  PopupItem,
  PopupClose,
} from './PopupCalendarElements';

const PopupCalndar = ({ setOpen }) => {
  const closePopup = () => {
    setOpen(false);
    document.body.style.overflow = 'auto';
  };

  const event = {
    start: moment
      .tz('2024-10-03T10:00:00', 'America/Los_Angeles')
      .format('YYYY-MM-DD HH:mm:ss [-7:00]'),
    end: moment
      .tz('2024-10-03T19:00:00', 'America/Los_Angeles')
      .format('YYYY-MM-DD HH:mm:ss [-7:00]'),
    title: 'SDC24',
    description: 'Samsung Developer Conference 2024',
    location: 'San Jose McEnery Convention Center',
    url: 'https://developer.samsung.com/sdc',
  };

  const downloadHandler = () => {
    const fileURL = ics(event);
    const filename = 'SDC24.ics';

    const anchor = document.createElement('a');
    anchor.href = fileURL;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(fileURL);
  };

  const Link = {
    CALENDAR_LINK_GOOGLE: google(event),
    CALENDAR_LINK_OFFICE365: office365(event),
    CALENDAR_LINK_OUTLOOK: outlook(event),
    CALENDAR_LINK_YAHOO: yahoo(event),
  };

  return (
    <PopupContainer>
      <PopupInner>
        <PopupTitle>Add to Calendar</PopupTitle>
        <PopupCont>
          <PopupItem className="apple" onClick={downloadHandler}>
            <img
              src={`${process.env.PUBLIC_URL}/images/ico-apple.png`}
              alt=""
            />
            Apple
          </PopupItem>
          <PopupItem
            className="google"
            onClick={() =>
              window.open(`${Link.CALENDAR_LINK_GOOGLE}`, '_blank')
            }
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/ico-google.png`}
              alt=""
            />
            Google
          </PopupItem>
          <PopupItem
            className="office"
            onClick={() =>
              window.open(`${Link.CALENDAR_LINK_OFFICE365}`, '_blank')
            }
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/ico-office-365.png`}
              alt=""
            />
            Office 365
          </PopupItem>
          <PopupItem
            className="outlook"
            onClick={() =>
              window.open(`${Link.CALENDAR_LINK_OUTLOOK}`, '_blank')
            }
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/ico-outlook.png`}
              alt=""
            />
            Outlook
          </PopupItem>
          <PopupItem
            className="yahoo"
            onClick={() => window.open(`${Link.CALENDAR_LINK_YAHOO}`, '_blank')}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/ico-yahoo.png`}
              alt=""
            />
            Yahoo
          </PopupItem>
        </PopupCont>
        <PopupClose onClick={closePopup} />
      </PopupInner>
    </PopupContainer>
  );
};

export default PopupCalndar;
