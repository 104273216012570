import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import ButtonLink from '../../components/ButtonLink';
import ALink from '../../components/ALink';

const SurveyWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #f6f6f6;
  z-index: 101;

  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 188px;
    height: 46px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/meetup-cancel-bg-1.s.svg`});
    background-repeat: no-repeat;
    background-size: contain;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 256px;
    height: 122px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/meetup-cancel-bg-3.s.png`});
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
  }
  .container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    max-width: none;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 91px;
      width: 165px;
      height: 162px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/meetup-cancel-bg-2.s.svg`});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left bottom;
    }
  }
  .inner-wrap {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 24px;
    box-sizing: border-box;
  }
  .title {
    font-family: 'samsungsharpsans-bold';
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    width: 290px;
    margin: 0 auto;
    &:before {
      content: '';
      display: block;
      position: relative;
      height: 16px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/txt-sdc24.svg`});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-bottom: 14px;
    }
  }
  .desc {
    margin-top: 12px;
    text-align: center;
    color: #363636;
  }
  .btn-notice {
    display: inline-block;
    position: relative;
    margin-top: 12px;
    padding-right: 18px;
    font-size: 14px;
    line-height: 20px;
    color: #2088fd;
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      right: 0;
      width: 14px;
      height: 14px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-blue.svg`});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  .Link,
  a {
    margin-top: 40px;
  }

  @media screen and (min-width: 768px) {
    &:before {
      width: 815px;
      height: 132px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/meetup-cancel-bg-1.svg`});
    }
    &:after {
      width: 736px;
      height: 377px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/meetup-cancel-bg-3.png`});
    }
    .container:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 399px;
      width: 105px;
      height: 180px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/meetup-cancel-bg-4.svg`});
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 2;
    }
    .container:after {
      bottom: 142px;
      width: 495px;
      height: 533px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/meetup-cancel-bg-2.svg`});
    }
    .inner-wrap {
      max-width: 1140px;
      margin: 0 auto;
    }
    .title {
      font-size: 42px;
      line-height: 54px;
      width: auto;
      &:before {
        height: 24px;
        margin-bottom: 20px;
      }
    }
    .desc {
      margin-top: 20px;
      font-size: 16px;
      line-height: 24px;
    }
    .Link,
    a {
      margin-top: 48px;
    }
  }
`;

const SurveyIndex = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get('anonymize');
  return (
    <>
      <SurveyWrap>
        <div className="container">
          <div className="inner-wrap">
            <p className="title">
              Thank you for trying to participate in the survey!
            </p>
            <p className="desc">
              Your survey responses are collected by Samsung Electronics for
              analysis, to better serve you.
            </p>
            <Link
              className="btn-notice"
              target="_blank"
              to="https://www.samsungdeveloperconference.com/privacy"
            >
              Privacy Notice
            </Link>
            <a
              href={`/survey/submit${
                query && query === '1' ? '?anonymize=1' : ''
              }`}
              className={classNames('aLink', 'black', 'medium')}
            >
              Go to Survey
            </a>
          </div>
        </div>
      </SurveyWrap>
    </>
  );
};

export default SurveyIndex;
