import React from 'react';
import { useSelector } from 'react-redux';
import {
  SpeakerContainer,
  SpeakerInner,
  SpeakerTitle,
  CardWrap,
  CardInner,
  SpeakerSubTitle,
} from './KeynoteSpeakerElements';
import '../MainSlide/style.scss';
import Card from '../CardSpeaker';
import { MainSpeakers } from '../../sample/MainSlideData';
import { KeynoteData } from '../../sample/KeynoteData';

const Speaker = () => {
  const keynoteData = useSelector((state) => state.sessionList.keynoteData);

  return (
    <SpeakerContainer>
      <SpeakerInner>
        <SpeakerTitle>Keynote Speakers</SpeakerTitle>
        <CardWrap className="KeynoteCards">
          {/* subTitle dummy data for grouping presenters */}
          {/* <SpeakerSubTitle>{keynoteData.PresentationTitle}</SpeakerSubTitle> */}
          <CardInner>
            {(keynoteData?.Presenters || [{}, {}, {}, {}]).map((item, idx) => (
              <Card
                key={item.PresenterID}
                id={item.PresenterID}
                title={`${item.PresenterFirstName} ${item.PresenterLastName}`}
                description={`${item.PresenterOrganization}`}
                personImage={item.PresenterPhotoFileName}
                subject="Keynote"
                hover
                hoverTitle={item.PresenterCustomField3}
                hoverBg={`${process.env.PUBLIC_URL}/images/speaker-hover-bg-${
                  (idx % 6) + 1
                }-1.svg`}
                StaticIcon={false}
                blBg={MainSpeakers[idx] ? MainSpeakers[idx].blBg : false}
                isKeynote
              />
            ))}
            {/* {MainSpeakers.map((item, idx) => (
              <Card
                key={idx}
                hover
                id={item.id}
                title={item.title}
                description={item.description}
                personImage={item.personImage}
                hoverTitle={item.hoverTitle}
                subject={item.subject}
                StaticIcon
                blBg={item.blBg}
                isKeynote
              />
            ))} */}
          </CardInner>
        </CardWrap>
      </SpeakerInner>
    </SpeakerContainer>
  );
};

export default Speaker;
