import styled, { css } from 'styled-components';

export const MainCodeLabWrap = styled.div`
  padding: 0 40px 148px;
  background-color: #fff;

  @media screen and (max-width: 768px) {
    padding: 0 24px 60px;

    .MainSectionTitleArea {
      padding-bottom: 36px;
    }
  }
`;

export const MainCodeLabArea = styled.div`
  position: relative;
  max-width: 1200px;
  height: 380px;
  margin: 0 auto;
  padding: 40px 44px;
  box-sizing: border-box;
  border-radius: 24px;
  background: #0f42cf;
  overflow: hidden;
  transform: translateY(260px);
  transition: 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &.active {
    transform: translateY(0);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 699px;
    height: 301px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/main-codelab-img.png`});
    background-size: contain;
    background-repeat: no-repeat;
  }

  p {
    font-family: 'samsungsharpsans-bold';
    font-size: 34px;
    font-weight: 700;
    line-height: 44px;
    color: #fff;
  }
  p + p {
    font-family: 'SamsungOne-400';
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.8;
  }

  @media screen and (max-width: 768px) {
    height: 200px;
    padding: 20px;
    border-radius: 12px;

    &:before {
      width: 170px;
      height: 124px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/main-codelab-img.s.png`});
    }

    p {
      font-size: 18px;
      line-height: 24px;
    }
    p + p {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
