import { BarcodeDetector } from 'barcode-detector';

export const useActivityQRDetect = () => {
  return {
    /** @type {React.ChangeEventHandler}  */
    qrFileHandler: (ev) => {
      const detector = new BarcodeDetector();
      detector.addEventListener('error', ({ detail }) => {
        console.error(detail);
      });

      const { files } = ev.target;
      if (!files || !files.length) {
        return;
      }

      detector.detect(ev.target.files[0]).then((barcodes) => {
        console.log(barcodes);

        if (barcodes.length === 0) {
          return;
        }

        const { rawValue } = barcodes[0];
        window.location.href = rawValue;
      });
    },
  };
};
