import styled from 'styled-components';

export const ActivityMsgContContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  min-height: 750px;
  background-color: #2088fd;
  padding: 0 24px;
  box-sizing: border-box;
  z-index: 101;
  overflow: hidden;

  .text-area {
    position: relative;
    padding-top: 84px;
    text-align: center;
    color: #fff;
    img {
      display: block;
      width: 66px;
      margin: 0 auto;
    }
    img + p {
      margin-top: 14px;
      font-family: 'samsungsharpsans-bold';
      font-size: 20px;
      line-height: 28px;
    }
    p + p {
      margin-top: 12px;
      font-size: 14px;
      line-height: 20px;
    }
    .Link {
      margin-top: 32px;
    }
  }
  .motion {
    position: absolute;
    left: 50%;
    top: 0;
    width: 700px;
    height: 860px;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 4;
  }
  .copyright {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    font-family: 'samsungsharpsans-bold';
    font-size: 14px;
    line-height: 1;
    text-align: center;
    color: #fff;
  }

  &.codelab {
    background-color: #ca6ceb;
  }
  &.pickup {
    background-color: #2188fd;
    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 188px;
      height: 46px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/pickup-bg-1.svg`});
      background-repeat: no-repeat;
      background-size: contain;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 79px;
      width: 130px;
      height: 132px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/pickup-bg-2.svg`});
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &.error {
    background-color: #f6f6f6;
    .text-area,
    .copyright {
      color: #000;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 188px;
      height: 46px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-error-bg-1.svg`});
      background-repeat: no-repeat;
      background-size: contain;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 100px;
      width: 130px;
      height: 132px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-error-bg-2.svg`});
      background-repeat: no-repeat;
      background-size: contain;
    }
    .text-area {
      position: static;
    }
    .text-area:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: 102px;
      width: 360px;
      height: 303px;
      transform: translateX(-50%);
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-error-bg-3.png`});
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
`;
