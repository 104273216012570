import React from 'react';
import {
  Card,
  CardImage,
  VideoIcon,
  CardInfo,
  CardTitle,
  CardText,
  CardLink,
  CardTitleVideo,
} from '../CardSession/CardSessionElements';

// id : 아이디
// col : card 너비
// border : border 유무
// image : 이미지 URL
// title : 제목
// description : 내용
// video : 비디오인 경우
// link : 이동할 링크
const CardSession = ({
  id,
  col,
  border,
  image,
  title,
  description,
  video,
  link,
  className,
}) => {
  return (
    <Card className={col} border={border} video={video}>
      <CardLink
        href={link}
        target="_blank"
        rel="noreferrer"
        className={className}
      >
        <CardImage className={video ? 'card_img has-dim' : 'card_img'}>
          {video ? <VideoIcon className="video-icon" /> : null}
          <img src={image} alt={description} />
        </CardImage>

        <CardInfo className="Text" video={video}>
          <CardTitleVideo className={`Title${video ? ' title-only' : ''}`}>
            {title}
          </CardTitleVideo>
          <CardText>{description}</CardText>
        </CardInfo>
      </CardLink>
    </Card>
  );
};

export default CardSession;
