import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import ErrorCont from '../../components/ErrorCont';
import withRouter from '../../components/Router/withRouter';

const Error = (props) => {
  const { num, title, desc } = props;
  return (
    <>
      <DetailContainer>
        <ErrorCont num={num} errorTitle={title} errorDesc={desc} />
      </DetailContainer>
    </>
  );
};

export default withRouter(Error);
