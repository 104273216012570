// main page > session area data

export const MainSessionData = [
  {
    id: 1,
    title: 'Device AI',
    date: '10/5 at 11:30AM',
    description: 'SmartThings & Matter, Bixby, Knox, Tizen',
    sessionLink: `sessions?filter=${encodeURIComponent('Device AI')}`,
    bgImgNum: 5,
  },
  {
    id: 2,
    title: 'Responsible AI',
    date: '10/5 at 11:30AM',
    description:
      'Digital Health Platform, Health SDKs & Partner Ecosystem, Open Innovation',
    sessionLink: `sessions?filter=${encodeURIComponent('Responsible AI')}`,
    bgImgNum: 2,
  },
  {
    id: 3,
    title: 'SmartThings',
    date: '10/5 at 11:30AM',
    description: 'Galaxy & One UI, Gaming, Wearable, Samsung Wallet',
    sessionLink: `sessions?filter=${encodeURIComponent('SmartThings')}`,
    bgImgNum: 2,
  },
  {
    id: 4,
    title: 'Advanced Tech',
    date: '10/5 at 11:30AM',
    description: 'Smart TV & Tizen, Tizen TV Development Tools, Gaming',
    sessionLink: `sessions?filter=${encodeURIComponent('Advanced Tech')}`,
    bgImgNum: 4,
  },
  {
    id: 5,
    title: 'Enterprise & Ecosystem',
    date: '10/5 at 11:30AM',
    description: 'Relumino, Programmable Picture Quality, Start-up Projects',
    sessionLink: `sessions?filter=${encodeURIComponent(
      'Enterprise & Ecosystem'
    )}`,
    bgImgNum: 3,
  },
];

export default MainSessionData;
