import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useSelector } from 'react-redux';
import {
  TechContainer,
  TechInner,
  TechFilterBtn,
  TechTop,
  TechBottom,
  CardWrap,
  CardMoreBtn,
  CardInner,
  LiveRoom,
  LiveRoomInner,
  LiveRoomTag,
} from './TechContElements';
import Filter from '../Filter';
import CardSession from '../CardSession';
import Button from '../Button';
import ToastPopup from '../ToastPopup';
import { SESSION_ON_DEMAND } from '../../data/status';
import { colorMapping } from '../../utils/SessionColor';

const LoadingItem = () => {
  // const NUM = 8;
  return [
    [...Array(6)].map((m, idx) => {
      return (
        <CardSession
          key={`session-loading-${idx}`}
          loading
          listYn
          col="col-3"
        />
      );
    }),
  ];
};

const Tech = () => {
  let sessionData = useSelector((state) => state.sessionList.sessionList);
  const { sessionLoading, filterData, filterChecked, megaRoomStatus } =
    useSelector((state) => state.sessionList);
  // const { checkedTypes, checkedTopics, checkedMainCategories } = useSelector(
  //   (state) => state.sessionFilter
  // );
  sessionData = filterChecked ? filterData : sessionData;

  const phase = useSelector((state) => state.meta.data.phase);
  const mega = useSelector((state) => state.meta.data.mega);

  const [filterOpen, setFilterOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalFalse, setModalFalse] = useState(false);

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  // PC, MO check
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
  };

  const [count, setCount] = useState(0);
  const [moreBtn, setMoreBtn] = useState(false);

  // show/hide filter based on window size
  const filterHandler = () => {
    if (!isDesktop) {
      setFilterOpen(false);
    } else {
      setFilterOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia, filterHandler);

    return () =>
      window.removeEventListener('resize', updateMedia, filterHandler);
  });

  // card interaction
  const tl = useRef();
  const cardRef = useRef();
  useLayoutEffect(() => {
    if (sessionData.length === 0 && !sessionLoading) {
      return;
    }

    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      '(min-width: 768px)': function () {
        const cardArray = cardRef.current
          ? gsap.utils.toArray(cardRef.current.children)
          : [];
        cardArray.forEach((el) => {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: '0 95%',
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
      '(max-width: 767px)': function () {
        const cardArray = cardRef.current
          ? gsap.utils.toArray(cardRef.current.children)
          : [];
        cardArray.forEach((el) => {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: '0 100%',
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
      if (tl.current) {
        tl.current.kill();
      }

      ScrollTrigger.clearMatchMedia();
    };
  }, [sessionData, sessionLoading]);

  useEffect(() => {
    if (!isDesktop && sessionData) {
      if (sessionData.length < 6) {
        setCount(sessionData.length);
      } else {
        setCount(6);
      }
      setMoreBtn(false); // reset more button status
    }
  }, [sessionData, isDesktop]);

  const onClick = (e) => {
    e.preventDefault();
    if (!isDesktop) {
      setCount(() => sessionData.length);
      if (count !== sessionData.length && !moreBtn) {
        setMoreBtn(true);
      } else {
        setCount(moreBtn ? 6 : sessionData.length);
        setMoreBtn(false);
      }
    }
  };

  return (
    <TechContainer>
      <TechInner>
        <TechFilterBtn
          onClick={toggleFilter}
          className={filterOpen && 'is-checked'}
          style={{
            pointerEvents: sessionLoading && 'none',
            opacity: sessionLoading && '0.5',
          }}
        >
          Filter
        </TechFilterBtn>
        <TechTop className={sessionLoading && 'disabled'}>
          {isDesktop ? (
            <Filter setOpen={setFilterOpen} />
          ) : filterOpen ? (
            <Filter setOpen={setFilterOpen} />
          ) : null}
        </TechTop>
        <TechBottom>
          <CardWrap>
            {/* {phase === 'phase3' && mega?.videoStatus !== SESSION_ON_DEMAND ? (
              <Link to="/mega-session">
                <LiveRoom>
                  <LiveRoomInner>
                    {megaRoomStatus === 'live' ? (
                      <LiveRoomTag className="live-on">Live</LiveRoomTag>
                    ) : (
                      <LiveRoomTag>MEGA</LiveRoomTag>
                    )}
                    <div className="title">Mega Session</div>
                    <div className="time">11:30 - 18:00 PT</div>
                    <p className="noti">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.9999 8C15.9999 12.4182 12.418 16 7.99992 16C3.58168 16 -8.39233e-05 12.4182 -8.39233e-05 8C-8.39233e-05 3.58195 3.58168 0 7.99992 0C12.418 0 15.9999 3.58195 15.9999 8Z"
                          fill="#ADADAD"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.14729 11.2394C7.14729 10.7687 7.52911 10.3867 8 10.3867C8.47108 10.3867 8.85271 10.7687 8.85271 11.2394C8.85271 11.7107 8.47108 12.0921 8 12.0921C7.52911 12.0921 7.14729 11.7107 7.14729 11.2394Z"
                          fill="white"
                        />
                        <path
                          d="M8.68217 3.90625V9.19307H7.31783V3.90625H8.68217Z"
                          fill="white"
                        />
                      </svg>
                      The on-demand Tech and Open Theater <br />
                      sessions will open on October 6.
                    </p>
                    <div className="ic">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <circle
                          cx="16"
                          cy="16"
                          r="16"
                          fill="black"
                          fillOpacity="0.06"
                        />
                        <path
                          d="M14 21L19 16L14 11"
                          stroke="#9E9E9E"
                          strokeWidth="1.4"
                          strokeLinecap="square"
                        />
                      </svg>
                    </div>
                  </LiveRoomInner>
                </LiveRoom>
              </Link>
            ) : null} */}
            <CardInner ref={cardRef}>
              {sessionLoading ? (
                <LoadingItem />
              ) : sessionData.length > 0 ? (
                <>
                  {sessionData.map((item, idx) => {
                    const type =
                      item.CustomPresField65 && item.CustomPresField65 !== '0'
                        ? item.CustomPresField65
                        : '-';
                    return (
                      <CardSession
                        key={item.PresentationID}
                        id={item.PresentationID}
                        listYn
                        col={
                          !isDesktop
                            ? `${idx + 1 > count ? 'none' : 'col-3 is-active'}`
                            : `${idx + 1 > 6 ? 'col-3' : 'col-3 is-active'}`
                        }
                        bookmark
                        title={
                          item.PresentationTitle
                          /* item.SessionBuilderCode === 'M'
                        ? item.SessionName
                        : item.PresentationTitle */
                        }
                        description={item.AbstractTextShort?.replace(
                          /\u00a0/gi,
                          ' '
                        )}
                        image={item.CustomPresField1}
                        // tag={
                        //   typeof item.CustomPresField2 === 'object' &&
                        //   item.CustomPresField65 &&
                        //   item.CustomPresField65 !== '0'
                        //     ? [item.CustomPresField65, ...item.CustomPresField2]
                        //     : item.CustomPresField65 &&
                        //       item.CustomPresField65 !== '0'
                        //     ? [item.CustomPresField65]
                        //     : typeof item.CustomPresField2 === 'object'
                        //     ? [...item.CustomPresField2]
                        //     : null
                        // }
                        tag={
                          typeof item.CustomPresField2 === 'object' &&
                          item.CustomPresField2
                            ? [...item.CustomPresField2]
                            : null
                        }
                        // type={item.SessionType ? item.SessionType : '-'}
                        type={type}
                        color={type !== '-' ? colorMapping[type] : ''}
                        sessionData={item}
                        live={item.status === 'live' && 'on'}
                        setModal={setModal}
                        setModalFalse={setModalFalse}
                      />
                    );
                  })}
                  {/* <CardSession
                    listYn
                    col={
                      !isDesktop
                        ? `${
                            sessionData.length > count
                              ? 'none'
                              : 'col-3 is-active'
                          }`
                        : `${
                            sessionData.length > 6 ? 'col-3' : 'col-3 is-active'
                          }`
                    }
                  /> */}
                </>
              ) : (
                <div className="empty">No matches found.</div>
              )}
            </CardInner>
            {!isDesktop && sessionData.length > 6 && (
              <CardMoreBtn>
                <Button
                  color="white"
                  size="medium"
                  outline="outline"
                  className="moreBtn"
                  onClick={(e) => onClick(e)}
                >
                  {moreBtn ? 'Less' : 'More'}
                </Button>
              </CardMoreBtn>
            )}
          </CardWrap>
        </TechBottom>
      </TechInner>

      {/* toast popup */}
      {/* 북마크 설정 팝업 */}
      <ToastPopup
        modal={modal}
        setModal={setModal}
        state="success"
        text="This session is kept as a bookmark."
      />

      {/* 북마크 해제 팝업 */}
      <ToastPopup
        modal={modalFalse}
        setModal={setModalFalse}
        state="success"
        text="This session is no longer bookmarked."
      />
    </TechContainer>
  );
};

export default Tech;
