import React, { useState } from 'react';
import {
  BannerBox,
  BannerBoxInner,
  BannerBoxInfo,
  BannerTitle,
  BannerDesc,
  BannerLink,
  BannerBtnLink,
  BannerDate,
} from './BannerElement';
import ALink from '../ALink';
import Button from '../Button';
import Chevron from './Chevron';
import Popup from '../Popup';

const Banner = (props) => {
  const {
    url,
    linkText,
    buttonUrl,
    fullWidth,
    download,
    title,
    desc,
    buttonText,
    border,
    isLoginCheck,
    onClick,
    buttonLengthOne,
    popupRight,
    buttonText2,
    buttonUrl2,
    bannerDate,
    linkType,
    className,
  } = props;

  const [modal, setModal] = useState(false);
  const popupHandler = (target) => {
    setModal(true);
  };

  return (
    <>
      {!fullWidth ? (
        <BannerBox>
          <BannerBoxInner className="inner">
            <BannerBoxInfo>
              {title ? (
                <BannerTitle className="title">{title}</BannerTitle>
              ) : null}
              {bannerDate ? <BannerDate>{bannerDate}</BannerDate> : null}
              {desc ? (
                <BannerDesc className={`${className} desc`}>{desc}</BannerDesc>
              ) : null}

              {typeof url !== 'string' ? (
                url &&
                url.map((item, idx) => (
                  <BannerLink href={item} target="_blank" key={idx}>
                    <span>{linkText[idx]}</span>
                    <Chevron />
                  </BannerLink>
                ))
              ) : (
                <BannerLink className="moreLink" href={url} target="_blank">
                  <span>{linkText}</span>
                  <Chevron />
                </BannerLink>
              )}

              {buttonLengthOne === false ? (
                <>
                  <BannerBtnLink>
                    <ALink
                      className="btn"
                      href={`${buttonUrl}`}
                      color="border"
                      size="medium"
                      rel="noopener noreferrer"
                    >
                      {buttonText}
                    </ALink>
                  </BannerBtnLink>
                  {popupRight ? (
                    <BannerBtnLink>
                      <Button
                        onClick={popupHandler}
                        color="border"
                        size="medium"
                        rel="noopener noreferrer"
                      >
                        {buttonText2}
                      </Button>
                    </BannerBtnLink>
                  ) : (
                    <BannerBtnLink>
                      <ALink
                        className="btn"
                        href={`${buttonUrl}`}
                        color="border"
                        size="medium"
                        rel="noopener noreferrer"
                      >
                        {buttonText2}
                      </ALink>
                    </BannerBtnLink>
                  )}
                </>
              ) : null}

              {buttonLengthOne ? (
                <BannerBtnLink className="banner-btn">
                  <ALink
                    className="btn"
                    href={`${buttonUrl}`}
                    color="black"
                    size="medium"
                    rel="noopener noreferrer"
                  >
                    {buttonText}
                  </ALink>
                </BannerBtnLink>
              ) : null}
            </BannerBoxInfo>
          </BannerBoxInner>
        </BannerBox>
      ) : (
        /* If there is only one, add border class */
        <BannerBox className={`fullWidth ${border} BannerBox`}>
          <BannerBoxInner className="fullWidthInner">
            <BannerBoxInfo>
              {title ? (
                <BannerTitle className="title">{title}</BannerTitle>
              ) : null}
              {desc ? <BannerDesc className="desc">{desc}</BannerDesc> : null}
              {typeof url !== 'string' ? (
                url &&
                url.map((item, idx) => (
                  <BannerLink
                    className={linkType ? 'large' : ''}
                    href={item}
                    target="_blank"
                    key={idx}
                  >
                    <span>{linkText[idx]}</span>
                    <Chevron />
                  </BannerLink>
                ))
              ) : (
                <BannerLink href={url} target="_blank">
                  <span>{linkText}</span>
                  <Chevron />
                </BannerLink>
              )}

              {buttonUrl ? (
                <BannerBtnLink className="right">
                  <ALink
                    className="btn"
                    href={`${buttonUrl}`}
                    color="border"
                    size="medium"
                    rel="noopener noreferrer"
                  >
                    {buttonText}
                  </ALink>
                </BannerBtnLink>
              ) : null}
            </BannerBoxInfo>
          </BannerBoxInner>
        </BannerBox>
      )}

      {/* alert popup */}
      {modal ? (
        <Popup
          modal={modal}
          setModal={setModal}
          title="Alert Sign In"
          desc="You'll have to sign in to do that."
          linkText="Sign In"
          link={`/login?redirectURL=${window.location.pathname}`}
          btnText="OK"
        />
      ) : null}
    </>
  );
};

export default Banner;
