import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Lottie from 'lottie-react';
import 'odometer/themes/odometer-theme-default.css';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useSelector, useStore } from 'react-redux';
import titleLottie from '../../lottie/main-hero-title.json';
import titleMoLottie from '../../lottie/main-hero-title.s.json';
import ALink from '../ALink';
import ButtonLink from '../ButtonLink';
import withRouter from '../Router/withRouter';
import {
  HeroArrow,
  HeroContainer,
  HeroContent,
  HeroInner,
  HeroIntroBtn,
  HeroIntroBtnWrap,
  HeroIntroDesc,
  HeroIntroDescItem,
  HeroIntroText,
  HeroIntroTitle,
  HeroVisual,
} from './MainHeroElements';

const MainHero = (props) => {
  const phase = useSelector((state) => state.meta.data.phase);
  // const main = useSelector((state) => state.meta.data);
  // const keynoteLive = useSelector((state) => state.meta.keynoteLive);

  const mainRef = useRef();
  const q = gsap.utils.selector(mainRef);
  const tl = useRef();
  const visualRef = useRef();
  const contentRef = useRef();
  const titleRef = useRef();
  const descRef = useRef();
  const buttonRef = useRef();
  const arrowRef = useRef();

  // console.log('q', q);
  // console.log('mainRef', mainRef);
  // console.log('visualRef', visualRef);
  // console.log('contentRef', contentRef);
  // console.log('titleRef', titleRef);
  // console.log('descRef', descRef);
  // console.log('buttonRef', buttonRef);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 769);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const scrollPos = window.scrollY;
      if (scrollPos < 10) {
        window.scrollTo({
          top: isDesktop ? 130 : 100,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 3000);

    window.addEventListener('resize', updateMedia);
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', updateMedia);
    };
  });

  gsap.registerPlugin(ScrollTrigger);
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    setHasRendered(true);
  }, []);
  useEffect(() => {
    if (hasRendered) {
      ScrollTrigger.matchMedia({
        '(min-width: 769px)': function () {
          tl.current = gsap
            .timeline({
              scrollTrigger: {
                trigger: mainRef.current,
                scrub: 0.5,
                start: 'top',
                end: '+=200%',
                onUpdate: (self) => {
                  if (self.progress > 0.3) {
                    buttonRef.current?.classList.add('active');
                  } else {
                    buttonRef.current?.classList.remove('active');
                  }
                },
                pin: true,
                anticipatePin: 1,
              },
            })
            .fromTo(q('.title-motion'), { y: 0 }, { y: -500, duration: 0.5 })
            .fromTo(
              visualRef.current,
              { scale: 0.78, y: 460, borderRadius: '24px' },
              { scale: 1, y: 0, borderRadius: 0 },
              '-=0.5'
            )
            .fromTo(contentRef.current, { y: 450 }, { y: 0 }, '-=0.5')
            .fromTo(
              titleRef.current,
              { scale: 0.85, y: 10 },
              { scale: 1, y: 0 },
              '-=0.5'
            )
            .fromTo(
              descRef.current,
              { y: 50, opacity: 1 },
              { y: 0, opacity: 1, duration: 1 },
              '-=0'
            )
            .fromTo(
              buttonRef.current,
              { y: 50, opacity: 1 },
              { y: 0, opacity: 1, duration: 1 },
              '-=1'
            );
        },
        '(max-width: 768px)': function () {
          tl.current = gsap
            .timeline({
              scrollTrigger: {
                trigger: mainRef.current,
                scrub: 0.5,
                start: 'top',
                end: '+=200%',
                onUpdate: (self) => {
                  if (self.progress > 0.2) {
                    buttonRef.current?.classList.add('active');
                  } else {
                    buttonRef.current?.classList.remove('active');
                  }
                },
                pin: true,
                anticipatePin: 1,
              },
            })
            .fromTo(q('.title-motion'), { y: 0 }, { y: -250, duration: 0.5 })
            .fromTo(
              visualRef.current,
              { scale: 0.86, y: 250, borderRadius: '24px' },
              { scale: 1, y: 0, borderRadius: 0 },
              '-=0.5'
            )
            .fromTo(contentRef.current, { y: 300 }, { y: 0 }, '-=0.5')
            .fromTo(
              descRef.current,
              { y: 50, opacity: 1 },
              { y: 0, opacity: 1, duration: 1 },
              '-=0'
            )
            .fromTo(
              buttonRef.current,
              { y: 50, opacity: 1 },
              { y: 0, opacity: 1 },
              '-=1'
            );
        },
      });
    }

    return () => {
      if (tl.current) tl.current.kill();
    };
  }, [q]);

  // When accessing with useSelector, the screen re-renders and the scroll is broken.
  // So, access the store and process it in a way that does not re-render the screen.
  const store = useStore();
  const keynoteLiveRef = useRef(store.getState().meta.keynoteLive);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      const updateKeynoteStatus = state.meta.keynoteLive;
      const updateMainStatus = state.meta.data.main;

      // p3 live dot UI
      // Check and change when values ​​are different
      // If the values ​​are equal and the value is live
      if (
        updateKeynoteStatus !== keynoteLiveRef.current ||
        (keynoteLiveRef.current === updateKeynoteStatus &&
          keynoteLiveRef.current === 'live')
      ) {
        keynoteLiveRef.current = updateKeynoteStatus;
        const element = document.getElementById('keynote-btn');
        const sessionBtn = document.querySelector('.p3-session-btn');
        if (element && sessionBtn) {
          element.innerText =
            updateKeynoteStatus === 'live' ? 'Join Keynotes' : 'Go to Keynotes';
          if (updateKeynoteStatus === 'live') {
            element.classList.add('live');
            sessionBtn.classList.remove('white');
            sessionBtn.classList.add('whiteOpacity');
          } else {
            element.classList.remove('live');
            sessionBtn.classList.add('white');
            sessionBtn.classList.remove('whiteOpacity');
          }
        }
      }

      // p4 highlight link
      if (updateMainStatus?.highlight) {
        const element = document.getElementById('highlight-btn');
        if (element) {
          if (
            updateMainStatus.highlight &&
            element.classList.contains('none')
          ) {
            element.classList.remove('none');
            element.setAttribute('href', updateMainStatus.highlight);
          }
        }
      }
    });

    return () => {
      if (keynoteLiveRef.current) {
        keynoteLiveRef.current = undefined;
      }
      unsubscribe(); // cleanup
    };
  }, [store]);

  return (
    <>
      <HeroContainer>
        <HeroInner
          ref={mainRef}
          className={
            phase === 'phase3' || phase === 'phase4' ? 'is-phase4' : ''
          }
        >
          {/* <HeroDim className="hero_dim" /> */}
          <HeroVisual>
            <Lottie
              animationData={isDesktop ? titleLottie : titleMoLottie}
              loop={false}
              className="title-motion"
            />
            <div className="visual-img" ref={visualRef}>
              <ReactPlayer
                id="video"
                url={
                  isDesktop
                    ? `${process.env.PUBLIC_URL}/images/main-hero-bg-2.mp4`
                    : `${process.env.PUBLIC_URL}/images/main-hero-bg.s.mp4`
                }
                playing
                playsinline
                muted
                loop
                width=""
                height=""
              />
            </div>
          </HeroVisual>
          <HeroContent ref={contentRef}>
            <HeroIntroText className="hero_intro_text">
              <HeroIntroTitle className="hero_intro_title" ref={titleRef}>
                {phase === 'phase4' ? (
                  <span>
                    Thanks for coming!
                    <br />
                    Join us next year.
                  </span>
                ) : (
                  <span>
                    Samsung Developer <br />
                    Conference 2024
                  </span>
                )}
              </HeroIntroTitle>
              <HeroIntroDesc className="hero_intro_desc" ref={descRef}>
                {/* It is necessary to check whether text branching is necessary for each phase. */}
                <HeroIntroDescItem>
                  San Jose McEnery Convention Center
                </HeroIntroDescItem>
                <HeroIntroDescItem>
                  Thur, Oct 03, 2024 10:00 AM PT
                </HeroIntroDescItem>
              </HeroIntroDesc>
              <HeroIntroBtnWrap ref={buttonRef}>
                {phase === 'phase2' ? (
                  <HeroIntroBtn className="hero_intro_btn">
                    <ALink
                      color="whiteOpacity"
                      size="medium"
                      href="https://youtu.be/MHq3rGquQhs"
                    >
                      Watch Video
                    </ALink>
                    <ButtonLink
                      url="/register"
                      color="white"
                      size="medium"
                      className="btn-reg"
                    >
                      <s className="arrow" />
                      <span>Register Now</span>
                    </ButtonLink>
                  </HeroIntroBtn>
                ) : null}
                {phase === 'phase3' ? (
                  <HeroIntroBtn className="hero_intro_btn">
                    <ButtonLink url="/keynote" color="white" size="medium">
                      <span id="keynote-btn">Go to Keynotes</span>
                    </ButtonLink>
                    <ButtonLink
                      url="/sessions"
                      color="white"
                      size="medium"
                      className="p3-session-btn"
                    >
                      <span>Go to Sessions</span>
                    </ButtonLink>
                  </HeroIntroBtn>
                ) : null}
                {phase === 'phase4' ? (
                  <HeroIntroBtn className="hero_intro_btn">
                    <ButtonLink url="/keynote" color="white" size="medium">
                      <span>Watch Keynote</span>
                    </ButtonLink>
                    <ALink
                      color="whiteOpacity"
                      size="medium"
                      id="highlight-btn"
                      className="none"
                    >
                      Watch Highlight
                    </ALink>
                  </HeroIntroBtn>
                ) : null}
              </HeroIntroBtnWrap>
            </HeroIntroText>

            <HeroArrow ref={arrowRef}>
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-hero-arrow.svg`}
                alt="scroll down"
              />
            </HeroArrow>
          </HeroContent>
        </HeroInner>
      </HeroContainer>
    </>
  );
};

export default withRouter(MainHero);
