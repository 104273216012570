import styled from 'styled-components';

export const TermsContContainer = styled.div`
  padding: 120px 0;

  @media screen and (max-width: 768px) {
    padding: 40px 0 20px;
  }
`;

export const TermsContInner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 24px;
  }
`;

export const CookieInner = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const TermsContWrap = styled.div`
  width: calc(100% - 278px);
  @media screen and (max-width: 768px) {
    order: 2;
    width: 100%;
  }
`;

export const TermsContTop = styled.div`
  margin-bottom: 40px;
  .space {
    margin-top: 24px;
  }
  @media screen and (max-width: 768px) {
    .space {
      margin-top: 16px;
    }
  }
`;

export const TermsContTitle = styled.div`
  font-family: 'samsungSharpSans-bold';
  font-size: 42px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const CookieTitle = styled.div`
  font-family: 'samsungSharpSans-bold';
  font-size: 42px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const TermsContVersion = styled.div`
  margin: 40px 0 12px;
  font-size: 16px;
  line-height: 24px;
  color: #535353;
  span {
    padding-right: 4px;
    font-family: 'SamsungOne-400';
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {
    margin: 24px 0 12px;
    font-size: 14px;
    line-height: 1.57;
  }
`;

export const TermsDropdownGroup = styled.div`
  display: flex;
  margin: 24px 0 40px;
  .dropdown {
    min-width: 218px;
    max-width: 260px;
    + .dropdown {
      margin-left: 14px;
      min-width: 312px;
      max-width: 312px;
    }
  }
  @media screen and (max-width: 900px) {
    display: block;
    margin: 16px 0 24px;
    .dropdown {
      min-width: 100%;
      max-width: 100%;
      + .dropdown {
        margin-top: 10px;
        margin-left: 0;
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
`;

export const TermsContDesc = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #535353;
  .space {
    margin-top: 12px;
  }
  .space-large {
    margin-top: 16px;
  }
  strong {
    font-weight: bold;
    font-family: 'SamsungOne-700';
  }
  a {
    color: #1259aa;
    &:hover {
      color: #2088fd;
      text-decoration: none;
    }
  }
  .table-link {
    display: inline;
    word-break: break-all;
    margin-top: 0;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 1.57;

    .space-large {
      margin-top: 16px;
    }
  }
`;

export const TermsContLink = styled.div`
  margin-top: 10px;
  a {
    padding-right: 18px;
    color: #1259aa;
    font-size: 14px;
    line-height: 1.57;
    font-family: 'SamsungOne-700';
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-download.png`});
    background-position: right center;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    transition: all 0.3s;
    text-decoration: none;

    &:hover {
      color: #2088fd;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-download-hover.png`});
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    button {
      width: 100%;
    }
  }
`;

export const TermsContItem = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #535353;
  & + & {
    margin-top: 40px;
  }
  .space {
    margin-top: 12px;
  }
  .space-large {
    margin-top: 20px;
  }
  .text_link {
    color: #1259aa;
    &:hover {
      color: #2088fd;
      text-decoration: underline;
    }
  }
  strong {
    font-style: italic;
  }

  em {
    font-family: 'SamsungOne-700';
    font-weight: bold;
  }

  .italic {
    font-style: italic;
    padding-bottom: 4px;
  }

  .color-light {
    color: #7c7c7c;
  }

  .space + .color-light {
    margin-top: 4px;
  }

  .text-light {
    display: inline-block;
    color: #7c7c7c;
    margin-top: 4px;
  }

  @media screen and (max-width: 768px) {
    & + & {
      margin-top: 60px;
    }

    .space-large {
      margin-top: 16px;
    }

    .hide-m {
      display: none;
    }
  }
`;

export const TermsItemTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-family: 'SamsungOne-700';
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: normal;
  color: #000;
  @media screen and (max-width: 768px) {
    display: block;
    font-size: 16px;
    line-height: 1.25;
  }
`;

export const TermsItemTitleIconWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;

  img {
    display: block;
  }

  img + img {
    margin-left: 8px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 8px;
    margin-left: 0;

    img + img {
      margin-left: 4px;
    }
  }
`;

export const TermsItemSubTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'SamsungOne-600';
  font-size: 14px;
  font-weight: bold;
  line-height: 1.42;
  letter-spacing: normal;
  color: #000;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 6px;
  }
`;

export const TermsItemP = styled.div`
  position: relative;
  padding-left: 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #7c7c7c;
  &:before {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #000;
  }
  & + & {
    margin-top: 10px;
  }
`;

export const TermsCheckP = styled.div`
  position: relative;
  padding-left: 36px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #7c7c7c;
  margin-top: 10px;

  &:before {
    content: '';
    position: absolute;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-pp-check.svg`});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    margin-left: 16px;
  }
  & + & {
    margin-top: 10px;
  }
`;
export const TermsItemNumber = styled.div`
  span {
    display: inline-block;
    width: 24px;
    font-family: 'SamsungOne-700';
    color: #7c7c7c;
    margin-right: 4px;
    margin-top: 8px;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    span {
      margin-top: 10px;
      display: block;
      margin-top: 0;
    }
  }
`;

export const TermsItemPtagTitle = styled.div`
  margin-bottom: 4px;
  font-family: 'SamsungOne-700';
  color: #000;
`;

export const TermsContListLoman = styled.ul`
  margin-top: 12px;
  li {
    position: relative;
    padding-left: 28px;
    color: #7c7c7c;

    + li {
      margin-top: 10px;
    }
    span {
      position: absolute;
      left: 0;
      top: 0;
      font-weight: bold;
      font-family: 'SamsungOne-700';
      color: #4e4e4e;
    }
  }
`;

export const TermsContListNumber = styled.ul`
  margin-top: 12px;
  padding-left: 14px;
  li {
    position: relative;
    padding-left: 4px;
    color: #7c7c7c;
    list-style: auto;

    + li {
      margin-top: 10px;
    }
    ::marker {
      font-weight: bold;
      font-family: 'SamsungOne-700';
      color: #4e4e4e;
    }
  }
`;

export const TermsContListAlpha = styled.ul`
  margin-top: 12px;
  padding-left: 14px;
  li {
    position: relative;
    padding-left: 4px;
    color: #7c7c7c;
    list-style: lower-alpha;

    + li {
      margin-top: 10px;
    }
    ::marker {
      font-weight: bold;
      font-family: 'SamsungOne-700';
      color: #4e4e4e;
    }
  }
`;

export const TermsItemLink = styled.a`
  display: inline-block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #1259aa;
  transition: all 0.3s;

  &.table-link {
    display: inline;
    word-break: break-all;
    margin-top: 0;
  }
  &.table-link-inblock {
    display: inline-block;
    word-break: break-all;
    margin-top: 0;
  }

  &:hover {
    color: #2088fd;
    text-decoration: none;
  }

  @media screen and (max-width: 768px) {
    &.table-link {
      br {
        display: none;
      }
    }
  }
`;

export const TermsContToc = styled.div`
  position: relative;

  @media screen and (max-width: 768px) {
    order: 1;
  }
`;

export const TermsTocInner = styled.div`
  position: sticky;
  top: 125px;
  display: inline-block;
  width: 218px;
  box-sizing: border-box;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const TermsTocItem = styled.button`
  position: relative;
  width: 100%;
  padding-left: 20px;
  border-left: 2px solid #f5f7fb;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #7c7c7c;
  text-align: left;
  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: -2px;
    width: 2px;
    height: 15px;
    background-color: #f5f7fb;
    z-index: 10;
  }
  &.is-active {
    font-family: 'SamsungOne-700';
    font-weight: bold;
    color: #000;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -2px;
      width: 2px;
      height: 100%;
      background-color: #2088fd;
      z-index: 10;
    }
  }
  & + & {
    margin-top: 14px;
  }
`;

export const TermsTable = styled.table`
  margin-top: 12px;
  caption {
    margin-bottom: 12px;
    font-family: 'SamsungOne-400';
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    color: #7c7c7c;
    text-align: left;
    span {
      font-family: 'SamsungOne-700';
      color: #535353;
      text-align: left;
    }
  }

  tr {
    border-bottom: 1px solid #efefef;
  }

  th {
    padding: 16px;
    font-family: 'SamsungOne-700';
    border-top: 1px solid #000;
    text-align: left;
    vertical-align: middle;
  }

  td {
    vertical-align: top;

    &.center {
      vertical-align: middle;
    }
  }

  tbody {
    th {
      border-top: 1px solid #efefef;
    }
  }

  th,
  td {
    color: #4e4e4e;
    border-left: 1px solid #efefef;

    &:first-child {
      border-left: 0;
    }
  }

  td {
    padding: 16px;
    ul {
      li {
        span {
          display: inline-block;
          font-size: 24px;
          line-height: 17px;
          vertical-align: text-top;
        }
      }
    }

    span {
      display: inline-block;
      font-size: 24px;
      line-height: 17px;
      vertical-align: text-top;
    }
  }

  + table {
    margin-top: 20px;
  }
  @media screen and (max-width: 768px) {
    + table {
      margin-top: 16px;
    }

    th {
      padding-left: 4px;
      padding-right: 4px;
    }

    td {
      padding-left: 4px;
      padding-right: 4px;
      span {
        line-height: 12px;
      }

      ul {
        li {
          span {
            lin-height: 12px;
          }
        }
      }
    }
  }
`;

export const TermsExtra = styled.table`
  margin-top: 20px;
  .title {
    font-family: 'SamsungOne-700';
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000;
  }
  .desc {
    margin-top: 4px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #7c7c7c;
  }
`;

export const TermsLabelInfo = styled.div`
  margin-top: -36px;
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: #535353;

  .labelWrap {
    display: none;
  }

  strong {
    font-family: 'SamsungOne-700';
    color: #000;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  p {
    margin-top: 16px;
    text-align: center;
  }

  span {
    display: block;
    margin-top: 16px;
    color: #7c7c7c;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .labelTable {
    margin-top: 8px;
    &:first-of-type {
      tr {
        border-bottom: 0;
      }
    }

    &:last-of-type {
      td:nth-of-type(3) {
        p {
          text-align: left;
        }
      }
    }
  }

  .labelTable + .labelTable {
    margin-top: 0;
  }

  table {
    th {
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: -20px;
    margin-bottom: 60px;

    strong {
      display: block;
      margin-bottom: 8px;
    }

    .labelTable {
      display: none;
    }

    .labelWrap {
      display: flex;
      align-items: base-line;
      border-top: 1px solid #000;
      &:last-of-type {
        border-bottom: 1px solid #efefef;
      }

      .labelBox {
        width: 50%;
        &:first-of-type {
          border-right: 1px solid #efefef;
        }
      }

      dt {
        display: block;
        padding: 16px 8px;
        font-family: 'SamsungOne-700';
        color: #535353;
        text-align: center;
        border-bottom: 1px solid #efefef;
      }

      dd {
        display: block;
        padding: 16px 18px;
        text-align: center;

        img {
          display: block;
          margin: 0 auto 12px;
        }
      }
    }
  }
`;
