import React, { useEffect, useRef, useState } from 'react';
import {
  AccordionBody,
  AccordionButton,
  AccordionCon,
  AccordionHeader,
  AccordionText,
  AccordionWrap,
} from './AccordionElement';
import Chevron from './Chevron';

const Accordion = (props) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('');

  const { title, content, defaultActive } = props;

  const contentBody = useRef(null);

  useEffect(() => {
    if (defaultActive) {
      setActiveState('active');
      setHeightState(`${contentBody.current.scrollHeight}px`);
      setRotateState('open');
    }
  }, []);

  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${contentBody.current.scrollHeight}px`
    );
    setRotateState(setActive === 'active' ? '' : 'open');
  };

  useEffect(() => {
    const handleResize = () => {
      setHeightState(
        setActive === 'active' ? '0px' : `${contentBody.current.scrollHeight}px`
      );
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AccordionWrap>
      <AccordionCon className={`${setActive}`}>
        <AccordionHeader onClick={() => toggleAccordion()}>
          <span>Q</span>
          {title}
          <AccordionButton className={`${setActive}`}>
            <Chevron className={`arr ${setRotate}`} />
          </AccordionButton>
        </AccordionHeader>
        <AccordionBody ref={contentBody} style={{ maxHeight: `${setHeight}` }}>
          <AccordionText dangerouslySetInnerHTML={{ __html: content }} />
        </AccordionBody>
      </AccordionCon>
    </AccordionWrap>
  );
};

export default Accordion;
