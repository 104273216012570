import React, { useEffect, useState } from 'react';
import {
  ToastPopupContainer,
  ToastPopupInner,
  ToastPopupIcon,
  ToastPopupText,
} from './ToastPopupElements';

const Popup = ({
  modal,
  setModal,
  state,
  text,
  reservation,
  setReservation,
}) => {
  const [popupState, setPopupState] = useState(state);

  useEffect(() => {
    if (popupState === 'success') {
      setPopupState('success');
    } else if (popupState === 'warn') {
      setPopupState('warn');
    } else if (popupState === 'error') {
      setPopupState('error');
    }
  }, [popupState]);

  useEffect(() => {
    let timer;

    if (modal || reservation) {
      timer = setTimeout(() => {
        setModal(false);

        if (setReservation) {
          setReservation(false);
        }
      }, 4000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [modal, reservation, setModal, setReservation]);

  return (
    <ToastPopupContainer modal={modal || reservation}>
      <ToastPopupInner>
        <ToastPopupIcon state={popupState} />
        <ToastPopupText>{text}</ToastPopupText>
      </ToastPopupInner>
    </ToastPopupContainer>
  );
};

export default Popup;
