import styled, { css } from 'styled-components';

export const MainTechSquareWrap = styled.div`
  position: relative;
  padding: 60px 24px;
  background-color: #fff;

  .MainSectionTitleArea {
    padding-top: 0;
    padding-bottom: 36px;
  }

  @media screen and (min-width: 1024px) {
    max-width: 1200px;
    margin: auto;
    padding: 135px 24px;
    display: flex;
    align-items: center;
    .MainSectionTitleArea {
      padding-bottom: 0;
      padding-right: 80px;
      text-align: left;

      .desc {
        padding: 0;
        margin: 0;
      }
    }
  }
`;

export const MainTechSquareArea = styled.div`
  position: relative;
  img {
    display: block;
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    img {
      width: 570px;
    }
  }
`;

export const MainTechSquareBg = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;

  div {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;

    &:nth-of-type(1) {
      right: 339px;
      top: 224px;
      width: 60px;
      height: 60px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/main-techSquare-bg-1.svg`});
    }
    &:nth-of-type(2) {
      right: -87px;
      top: 70px;
      width: 174px;
      height: 174px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/main-techSquare-bg-2.svg`});
    }
    &:nth-of-type(3) {
      right: 512px;
      top: 548px;
      width: 80px;
      height: 80px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/main-techSquare-bg-3.svg`});
    }
    &:nth-of-type(4) {
      right: 103px;
      top: 662px;
      width: 108px;
      height: 108px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/main-techSquare-bg-4.svg`});
    }
  }

  @media screen and (max-width: 1024px) {
    left: 0;
    width: 100%;
    height: 100%;
    div {
      &:nth-of-type(1) {
        left: 23px;
        right: auto;
        top: 90px;
        width: 36px;
        height: 36px;
      }
      &:nth-of-type(2) {
        right: 10px;
        top: 217px;
        width: 80px;
        height: 80px;
      }
      &:nth-of-type(3) {
        left: 6px;
        right: auto;
        top: 425px;
        width: 24px;
        height: 24px;
      }
      &:nth-of-type(4) {
        left: 90px;
        right: auto;
        top: auto;
        bottom: 26px;
        width: 60px;
        height: 60px;
      }
    }
  }
`;
