import styled from 'styled-components';

export const ActivityBingoMapContainer = styled.div`
  position: relative;
  background-color: #2088fd;
  margin-top: 48px;
  padding: 16px 0 50px;
`;

export const ActivityBingoMapCont = styled.div`
  position: relative;
  margin: 14px 24px 0;
  padding: 26px 20px 20px;
  background-color: #fff;
  border-radius: 14px;

  > p {
    font-family: 'samsungsharpsans-bold';
    font-size: 20px;
    line-height: 28px;
  }
  img {
    margin-top: 18px;
    display: block;
    width: 100%;
  }
  ul {
    margin-top: 24px;
    li {
      position: relative;
      padding: 12px 0 12px 40px;
      display: flex;
      align-items: center;
      font-family: 'SamsungOne-700';
      font-size: 14px;
      line-height: 20px;
      &:before {
        content: '';
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 28px;
        height: 28px;
        border-radius: 6px;
        text-align: center;
        font-family: 'SamsungOne-700';
        font-size: 12px;
        line-height: 100%;
        color: #fff;
      }
      &:nth-of-type(1):before {
        content: '1';
        background-color: #2088fd;
      }
      &:nth-of-type(2):before {
        content: '2';
        background-color: #ffae34;
      }
      &:nth-of-type(3):before {
        content: '3';
        background-color: #756bf1;
      }
      &:nth-of-type(4):before {
        content: '4';
        background-color: #756bf1;
      }
      &:nth-of-type(5):before {
        content: '5';
        background-color: #756bf1;
      }
      &:nth-of-type(6):before {
        content: '6';
        background-color: #1259aa;
      }
      &:nth-of-type(7):before {
        content: '7';
        background-color: #10b77b;
      }
      &:nth-of-type(8):before {
        content: '8';
        background-color: #535353;
      }
      &:nth-of-type(9):before {
        content: '';
        width: 28px;
        background: url(${`${process.env.PUBLIC_URL}/images/ico-activity-map-info.svg`});
      }
    }
    li + li {
      border-top: 1px solid rgba(0, 0, 0, 0.08);
    }
    &.codelab li {
      &:nth-of-type(1):before {
        content: '1';
        background-color: #ca6ceb;
      }
      &:nth-of-type(2):before {
        content: '';
        width: 28px;
        background: url(${`${process.env.PUBLIC_URL}/images/ico-activity-map-info.svg`});
      }
    }
  }
`;
