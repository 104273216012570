import styled, { css } from 'styled-components';

export const MainSession = styled.div`
  position: relative;
  padding: 0 24px 160px;
  background-color: #f6f6f6;

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
    height: 300vh;
    box-sizing: border-box;

    .sticky {
      position: sticky;
      top: 0;
      height: 100vh;
      min-height: 800px;
      padding-top: 57px;
      box-sizing: border-box;
    }

    .MainSectionTitleArea {
      padding: 52px 0 44px;
    }
  }
`;

export const MainSessionInner = styled.div`
  position: relative;
  max-width: 1200px;
  margin: -8px auto 0;
  padding: 0 16px;
  display: grid;
  gap: 1.5em;
  grid-template-columns: 0.405fr 0.19fr 0.405fr;
  @media screen and (max-width: 768px) {
    display: block;
    margin: 0 auto;
    padding: 0;
    max-width: 480px;
  }
`;

export const MainSessionCardWrap = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const MainSessionCard = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 24px;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(200px);
  //opacity: 0;
  transition: 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .active & {
    transform: translateY(0);
    //opacity: 1;
    &:nth-of-type(2) {
      transition-delay: 0.2s;
    }
    &:nth-of-type(3) {
      transition-delay: 0.3s;
    }
    &:nth-of-type(4) {
      transition-delay: 0.4s;
    }
    &:nth-of-type(5) {
      transition-delay: 0.5s;
    }
  }

  /* pc hover style */
  @media screen and (min-width: 769px) {
    &:hover {
      .Title {
        color: #000;
      }
      .Text {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .MainSessionLink {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 33;
  }

  @media screen and (max-width: 768px) {
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    transform: none;

    & + & {
      margin-top: -150px;
    }
  }
`;

export const MainSessionCardInner = styled.div`
  position: relative;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: 200px;
  }
`;

export const MainSessionCardBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 390ms cubic-bezier(0.215, 0.61, 0.355, 1);

  span {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;
    transition: all 390ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @media screen and (max-width: 768px) {
    span {
      display: none;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`;

export const MainSessionCardInfo = styled.div`
  height: 100%;
  transform: scale(1);
  transform-origin: top left;
  transition: all 390ms cubic-bezier(0.215, 0.61, 0.355, 1);

  @media screen and (max-width: 768px) {
    position: relative;
    bottom: 0;
    transform: none;

    // .more-link {
    //   width: 32px;
    //   height: 32px;
    //   left: 20px;
    //   bottom: 18px;
    // }
  }
`;

export const MainSessionCardTitle = styled.div`
  margin-bottom: 16px;
  padding: 40px 44px 0;
  font-family: 'samsungsharpsans-bold';
  font-weight: 700;
  font-size: 34px;
  line-height: 1.33;
  transition: all 450ms cubic-bezier(0.215, 0.61, 0.355, 1);

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 62px;
  }

  @media screen and (max-width: 768px) {
    max-width: 64.333%;
    margin-bottom: 8px;
    padding: 20px 20px 0;
    font-size: 18px;
    line-height: 24px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      height: 60px;
    }
  }
`;

export const MainSessionSubText = styled.div`
  display: none;
  opacity: 0;
  width: 80%;
  padding: 0 44px;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  transform: translateY(30%);
  transition: all 450ms cubic-bezier(0.215, 0.61, 0.355, 1);

  @media screen and (max-width: 768px) {
    opacity: 1;
    font-size: 14px;
    line-height: 20px;
    padding: 0 20px;
    transform: none;
    color: #fff;
  }
`;

export const MainSessionCardBtnArea = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    top: 14px;
    right: 14px;
    opacity: 1;
  }
`;

export const LoadingCard = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 27.154em;
  border-radius: 24px;
  background-color: #f3f5f8;
  @media screen and (max-width: 768px) {
    border-radius: 12px;
    height: 320px;
  }
`;

export const LoadingCardInner = styled.div`
  width: 100%;
  padding: 40px;
  @media screen and (max-width: 768px) {
    padding: 24px;
  }
`;

export const LoadingCardTop = styled.div`
  position: relative;
  height: 194px;
  @media screen and (max-width: 768px) {
    height: 204px;
  }
`;

export const LoadingCardIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    animation-name: blink;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    & + .dot {
      margin-left: 10px;
    }
    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.08;
    }
    35% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.08;
    }
  }
`;

export const LoadingCardBottom = styled.div`
  width: 100%;
  margin-top: 20px;
  div {
    width: 100%;
    height: 32px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.04);
    & + div {
      margin-top: 8px;
    }
    &:first-of-type {
      width: 50%;
    }
    &:last-of-type {
      width: 27.559%;
      height: 24px;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 24px;
    div {
      height: 20px;
      & + div {
        margin-top: 4px;
      }
      &:last-of-type {
        display: none;
      }
    }
  }
`;

export const Button = styled.div`
  &.more-link {
    position: absolute;
    left: 44px;
    bottom: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    &.hasW100 {
      background-color: rgba(255, 255, 255, 0.2);
    }

    svg {
      display: block;
      width: 18px !important;
      height: 18px !important;
    }
  }

  @media (max-width: 768px) {
    &.more-link {
      width: 32px;
      height: 32px;
      left: 20px;
      bottom: 20px;

      img {
        display: block !important;
        height: 12px;
        transform: inherit;
      }
    }
  }
`;
