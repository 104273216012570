import styled from 'styled-components';

export const PopupContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const PopupInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 312px;
  padding: 220px 24px 32px;
  border-radius: 24px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;

  .point {
    position: absolute;
    left: 20px;
    top: 18px;
    width: 85px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 18px;
    font-family: 'samsungsharpsans-bold';
    font-size: 16px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 220px;
    background-repeat: no-repeat;
    background-size: cover;
    .type-1 & {
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-bingo-popup-bg-1.png`});
    }
    .type-2 & {
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-bingo-popup-bg-2.png`});
    }
    .type-3 & {
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-bingo-popup-bg-3.png`});
    }
    .type-4 & {
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-bingo-popup-bg-4.png`});
    }
    .type-5 & {
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-bingo-popup-bg-5-1.png`});
    }
    .type-6 & {
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-bingo-popup-bg-6.png`});
    }
  }

  .btn-notice {
    display: inline-block;
    position: relative;
    margin-top: 12px;
    padding-right: 18px;
    font-size: 14px;
    line-height: 20px;
    color: #2088fd;
    &:after {
      content: '';
      position: absolute;
      top: 3px;
      right: 0;
      width: 14px;
      height: 14px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-blue.svg`});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
`;

export const PopupTitle = styled.div`
  margin-top: 8px;
  font-family: 'samsungsharpsans-bold';
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

export const PopupLocation = styled.div`
  margin-top: 4px;

  span {
    margin: 2px 4px;
    display: inline-flex;
    align-items: center;
    font-family: 'SamsungOne-700';
    font-size: 14px;
    line-height: 20px;
    &:before {
      content: '';
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      text-align: center;
      font-family: 'SamsungOne-700';
      font-size: 12px;
      line-height: 100%;
      color: #fff;
      margin-right: 6px;
    }
    &.n1:before {
      content: '1';
      background-color: #2088fd;
    }
    &.n2:before {
      content: '2';
      background-color: #ffae34;
    }
    &.n3:before {
      content: '3';
      background-color: #756bf1;
    }
    &.n4:before {
      content: '4';
      background-color: #756bf1;
    }
    &.n5:before {
      content: '5';
      background-color: #756bf1;
    }
    &.n6:before {
      content: '6';
      background-color: #1259aa;
    }
    &.n7:before {
      content: '7';
      background-color: #10b77b;
    }
    &.n8:before {
      content: '8';
      background-color: #535353;
    }
    &.info:before {
      content: '';
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-activity-map-info.svg`});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`;
export const PopupText = styled.div`
  margin-top: 16px;
  line-height: 20px;
  opacity: 0.8;
`;

export const PopupBottom = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  text-align: center;
`;

export const PopupButton = styled.div`
  display: inline-block;

  & + & {
    margin-left: 8px;
  }
`;
