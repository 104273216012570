import React from 'react';
import styled from 'styled-components';

const DetailInner = styled.div`
  margin-top: 102px;

  &.no-margin {
    position: absolute;
    inset: 0px;
    width: 100%;
    margin: 0px;
    background-color: rgb(255, 255, 255);
    z-index: 101;
  }

  @media screen and (max-width: 768px) {
    margin-top: 57px;
  }
`;

const DetailContainer = ({ children, noMargin }) => {
  return (
    <DetailInner className={noMargin ? 'no-margin' : null}>
      {children}
    </DetailInner>
  );
};

export default DetailContainer;
