import { useSelector } from 'react-redux';

const BLANK_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

/**
 * Gets the user's profile information for activities.
 */
export const useActivityProfile = () => {
  const name = useSelector((state) => state.userSession.name) || `SDC Attendee`;
  const email =
    useSelector((state) => state.userSession.email) || `sdc-attendee@email.com`;
  const profileImage =
    useSelector((state) => state.userSession.profileImage) || undefined;

  return { name, email, profileImage };
};
