import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment-timezone';
import { Get } from '../../utils/Requester';
import { diffTime, markEventVersion } from '../../utils/Functions';
import * as types from '../ActionTypes';
import { getMetaLive } from './MetaSaga';
import { speakerDetail } from '../../sample/SpeakerDetail';

export function* getSpeakerDetail(action) {
  const { presenterId } = action;
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield delay(3000);
      yield put({
        type: types.GET_SPEAKER_DETAIL_SUCCESS,
        data: { speaker: speakerDetail },
      });
      return;
    }

    const { data, headers, error } = yield call(Get, {
      url: `/api/event/speaker/${presenterId}`,
    });

    if (error || typeof data === 'string') {
      console.log('error', error);
      yield put({
        type: types.GET_SPEAKER_DETAIL_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
      return;
    }
    if (headers['mark-event-version']) {
      markEventVersion(headers['mark-event-version']);
    }

    let gapTime = yield select((state) => state.meta.gapTime);
    if (!gapTime) {
      yield call(getMetaLive);
      gapTime = yield select((state) => state.meta.gapTime);
    }
    const d =
      gapTime === 0 ? moment.utc().valueOf() : moment.utc().valueOf() - gapTime;
    const currentTime = moment
      .tz(d, 'America/Los_Angeles')
      .format('YYYY-MM-DD[T]HH:mm:ss');

    const { phase } = yield select((state) => state.meta.data);
    if (phase === 'phase3') {
      if (data.speaker[0].Presentations?.length > 0) {
        const presentation = data.speaker[0].Presentations;
        presentation.forEach((x) => {
          if (x.SessionType === 'Mega') {
            // x.status = mega.badgeStatus;
            const { status, diff } = diffTime(
              currentTime,
              x.PresentationDateTimeStart,
              x.PresentationDateTimeEnd
            );
            x.status = status;
          }
        });
      }
    }
    yield put({ type: types.GET_SPEAKER_DETAIL_SUCCESS, data });
  } catch (error) {
    if (error) {
      console.log('error', error);
      yield put({ type: types.GET_SPEAKER_DETAIL_ERROR, error });
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_SPEAKER_DETAIL_REQUEST, getSpeakerDetail);
}
