import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MeetupCont from '../../components/MeetupCont';
import { GET_MEETUP_REQUEST } from '../../redux/ActionTypes';
import withRouter from '../../components/Router/withRouter';
import Loading from '../../components/Loading';

const MeetUp = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_MEETUP_REQUEST });
  }, [dispatch]);

  const { isLoading } = useSelector((state) => state.meetup);

  return <>{isLoading ? <Loading /> : <MeetupCont />}</>;
};

export default withRouter(MeetUp);
