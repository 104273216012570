import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import SpeakersCont from '../../components/SpeakersCont';
import { GET_SPEAKERS_LIST_REQUEST } from '../../redux/ActionTypes';
import withRouter from '../../components/Router/withRouter';

const Speakers = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_SPEAKERS_LIST_REQUEST });
  }, [dispatch]);

  const { error } = useSelector((state) => state.speakersList);

  useEffect(() => {
    const { navigate } = props;
    if (error && navigate) {
      navigate('/internal_server_error');
    }
  }, [error]);

  return (
    <>
      <DetailContainer>
        <DetailHero
          bgPc1="bg-speakers-banner-pc-1.svg"
          bgPc2="bg-session-banner-pc-2.svg"
          bgMo1="bg-speakers-banner-mo-1.png"
          bgMo2="bg-session-banner-mo-2.svg"
          // bgPc="sdc23-bg-speakers.png"
          // bgMo="sdc23-bg-speakers-mo.png"
          bgColor="#756BF1"
          title="Speakers"
          depth="Session"
          depth2="Speakers"
          is2Depth
          type="speakers"
        />
        <SpeakersCont />
      </DetailContainer>
    </>
  );
};

export default withRouter(Speakers);
