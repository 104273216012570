import { combineReducers } from 'redux';
import activity from './ActivityReducer';
import codelab from './CodelabReducer';
import meetup from './MeetUpReducer';
import meta from './MetaReducer';
import metaTechSquare from './MetaTechSquareReducer';
import myPageData from './MyPageReducer';
import reservationData from './ReservationReducer';
import schedule from './ScheduleDataReducer';
import sessionDetail from './SessionDetailReducer';
import sessionFilter from './SessionFilterReducer';
import sessionList from './SessionListReducer';
import speakerDetail from './SpeakerDetailReducer';
import speakersList from './SpeakersListReducer';
import subscribeData from './SubscribeReducer';
import bookmarkList from './UserBookmarkReducer';
import sessionLikeStatus from './UserLikeReducer';
import userSession from './UserSessionReducer';
import utils from './UtilReducer';

export default combineReducers({
  sessionList,
  userSession,
  sessionDetail,
  speakersList,
  speakerDetail,
  sessionFilter,
  subscribeData,
  bookmarkList,
  myPageData,
  sessionLikeStatus,
  reservationData,
  utils,
  meta,
  metaTechSquare,
  codelab,
  schedule,
  meetup,
  activity,
});
