import * as types from '../ActionTypes';

const initialState = {
  isLoading: false,
  error: null,
  data: {
    // TODO: reconstructing the data if it needs
  },
  gapTime: 0,
};

const MetaTechSquareData = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_META_TECH_SQUARE_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: {},
        error: null,
      };
    case types.GET_META_TECH_SQUARE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: { ...action.data },
        gapTime: action.gapTime,
        error: null,
      };
    case types.GET_META_TECH_SQUARE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default MetaTechSquareData;
