import styled from 'styled-components';

export const SessionRoomNoti = styled.div`
  margin-top: 28px;
  padding: 20px 24px;
  font-size: 14px;
  line-height: 1.5;
  color: #535353;
  background-color: #f6f6f6;
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    margin-top: 24px;
    padding: 20px;
  }
`;

export const SessionRoomSchedule = styled.div`
  position: relative;
  margin-top: -40px;
  @media screen and (max-width: 768px) {
    margin-top: -20px;
  }
`;

export const SessionRoomScheduleInner = styled.div`
  max-width: 1200px;
  padding: 0 40px 120px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 0 24px 60px;
  }
`;

export const RoomTitle = styled.div`
  margin-bottom: 40px;
  padding-top: 80px;
  font-family: 'samsungsharpsans-bold';
  font-size: 32px;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    padding-top: 40px;
    font-size: 20px;
    line-height: 1.4;
  }
`;

export const RoomScheduleWrap = styled.div`
  background-color: #f6f6f6;
  border-radius: 16px;

  & + & {
    margin-top: 24px;
  }

  @media screen and (max-width: 768px) {
    border-radius: 8px;

    & + & {
      margin-top: 18;
    }
  }
`;

export const RoomScheduleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;

  &.w-100 {
    grid-template-columns: none;
  }
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const RoomScheduleInner = styled.div`
  padding: 35px 40px 40px;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 768px) {
    padding: 24px;
    .top {
      margin-bottom: 16px;
    }
  }
`;

export const RoomScheduleTitle = styled.div`
  font-family: 'SamsungOne-700';
  font-size: 20px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const RoomScheduleTime = styled.div`
  font-family: 'SamsungOne-600';
  color: #9e9e9e;
  @media screen and (max-width: 768px) {
  }
`;

export const RoomScheduleBox = styled.div`
  position: relative;
  min-height: 166px;
  background-color: #fff;
  border-radius: 12px;
  box-sizing: border-box;

  &:nth-of-type(2) {
    margin-top: 0;
  }

  a {
    display: block;
    padding: 24px;
  }

  .info {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
  }

  .title {
    margin-bottom: 2px;
    font-family: 'SamsungOne-700';
    font-size: 18px;
    line-height: 24px;
    color: #000;
  }

  .time {
    min-width: 82px;
    color: #7c7c7c;
    line-height: 1.43;
  }

  .speaker {
    position: relative;
    width: 80%;
    padding-left: 17px;
    line-height: 20px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: 8px;
      width: 1px;
      height: 12px;
      background-color: #ddd;
    }
  }

  .more {
    position: absolute;
    bottom: 24px;
    left: 24px;
    display: inline-block;
    padding-right: 18px;
    font-family: 'SamsungOne-700';
    color: #2088fd;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-blue.svg`});
    background-repeat: no-repeat;
    background-position: center right;
  }
  @media screen and (max-width: 768px) {
    min-height: auto;
    border-radius: 8px;
    & + & {
      margin-top: 8px;
    }

    a {
      display: flex;
      flex-direction: column;
    }

    .info {
      display: block;
      margin-top: 4px;
      margin-bottom: 24px;
    }

    .speaker {
      display: block;
      padding-left: 0;
      &:before {
        display: none;
      }
    }

    .more {
      position: relative;
      display: inline;
      bottom: auto;
      left: auto;
      width: 65px;
    }
  }
`;
