import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import EventCont from '../../components/EventCont';

const Event = () => {
  return (
    <>
      <DetailContainer>
        <DetailHero
          bgPc1="bg-event-banner-pc-1.svg"
          bgPc2="bg-event-banner-pc-2.svg"
          bgMo1="bg-event-banner-mo-1.png"
          bgMo2="bg-event-banner-mo-2.svg"
          bgColor="#0F42CF"
          title="On-site Events"
          path="On-site Events"
        />
        <EventCont />
      </DetailContainer>
    </>
  );
};

export default Event;
