import styled from 'styled-components';

export const TechContainer = styled.div`
  position: relative;
  padding: 80px 0 148px 0;
  background-color: #f6f6f6;
  @media screen and (max-width: 1024px) {
    padding: 72px 0 76px 0;
  }
`;

export const TechInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    display: block;
    padding: 0 24px;
  }
`;

export const CardWrap = styled.div``;

export const CardInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -12px;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const CodeLabFilter = styled.div`
  display: flex;
  padding: 24px 36px;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 24px;
  .codelab {
    margin-right: auto;
    &:before,
    &:after {
      top: 5px;
    }
  }
  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 50px;
    left: 0;
    border-radius: 0;
    z-index: 1;
    flex-direction: column;
    padding: 24px 28px;
    width: calc(100% - 56px);
    border-bottom: 1px solid #dddddd;
    .codelab {
      min-width: 230px;
    }
    &.close {
      display: none;
    }
  }
`;

export const TechFilterBtn = styled.button`
  display: none;
  /* position: fixed;
	top: 56px; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 14px 24px;
  font-family: 'SamsungOne-700';
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000;
  text-align: left;
  background-color: #fff;
  background-image: url(${`${process.env.PUBLIC_URL}/images/ico-filter.svg`});
  background-repeat: no-repeat;
  background-position: right 24px center;
  background-size: 16px 16px;
  border-bottom: 1px #efefef solid;
  z-index: 1;

  span {
    display: none;
  }

  &.is-checked {
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-filter-fill.svg`});
    background-repeat: no-repeat;
    background-position: right 24px center;
    background-size: 16px 16px;

    span {
      display: inline;
      padding-left: 4px;
      color: #7c7c7c;
    }
  }
  @media screen and (max-width: 1024px) {
    display: block;
    border-bottom: solid 1px #efefef;
  }
`;
