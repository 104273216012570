/* session Data
ID       Title
2540867	 Exploring the Digital Health Ecosystem: Samsung Health as Digital Front Door
2540868	 Games with Samsung Galaxy
2540869	 Gamepad on Tizen TV
2540870	 HDR10+ Gaming
*/

export const KeynoteChapter = [
  {
    id: 1,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_1.png`,
    time: 6,
    title: 'Intro',
    text: '00:00:06',
  },
  {
    id: 2,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_2.png`,
    time: 67,
    title: 'Opening (JH Han)',
    text: '00:01:07',
  },
  {
    id: 3,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_3.png`,
    time: 403,
    title: 'SmartThings (Jaeyeon Jung)',
    text: '00:06:43',
  },
  {
    id: 4,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_4.png`,
    time: 887,
    title: 'Google (Matthew J. McCullough)',
    text: '00:14:47',
  },
  {
    id: 5,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_5.png`,
    time: 1034,
    title: 'Matter (Mark Benson)',
    text: '00:17:14',
  },
  {
    id: 6,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_6.png`,
    time: 1220,
    title: 'Bixby (Anil Yadav)',
    text: '00:20:20',
  },
  {
    id: 7,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_7.png`,
    time: 1459,
    title: 'Security & Privacy (Shin Baik)',
    text: '00:24:19',
  },
  {
    id: 8,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_8.png`,
    time: 1803,
    title: 'TV-Mobile Services (Sang Kim)',
    text: '00:30:03',
  },
  {
    id: 9,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_9.png`,
    time: 2234,
    title: 'Smart TV & Tizen (Yongjae Kim)',
    text: '00:37:14',
  },
  {
    id: 10,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_10.png`,
    time: 2820,
    title: 'One UI 5 (Sally Hyesoon Jeong)',
    text: '00:47:00',
  },
  {
    id: 11,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_11.png`,
    time: 3654,
    title: 'Future R&D (Sebastian Seung)',
    text: '01:00:54',
  },
  {
    id: 12,
    thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_12.png`,
    time: 4127,
    title: 'Closing (JH Han)',
    text: '01:08:47',
  },
];

export const ChapterData = [
  {
    2540867: [
      {
        id: 1,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_1.png`,
        time: 6,
        title: 'Intro',
        text: '00:00:06',
      },
      {
        id: 2,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_2.png`,
        time: 67,
        title: 'Opening (JH Han)',
        text: '00:01:07',
      },
      {
        id: 3,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_3.png`,
        time: 403,
        title: 'SmartThings (Jaeyeon Jung)',
        text: '00:06:43',
      },
    ],
  },
  {
    2540868: [
      {
        id: 1,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_1.png`,
        time: 6,
        title: 'Intro',
        text: '00:00:06',
      },
    ],
  },
  {
    2540869: [
      {
        id: 1,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_1.png`,
        time: 6,
        title: 'Intro',
        text: '00:00:06',
      },
      {
        id: 2,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_2.png`,
        time: 67,
        title: 'Opening (JH Han)',
        text: '00:01:07',
      },
      {
        id: 3,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_3.png`,
        time: 403,
        title: 'SmartThings (Jaeyeon Jung)',
        text: '00:06:43',
      },
      {
        id: 4,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_4.png`,
        time: 887,
        title: 'Google (Matthew J. McCullough)',
        text: '00:14:47',
      },
      {
        id: 5,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_5.png`,
        time: 1034,
        title: 'Matter (Mark Benson)',
        text: '00:17:14',
      },
      {
        id: 6,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_6.png`,
        time: 1220,
        title: 'Bixby (Anil Yadav)',
        text: '00:20:20',
      },
      {
        id: 7,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_7.png`,
        time: 1459,
        title: 'Security & Privacy (Shin Baik)',
        text: '00:24:19',
      },
    ],
  },
  {
    2540870: [
      {
        id: 1,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_1.png`,
        time: 6,
        title: 'Intro',
        text: '00:00:06',
      },
      {
        id: 2,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_2.png`,
        time: 67,
        title: 'Opening (JH Han)',
        text: '00:01:07',
      },
      {
        id: 3,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_3.png`,
        time: 403,
        title: 'SmartThings (Jaeyeon Jung)',
        text: '00:06:43',
      },
      {
        id: 4,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_4.png`,
        time: 887,
        title: 'Google (Matthew J. McCullough)',
        text: '00:14:47',
      },
      {
        id: 5,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_5.png`,
        time: 1034,
        title: 'Matter (Mark Benson)',
        text: '00:17:14',
      },
      {
        id: 6,
        thumbnail: `${process.env.PUBLIC_URL}/images/KeynotePlayerThumb_6.png`,
        time: 1220,
        title: 'Bixby (Anil Yadav)',
        text: '00:20:20',
      },
    ],
  },
];
