/* eslint-disable import/named */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import {
  MainTechSquareArea,
  MainTechSquareBg,
  MainTechSquareWrap,
} from './MainTechSquareElement';
import MainSectionTitle from '../MainSectionTitle';

const MainTechSquare = () => {
  const tl = useRef();
  const mainRef = useRef();
  const imgRef = useRef();

  gsap.registerPlugin(ScrollTrigger);
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    setHasRendered(true);
  }, []);
  useEffect(() => {
    if (hasRendered) {
      tl.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: mainRef.current,
            scrub: true,
            start: 'top 80%',
            end: 'center 70%',
          },
        })
        .fromTo(
          imgRef.current,
          { opacity: '0' },
          { opacity: '1', duration: 1 }
        );
    }

    return () => {
      if (tl.current) tl.current.kill();
    };
  }, [hasRendered]);

  return (
    <>
      <MainTechSquareWrap ref={mainRef}>
        <MainSectionTitle
          title="Tech Square"
          desc={
            <div>
              Explore Tech Square, an exhibition space showcasing a variety of
              innovations. With the help of experts, get hands-on experience and
              see how technology comes to life.
            </div>
          }
          linkText="Go to Tech Square"
          buttonUrl="/tech-square"
        />
        <MainTechSquareArea ref={imgRef}>
          <img
            src={`${process.env.PUBLIC_URL}/images/main-techSquare-img-1.png`}
            alt="tech squeare"
          />
        </MainTechSquareArea>
      </MainTechSquareWrap>
    </>
  );
};

export default MainTechSquare;
