import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import SupportFaq from '../../components/SupportCont';

const Support = () => {
  return (
    <>
      <DetailContainer>
        <DetailHero
          bgPc1="bg-support-banner-pc-1.svg"
          bgPc2="bg-support-banner-pc-2.svg"
          bgMo1="bg-support-banner-mo-1.png"
          bgMo2="bg-support-banner-mo-2.svg"
          bgColor="#2088FD"
          title="Support"
          path="Support"
        />
        <SupportFaq />
      </DetailContainer>
    </>
  );
};

export default Support;
