import React, { useState } from 'react';
import DetailContainer from '../../components/DetailContainer';
import AlreadyFormError from '../../components/ErrorCont/AlreadyError';
import withRouter from '../../components/Router/withRouter';

const Error = (props) => {
  const { location } = props;

  return (
    <>
      <DetailContainer noMargin>
        <AlreadyFormError search={location.search} />
      </DetailContainer>
    </>
  );
};

export default withRouter(Error);
