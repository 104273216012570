import styled from 'styled-components';

export const TechContainer = styled.div`
  position: relative;
  background-color: #fff;
  @media screen and (max-width: 768px) {
  }
`;

export const TechInner = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .line {
    margin: 0 auto;
    max-width: 1200px;
    border-bottom: 2px solid #dedede;
  }
  @media screen and (max-width: 768px) {
    display: block;
    .line {
      margin: 0 24px;
    }
  }
`;

export const TechContent = styled.div`
  width: 100%;
  padding: 100px 0;

  @media screen and (max-width: 768px) {
    padding: 40px 0 48px 0;
  }
`;

export const TechTitle = styled.div`
  color: #000;
  font-family: 'samsungSharpSans-bold';
  font-size: 32px;
  font-weight: 700;
  line-height: 1.31;
  max-width: 1200px;
  padding: 0 40px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 1.4;
    padding: 0 24px;
  }
`;

export const TechCardArea = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 24px auto 32px auto;
  padding: 0 40px;
  display: grid;
  gap: 1.2em;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 20px;
    padding: 0 24px;
  }
`;

export const TechCardBox = styled.div`
  position: relative;
  width: 100%;
  max-width: 588px;
  height: 27.1444em;
  margin: 0 auto;
  border-radius: 24px;
  background-color: #fff;
  cursor: pointer;

  &.col-1 {
    max-width: 100%;
    grid-column: span 2;

    .TechBoxText {
      max-width: 800px;
    }
  }

  .TechSquareBoxLink {
    position: relative;
    display: block;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;

    @media screen and (min-width: 769px) {
      &:hover {
        .Blur {
          opacity: 1;
        }

        .TechSquareImg {
          transform: scale(1.2);
        }

        .TechBoxText {
          position: relative;
          min-height: 96px;
          height: auto;
          opacity: 1;
          p {
            white-space: pre-line;
            transform: translateY(0);
            opacity: 1;
          }
        }

        .moreLink {
          background-color: rgba(255, 255, 255, 0.5);
          &.hideBtn {
            display: none;
          }
        }

        .bookmark {
          opacity: 1;
        }
      }
    }

    @media screen and (max-width: 768px) {
      border-radius: 12px;
      height: 100%;
      min-height: 284px;

      &.clicked {
        .Blur {
          opacity: 1;
        }

        .TechSquareImg {
          transform: scale(1.2);
        }

        .TechBoxText {
          position: relative;
          min-height: 96px;
          height: auto;
          opacity: 1;
          p {
            white-space: pre-line;
            transform: translateY(0);
            opacity: 1;
          }
        }

        .moreLink {
          background-color: rgba(255, 255, 255, 0.5);
          &.hideBtn {
            display: none;
          }
        }

        .bookmark {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    max-width: none;
    height: 100%;
    min-height: 284px;
    border-radius: 12px;
    margin-top: 16px !important;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const TechCardBoxBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  transition: all 430ms cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  z-index: 1;

  @media screen and (max-width: 768px) {
    border-radius: 12px;
  }
`;

export const TechCardBoxDimmed = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const TechCardBoxBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .TechSquareImg {
    display: block;
    width: 100%;
    height: 100%;
    transition: all 430ms cubic-bezier(0.215, 0.61, 0.355, 1);
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
  }
`;

export const TechCardBoxInner = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 150px);
  padding: 0 48px 0;
  text-align: left;
  z-index: 2;

  @media screen and (max-width: 768px) {
    padding: 0 20px 0;
    height: 100%;
  }
`;

export const TechBoxInfo = styled.div`
  padding-top: 44px;
  padding-bottom: 22px;

  @media screen and (max-width: 768px) {
    padding: 20px 0 12px 0;
    z-index: 2;
  }
`;

export const Button = styled.div`
  position: absolute;
  left: 48px;
  bottom: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(9px);
  border-radius: 100%;
  z-index: 11;
  svg {
    display: block;
    width: 18px !important;
    height: 18px !important;
  }
  &.empty-url {
    svg {
      width: 48px !important;
      height: 48px !important;
    }
  }

  @media screen and (max-width: 768px) {
    left: 20px;
    bottom: 22px;
    width: 32px;
    height: 32px;

    img {
      width: 7px;
    }

    svg {
      display: block;
      width: 14px !important;
      height: 14px !important;
    }
    &.empty-url {
      svg {
        width: 32px !important;
        height: 32px !important;
      }
    }

    &.hideBtn {
      display: none;
    }
  }
`;

export const TechBoxTitle = styled.div`
  color: #fff;
  font-family: 'SamsungOne-700';
  font-size: 36px;
  font-weight: 700;
  line-height: 1.27;
  @media screen and (max-width: 900px) {
    font-size: 32px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 1.3;
  }
`;

export const TechBoxDate = styled.div`
  color: #fff;
  font-family: 'SamsungOne-700';
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  opacity: 0.8;
  margin-top: 16px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 1.42;
    margin-top: 8px;
  }
`;

export const TechBoxText = styled.div`
  height: 0;
  opacity: 0;
  transition: all 450ms cubic-bezier(0.215, 0.61, 0.355, 1);

  &.singleLine {
    p {
      margin-top: -30px;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    font-family: 'SamsungOne-400';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    transform: translateY(30%);
    transition: all 450ms cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
  }

  @media screen and (max-width: 1040px) {
    p {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    p {
      font-family: 'SamsungOne-700';
      font-weight: 700;
    }
  }
`;
