// CodeLab Page
export const CodeLabData = [
  {
    id: '01',
    title:
      'Matter: Create a virtual device and make an open source contribution',
    url: 'https://developer.samsung.com/codelab/smartthings/matter-virtual-device.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-matter-virtual-device.png`,
    tag: ['SmartThings'],
    type: 'code lab data',
    live: 'off',
    timeTaken: '25min',
  },
  {
    id: '02',
    title: 'Matter: Build a Matter IoT app with SmartThings Home API',
    url: 'https://developer.samsung.com/codelab/smartthings/matter-iot-app.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-matter-iot-app.png`,
    tag: ['SmartThings'],
    type: 'Mega Session',
    live: 'off',
    timeTaken: '25min',
  },
  {
    id: '03',
    title: 'Develop a SmartThings Find-compatible device',
    url: 'https://developer.samsung.com/codelab/smartthings/find-device.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-find-device.png`,
    tag: ['SmartThings'],
    type: 'Mega Session',
    live: 'off',
    timeTaken: '30min',
  },
  {
    id: '04',
    title: 'Develop a widget for Flex Window',
    url: 'https://developer.samsung.com/codelab/galaxy-z/widget-flex-window.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-widget-flex-window.png`,
    tag: ['Foldable'],
    type: 'Mega Session',
    live: 'off',
    timeTaken: '25min',
  },
  {
    id: '05',
    title: "Integrate 'Add to Samsung Wallet' button into partner services",
    url: 'https://developer.samsung.com/codelab/wallet/add-to-wallet-interface.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-add-to-wallet-interface.png`,
    tag: ['Samsung Wallet'],
    type: 'Tech Session',
    live: 'off',
    timeTaken: '30min',
  },
  {
    id: '06',
    title: 'Integrate In-App payment into merchant apps using Samsung Pay SDK',
    url: 'https://developer.samsung.com/codelab/pay/in-app-payment.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-in-app-payment.png`,
    tag: ['Samsung Wallet'],
    type: 'Tech Session',
    live: 'off',
    timeTaken: '30min',
  },
  {
    id: '07',
    title: 'Optimize game performance with Adaptive Performance in Unity',
    url: 'https://developer.samsung.com/codelab/gamedev/adaptive-performance-unity.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-adaptive-performance-unity.png`,
    tag: ['Game'],
    type: 'Tech Session',
    live: 'off',
    timeTaken: '30min',
  },
  {
    id: '08',
    title: 'Implement Flex Mode into a Unity game',
    url: 'https://developer.samsung.com/codelab/gamedev/flex-mode-unity.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-flex-mode-unity.png`,
    tag: ['Game'],
    type: 'Tech Session',
    live: 'off',
    timeTaken: '30min',
  },
  {
    id: '09',
    title: 'Customize styles of a watch face with Watch Face Studio',
    url: 'https://developer.samsung.com/codelab/watch-face-studio/style-customization.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-style-customization.png`,
    tag: ['Watch Face Studio'],
    type: 'Tech Session',
    live: 'off',
    timeTaken: '30min',
  },
  {
    id: '10',
    title: 'Customize Flex Window using Good Lock plugin on Watch Face Studio',
    url: 'https://developer.samsung.com/codelab/watch-face-studio/flex-window-customization.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-flex-window-customization.png`,
    tag: ['Watch Face Studio'],
    type: 'Tech Session',
    live: 'off',
    timeTaken: '20min',
  },
  {
    id: '11',
    title:
      'Measure skin temperature on Galaxy Watch with Samsung Privileged Health SDK',
    url: 'https://developer.samsung.com/codelab/health/skin-temperature.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-skin-temperature.png`,
    tag: ['Health'],
    type: 'Tech Session',
    live: 'off',
    timeTaken: '20min',
  },
  {
    id: '12',
    title:
      'Transfer heart rate data from Galaxy Watch to a mobile device with Samsung Privileged Health SDK',
    url: 'https://developer.samsung.com/codelab/health/heart-rate-data-transfer.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-heart-rate-data-transfer.png`,
    tag: ['Health'],
    type: 'Tech Session',
    live: 'off',
    timeTaken: '30min',
  },
  {
    id: '13',
    title: 'Create a health research app using Samsung Health Stack',
    url: 'https://developer.samsung.com/codelab/health/research-app.html',
    image: `${process.env.PUBLIC_URL}/images/CodeLab/codeLab-research-app.png`,
    tag: ['Health'],
    type: 'Tech Session',
    live: 'off',
    timeTaken: '30min',
  },
];
