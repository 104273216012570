import React, { useState } from 'react';
import {
  TermsContContainer,
  CookieInner,
  TermsContTop,
  CookieTitle,
  TermsContDesc,
  TermsContItem,
  TermsItemTitle,
  TermsItemLink,
  TermsTable,
  TermsExtra,
} from '../TermsCont/TermsContElements';
import PopupManage from '../PopupManage';
import PopupConfirm from '../Popup';

const CookieEu = () => {
  const [open, setOpen] = useState(false);

  const closePopup = () => {
    setOpen(false);
    document.body.style.overflow = 'auto';
  };

  // Management popup
  const [openManage, setOpenManage] = useState(false);

  // cookie confirm popup
  const [openConfirm, setOpenConfirm] = useState(false);

  return (
    <>
      <TermsContContainer>
        <CookieInner>
          <TermsContTop>
            <CookieTitle>Samsung Cookie Policy</CookieTitle>
            <div className="space" />
            <TermsContDesc>
              <strong>
                Updated on <span className="date">August 29, 2023</span>
              </strong>
            </TermsContDesc>
            <br />
            <TermsContDesc>
              This Cookie Policy describes the different types of cookies that
              may be used in connection with [Samsung Developer Conference]
              which is owned and controlled by Samsung Electronics Co., Ltd
              (“Samsung Electronics”). This Cookie Policy also describes how you
              can manage cookies.
              <br />
              <br />
              It’s important that you check back often for updates to the Policy
              as we may change it from time to time to reflect changes to our
              use of cookies. Please check the date at the top of this page to
              see when this Policy was last revised. Any changes to this Policy
              will become effective when we make the revised Policy available on
              our website.
              <br />
              <br />
              Samsung Electronics has offices across Europe, so we can ensure
              that your request or query will be handled by the data protection
              team based in your region. If you have any questions, the easiest
              way to contact us is through our Privacy Support Page at{' '}
              <a
                href="https://www.samsung.com/request-desk"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.samsung.com/request-desk
              </a>
              .
              <br />
              <br />
              <strong>
                You can also contact us at:
                <br />
                <br />
                European Data Protection Officer
                <br />
                Samsung Electronics (UK) Limited
                <br />
                Samsung House, 2000 Hillswood Drive, Chertsey, Surrey KT16 0RS,
                UK
              </strong>
            </TermsContDesc>
          </TermsContTop>

          <TermsContItem>
            <TermsItemTitle>Cookies</TermsItemTitle>
            Cookies are small files that store information on your computer, TV,
            mobile phone, or other device. They enable the entity that put the
            cookie on your device to recognize you across different websites,
            services, devices, and/or browsing sessions.
            <br /> <br />
            We use the following types of cookies on this website:
            <br />
            <TermsTable>
              <caption>
                <span>Essential Cookies:</span>&nbsp;enable you to receive the
                services you request via our website. Without these cookies,
                services that you have asked for cannot be provided. For
                example, these enable you to view the website based on your
                location or preferred language, and other accessibility settings
                you may have selected. These cookies are automatically enabled
                and cannot be turned off because they are essential to enable
                you to browse our website. Without these cookies the Samsung
                Developer Conference website features cannot be provided.
              </caption>
              <colgroup>
                <col width="15%" />
                <col width="36.66%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Cookie</th>
                  <th>Domain</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>sdc_samsung_com_visit_id</td>
                  <td>www.samsungdeveloperconference.com</td>
                  <td>To identify a visitor</td>
                </tr>
                <tr>
                  <td>sdc_samsung_com_jwt</td>
                  <td>www.samsungdeveloperconference.com</td>
                  <td>To keep login session</td>
                </tr>
                <tr>
                  <td>sdp_sso_jwt</td>
                  <td>www.samsungdeveloperconference.com</td>
                  <td>To keep login session</td>
                </tr>
              </tbody>
            </TermsTable>
            <TermsTable>
              <caption>
                <span>Analytical or performance cookies:</span>&nbsp;allow us to
                recognise and count the number of visitors and to see how
                visitors move around our website when they are using it. This
                helps us to improve the way our website works, for example, by
                ensuring that users are finding what they are looking for
                easily. This type of cookies is automatically disabled and can
                be configured to your particular preferences. We will use these
                cookies for a period of up to two years.
              </caption>
              <colgroup>
                <col width="15%" />
                <col width="36.66%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Cookie</th>
                  <th>Domain</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>_fbp</td>
                  <td>.samsungdeveloperconference.com</td>
                  <td>To store and track visits across websites.</td>
                </tr>
                <tr>
                  <td>_ga</td>
                  <td>.samsungdeveloperconference.com</td>
                  <td>To store and count pageviews.</td>
                </tr>
                <tr>
                  <td>_dc_gtm_UA-*</td>
                  <td>.samsungdeveloperconference.com</td>
                  <td>To store the number of service requests.</td>
                </tr>
                <tr>
                  <td>_gid</td>
                  <td>.samsungdeveloperconference.com</td>
                  <td>To store and count pageviews.</td>
                </tr>
                <tr>
                  <td>_gcl_au</td>
                  <td>.samsungdeveloperconference.com</td>
                  <td>To store and track conversions.</td>
                </tr>
                {/* <tr>
                  <td>lms_analytics</td>
                  <td>.linkedin.com</td>
                  <td>
                    To identify LinkedIn members off LinkedIn for analytics
                  </td>
                </tr> */}
                <tr>
                  <td>AnalyticsSyncHistory</td>
                  <td>.linkedin.com</td>
                  <td>
                    To store information about the time a sync took place with
                    the lms_analytics cookie
                  </td>
                </tr>
              </tbody>
            </TermsTable>
            <TermsTable>
              <caption>
                <span>Functionality Cookies:</span>&nbsp;allow us to deliver a
                better user experience. For example, this type of cookie ensures
                that the information displayed on your next visit to our website
                will match up with your user preferences. This type of cookies
                is automatically disabled and can be configured to your
                particular preferences. We will use these type of cookies for a
                period of one year.
              </caption>
              <colgroup>
                <col width="15%" />
                <col width="36.66%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Cookie</th>
                  <th>Domain</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>lang</td>
                  <td>.ads.linkedin.com</td>
                  <td>
                    To remember a user's language setting to ensure LinkedIn.com
                    displays in the language selected by the user in their
                    settings
                  </td>
                </tr>
                <tr>
                  <td>lidc</td>
                  <td>.linkedin.com</td>
                  <td>To facilitate data center selection</td>
                </tr>
                <tr>
                  <td>bcookie</td>
                  <td>.linkedin.com</td>
                  <td>To store browser details.</td>
                </tr>
              </tbody>
            </TermsTable>
            <TermsTable>
              <caption>
                <span>Advertising Cookies:</span>&nbsp;allow us and our
                carefully selected partners to personalise our website, deliver
                advertisements to you, or contact you directly where you have
                separately consented to such communications, in a way which is
                relevant to you and which matches your interests by, for
                example, using information about products you have browsed or
                ordered on our website. This type of cookies is automatically
                disabled and can be configured to your particular preferences.
                We will use these cookies for a period of one year.
              </caption>
              <colgroup>
                <col width="15%" />
                <col width="36.66%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Cookie</th>
                  <th>Domain</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>IDE</td>
                  <td>.doubleclick.net</td>
                  <td>To provide ad delivery or retargeting</td>
                </tr>
                <tr>
                  <td>fr</td>
                  <td>.facebook.com</td>
                  <td>To provide ad delivery or retargeting</td>
                </tr>
                {/* <tr>
                  <td>lms_ads</td>
                  <td>.linkedin.com</td>
                  <td>
                    To identify LinkedIn members off LinkedIn for advertising
                  </td>
                </tr> */}
                <tr>
                  <td>UserMatchHistory</td>
                  <td>.linkedin.com</td>
                  <td>To provide ad delivery or retargeting</td>
                </tr>
                <tr>
                  <td>li_sugr</td>
                  <td>.linkedin.com</td>
                  <td>To store browser details.</td>
                </tr>
                <tr>
                  <td>ar_debug</td>
                  <td>.linkedin.com</td>
                  <td>
                    Supports Google attribution reporting API integration to
                    mitigate signal loss
                  </td>
                </tr>
                <tr>
                  <td>ar_debug</td>
                  <td>.linkedin.com</td>
                  <td>
                    Supports Google attribution reporting API integration to
                    mitigate signal loss
                  </td>
                </tr>
                <tr>
                  <td>guest_id</td>
                  <td>.twitter.com</td>
                  <td>
                    This cookie is set by Twitter and serves to identify the
                    user with a unique number associated with Twitter.
                  </td>
                </tr>
                <tr>
                  <td>guest_id_ads</td>
                  <td>.twitter.com</td>
                  <td>
                    This cookie is set due to Twitter integration and sharing
                    capabilities for the social media
                  </td>
                </tr>
                <tr>
                  <td>guest_id_marketing</td>
                  <td>.twitter.com</td>
                  <td>Used to detect whether a user is logged into Twitter</td>
                </tr>
                <tr>
                  <td>personalization_id</td>
                  <td>.twitter.com</td>
                  <td>this cookie for behavioural advertising and analytics</td>
                </tr>
                <tr>
                  <td>muc_ads</td>
                  <td>.twitter.com</td>
                  <td>to provide ad delivery or retargeting</td>
                </tr>
              </tbody>
            </TermsTable>
          </TermsContItem>

          <TermsContItem>
            <TermsItemTitle>Third party Cookies</TermsItemTitle>
            Our website may also display content from a third party website or
            service, which may result in cookies from third parties being stored
            on your hard drive or browser. These third parties can be seen and
            their cookies can be blocked in the&nbsp;
            <TermsItemLink
              href="#;"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                setOpenManage(true);
                closePopup();
              }}
            >
              Cookie Management Tool
            </TermsItemLink>
            . We do not control the storage and use of these cookies by these
            third parties and you should refer to the privacy policies of the
            relevant third parties for more information. This type of cookie is
            automatically disabled and can be configured to your particular
            preferences.
          </TermsContItem>

          <TermsContItem>
            <TermsItemTitle>Other Local Storage</TermsItemTitle>
            We, along with certain third parties, may use other kinds of local
            storage technologies, such as Local Shared Objects (also referred to
            as “Flash cookies”) and HTML5 local storage, in connection with our
            services. These technologies are similar to the cookies discussed
            above in that they are stored on your device and can be used to
            store certain information about your activities and preferences.
            However, these technologies may make use of different parts of your
            device from standard cookies, and so you might not be able to
            control them using standard browser tools and settings.
          </TermsContItem>

          <TermsContItem>
            <TermsItemTitle>Pixel</TermsItemTitle>
            Pixels on our website also deliver information about the website
            usage to Samsung.com & third party tracing servers, but do not store
            the information on the users' browser like a cookie does. Pixels
            allow Samsung to ensure the site is running and pages are loading.
            <br />
            <br />
            Pixels on our website are categorised and managed in the same way as
            cookies. Performance, functional and advertising pixels are disabled
            and can be configured to your preferences using the Cookies
            Management Tool.
            <br />
            <br />
            An e-mail pixel is a similar technology used by Samsung in
            communications sent via e-mail. Where you have separately consented
            to receiving communications which have been carefully selected from
            our partners to deliver advertisements to you, each communication
            contains both a cookie and pixel.
          </TermsContItem>

          <TermsContItem>
            <TermsItemTitle>
              Managing Cookies and Other Technologies
            </TermsItemTitle>
            You can change your cookie preferences at any time by adjusting the
            cookie settings through the Cookies Management Tool, which can be
            found here: Cookies Management Tool.
            <br />
            <br />
            You can also update your browser settings at any time, if you want
            to remove or block cookies from your device (consult your browser's
            "help" menu to learn how to remove or block cookies). Samsung
            Electronics is not responsible for your browser settings. You can
            find good and simple instructions on how to manage cookies on the
            different types of web browsers at{' '}
            <TermsItemLink
              href="https://allaboutcookies.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="table-link"
            >
              http://www.allaboutcookies.org
            </TermsItemLink>
            .
            <br />
            <br />
            Please be aware that rejecting cookies may affect your ability to
            perform certain transactions on the website, and our ability to
            recognize your browser from one visit to the next.
          </TermsContItem>
        </CookieInner>
      </TermsContContainer>

      {/* management modal */}
      {openManage ? (
        <PopupManage setOpen={setOpenManage} setOpenConfirm={setOpenConfirm} />
      ) : null}

      {/* Cookie confirm popoup */}
      {openConfirm ? (
        <PopupConfirm
          modal={openConfirm}
          setModal={setOpenConfirm}
          title="Preferences Submitted"
          desc="You have successfully updated your cookie preferences."
          btnText="Close"
        />
      ) : null}
    </>
  );
};

export default CookieEu;
