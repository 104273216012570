import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import {
  PopupContainer,
  PopupInner,
  PopupTitle,
  PopupDesc,
  PopupCont,
  PopupItem,
  PopupClose,
  ReserveAgree,
} from './PopupCalendarElements';
import CheckBox from '../Checkbox';
import Button from '../Button';
import ToastPopup from '../ToastPopup';

const SessionCalendar = ({ setOpen, data }) => {
  const {
    PresentationDateTimeStart,
    PresentationDateTimeEnd,
    SessionType,
    PresentationTitle,
    AbstractText,
  } = data;
  const [modal, setModal] = useState(false);

  const [checked, setChecked] = useState(false);
  const checkHandler = () => {
    setChecked(!checked);
  };

  const closePopup = () => {
    setOpen(false);
    document.body.style.overflow = 'auto';
  };

  const handleConfirmClick = () => {
    if (checked) {
      localStorage.setItem('confirmPopup', true);
    }
    closePopup();
    // setModal(true);
  };

  const event = {
    start: moment(PresentationDateTimeStart).format(
      'YYYY-MM-DD HH:mm:ss [-7:00]'
    ),
    end: moment(PresentationDateTimeEnd).format('YYYY-MM-DD HH:mm:ss [-7:00]'),
    title: `${
      SessionType ? `[${SessionType.toUpperCase()}]` : ''
    } ${PresentationTitle}`,
    description: AbstractText?.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br/>').replace(
      /\u00a0/gi,
      ' '
    ),
    location: 'Moscone North in San Francisco',
  };

  const downloadHandler = () => {
    const fileURL = ics(event);
    const filename = 'SDC23-Session Calendar.ics';

    const anchor = document.createElement('a');
    anchor.href = fileURL;
    anchor.download = filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    URL.revokeObjectURL(fileURL);
  };

  const Link = {
    CALENDAR_LINK_GOOGLE: google(event),
    CALENDAR_LINK_OFFICE365: office365(event),
    CALENDAR_LINK_OUTLOOK: outlook(event),
    CALENDAR_LINK_YAHOO: yahoo(event),
  };

  return (
    <>
      <PopupContainer>
        <PopupInner>
          <PopupTitle>Completed Reservation</PopupTitle>
          <PopupDesc>
            Session reservation is complete. <br />
            Try adding the reserved session to your calendar
          </PopupDesc>

          <PopupCont>
            <PopupItem className="apple" onClick={downloadHandler}>
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-apple.png`}
                alt=""
              />
              Apple
            </PopupItem>
            <PopupItem
              className="google"
              onClick={() =>
                window.open(`${Link.CALENDAR_LINK_GOOGLE}`, '_blank')
              }
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-google.png`}
                alt=""
              />
              Google
            </PopupItem>
            <PopupItem
              className="office"
              onClick={() =>
                window.open(`${Link.CALENDAR_LINK_OFFICE365}`, '_blank')
              }
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-office-365.png`}
                alt=""
              />
              Office 365
            </PopupItem>
            <PopupItem
              className="outlook"
              onClick={() =>
                window.open(`${Link.CALENDAR_LINK_OUTLOOK}`, '_blank')
              }
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-outlook.png`}
                alt=""
              />
              Outlook
            </PopupItem>
            <PopupItem
              className="yahoo"
              onClick={() =>
                window.open(`${Link.CALENDAR_LINK_YAHOO}`, '_blank')
              }
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/ico-yahoo.png`}
                alt=""
              />
              Yahoo
            </PopupItem>
          </PopupCont>
          <ReserveAgree>
            <CheckBox id="chkAgree" checked={checked} onChange={checkHandler} />
            <span className="text">Don't show this again</span>
          </ReserveAgree>
          <Button className="btn-Confirm" onClick={handleConfirmClick}>
            Confirm
          </Button>
        </PopupInner>
      </PopupContainer>
    </>
  );
};

export default SessionCalendar;
