import React from 'react';
import classNames from 'classnames';
import './style.scss';

function Button({
  className,
  children,
  size,
  color,
  outline,
  fullWidth,
  onClick,
  type,
  disabled,
}) {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={classNames(
        'Button',
        className,
        size || 'medium',
        color || 'black',
        {
          outline,
          fullWidth,
        }
      )}
    >
      {children}
    </button>
  );
}

export default Button;
