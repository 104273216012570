import styled from 'styled-components';

export const ActivityMyPointContainer = styled.div`
  position: relative;
  margin: 12px 24px 0;
  padding: 18px 24px 24px;
  border-radius: 16px;
  background-color: #f6f6f6;
  text-align: center;

  p:nth-of-type(1) {
    font-family: 'samsungsharpsans-bold';
    font-size: 16px;
    line-height: 20px;
  }
  p:nth-of-type(2) {
    margin-top: 17px;
    font-family: 'samsungsharpsans-bold';
    font-size: 36px;
    line-height: 45px;
  }
  .Button {
    margin-top: 20px;
    width: 264px;
    color: #fff;
    background-color: #2088fd;
  }
  .Button + p {
    margin-top: 14px;
    font-size: 12px;
    line-height: 16px;
    color: #7c7c7c;
  }
  .btn-link {
    display: block;
    position: absolute;
    right: 14px;
    top: 14px;
    width: 24px;
    height: 24px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-mypoint-arrow.svg`});
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const HowToUsePopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);

  .inner-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 48px);
    padding: 20px 24px 22px;
    border-radius: 16px;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    box-sizing: border-box;
    text-align: center;
  }
  .popup-cont {
    position: relative;
    height: 340px;
    img {
      display: block;
      width: 272px;
      max-width: 100%;
      margin: auto;
    }
    img + p {
      font-family: 'samsungsharpsans-bold';
      font-size: 24px;
      line-height: 32px;
    }
    p + p {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
    }
    .Button {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
`;
