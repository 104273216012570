// Event > Tech Square Page
export const EventTechSquareData = [
  {
    title: 'Gaming Hub',
    desc: 'Gaming Hub is an All-In-One Game Platform for gamers to enjoy various games through streaming with over 3,000 + game titles with industry-leading partners. Spend more time together with family. Also, at this booth, we’ll show game app developers how to utilize gamepads effectively with live TV demos. Additionally, we have exciting content for gamepad manufacturers interested in TV integration.',
    slide: true,
    images: [
      `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MobileEx-1.png`,
      `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MS1-2.png`,
    ],
  },
  {
    title: 'Multi Control',
    desc: 'Multi-control is a function that allows you to control other devices with a keyboard mouse connected to the device. Control two or more devices at the same time with one keyboard/mouse without the remote control. You can turn on contents such as news and Netflix with your mouse and return to your PC to proceed with your work.',
    img: `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MS2-1.png`,
  },
  {
    title:
      'Introduction to IRIS (Instant Rendering & Immediate Sign-off) for HDR10+ GAMING',
    desc: 'IRIS is a SW solution to simulate HDR10+ GAMING video process on compliant displays. During the session, technical details of the HDR10+ GAMING, related IRIS features and SW development guide based on Unreal Plugin will be presented. \n\nThes ession also will include the followings. \n(1) HDR10+ GAMING protocol between PC and Display \n(2) Effect of HDR10+ GAMING video process \n(3) SW development guide',
    slide: true,
    video: false,
    images: [
      `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MS3-1.png`,
      `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MS3-2.png`,
    ],
    videoUrl: 'https://www.youtube.com/embed/M-P1TTWdwP8',
  },
  {
    title:
      'Feel the pleasure and value of “being together” with Witty Conversations',
    desc: 'Chat with various people while watching your favorite TV program\nYou can meet Notification and start Chat Together experience\nTry chatting comfortably on TV in various ways\n 1. AI Recommanded preset text and emoji\n 2. Voice input\n 3. Mobile App and Web Browser \nWe is also preparing to develop TV apps that provide various Togetherness experiences by providing TV Chat Open API and Social Platform',
    slide: true,
    video: true,
    images: [
      `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MobileEx-2.png`,
      `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MobileEx-3.png`,
      `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MobileEx-1.png`,
    ],
    videoUrl: 'https://www.youtube.com/embed/M-P1TTWdwP8',
  },
  {
    title:
      'Booth Title Booth Title Booth Title Booth Title Booth Title Booth Title',
    desc: 'SDC23 brings thousands of developers, creators and designers together to build connections and shape the future of customer experiences.',
    img: `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MobileEx-3.png`,
  },
  {
    title:
      'Booth Title Booth Title Booth Title Booth Title Booth Title Booth Title',
    desc: 'SDC23 brings thousands of developers, creators and designers together to build connections and shape the future of customer experiences.',
    slide: true,
    video: true,
    images: [
      `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MobileEx-3.png`,
      `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MobileEx-2.png`,
      `${process.env.PUBLIC_URL}/images/EventDetail/Thumb-MobileEx-1.png`,
    ],
    videoUrl: 'https://www.youtube.com/embed/M-P1TTWdwP8',
  },
];
