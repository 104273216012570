import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ClabWrap,
  ClabInner,
  DetailContainer,
  SessionTopUtil,
  ClabCards,
  ClabCardsInner,
  ClabCardsInnerCon,
  CardMoreBtn,
} from './EventSamsungCLabElement';
import Button from '../Button';
import ClabCont from '../ClabCont';
import EventTechSquareCard from '../EventTechCard';
import { EventSamsungClabData } from '../../sample/EventSamsungClab';
import TechSquareMap from '../TechSquareMap';

const EventCLabCont = () => {
  // card interaction
  const tl = useRef();
  const [count, setCount] = useState(0);
  const [more, setMoreBtn] = useState(false);

  const phase = useSelector((state) => state.meta.data.phase);

  const cardRef = useRef();

  const handleClick = () => {
    if (!more) {
      setCount(EventSamsungClabData.length);
    } else {
      setCount(6);
    }
    setMoreBtn(!more);
  };

  useEffect(() => {
    if (EventSamsungClabData.length < 7) {
      setCount(EventSamsungClabData.length);
    } else {
      setCount(6);
    }
    setMoreBtn(false);
  }, []);

  useEffect(() => {
    if (phase === 'phase2') {
      return;
    }
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      '(min-width: 768px)': function () {
        const cardArray = gsap.utils.toArray(cardRef.current?.children);
        cardArray.forEach((el) => {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: '0 95%',
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
      '(max-width: 767px)': function () {
        const cardArray = gsap.utils.toArray(cardRef.current?.children);
        cardArray.forEach((el) => {
          tl.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: '0 100%',
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
      tl.current.kill();

      ScrollTrigger.clearMatchMedia();
    };
  }, [phase]);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <ClabWrap>
      <ClabInner>
        <DetailContainer>
          <SessionTopUtil>
            <button type="button" className="link-prev" onClick={goBack}>
              Back
            </button>
          </SessionTopUtil>
          <ClabCont
            title="Samsung C-Lab"
            desc="C-Lab Inside is a corporate venture program that encourages and nurtures innovative ideas from Samsung employees.<br/>
              It was established in 2012, to discover new ideas and to disperse a creative corporate culture throughout the company.<br/>
              C-Lab Inside projects that have proven marketability will either transfer to an in-house division or turned into spin-off companies supported by Samsung Electronics.<br/><br/>
              C-Lab Outside is a start-up acceleration program designed to encourage competent and prospective entrepreneurs outside Samsung Electronics.<br/>
              The C-Lab Outside program helps start-ups to grow their businesses by providing financial aid, mentoring, consultation, business co-operation, and infrastructure."
            tag=""
            /*
                Live or VOD
              */
            type=""
            released={false}
            btnBlock
            url="https://youtu.be/1O3drPCZlTo"
            // 세션 스케줄 확정 이후 해당 시작 시각으로 변경
            time="14:10 PM PT"
            /*
              Session Room 1 or Session Room 2
              */
            room="<a href=/#/sessionRoom>Session Room 1</a>"
            infoTime=""
            infoPlace=""
            uploadedBefore={false}
            on_Demand={false}
          />
        </DetailContainer>
      </ClabInner>
      <TechSquareMap path="c-lab" onSquareClick={() => {}} />
      {phase !== 'phase2' && (
        <ClabCards>
          <ClabCardsInner>
            <ClabCardsInnerCon ref={cardRef}>
              {EventSamsungClabData.map((item, idx) => (
                <EventTechSquareCard
                  key={idx}
                  slide={item.slide}
                  listYn={idx + 1 > count}
                  video={item.video}
                  title={item.title}
                  subtitle={item.subtitle}
                  desc={item.desc}
                  popupDesc={item.popupDesc}
                  image={item.img}
                  images={item.images}
                  videoUrl={item.videoUrl}
                  link={item.link}
                  hideCard={idx + 1 > count}
                  // 로딩중일 경우 true로 전송
                  // loading={true}
                />
              ))}
            </ClabCardsInnerCon>
          </ClabCardsInner>
          {EventSamsungClabData.length > 6 && (
            <CardMoreBtn>
              <Button
                color="white"
                outline="outline"
                size="medium"
                className="moreBtn"
                onClick={handleClick}
              >
                {more ? 'Less' : 'More'}
              </Button>
            </CardMoreBtn>
          )}
        </ClabCards>
      )}
    </ClabWrap>
  );
};

export default EventCLabCont;
