const local = {
  CADMIUM_PHOTO_URL:
    'https://www.conferenceharvester.com/Uploads/harvester/photos',
  CALENDAR_LINK_APPLE: `${process.env.PUBLIC_URL}/SDC23.ics?v=0902221939`,
  CALENDAR_LINK_GOOGLE:
    'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20231005T170000Z%2F20231006T020000Z&details=Samsung%20Developer%20Conference%202023&location=Moscone%20North%20in%20San%20Francisco&text=SDC23',
  CALENDAR_LINK_OFFICE365:
    'https://outlook.office.com/calendar/action/compose?allday=false&body=Samsung%20Developer%20Conference%202023&enddt=2023-10-06T02%3A00%3A00%2B00%3A00&location=Moscone%20North%20in%20San%20Francisco&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-10-05T17%3A00%3A00%2B00%3A00&subject=SDC23',
  CALENDAR_LINK_OUTLOOK:
    'https://outlook.live.com/calendar/0/action/compose?allday=false&body=Samsung%20Developer%20Conference%202023&enddt=2023-10-06T02%3A00%3A00%2B00%3A00&location=Moscone%20North%20in%20San%20Francisco&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-10-05T17%3A00%3A00%2B00%3A00&subject=SDC23',
  CALENDAR_LINK_YAHOO:
    'https://calendar.yahoo.com/?desc=Samsung%20Developer%20Conference%202023&dur=&et=20231006T020000Z&in_loc=Moscone%20North%20in%20San%20Francisco&st=20231005T170000Z&title=SDC23&v=60',
  EVENT_DATE: '2022-10-12T00:00',
};

const config = local;

export default {
  ...config,
};
