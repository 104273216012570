const moment = require('moment');

module.exports = {
  sortSpeakers: (presenters) => {
    const ceoArray = [];
    const executiveArray = [];
    const seniorArray = [];
    const viceArray = [];
    const engineerArray = [];

    presenters.forEach((x) => {
      const position = x.PresenterPosition;
      if (position.indexOf('CEO') > -1) {
        ceoArray.push(x);
      } else if (position.indexOf('Executive') > -1) {
        executiveArray.push(x);
      } else if (position.indexOf('Senior') > -1) {
        seniorArray.push(x);
      } else if (position.indexOf('Vice') > -1) {
        viceArray.push(x);
      } else {
        engineerArray.push(x);
      }
    });

    seniorArray.sort((a, b) =>
      a.PresenterFirstName > b.PresenterFirstName ? 1 : -1
    );
    viceArray.sort((a, b) =>
      a.PresenterFirstName < b.PresenterFirstName ? 1 : -1
    );

    const presenterData = [
      ...ceoArray,
      ...executiveArray,
      ...seniorArray,
      ...viceArray,
      ...engineerArray,
    ];

    return presenterData;
  },
  convertURL(string) {
    return (string || '')
      .replace(/[^A-Za-z0-9  ]/g, '')
      .replace(/[  ]/g, '-')
      .replace(/--/g, '-')
      .toLowerCase();
  },
  markEventVersion: (version) => {
    const div = document.createElement('div');
    div.style.backgroundColor = 'red';
    div.style.color = 'white';
    div.style.position = 'fixed';
    div.style.padding = '8px';
    div.style.bottom = 0;
    div.style.right = 0;
    div.style.fontSize = '16px';
    div.style.zIndex = '999';
    div.innerHTML = version;
    document.getElementById('root').appendChild(div);
  },
  diffTime: (current, st, et) => {
    const startTime = moment(st);
    const endTime = moment(et);
    if (
      (startTime.isBefore(current) || startTime.isSame(current)) &&
      endTime.isAfter(current)
    ) {
      const diff = endTime.diff(current);
      return { status: 'live', diff };
    }

    if (
      startTime.isBefore(current) &&
      (endTime.isBefore(current) || endTime.isSame(current))
    ) {
      return { status: 'done', diff: -1 };
    }

    if (startTime.isAfter(current) && endTime.isAfter(current)) {
      const diff = startTime.diff(current);
      return { status: 'prepare live', diff };
    }
  },
  getGaClientId: () => {
    // const gaCookie = document.cookie
    //   .split('; ')
    //   .find((row) => row.startsWith('_ga='));
    // return gaCookie ? gaCookie.split('.').slice(-2).join('.') : null;

    const formNode = document.createElement('FORM');
    formNode.action = process.env.REACT_APP_SDP_HOST;
    formNode.style.opacity = '0';
    formNode.addEventListener('submit', (event) => {
      event.preventDefault();
    });
    const buttonNode = document.createElement('BUTTON');
    buttonNode.type = 'submit';
    formNode.append(buttonNode);
    document.body.append(formNode);
    buttonNode.click();
    const glNode = formNode.querySelector('input[name="_gl"]');
    if (glNode) {
      return glNode.value;
    }
    return null;
  },
};
