import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const SpeakerContainer = styled.div`
  padding: 100px 0 148px;
  background-color: #fff;
  @media screen and (max-width: 768px) {
    padding: 60px 0;
  }
`;

export const SpeakerInner = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const SpeakerTitle = styled.div`
  margin-bottom: 32px;
  font-family: 'samsungSharpSans-bold';
  font-size: 32px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.4;
  }
`;

export const CardWrap = styled.div`
	.Card {
		// &:nth-of-type(2) {
		// 	.cardHover {
		// 		.text {
		// 			font-size: 20px;
		// 			line-height: 26px;
		// 		}
		// 	}
		// }
		
		// &:nth-of-type(4) {
		// 	.cardHover {
		// 		.text {
		// 			font-size: 18px;
		// 			line-height: 24px;
		// 		}
		// 	}
		// }
	}

	// @media screen and (max-width: 1294px){
	// 	.Card{
	// 		&:nth-of-type(8) {
	// 			&.hover {
	// 				.Text {
	// 					.Text-title {
	// 						font-size: 22px;
	// 					}
	// 				}
	// 			}
	// 		}

	// 		&:nth-of-type(4) {
	// 			.cardHover {
	// 				.text {
	// 					font-size: 16px;
	// 					line-height: 22px;
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	@media screen and (max-width: 1221px){
		.Card{
			&.hover {
				.Text {
					.Text-title {
						font-size: 20px;
					}
				}
			}

			// &:nth-of-type(8) {
			// 	&.hover {
			// 		.Text {
			// 			.Text-title {
			// 				font-size: 19px;
			// 			}
			// 		}
			// 	}
			// }

			.cardHover {
				.text {
					font-size: 18px;
					line-height: 24px;
				}
			}

			// &:nth-of-type(4) {
			// 	.cardHover {
			// 		.text {
			// 			font-size: 14px;
			// 			line-height: 20px;
			// 		}
			// 	}
			// }
		}
	}

  @media screen and (max-width: 1140px){
		.Card {
			width: calc(33% - 24px)
		}

		.Card{
			.cardHover {
				.text {
					font-size: 20px;
					line-height: 24px;
				}
			}

			// &:nth-of-type(4) {
			// 	.cardHover {
			// 		.text {
			// 			font-size: 20px;
			// 			line-height: 26px;
			// 		}
			// 	}
			// }
		}
	}

	@media screen and (max-width: 970px){

		.Card{
			// &:nth-of-type(2) {
			// 	.cardHover {
			// 		.text {
			// 			font-size: 18px;
			// 			line-height: 22px;
			// 		}
			// 	}
			// }

			// &:nth-of-type(4) {
			// 	.cardHover {
			// 		.text {
			// 			font-size: 18px;
			// 			line-height: 22px;
			// 		}
			// 	}
			// }
		}
	}

	@media screen and (max-width: 930px){
		.Card {
			width: calc(50% - 24px)
		}
	}

	@media screen and (max-width: 768px){
		.Card {
			width: calc(50% - 4px);
			margin: 0;

			&.hover {
				.Text {
					.Text-desc {
						margin-top: 0;
						margin-bottom: 2px;
						font-size: 12px;
						line-height: 16px;
					}
					.Text-title {
						font-size: 14px;
						line-height: 20px;
					}
				}
			}

			+.Card {
				margin-top: 16px;
			}
			&:nth-of-type(2n-1) {
				margin-right: 8px;
			}
			&:nth-of-type(2) {
				margin-top: 0;
			}

			.Text {
					position: absolute;
					z-index: 1;
					top: 0;
					padding: 16px;
				}

				.card_img {
					height: 60vw;
				}
			}
		}
	}
`;

export const CardInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -12px;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const SpeakerSubTitle = styled.div`
  margin-bottom: 32px;
  font-size: 24px;
  font-family: 'samsungsharpsans-bold';
  font-weight: 600;
  line-height: 32px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 24px;
  }
`;
