import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useSelector } from 'react-redux';
import {
  ScheduleContainer,
  ScheduleInner,
  ScheduleList,
  ScheduleCalendar,
} from './MainScheduleElements';
import MainSectionTitle from '../MainSectionTitle';
import ButtonLink from '../ButtonLink';

const MainSchedule = () => {
  const MainRef = useRef();
  const ScheduleListRef = useRef();
  const tl = useRef();
  const [hasRendered, setHasRendered] = useState(false);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    setHasRendered(true);
  }, []);
  useEffect(() => {
    if (hasRendered) {
      const cardArray = gsap.utils.toArray(ScheduleListRef.current.children);
      cardArray.forEach((el) => {
        tl.current = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: 'top 95%',
            end: 'top center',
            scrub: true,
            onUpdate: (self) => {
              if (self.progress > 0.1) {
                el.classList.add('active');
              } else {
                el.classList.remove('active');
              }
            },
          },
        });
      });
    }
    return () => {
      if (tl.current) tl.current.kill();
    };
  });
  return (
    <>
      <ScheduleContainer ref={MainRef}>
        <ScheduleInner>
          <MainSectionTitle
            title="Schedule"
            desc={
              <div>
                From Keynote to Code Lab, here's what's happening at SDC24.
              </div>
            }
            linkText="Go to Schedule"
            buttonUrl="/schedule"
          />
          <ScheduleList ref={ScheduleListRef}>
            <ScheduleCalendar>
              <div className="title">
                <span>Open</span>
              </div>
              <div className="content">
                <div className="cont-li">
                  <p>Badge Pick-up</p>
                  <p>9:00 - 10:00</p>
                </div>
              </div>
            </ScheduleCalendar>
            <ScheduleCalendar>
              <div className="title">
                <span>Keynote</span>
              </div>
              <div className="content">
                <div className="cont-li">
                  <p>Keynote Live Streaming</p>
                  <p>10:00 - 11:30</p>
                </div>
              </div>
            </ScheduleCalendar>
            <ScheduleCalendar>
              <div className="title">
                <span>Session</span>
              </div>
              <div className="content">
                <div className="cont-li">
                  <p>Tech Sessions & Open Stage</p>
                  <p>11:30 - 17:30</p>
                </div>
              </div>
            </ScheduleCalendar>
            <ScheduleCalendar>
              <div className="title">
                <span>Tech Square</span>
              </div>
              <div className="content">
                <div className="cont-li">
                  <p>Exhibition</p>
                  <p>11:30 - 17:30</p>
                </div>
              </div>
            </ScheduleCalendar>
            <ScheduleCalendar>
              <div className="title">
                <span>Code Lab</span>
              </div>
              <div className="content">
                <div className="cont-li">
                  <p>Hands on activity</p>
                  <p>11:30 - 17:30</p>
                </div>
              </div>
            </ScheduleCalendar>
            <ScheduleCalendar>
              <div className="title">
                <span>On-site Events</span>
              </div>
              <div className="content">
                <div className="cont-li">
                  <p>All day Networking & Various Programs</p>
                  <p>11:30 - 17:30</p>
                </div>
                <div className="cont-li">
                  <p>"Code&Chill" Night Party</p>
                  <p>17:30 - 19:00</p>
                </div>
              </div>
            </ScheduleCalendar>
          </ScheduleList>
        </ScheduleInner>
      </ScheduleContainer>
    </>
  );
};

export default MainSchedule;
