import React from 'react';
import ActivityMsgCont from '../../components/ActivityMsgCont';

const ActivityTicketError = () => {
  return (
    <>
      <ActivityMsgCont
        type="error"
        msgTitle="It looks like you don't have a Lucky Draw ticket to pick up!"
        buttonLink="/activity/ticket"
        buttonText="Go to Ticket history"
      />
    </>
  );
};

export default ActivityTicketError;
