/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import FloatingRegisterBtn from './components/FloatingRegister';
import FooterCon from './components/Footer';
import ModalCookie from './components/ModalCookie';
import Navbar from './components/Navbar';
import PopupConfirm from './components/Popup';
import PopupCookie from './components/PopupCookie';
import PopupManage from './components/PopupManage';
import PopupSurvey from './components/PopupSurvey';
import Topbutton from './components/Topbutton';
import GlobalScript from './GlobalScript';
import EventSamsungCLab from './pages/EventSamsungCLab';

import {
  ActivityBingo,
  ActivityBingoComplete,
  ActivityBingoMap,
  ActivityBingoPrizes,
  ActivityCodeLab,
  ActivityCodeLabMap,
  ActivityCodeLabPrizes,
  ActivityCodelabComplete,
  ActivityEvent,
  ActivityOverlapError,
  ActivityPickupComplete,
  ActivityPickupTicketComplete,
  ActivityPoint,
  ActivityPointError,
  ActivityScanError,
  ActivityTicket,
  ActivityTicketError,
  AlreadyError,
  CodeLab,
  Cookie,
  Error,
  Event,
  EventTechSquare,
  Keynote,
  KoreaConsent,
  LimitError,
  Main,
  Meetup,
  Mypage,
  Privacy,
  RegConfirm,
  RegModification,
  Registration,
  RegistrationClosed,
  RegistrationInvited,
  Schedule,
  SessionDetail,
  Sitemap,
  SpeakerDetail,
  Speakers,
  Support,
  SurveyIndex,
  Tech,
  Terms,
  UnRegister,
} from './pages';

import './App.scss';

import Activity from './pages/Activity';
import QR from './pages/QR';
import ClaimQR from './pages/QR/ClaimQR';
import {
  CLOSE_RESERVATION_NOTI_DATA,
  GET_USER_SESSION_REQUEST,
} from './redux/ActionTypes';
import { convertURL } from './utils/Functions';

const App = (props) => {
  const dispatch = useDispatch();
  const [openManage, setOpenManage] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [surveyPopup, setSurveyPopup] = useState(false);

  const phase = useSelector((state) => state.meta.data.phase);
  const { isLogin, isLoading: isLoginLoading } = useSelector(
    (state) => state.userSession
  );

  const { isLoading, showGDPRPopup, showGDPRModal, isCookiePolicy } =
    useSelector((state) => state.userSession);

  const showReservationNoti = useSelector(
    (state) => state.reservationData?.notiPopup
  );
  const notiSessions = useSelector(
    (state) => state.reservationData?.notiSessions
  );
  const surveyURL = useSelector((state) => state.meta?.data?.main?.survey?.url);

  const query = new URLSearchParams(window.location.search);
  const locationObj = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
      });
    }

    if (query) {
      const gaQuery = {};
      for (const [key, value] of query.entries()) {
        if (key.startsWith('utm_')) {
          gaQuery[key] = value;
        }
      }
      if (Object.keys(gaQuery).length > 0) {
        sessionStorage.setItem('sdc-utm-data', JSON.stringify(gaQuery));
      }
    }

    // get user Session data
    dispatch({ type: GET_USER_SESSION_REQUEST });
  }, []);

  useEffect(() => {
    if (!isLogin) {
      dispatch({ type: GET_USER_SESSION_REQUEST });
    }
  }, [locationObj.pathname, isLogin]);

  if (isLoading || isLoginLoading) {
    return <></>;
  }

  const isActivityPage = locationObj.pathname.startsWith('/activity');
  const isQRPage = locationObj.pathname.startsWith('/qr');

  // Redirect to login if on specific page and not logged in
  if (!isLogin && (isActivityPage || isQRPage)) {
    const url = new URL(window.location.href);
    window.location.href = `/login?redirectURL=${url.toString()}`;
    return <></>;
  }

  return (
    <div className="App">
      {/* Navbar */}
      {locationObj.pathname !== '/event/activity' &&
      locationObj.pathname.includes('/activity') ? null : (
        <Navbar />
      )}

      {/* page content */}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/keynote" element={<Keynote />} />
        <Route path="/sessions" element={<Tech />} />
        <Route path="/sessions/:id" element={<SessionDetail />} />
        <Route path="/event" element={<Event />} />
        <Route path="/event/meet-up" element={<Meetup />} />
        <Route path="/event/activity" element={<Activity />} />
        <Route path="/speakers" element={<Speakers />} />
        <Route path="/speakers/:id" element={<SpeakerDetail />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/support" element={<Support />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/mypage" element={<Mypage />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/codelab" element={<CodeLab />} />
        <Route path="/tech-square" element={<EventTechSquare />} />
        <Route path="/event/samsung-clab" element={<EventSamsungCLab />} />
        <Route path="/privacy-consent" element={<KoreaConsent />} />
        <Route path="/cookie" element={<Cookie />} />
        {/* <Route path="/mega-session" element={<SessionRoom />} /> */}
        {/* <Route path="/session-room2" element={<SessionRoom2 />} /> */}
        {/* <Route path="/mega-session" element={<MegaSession />} /> */}
        <Route path="/registration/confirm" element={<RegConfirm />} />
        <Route
          path="/registration/modification"
          element={<RegModification />}
        />
        <Route path="/registration/canceled" element={<UnRegister />} />
        <Route path="/registration/full" element={<LimitError />} />
        <Route path="/registration/closed" element={<RegistrationClosed />} />
        <Route path="/registration/invited" element={<RegistrationInvited />} />
        <Route path="/register" element={<Registration />} />

        {/* QR Code */}
        <Route path="/qr/staff/:qrId" element={<QR />} />
        <Route path="/qr/claim/:qrId" element={<ClaimQR />} />

        {/* already form error */}
        <Route path="/already_registered" element={<AlreadyError />} />

        {/* After scanning the QR code, the survey entry point */}
        <Route path="/survey" element={<SurveyIndex />} />

        {/* activity */}
        {/* The bottom pages can accessible starting from phase 3. */}
        {(phase === 'phase2' || phase === 'phase3' || phase === 'phase4') && (
          <>
            <Route path="/activity" element={<ActivityEvent />} />
            <Route path="/activity/bingo" element={<ActivityBingo />} />
            <Route path="/activity/bingo/map" element={<ActivityBingoMap />} />
            <Route
              path="/activity/bingo/prizes"
              element={<ActivityBingoPrizes />}
            />
            <Route path="/activity/codelab" element={<ActivityCodeLab />} />
            <Route
              path="/activity/codelab/map"
              element={<ActivityCodeLabMap />}
            />
            <Route
              path="/activity/codelab/prizes"
              element={<ActivityCodeLabPrizes />}
            />
            <Route path="/activity/point" element={<ActivityPoint />} />
            <Route path="/activity/ticket" element={<ActivityTicket />} />
            <Route
              path="/activity/bingo-complete"
              element={<ActivityBingoComplete />}
            />
            <Route
              path="/activity/codelab-complete"
              element={<ActivityCodelabComplete />}
            />
            <Route
              path="/activity/pickup-complete"
              element={<ActivityPickupComplete />}
            />
            {/* 스캔 후, 럭키드로우 티켓 수령을 위한 페이지 */}
            <Route
              path="/activity/ticket-complete"
              element={<ActivityPickupTicketComplete />}
            />
            {/* 스캔 후,  빙고가 없는 경우 */}
            <Route
              path="/activity/ticket-error"
              element={<ActivityTicketError />}
            />
            {/* 스캔 후, 포인트가 충분하지 않은 경우 */}
            <Route
              path="/activity/point-error"
              element={<ActivityPointError />}
            />
            {/* Scan Error */}
            <Route
              path="/activity/scan-error"
              element={<ActivityScanError />}
            />
            {/* 스캔 후, 상품 중복 수령 불가 */}
            <Route
              path="/activity/overlap-error"
              element={<ActivityOverlapError />}
            />
          </>
        )}

        {/* unauthorized error */}
        {['/unauthorized', '/invalid_session'].map((path, index) => (
          <Route
            path={path}
            element={<Error num="401" title="Unauthorized" />}
            key={index}
          />
        ))}

        {/* internal server error */}
        <Route
          path="/internal_server_error"
          element={<Error num="500" title="Internal Server Error" />}
        />

        {/* all other errors */}
        <Route
          path="*"
          element={
            <Error
              num="404"
              title="Page Not Found"
              desc="The page does not exist or is not available.<br> Check that the address is correct."
            />
          }
        />
      </Routes>

      <FooterCon cookieCountry={isCookiePolicy} />

      <Topbutton />
      {showGDPRPopup && (
        <PopupCookie
          setOpenManage={(status) => {
            setOpenManage(status);
          }}
        />
      )}
      {showGDPRModal && (
        <ModalCookie
          setOpenManage={(status) => {
            setOpenManage(status);
          }}
          openManage={openManage}
        />
      )}
      {openManage && (
        <PopupManage
          setOpen={(status) => {
            setOpenManage(status);
          }}
          setOpenConfirm={(status) => {
            setOpenConfirm(status);
          }}
        />
      )}
      {openConfirm && (
        <PopupConfirm
          modal={openConfirm}
          setModal={(status) => {
            setOpenConfirm(status);
          }}
          title="Preferences Submitted"
          desc="You have successfully updated your cookie preferences."
          btnText="Close"
        />
      )}

      {showReservationNoti && notiSessions.length > 0 && (
        <PopupConfirm
          modal={showReservationNoti}
          onClick={(e) => {
            e.preventDefault();
            // close noti popup
            dispatch({ type: CLOSE_RESERVATION_NOTI_DATA });
          }}
          title="Alert"
          desc={
            <>
              It's 5 minutes before
              <br /> the session opens
            </>
          }
          btnText="Confirm"
          multilink
          linkBlock
          link
          linkData={notiSessions.map((x) => {
            return {
              url: `/sessions/${convertURL(x.PresentationTitle)}`,
              title: x.PresentationTitle,
            };
          })}
        />
      )}
      {surveyURL && !surveyPopup && !localStorage.getItem('sdc23-survey') && (
        <PopupSurvey
          setOpenSurvey={() => {
            localStorage.setItem('sdc23-survey', true);
            setSurveyPopup(true);
          }}
          surveyURL={surveyURL}
        />
      )}

      {/* {phase !== 'phase4' && <FloatingRegisterBtn />} */}
      {/* <FloatingRegisterBtn /> */}
      <GlobalScript />
    </div>
  );
};

export default App;
