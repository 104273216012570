import React, { useLayoutEffect, useState } from 'react';
import {
  ErrorWrap,
  ErrorWrapInner,
  ErrorTitle,
  ErrorDesc,
  ErrorLogo,
} from '../RegisterErrorCont/RegisterErrorContElement';
import ButtonLink from '../ButtonLink';

const AlreadyErrorCont = ({ search }) => {
  const [type, setType] = useState('');
  useLayoutEffect(() => {
    const getType = new URLSearchParams(search).get('type');
    if (getType === 'staff') {
      setType('Staff');
    } else {
      setType('Attendee');
    }
  }, []);

  return (
    <ErrorWrap className="only-content">
      <ErrorWrapInner>
        <ErrorLogo />
        <ErrorTitle>{`Looks like you're already registered for ${type}. 
        Please check your inbox for a confirmation email.`}</ErrorTitle>
        <ErrorDesc>
          To change type of registration, delete the existing registration from
          My page on SDC24 and try again.
        </ErrorDesc>
        <ButtonLink url="/mypage" color="black" size="xsmall">
          Go To My Page
        </ButtonLink>
      </ErrorWrapInner>
    </ErrorWrap>
  );
};

export default AlreadyErrorCont;
