import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DetailContainer from '../../components/DetailContainer';
import SpeakerDetailCont from '../../components/SpeakerDetailCont';
import SpeakerDetailBottom from '../../components/SpeakerDetailBottom';
import withRouter from '../../components/Router/withRouter';
import { GET_SPEAKER_DETAIL_REQUEST } from '../../redux/ActionTypes';
import Loading from '../../components/Loading';
import Config from '../../Config';

const SpeakerDetail = (props) => {
  const { navigate, params } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_SPEAKER_DETAIL_REQUEST, presenterId: params.id });
  }, [params.id]);

  const { isLoading, data, error } = useSelector(
    (state) => state.speakerDetail
  );

  useEffect(() => {
    if (error && navigate) {
      if (error === 'Not Found') {
        navigate('/page_not_found');
      } else {
        navigate('/internal_server_error');
      }
    }
  }, [error]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <DetailContainer>
          {data &&
            data.speaker.map((item) => (
              <div key={item.PresenterID}>
                <SpeakerDetailCont
                  key={item.PresenterID}
                  title={`${item.PresenterFirstName} ${item.PresenterLastName}`}
                  subTitle={
                    item.PresenterPosition && item.PresenterOrganization
                      ? `${item.PresenterPosition}, ${item.PresenterOrganization}`
                      : item.PresenterPosition
                      ? item.PresenterPosition
                      : item.PresenterOrganization
                  }
                  desc={`${item.PresenterBiographyText}`}
                  img={item.PresenterPhotoFileName}
                />
                <SpeakerDetailBottom sessionData={item.Presentations} />
              </div>
            ))}
        </DetailContainer>
      )}
    </>
  );
};

export default withRouter(SpeakerDetail);
