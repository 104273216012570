import styled from 'styled-components';

export const ActivityHeaderContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
`;

export const ActivityHeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 48px;
  padding: 9px 20px 9px 24px;
  box-sizing: border-box;
`;

export const ActivityMenu = styled.div`
  position: relative;

  > * {
    display: inline-block;
    vertical-align: top;
  }

  .btn-activity {
    position: relative;
    padding: 6px 0 5px 0;
    font-family: 'samsungsharpsans-bold';
    font-size: 15px;
    color: #000;
  }

  .btn-dropdown {
    position: relative;
    padding: 6px 20px 5px 4px;
    margin-left: 16px;
    font-family: 'samsungsharpsans-bold';
    font-size: 15px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -7px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 12px;
      background-color: #d1d1d1;
    }
  }

  .btn-prev {
    display: flex;
    align-items: center;
    padding-left: 22px;
    font-family: 'samsungOne-700';
    font-size: 16px;
    line-height: 20px;
    color: #000;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      width: 20px;
      height: 20px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-previous.svg`});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
`;

export const ActivityMenuDropdown = styled.div`
  display: ${({ open }) => (open ? 'inline-block' : 'none')};
  position: absolute;
  min-width: 91px;
  top: 43px;
  left: -8px;

  .inner {
    padding: 10px 12px;
    border-radius: 12px;
    background-color: #fff;
  }

  a {
    display: block;
    font-family: 'samsungsharpsans-bold';
    font-size: 14px;
    line-height: 24px;
    color: #363636;
    text-align: left;

    &.is-selected {
      color: #2088fd;
    }
  }
  a + a {
    margin-top: 4px;
  }
`;

export const ActivityMenuArrow = styled.span`
  position: absolute;
  top: 8px;
  right: 0;
  width: 14px;
  height: 14px;
  transform: rotate(${({ open }) => (open ? '180deg' : 'none')});
`;

export const ActivityHeaderRight = styled.div`
  display: inline-flex;
  a + a,
  a + label {
    margin-left: 8px;
  }
  a {
    display: inline-block;
  }
  img {
    display: block;
  }
`;
