import Lottie from 'lottie-react';
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import prizesTitleLottie from '../../lottie/activity-prizes-title-bg.json';
import {
  ActivityContainer,
  ActivityTab,
} from '../ActivityBingoMain/ActivityBingoElements';
import {
  ActivityPrizesCont,
  ActivityPrizesList,
  ActivityPrizesRandom,
  ActivityPrizesSchedule,
  ActivityPrizesTitle,
} from '../ActivityBingoPrizesContent/ActivityBingoPrizesElements';

const ActivityCodeLabPrizesContent = () => {
  const settings = {
    dots: true,
    speed: 400,
    centerMode: true,
    infinite: false,
    variableWidth: true,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    cssEase: 'ease-in-out',
  };

  return (
    <ActivityContainer type="codelab" className="codelab">
      <ActivityTab>
        <Link to="/activity/codelab">Activities</Link>
        <Link to="/activity/codelab/map">Map</Link>
        <Link to="/activity/codelab/prizes" className="active">
          Prizes
        </Link>
      </ActivityTab>
      <ActivityPrizesCont>
        <ActivityPrizesTitle>
          <Lottie animationData={prizesTitleLottie} loop className="bg" />
          <p>Bingo</p>
          <p>
            Lucky Draw <br />
            Prizes
          </p>
          <p>
            Complete each task to earn a lucky draw ticket, which will be
            entered in the Code Lab Lucky Draw at 6:00 PM on the Open Stage. Be
            sure to submit your ticket number to our promoter at the Giveaway
            Desk in the Code Lab zone.
          </p>
        </ActivityPrizesTitle>
        <ActivityPrizesList>
          <ul>
            <li>
              <span className="no1">1</span>
              <img
                src={`${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-zflip.png`}
                alt="Galaxy Z Flip6"
              />
              <p>Galaxy Z Flip6</p>
            </li>
            <li>
              <span>2</span>
              <img
                src={`${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-watch7.png`}
                alt="Galaxy Watch Ultra"
              />
              <p>Galaxy Watch7</p>
            </li>
          </ul>
        </ActivityPrizesList>
        <ActivityPrizesSchedule>
          <p>Lucky Draw Schedule</p>
          <ul>
            <li>
              <em>1st</em>
              <span>Open Stage</span>
              <span>6:45 PM</span>
            </li>
          </ul>
        </ActivityPrizesSchedule>
        <ActivityPrizesTitle>
          <Lottie animationData={prizesTitleLottie} loop className="bg" />
          <p>Bingo</p>
          <p>
            Point Redeem <br />
            Prizes
          </p>
          <p>
            Complete tasks to earn points and exchange them for gifts at the
            Giveaway Desk. Each person can receive one gift each.
          </p>
        </ActivityPrizesTitle>
      </ActivityPrizesCont>
      <ActivityPrizesRandom>
        <Slider {...settings}>
          <div className="random-item">
            <div className="img">
              <img
                src={`${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-keyring.png`}
                alt="Keychain"
              />
            </div>
            <p>Keychain</p>
            <p>1,000</p>
          </div>
          <div className="random-item">
            <div className="img">
              <img
                src={`${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-tumbler.png`}
                alt="Tumbler"
              />
            </div>
            <p>Tumbler</p>
            <p>1,500</p>
          </div>
          <div className="random-item">
            <div className="img">
              <img
                src={`${process.env.PUBLIC_URL}/images/activity-prizes/activity-prizes-charger.png`}
                alt="Charging Tray"
              />
            </div>
            <p>Charging Tray</p>
            <p>2,500</p>
          </div>
        </Slider>
      </ActivityPrizesRandom>
    </ActivityContainer>
  );
};

export default ActivityCodeLabPrizesContent;
