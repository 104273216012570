import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  NavContainer,
  NavInner,
  NavButton,
  NavTitle,
  NavMenu,
  NavLink,
  NavDropdown,
  NavDropdownLink,
  NavArrow,
  NavRight,
} from './NavbarElements';
import SideMenu from '../SideMenu';
import NavLogo from './NavLogo';
import Btn from './NavButton';
import Arrow from './NavArrow';

const Navbar = () => {
  // session dropdown controll
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownOpenHandler = () => {
    setDropdownOpen(true);
  };

  const dropdownCloseHandler = () => {
    setDropdownOpen(false);
  };

  // side menu controll
  const [sideMenu, setSideMenu] = useState(false);

  const sideMenuHandler = () => {
    setSideMenu((data) => !data);
    if (!sideMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const [color, setColor] = useState('black');
  const locationObj = useLocation();

  // phase3 유무 체크
  // const [phase3, setPhase3] = useState(false);
  const phase = useSelector((state) => state.meta.data.phase);

  // phase4 유무 체크
  const [phase4, setPhase4] = useState(false);

  const [scrollVal, setScrollVal] = useState(0);

  // check color version
  const checkColor = useCallback(() => {
    // phase에 따라 스크롤 양 계산
    setScrollVal(2.95);

    if (window.scrollY < 900) {
      setColor('white');
    } else if (window.scrollY >= window.innerHeight * scrollVal) {
      setColor('white');
    } else {
      setColor('black');
    }
  }, [phase, scrollVal]);

  useEffect(() => {
    // Other page
    if (locationObj.pathname !== '/') {
      setColor('white');
      // Main
    } else {
      checkColor();
    }
  }, [locationObj, checkColor]);

  useEffect(() => {
    // Not main return
    if (locationObj.pathname !== '/') return;

    // Only main page has scroll event
    window.addEventListener('scroll', checkColor);

    return () => window.removeEventListener('scroll', checkColor);
  }, [locationObj, checkColor]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setSideMenu(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { isLogin } = useSelector((state) => state.userSession);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <NavContainer color={color} className={sideMenu ? 'active' : ''}>
      <NavInner color={color} className={sideMenu ? 'active' : ''}>
        <NavTitle>
          {locationObj.pathname === '/' ? (
            <button
              type="button"
              className="logo"
              onClick={() => scrollTop()}
              aria-label="logo"
            >
              <NavLogo
                fill={!sideMenu && color === 'black' ? '#ffffff' : '#000'}
              />
            </button>
          ) : (
            <Link to="/" className="logo">
              <NavLogo
                fill={!sideMenu && color === 'black' ? '#ffffff' : '#000'}
              />
            </Link>
          )}
        </NavTitle>

        <NavMenu>
          <NavLink
            color={color}
            className={locationObj.pathname === '/keynote' ? 'is-selected' : ''}
          >
            <Link to="/keynote">Keynote</Link>
          </NavLink>
          <NavLink
            color={color}
            className={
              locationObj.pathname === '/sessions' ||
              locationObj.pathname === '/speakers' ||
              locationObj.pathname.includes('sessions/') ||
              locationObj.pathname.includes('speakers/')
                ? 'is-selected'
                : ''
            }
          >
            <button
              onMouseEnter={dropdownOpenHandler}
              onMouseLeave={dropdownCloseHandler}
              type="button"
            >
              Session
              <NavArrow open={dropdownOpen}>
                <Arrow
                  fill={
                    color === 'black'
                      ? '#fff'
                      : locationObj.pathname === '/sessions' ||
                        locationObj.pathname === '/speakers' ||
                        locationObj.pathname.includes('sessions/') ||
                        locationObj.pathname.includes('speakers/')
                      ? '#2088fd'
                      : '#000'
                  }
                  opacity="0.8"
                />
              </NavArrow>
              <NavDropdown open={dropdownOpen} color={color}>
                <div className="inner">
                  <NavDropdownLink
                    color={color}
                    className={
                      locationObj.pathname === '/sessions' ||
                      locationObj.pathname.includes('sessions/')
                        ? 'is-selected'
                        : ''
                    }
                  >
                    <Link to="/sessions" onClick={dropdownCloseHandler}>
                      Sessions
                    </Link>
                  </NavDropdownLink>
                  <NavDropdownLink
                    color={color}
                    className={
                      locationObj.pathname === '/speakers' ||
                      locationObj.pathname.includes('speakers/')
                        ? 'is-selected'
                        : ''
                    }
                  >
                    <Link to="/speakers" onClick={dropdownCloseHandler}>
                      Speakers
                    </Link>
                  </NavDropdownLink>
                </div>
              </NavDropdown>
            </button>
          </NavLink>
          <NavLink
            color={color}
            className={
              locationObj.pathname === '/tech-square' ? 'is-selected' : ''
            }
          >
            <Link to="/tech-square">Tech Square</Link>
          </NavLink>
          <NavLink
            color={color}
            className={locationObj.pathname === '/codelab' ? 'is-selected' : ''}
          >
            <Link to="/codelab">Code Lab</Link>
          </NavLink>
          <NavLink
            color={color}
            className={locationObj.pathname === '/event' ? 'is-selected' : ''}
          >
            <Link to="/event">On-site Events</Link>
          </NavLink>
          <NavLink
            color={color}
            className={
              locationObj.pathname === '/schedule' ? 'is-selected' : ''
            }
          >
            <Link to="/schedule">Schedule</Link>
          </NavLink>
          <NavLink
            color={color}
            className={locationObj.pathname === '/support' ? 'is-selected' : ''}
          >
            <Link to="/support">Support</Link>
          </NavLink>
        </NavMenu>

        <NavRight color={color}>
          {/* hamburger btn */}
          <NavButton
            color={color}
            className={sideMenu ? 'active' : ''}
            onClick={() => {
              sideMenuHandler();
            }}
          >
            <i className="toggleLine middle" />
          </NavButton>
          {isLogin ? (
            <>
              <NavLink
                color={color}
                className={`my-page ${
                  locationObj.pathname === '/mypage' ? 'is-active' : ''
                }`}
              >
                <Link to="/mypage">My Page</Link>
              </NavLink>
              <NavLink color={color} className="sign-out">
                <a href="/logout">Sign Out</a>
              </NavLink>
            </>
          ) : (
            <>
              <NavLink color={color} className="signin">
                <a href={`/login?redirectURL=${window.location.pathname}`}>
                  Sign in
                </a>
              </NavLink>
            </>
          )}
        </NavRight>
      </NavInner>

      {/* Side Menu */}
      <SideMenu
        sideMenu={sideMenu}
        setSideMenu={setSideMenu}
        pathname={locationObj.pathname}
      />
    </NavContainer>
  );
};

export default Navbar;
