import * as types from '../ActionTypes';

const initialState = {
  isReservation: false,
  registerType: null,
  data: [],
  motion: null,
  notiPopup: false,
  notiSessions: [],
};

const ReservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RESERVATION_LIST: {
      return { ...state, motion: null, data: [...action.data] };
    }
    case types.GET_RESERVATION_ITEM: {
      return {
        ...state,
        registerType: action.registerType,
        isReservation: action.isReservation,
        motion: null,
      };
    }
    case types.ADD_RESERVATION_REQUEST: {
      return { ...state, error: null, motion: null };
    }
    case types.ADD_RESERVATION_SUCCESS: {
      return { ...state, isReservation: action.isReservation, motion: 'add' };
    }
    case types.ADD_RESERVATION_ERROR: {
      return { ...state, error: action.error };
    }
    case types.DELETE_RESERVATION_REQUEST: {
      return { ...state, error: null, motion: null };
    }
    case types.DELETE_RESERVATION_SUCCESS: {
      return {
        ...state,
        isReservation: action.isReservation,
        data: [...action.data],
        motion: 'delete',
      };
    }
    case types.DELETE_RESERVATION_ERROR: {
      return { ...state, error: action.error };
    }
    case types.RESET_RESERVATION_MOTION: {
      return { ...state, motion: null };
    }
    case types.OPEN_RESERVATION_NOTI_DATA: {
      return {
        ...state,
        notiPopup: true,
        notiSessions: [...action.notiSessions],
      };
    }
    case types.CLOSE_RESERVATION_NOTI_DATA: {
      return { ...state, notiPopup: false, notiSessions: [] };
    }
    default:
      return state;
  }
};

export default ReservationReducer;
