import styled from 'styled-components';

export const ActivityContainer = styled.div`
  background-color: ${(props) =>
    props.type === 'bingo' ? '#2088fd' : '#ca6ceb'};
  margin-top: 48px;
  padding: ${(props) => (props.main ? '' : '16px 0 50px')};
`;

export const ActivityHero = styled.div`
  position: relative;
  height: 318px;
  padding: 0 24px;

  .text-area {
    position: relative;
    padding-top: 64px;
    text-align: center;
    color: #fff;
    img {
      display: block;
      width: 66px;
      margin: 0 auto;
    }
    img + p {
      margin-top: 6px;
      font-family: 'samsungsharpsans-bold';
      font-size: 32px;
      line-height: 42px;
    }
    p + p {
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
    }
    .Button {
      margin-top: 28px;
    }
  }
`;

export const ActivityHeroBg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  span {
    display: block;
    position: absolute;
    background-repeat: no-repeat;
  }
  span:nth-of-type(1) {
    left: 0;
    top: 171px;
    width: 135px;
    height: 132px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-bingo-hero-bg-ico-1-1.svg`});
    .codelab & {
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-codelab-hero-bg-ico-1-1.svg`});
    }
  }
  span:nth-of-type(2) {
    right: 0;
    top: 0;
    width: 188px;
    height: 40px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-bingo-hero-bg-ico-2.svg`});
    .codelab & {
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-codelab-hero-bg-ico-2.svg`});
    }
  }
  span:nth-of-type(3) {
    right: 29px;
    top: 198px;
    width: 39px;
    height: 25px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-bingo-hero-bg-ico-3.svg`});
    .codelab & {
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-codelab-hero-bg-ico-3.svg`});
    }
  }
`;

export const ActivityBingoCont = styled.div`
  position: relative;
`;

export const ActivityTab = styled.div`
  position: sticky;
  top: 56px;
  margin: 8px 24px 0;
  padding: 5px;
  display: flex;
  border-radius: 27px;
  background-color: #f6f6f6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  z-index: 9;

  a {
    display: inline-flex;
    flex: 1 1 0;
    height: 44px;
    font-family: 'SamsungOne-700';
    font-weight: 700;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    color: #000;
    border-radius: 22px;
    &.active {
      background-color: #fff;
      color: #2088fd;
    }
  }
`;

export const ActivityScore = styled.div`
  position: relative;
  margin: 14px 24px 0;
  padding: 30px 24px 24px;
  background-color: #fff;
  border-radius: 14px;
  &:before {
    content: '';
    position: absolute;
    right: 13px;
    top: 19px;
    width: 131px;
    height: 105px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-bingo-score-img.png`});
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.code-lab:before {
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-codelab-score-img.v2.png`});
  }
  > p:first-of-type {
    font-family: 'samsungsharpsans-medium';
    font-size: 14px;
    line-height: 20px;
    width: 144px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  > p:first-of-type + p {
    margin-top: 2px;
    font-family: 'samsungsharpsans-bold';
    font-size: 20px;
    line-height: 28px;
  }
  .progress {
    margin-top: 18px;
    .progress-text {
      font-family: 'SamsungOne-700';
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #9e9e9e;
      em {
        font-style: normal;
        color: #2088fd;
      }
    }
    .progress-bar {
      position: relative;
      margin-top: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #d1d1d1;
      overflow: hidden;
      .bar {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: #2088fd;
        border-radius: 3px;
      }
    }
  }
  .text-info {
    position: relative;
    margin-top: 14px;
    font-size: 12px;
    line-height: 16px;
    color: #9e9e9e;
    padding-left: 22px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-notice.svg`});
    }
  }
  .desc {
    margin-top: 20px;
    padding: 18px 14px 22px 14px;
    font-family: 'SamsungOne-600';
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    border-radius: 12px;
    background: #2088fd;
    text-align: center;
  }
  .ticket {
    margin-top: 24px;
    padding: 12px;
    border-radius: 12px;
    background: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #9e9e9e;
    p {
      display: flex;
      align-items: center;
      font-family: 'SamsungOne-700';
      font-size: 14px;
      line-height: 20px;
      &:before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background-image: url(${`${process.env.PUBLIC_URL}/images/ico-lucky-draw-ticket.svg`});
        margin-right: 8px;
      }
    }
    em {
      display: inline-block;
      position: relative;
      padding-left: 23px;
      margin-right: 12px;
      font-family: 'SamsungOne-700';
      font-size: 14px;
      line-height: 20px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 5px;
        width: 1px;
        height: 10px;
        background-color: #9e9e9e;
      }
    }

    &.on {
      background: rgba(32, 136, 253, 0.12);
      color: #2088fd;
      p:before {
        background-image: url(${`${process.env.PUBLIC_URL}/images/ico-lucky-draw-ticket.on.svg`});
      }
      em:before {
        background-color: #2088fd;
      }
    }
  }
`;

export const ActivityBingoBoard = styled.div`
  position: relative;
  margin: 31px auto 0;
  padding-bottom: 50px;
  width: 312px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 6px;
`;

export const ActivityBingoLine = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  hr {
    display: block;
    position: absolute;
    left: 37px;
    top: 27px;
    width: 238px;
    height: 20px;
    background-color: #fff;
    margin: 0;
    border: 0;
    transform-origin: left top;
    &.line-2 {
      top: 106px;
    }
    &.line-3 {
      top: 185px;
    }
    &.line-4 {
      top: 264px;
    }
    &.line-5,
    &.line-6,
    &.line-7,
    &.line-8 {
      left: 27px;
      top: 37px;
      height: 238px;
      width: 20px;
    }
    &.line-6 {
      left: 106px;
    }
    &.line-7 {
      left: 185px;
    }
    &.line-8 {
      left: 264px;
    }
    &.line-9 {
      left: 37px;
      top: 26px;
      width: 340px;
      height: 18px;
      transform: rotate(45deg);
    }
    &.line-10 {
      left: auto;
      right: 37px;
      top: 26px;
      transform-origin: right top;
      width: 340px;
      height: 18px;
      transform: rotate(315deg);
    }
  }
`;
export const ActivityBingoItem = styled.div`
  position: relative;
  width: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    position: relative;
    font-family: 'SamsungOne-700';
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    text-align: center;
  }
  .bg {
    position: absolute;
    left: -13px;
    top: -13px;
    width: 100px;
    height: 100px;
  }
  .num {
    position: absolute;
    left: -1px;
    top: -1px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'SamsungOne-700';
    font-weight: 700;
    font-size: 10px;
    line-height: 1;
    color: #0f42cf;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  }

  &.completed {
    .title {
      display: none;
    }
    .num {
      color: #fff;
      background-color: #0f42cf;
    }
    &:after {
      content: '';
      position: absolute;
      left: 7px;
      top: 7px;
      display: block;
      width: 60px;
      height: 60px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/activity-bingo-complete.png`});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`;

export const ActivityPrecaution = styled.div`
  padding: 32px 24px;
  background-color: #fff;

  p {
    font-family: 'samsungsharpsans-bold';
    font-size: 18px;
    line-height: 20px;
  }
  hr {
    display: block;
    border: 0;
    margin: 20px 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
  }
  ul {
    li {
      position: relative;
      padding-left: 13px;
      font-family: 'SamsungOne-600';
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #7c7c7c;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 8px;
        width: 4px;
        height: 4px;
        background-color: #7c7c7c;
        border-radius: 2px;
      }
    }
    li + li {
      margin-top: 18px;
    }
  }
`;
export const HowToPlayPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);

  .inner-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 48px);
    padding: 20px 24px 22px;
    border-radius: 16px;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    box-sizing: border-box;
    text-align: center;
  }

  .slick-dots {
    bottom: -48px;
  }

  .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0 8px;

    + li {
      margin-left: 0;
    }
  }

  .slick-dots li button:before {
    width: 8px;
    height: 8px;
    font-size: 0;
    background-color: #d9d9d9;
    border-radius: 100%;
    transition: width 0.3s;
  }

  .slick-dots li.slick-active button:before {
    background-color: #2088fd;
  }
`;

export const HowToPlayStepItem = styled.div`
  position: relative;
  height: 340px;
  img {
    display: block;
    width: 272px;
    max-width: 100%;
    margin: auto;
  }
  img + p {
    margin-top: 14px;
    font-family: 'samsungsharpsans-bold';
    font-size: 24px;
    line-height: 32px;
  }
  p + p {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
  }
  .Button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
`;
