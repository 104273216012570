import styled from 'styled-components';

export const ListCard = styled.div`
  position: relative;
  border-radius: 24px;
  background: #fff;

  & + & {
    margin-top: 16px;
  }

  &.none {
    display: none;
  }

  @media screen and (max-width: 768px) {
  }
`;

export const ListCardInner = styled.div`
  padding: 32px;
  border-radius: 24px;
  background: #fff;

  @media screen and (max-width: 768px) {
    padding: 24px;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const ListCardTime = styled.div`
  // width: calc(11.39% - 32px);
  color: #7c7c7c;
  font-family: 'SamsungOne-700';
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  margin-right: 32px;
  margin-top: 2px;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-top: 16px;
    font-size: 14px;
  }
`;

export const InfoWrap = styled.div`
  max-width: 950px;
  width: 88.61%;
  margin-right: 60px;

  @media screen and (max-width: 768px) {
    max-width: none;
    width: 100%;
    margin-right: 0;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const ListCardTitle = styled.div`
  color: #000;
  font-family: 'samsungsharpsans-bold';
  font-size: 20px;
  line-height: 1.4;
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 1.25;
    margin-right: 0;
    margin-top: 12px;
  }
`;

export const ListCardTag = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  border-radius: 18px;
  backdrop-filter: blur(13.59px);
  background: rgba(0, 0, 0, 0.2);
  padding: 2px 10px;
  white-space: nowrap;

  span {
    color: #fff;
    font-family: 'SamsungOne-700';
    font-size: 14px;
    line-height: 1.42;
    text-transform: uppercase;
  }

  @media screen and (max-width: 768px) {
  }
`;

export const ListCardDesc = styled.div`
  color: #363636;
  font-family: 'SamsungOne-400';
  font-size: 14px;
  line-height: 1.42;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SessionContMark = styled.div`
  width: 32px;
  height: 32px;
  background-image: url(${`${process.env.PUBLIC_URL}/images/ico-card-delete.svg`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;

  &:hover {
    background-image: url(${`${process.env.PUBLIC_URL}/images/Delete_icon_hover.svg`});
  }

  @media screen and (max-width: 768px) {
    width: 24px;
    height: 24px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/Delete_icon_24.svg`});

    &:hover {
      background-image: url(${`${process.env.PUBLIC_URL}/images/Delete_icon_hover_24.svg`});
    }
  }
`;
export const CardDeletemark = styled.button`
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translate(0, -50%);
  z-index: 10;
  @media screen and (max-width: 768px) {
    top: 24px;
    right: 24px;
    transform: none;
  }
`;

// Loading
export const LoadingCard = styled.div`
  border-radius: 24px;
  background-color: #fff;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
    border-radius: 16px;
  }
`;

export const LoadingCardInner = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const LoadingCardTop = styled.div`
  position: relative;
  background-color: #eaeaea;
  border-radius: 14px 14px 0 0;
  &:after {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }
  @media screen and (max-width: 768px) {
    &:after {
      padding-bottom: 56.25%;
    }
  }
`;

export const LoadingCardTopInner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  transition: 0.3s;
  object-fit: cover;
  border-radius: 24px 24px 0 0;
  @media screen and (max-width: 768px) {
    border-radius: 16px 16px 0 0;
  }
`;

export const LoadingCardIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    animation-name: blink;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    & + .dot {
      margin-left: 10px;
    }
    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.08;
    }
    25% {
      opacity: 0.14;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.08;
    }
  }
`;

export const LoadingCardBottom = styled.div`
  width: 100%;
  padding: 24px;
  background-color: #fff;
  border-radius: 0 0 14px 14px;
  box-sizing: border-box;
  div {
    width: 100%;
    height: 18px;
    border-radius: 4px;
    background-color: #f6f6f6;
    & + div {
      margin-top: 4px;
    }
    &:last-of-type {
      width: 50%;
      margin-top: 16px;
      height: 36px;
    }
  }
  @media screen and (max-width: 768px) {
    div {
      height: 18px;
      & + div {
        margin-top: 4px;
      }
      &:last-of-type {
        height: 32px;
        margin-top: 16px;
      }
    }
  }
`;
