import styled, { keyframes } from 'styled-components';

export const KeynoteContContainer = styled.div`
  position: relative;
  padding: 100px 0 60px 0;
  background-color: #fff;

  &.is-released {
    padding: 56px 0 60px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 24px 0 20px 0;

    &.is-released {
      padding: 24px 0 20px 0;
    }
  }
`;

export const KeynoteContInner = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;

  .is-session {
    .tag_wrap {
      position: relative;
      display: flex;
      margin: 24px -5px -5px -5px;
      width: 100%;

      @media screen and (max-width: 768px) {
        overflow: scroll;
        margin: 16px -5px -5px -5px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }

  /* tag in KeynoteContText */
  .tag_wrap {
    display: none;
    position: absolute;
  }
`;

export const KeynoteContVideo = styled.div`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  z-index: 2;
  &:after {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }
  &.dim:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => (props.released ? '#000' : '#fff')};
    width: 100%;
    height: 100%;
  }

  //Released SessionPage style
  .KeynoteBg {
    position: absolute;
    top: 0;
    left: 0;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: rgba(0, 0, 0, 0.3);
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &.is-live {
    .KeynoteBg {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &.is-beforeLive {
    background-color: #0c35a6;
    border-radius: 36px;
    max-height: 420px;
    img {
      position: absolute;
    }
    .bg-assets-1 {
      left: 0;
      top: 80px;
      width: 140px;
    }
    .bg-assets-2 {
      left: 145px;
      top: 280px;
      width: 158px;
    }
    .bg-assets-3 {
      right: -20px;
      bottom: 0;
      width: 400px;
      z-index: 1;
    }
    .bg-assets-4 {
      left: 772px;
      top: 0;
    }
    .bg-assets-5 {
      left: 1050px;
      top: 220px;
    }
  }

  //Released KeynotePage style
  &.is-released {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 40px;
    background-color: #000;
    border-radius: 0;
    box-sizing: border-box;
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }

  &.is-ondemand {
    + .KeynoteContTitle {
      padding: 32px 0 0 0;
    }
    &:before {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    border-radius: 14px;
    &:after {
      padding-bottom: 53.521%;
    }
    .react-player iframe {
      border-radius: 14px !important;
    }
    &.is-beforeLive {
      &.is-beforeLive {
        background-color: #0c35a6;
        border-radius: 12px;
        max-height: 420px;
        img {
          position: absolute;
        }
        .bg-assets-1 {
          left: 0;
          top: 23%;
          width: 13%;
        }
        .bg-assets-2 {
          left: 13%;
          top: 77%;
          width: 14%;
        }
        .bg-assets-3 {
          right: -1%;
          bottom: 0;
          width: 44%;
          z-index: 1;
        }
        .bg-assets-4 {
          right: 0;
          top: 0;
          left: initial;
          bottom: initial;
          width: 48%;
        }
        .bg-assets-5 {
          right: 5%;
          bottom: 33%;
          top: initial;
          left: initial;
          width: 11%;
        }
      }
    }
  }
`;

export const KeynoteContVideoInner = styled.div`
  position: relative;
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  &:after {
    content: '';
    display: block;
    padding-bottom: 52.737%;
  }
  @media screen and (max-width: 768px) {
    &:after {
      content: '';
      display: block;
      padding-bottom: 61.455%;
    }
  }
`;

export const KeynoteContVideoBox = styled.div`
  margin-top: -100px;
  &:after {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }
  @media screen and (max-width: 1350px) {
    //rerelased 일경우 KeynoteCont 의 padding값 만큼 마진 조정
    &:after {
      content: '';
      display: block;
      padding-bottom: 52.737%;
    }
  }
  @media screen and (max-width: 768px) {
    &:after {
      content: '';
      display: block;
      padding-bottom: calc(61.455% - 20px);
    }
    margin-top: -54px;
  }
`;

export const KeynoteContSoon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  vertical-align: middle;
  &.play-btn {
    width: 90px;
    height: 90px;
    backdrop-filter: blur(13.591408729553223px);
    border-radius: 50%;
  }
  &.has-dim {
    &:after {
      content: '';
      // position: absolute;
      // top: 0;
      // left: 0;
      // display: block;
      // width: 100%;
      // height: 100%;
      // background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .title {
    position: relative;
    font-family: 'samsungSharpSans-bold';
    font-size: 54px;
    line-height: 70px;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    z-index: 1;
  }
  .desc {
    margin-top: 10px;
    font-family: 'SamsungSharpSans-Medium';
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .time {
    position: relative;
    margin-top: 12px;
    font-family: 'SamsungOne-700';
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    z-index: 1;
  }

  &.LiveVideo {
    .title {
      font-size: 40px;
      line-height: 1.38;
    }
  }
  @media screen and (max-width: 1200px) {
    .title {
      font-size: 42px;
      line-height: 54px;
    }
  }
  @media screen and (max-width: 800px) {
    .title {
      font-size: 36px;
      line-height: 48px;
    }
  }
  @media screen and (max-width: 768px) {
    &.play-btn {
      width: 40px;
      height: 40px;
      backdrop-filter: blur(8.494630813598633px);
    }
    .play-btn {
      width: 40px;
      height: 40px;
    }
    .title {
      font-size: 20px;
      line-height: 20px;
    }
    .desc {
      margin-top: 2px;
      font-size: 14px;
      line-height: 1.43;
    }
    .time {
      margin-top: 6px;
      font-size: 14px;
      font-weight: 700;
    }
    &.LiveVideo {
      .title {
        font-size: 16px;
      }
    }
  }
`;

export const KeynoteContText = styled.div`
  &.is-session {
    margin-top: 28px;
    // display: none;
    // padding-top: 28px;
    // border-top: 1px solid #efefef;
  }

  @media screen and (max-width: 768px) {
    margin-top: 16px;
    
    &.is-session {
      // display: none;
      margin-top: 24px;
      padding-top: 4px;
    }

    .bookmark_tooltip {
      left: 0px;
      transform: none;
      &:after {
        right: auto;
        left: 15px;
        transform: none;
      }
    }
`;

export const KeynoteContTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 56px 0 0 0;

  &.is-session {
    margin: 56px 0 0 0;
    padding: 0;
  }
  &.is-released {
    padding: 56px 0 0 0;
    pointer-events: none;
  }
  &.phase35 {
    padding: 100px 0 0 0;
  }

  .title {
    font-family: 'samsungSharpSans-bold';
    font-size: 42px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000;
  }

  @media screen and (max-width: 768px) {
    padding: 36px 0 0 0;
    &.is-released {
      padding: 36px 0 0 0;
    }
    &.is-session {
      margin: 20px 0 0 0;
      padding: 0;
    }
    .title {
      font-size: 24px;
      line-height: 1.34;
    }
    &.phase35 {
      padding: 48px 0 0 0;
    }
  }
`;

export const KeynoteContMark = styled.button`
  width: 34px;
  height: 34px;
  background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon_black.svg`});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &:active {
    background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon_hover_black.svg`});
  }
  &.Bookmark-checked {
    background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon_checked_black.svg`});
  }
  @media screen and (min-width: 769px) {
    // &:active:not(.Bookmark-checked) {
    //     background-image: url(${`${process.env.PUBLIC_URL}/images/sdc24-Bookmark_icon.svg`});
    // }
  }
  @media screen and (max-width: 768px) {
    width: 26px;
    height: 26px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon_mo-black.svg`});
    &.Bookmark-checked {
      background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon_mo-black-checked.svg`});
    }
    &:active {
      background-image: url(${`${process.env.PUBLIC_URL}/images/Bookmark_icon_mo-black-hover.svg`});
    }
  }
`;

export const KeynoteContDesc = styled.div`
  p {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    color: #1e1e1e;
  }
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #1e1e1e;
  &.live {
    margin-top: 32px;
  }
  @media screen and (max-width: 768px) {
    p {
      font-size: 14px;
      line-height: 24px;
    }
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    &.live {
      margin-top: 20px;
    }
  }
`;

export const KeynoteContLink = styled.a`
  position: relative;
  display: inline-block;
  padding: 0 36px;
  font-family: 'SamsungOne-700';
  font-size: 18px;
  font-weight: bold;
  margin-top: 28px;
  line-height: 56px;
  color: #fff;
  letter-spacing: normal;
  border-radius: 36px;
  background-color: #1e1e1e;
  @media screen and (min-width: 769px) {
    transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    &:hover {
      background-color: #535353;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 20px;
    font-size: 16px;
    line-height: 48px;
    margin-top: 12px;
  }
`;

export const KeynoteContTag = styled.div`
  display: none;
`;

export const KeynoteContLike = styled.button`
  position: relative;
  width: 34px;
  height: 34px;
  background-image: url(${`${process.env.PUBLIC_URL}/images/sdc24-ico-like.svg`});
  background-repeat: no-repeat;
  background-size: 26px 23px;
  background-position: center;
  &.is-active {
    background-image: url(${`${process.env.PUBLIC_URL}/images/sdc24-ico-like-active.svg`});
  }
  @media screen and (min-width: 769px) {
    &:active:not(.is-active) {
      background-image: url(${`${process.env.PUBLIC_URL}/images/sdc24-ico-like-hover.svg`});
      .like_tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 26px;
    height: 26px;
    background-position: center;
    background-size: cover;
    background-image: url(${`${process.env.PUBLIC_URL}/images/sdc24-ico-like.svg`});
    background-size: 19px 17px;
    &.is-active {
      background-image: url(${`${process.env.PUBLIC_URL}/images/sdc24-ico-like-active.svg`});
    }
    .like_tooltip {
      display: none;
    }
  }
`;

export const KeynoteContUtil = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin-top: 16px;

  .btn {
    position: relative;
    display: flex;
    align-items: center;
  }

  .util-btn-num {
    padding-left: 8px;
    color: #1e1e1e;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8px;
    &.mt0 {
      margin-top: 20px;
    }

    .KeynoteContLink {
      order: 1;
    }

    .btn {
    }
    .btn-calendar {
      margin: 0 auto;
    }
  }
`;

const circleFade = keyframes`
  0% {
    opacity: 0.2;
  }
  100%{
    opacity: 1;
  }
`;

export const KeynoteContType = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 5px 0 5px;
  padding-right: 12px;
  &:after {
    content: '';
    position: absolute;
    top: 8px;
    right: 3px;
    display: block;
    width: 1px;
    height: 20px;
    background-color: #efefef;
  }

  .liveTag,
  .type {
    display: inline-block;
    padding: 8px 16px;
    font-family: 'SamsungOne
    border-radius: 18px;
    background-color: #efefef;
    color: #7c7c7c;
    white-space: nowrap;
  }

  .liveTag {
    display: none;
  }

  &.released {
    display: flex;
    align-items: center;
    .liveTag {
      display: block;
      margin-right: 8px;
      padding: 8px 12px;
      color: #fff;
      background-color: #fa5347;
      &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 6px;
        background-color: #fff;
        border-radius: 100%;
        animation: ${circleFade} 1s infinite linear alternate;
      }
    }

    .type {
      position: relative;
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width: 768px) {
    margin: 0 0 0 0;
  }
`;

export const KeynoteBtnBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 768px) {
    gap: 5px;
  }
`;

export const KeynotebtnWrap = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    &.block {
      display: block;
    }
  }
`;

export const KeynoteMore = styled.button`
  position: relative;
  margin: 16px auto 0;
  padding-right: 26px;
  font-family: 'SamsungOne-700';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #363636;
  &:after {
    content: '';
    position: absolute;
    top: 3px;
    right: 0;
    width: 20px;
    height: 20px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-more.svg`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
  }
  &.is-appended:after {
    transform: rotate(180deg);
  }

  @media screen and (max-width: 768px) {
    margin: 12px auto 0;
    padding-right: 20px;
    font-size: 14px;
    line-height: 20px;
    &:after {
      content: '';
      top: 2px;
      width: 16px;
      height: 16px;
    }
  }
`;

export const SessionDesc = styled.div`
  display: none;
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #535353;
  p {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    color: #535353;
  }
  @media screen and (max-width: 768px) {
    p {
      font-size: 14px;
    }
    font-size: 14px;
    margin-top: 14px;
  }
`;

export const BookmarkTooltip = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 17px;
  top: -90px;
  z-index: 99;
  width: 210px;
  padding: 14px 40px 14px 14px;
  border-radius: 8px;
  transform: translateX(50%);
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.1);
  background-color: #2088fd;
  text-align: left;
≈
  line-height: 1.43;
  letter-spacing: -0.29px;
  color: #fff;
  box-sizing: border-box;
  transition: opacity 0.3s;
  z-index: 50;
  &:after {
    content: '';
    left: 50%;
    bottom: -12px;
    transform: translateX(-50%);
    position: absolute;
    border-top: 12px solid #2088fd;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
  }
  &.is-open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
  .close_btn {
    opacity: 0.7;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 16px !important;
    height: 16px !important;
    background-image: url(${`${process.env.PUBLIC_URL}/images/ico-close-grey.svg`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1370px) {
    right: -10px;
    transform: none;
    &:after {
      right: 15px;
      left: auto;
      transform: none;
    }
  }
  @media screen and (max-width: 768px) {
    &:after {
      right: 10px;
    }
  }
`;

export const KeynoteContLikeTooltip = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -62px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 130px;
  padding: 12px 14px;
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  background-color: #2088fd;
  box-sizing: border-box;
  font-family: 'SamsungOne-600';
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: -0.29px;
  color: #fff;
  transition: 0.3s;
  &:after {
    content: '';
    left: 50%;
    bottom: -12px;
    transform: translateX(-50%);
    position: absolute;
    border-top: 12px solid #2088fd;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
  }
`;

export const KeynoteTimeStamp = styled.div`
  position: relative;
  padding-top: 36px;
  @media screen and (max-width: 768px) {
  }
`;

export const KeynoteTimeStampTitle = styled.div`
  // padding-top: 28px;
  &.is-keynote {
    margin-top: 40px;
  }
  font-family: 'SamsungOne-700';
  font-size: 20px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    &.is-keynote {
      margin-top: 32px;
    }
  }
`;

export const KeynoteTimeStampWrap = styled.div`
  display: flex;
  align-items: top;

  .slick-list:before {
    opacity: 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 100%;
    background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
    z-index: 10;
    transition: 0.2s;
  }

  &.not-first {
    .slick-list:before {
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    &.not-first {
      .slick-list:before {
        opacity: 0;
      }
    }
  }
`;

export const KeynoteTimeStampItem = styled.div`
  padding: 0;
  box-sizing: border-box;
  .title {
    font-family: 'SamsungOne-700';
    font-size: 16px;
    line-height: 20px;
    color: #000;
    text-align: left;
  }

  .txt {
    color: #535353;
    text-align: left;
    line-height: 20px;
  }

  /* TimeStamp active */
  &.active {
    .title {
      font-family: 'SamsungOne-700';
      color: #000;
    }
    .thumb {
      &:after {
        content: '';
        background-color: transparent;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .title {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.43;
    }
  }
`;

export const KeynoteTimeStampImg = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const SessionRoomLink = styled.div`
  color: blue;
  @media screen and (max-width: 768px) {
  }
`;

export const KeynoteBadgeWrap = styled.div`
  margin-top: 12px;
  display: block;
  @media screen and (max-width: 768px) {
    margin-top: 8px;
  }
`;

export const KeynoteBadge = styled.div`
  display: inline-block;
  .inner {
    position: relative;
    padding: 8px 12px 8px 12px;
    border-radius: 18px;
    background-color: #fa5347;
    font-weight: 700;
    font-family: 'SamsungOne-700';
    &::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 6px;
      background-color: #fff;
      border-radius: 100%;
      animation: ${circleFade} 0.8s infinite linear alternate;
      margin-bottom: 0.6px;
    }
  }
  span {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #fff;
  }
  @media screen and (max-width: 768px) {
    .inner {
      padding: 6px 12px 6px 12px;
    }
  }
`;
