import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  UPDATE_SCHEDULE_CATEGORY_FILTER,
  UPDATE_SCHEDULE_TOPIC_FILTER,
  UPDATE_SCHEDULE_LOCATION_FILTER,
} from '../../redux/ActionTypes';
import withRouter from '../Router/withRouter';
import CheckBox from '../Checkbox';

const ButtonFilterBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 4px;
  width: calc(50% - 8px);
  border-radius: 8px;
  transition: 0.2s;
  &.single {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    &:hover {
      background-color: #f6f6f6;
    }
  }
  span {
    display: block;
    width: 100%;
  }

  label {
    display: block;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 6px 0;
  }
`;

const ButtonFilterText = styled.div`
  font-size: 16px;
  &.is-active {
  }
`;

const ButtonFilter = (props) => {
  const { location, searchParams, item, navigate, title, type } = props;
  const [isCheck, setIsCheck] = useState(false);
  const dispatch = useDispatch();

  const {
    checkedTopics: checkedTopic,
    checkedMainCategories: checkedMainCategory,
    checkedLocations: checkedLocation,
  } = useSelector((state) => state.schedule);

  const checkFilter = () => {
    if (
      (checkedMainCategory.length > 0 && checkedMainCategory.includes(item)) ||
      (checkedTopic.length > 0 && checkedTopic.includes(item)) ||
      (checkedLocation.length > 0 && checkedLocation.includes(item))
    ) {
      setIsCheck(true);
    } else {
      setIsCheck(false);
    }
  };

  const clickHandler = (e) => {
    const prevQuery = searchParams[0].getAll('filter');
    if (prevQuery.toString().split(',').includes(e.target.id)) {
      const newQuery = prevQuery
        .toString()
        .split(',')
        .filter((query) => query !== e.target.id);
      navigate(
        `${
          newQuery.length > 0 ? `?filter=${encodeURIComponent(newQuery)}` : ''
        }`
      );
    } else {
      const prevEncode = encodeURIComponent(...prevQuery);
      navigate(
        `${
          prevEncode === 'undefined'
            ? `?filter=${encodeURIComponent(e.target.id)}`
            : `?filter=${[prevEncode, encodeURIComponent(e.target.id)]}`
        }`
      );
    }

    setIsCheck(!isCheck);
    if (
      e.target.id === 'Device AI' ||
      e.target.id === 'SmartThings' ||
      e.target.id === 'Responsible AI' ||
      e.target.id === 'Advanced Tech' ||
      e.target.id === 'Enterprise & Ecosystem'
    ) {
      dispatch({
        type: UPDATE_SCHEDULE_CATEGORY_FILTER,
        checkedMainCategory: e.target.id,
      });
    } else if (
      e.target.id === 'Tech Session Room 1' ||
      e.target.id === 'Tech Session Room 2' ||
      e.target.id === 'Tech Session Room 3' ||
      e.target.id === 'Open Stage'
    ) {
      dispatch({
        type: UPDATE_SCHEDULE_LOCATION_FILTER,
        checkedLocations: e.target.id,
      });
    } else {
      dispatch({
        type: UPDATE_SCHEDULE_TOPIC_FILTER,
        checkedTopic: e.target.id,
      });
    }
  };

  // useEffect(() => {
  //   const filter = location.state?.filter;
  //   if (filter) {
  //     if (filter === 'Mega' && item === 'Mega') {
  //       setIsCheck(true);
  //     } else if (filter === 'Tech' && item === 'Tech') {
  //       setIsCheck(true);
  //     } else if (filter === 'Open Theater' && item === 'Open Theater') {
  //       setIsCheck(true);
  //     }
  //   } else if (searchParams[0].get('filter') !== null) {
  //     checkFilter();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (clearFilter) {
  //     setIsCheck(false);
  //   }
  // }, [clearFilter]);

  useEffect(() => {
    checkFilter();
  }, [checkedLocation, checkedTopic, checkedMainCategory]);

  return (
    <ButtonFilterBox className={type ? 'single' : ''}>
      <CheckBox
        id={item}
        label={item}
        onChange={clickHandler}
        checked={isCheck}
        value={title}
      />
    </ButtonFilterBox>
  );
};

export default withRouter(ButtonFilter);
