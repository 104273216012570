import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import RegisterErrorCont from '../../components/RegisterErrorCont';

const UnRegister = () => {
  return (
    <>
      <DetailContainer noMargin>
        <RegisterErrorCont errorTitle="Unregistration is complete." />
      </DetailContainer>
    </>
  );
};

export default UnRegister;
