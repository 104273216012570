import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListCard,
  ListCardInner,
  LoadingCard,
  LoadingCardInner,
  LoadingCardTop,
  LoadingCardTopInner,
  LoadingCardIcon,
  LoadingCardBottom,
  CardDeletemark,
  SessionContMark,
  ListCardTitle,
  ListCardTag,
  ListCardDesc,
  ContentWrap,
  TitleWrap,
  ListCardTime,
  InfoWrap,
} from './CardReservedElements';
import {
  DELETE_RESERVATION_REQUEST,
  RESET_RESERVATION_MOTION,
} from '../../redux/ActionTypes';

const DeleteCardItem = ({ setModal, setModalFalse, sessionId, type }) => {
  const [Delete, setDelete] = useState(false);
  const dispatch = useDispatch();

  const [cancelMeetupWindow, setCancelMeetup] = useState(null);
  useEffect(() => {
    if (!cancelMeetupWindow) return;

    const checkUrlChange = () => {
      if (cancelMeetupWindow.closed) {
        clearInterval(urlCheckInterval);
        window.location.reload();
      }
    };
    const urlCheckInterval = setInterval(checkUrlChange, 1000);

    return () => clearInterval(urlCheckInterval);
  }, [cancelMeetupWindow]);

  // const { motion } = useSelector((state) => state.reservationData);

  const onClickHandler = (e) => {
    e.preventDefault();
    if (type.toLowerCase() === 'meetup') {
      const newWindow = window.open(`/meetup/cancel`);
      setCancelMeetup(newWindow);
    } else {
      dispatch({
        type: DELETE_RESERVATION_REQUEST,
        sessionId,
        actionPage: 'mypage',
      });
    }
  };

  // useEffect(() => {
  //   if (motion && motion === 'delete') {
  //     setModalFalse(false);
  //     setModal(true);

  //     // reset motion value
  //     dispatch({ type: RESET_RESERVATION_MOTION });
  //   }
  // }, [motion]);

  return (
    <SessionContMark
      className={Delete ? 'delete-checked' : ''}
      onClick={onClickHandler}
    />
  );
};

// id : 아이디
// col : card 너비
// border : border 유무
// bookmark : 북마크 유무
// img : 이미지 URL
// title : 제목
// description : 내용
// video : 비디오인 경우
// tag : 태그
// type : 세션 타입
// listYn : 리스트 페이지인 경우
// setModal : 북마크 설정
// setModalFalse : 북마크 해제
// loading : 로딩 유무
// keynote : 키노트 유무
const CardReserved = ({
  id,
  col,
  border,
  listYn,
  setModal,
  setModalFalse,
  loading,
  deleteCard,
  title,
  description,
  type,
  tag,
  date,
  url,
  sessionId,
}) => {
  return (
    <ListCard
      className={loading ? `loading ${col}` : col}
      border={border}
      listYn={listYn}
    >
      {loading ? (
        <LoadingCard>
          <LoadingCardInner>
            <LoadingCardTop>
              <LoadingCardTopInner>
                <LoadingCardIcon>
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </LoadingCardIcon>
              </LoadingCardTopInner>
            </LoadingCardTop>
            <LoadingCardBottom>
              <div />
              <div />
              <div />
            </LoadingCardBottom>
          </LoadingCardInner>
        </LoadingCard>
      ) : (
        <ListCardInner>
          {/* 퍼블 : 동일한 세션 디테일 페이지로 이동시킴 */}
          <Link to={url || '#'}>
            <ContentWrap>
              <ListCardTime>{date}</ListCardTime>
              <InfoWrap>
                <TitleWrap>
                  <ListCardTitle>{title}</ListCardTitle>
                  <ListCardTag>
                    <span>{type}</span>
                  </ListCardTag>
                </TitleWrap>

                <ListCardDesc>{description}</ListCardDesc>
              </InfoWrap>
            </ContentWrap>
          </Link>

          {deleteCard ? (
            <CardDeletemark>
              <DeleteCardItem
                setModal={setModal}
                setModalFalse={setModalFalse}
                sessionId={sessionId}
                type={type}
              />
            </CardDeletemark>
          ) : null}
        </ListCardInner>
      )}
    </ListCard>
  );
};

export default CardReserved;
