/* eslint-disable radix */
import React, { useLayoutEffect, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/all';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import {
  PopupSurveyWrap,
  PopupSurveyContainer,
  PopupSurveyInner,
  PopupSurveyContent,
} from './PopupSurveyElement';
import { PopupClose } from '../Popup/PopupElements';
import ALink from '../ALink';

const PopupSurvey = ({ setOpenSurvey, surveyURL }) => {
  const tl = useRef();
  const SurveyRef = useRef();

  const closePopup = () => {
    setOpenSurvey(false);
    document.body.style.overflow = 'auto';
  };

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    tl.current = gsap
      // .timeline({
      //   scrollTrigger: {
      //     trigger: SurveyRef.current,
      //   },
      // })
      .fromTo(
        SurveyRef.current,
        { opacity: 0, y: 40 },
        { opacity: 1, y: 0, duration: 0.55, delay: 0.85 }
      );

    return () => {
      tl.current.kill();
    };
  }, []);

  return (
    <PopupSurveyWrap>
      <PopupSurveyContainer>
        <PopupSurveyContent ref={SurveyRef}>
          <PopupSurveyInner>
            <p>Did you enjoy SDC23?</p>
            <p>Take a moment to tell us about your experience.</p>
            <ALink
              className="btn"
              href={surveyURL}
              color="black"
              size="medium"
              fullWidth={false}
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                closePopup();
                window.open(surveyURL, '_blank');
              }}
            >
              Go to survey
            </ALink>
            <PopupClose onClick={closePopup} />
          </PopupSurveyInner>
        </PopupSurveyContent>
      </PopupSurveyContainer>
    </PopupSurveyWrap>
  );
};

export default PopupSurvey;
