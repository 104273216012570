import styled from 'styled-components';

export const TechSquare = styled.div`
  position: relative;
  padding-bottom: 120px;
  background-color: #ffffff;

  .react-tabs__tab-list {
    display: flex;
    width: calc(100% - 48px);
    align-items: flex-end;
    max-width: 1200px;
    margin: 0 auto 48px;
    padding: 0 40px;
    overflow: auto;
  }

  .react-tabs__tab {
    width: 50%;
    padding: 20px 0;
    font-family: 'samsungsharpsans-Medium';
    font-size: 18px;
    line-height: 1.44;
    color: #9e9e9e;
    border-bottom: 1px solid #ddd;
    text-align: center;
    cursor: pointer;
    &--selected {
      font-family: 'samsungsharpsans-bold';
      color: #2088fd;
      border-bottom: 2px solid #2088fd;
    }

    .hide-pc {
      display: none;
    }
  }

  @media screen and (max-width: 1160px) {
    .react-tabs__tab {
      font-size: 16px;
      line-height: 24px;
      padding: 15px 0;
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 48px;
    padding-top: 0;

    .react-tabs__tab-list {
      padding: 0 24px;
      margin: 0 auto 32px;
      white-space: nowrap;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 0;
        line-height: 0;
      }
    }

    .react-tabs__tab {
      width: 33.333%;
      flex: 0 0 auto;
      line-height: 20px;
      font-size: 14px;
      white-space: pre-wrap;
      &--selected {
        color: #2088fd;
        border-bottom: 2px solid #2088fd;
      }
      .hide-pc {
        display: block;
      }
    }
  }
`;

export const TechSquareTabCon = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const TechSquareTabConInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -12px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const CardMoreBtn = styled.div`
  margin-top: 40px;
  text-align: center;
  @media (max-width: 768px) {
  }
`;

export const EventTechSquareMap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    display: block;
    padding: 0 24px;
  }
`;

export const MapContainer = styled.div`
  position: relative;
  max-width: 1200px;
  padding: 43px 0 120px;
  overflow-x: hidden !important;

  @media screen and (max-width: 768px) {
    padding: 30px 0 60px;
  }
`;

export const EventTopUtil = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 79px 0 0;

  .link-prev {
    position: relative;
    padding-left: 32px;
    font-family: 'samsungOne-700';
    font-size: 20px;
    line-height: 1.3;
    color: #000;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-previous.svg`});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 86px auto 0;
    padding: 4px 0;

    .link-prev {
      display: flex;
      align-items: center;
      padding-left: 26px;
      font-size: 16px;
      line-height: 20px;
      &:before {
        content: '';
        top: 0;
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const MapWrap = styled.div`
  position: relative;
  display: flex;
  border-radius: 36px;
  background: #f6f6f6;
  height: auto;
  max-height: 675px;
  padding: 5px 61px 5px 61px;

  .fl-txt {
    width: 80px;
    font-family: 'samsungsharpsans-bold';
    font-size: 42px;
    line-height: 1.28;
    color: #000;
    margin-top: 40px;
    text-align: center;
  }

  @media (max-width: 768px) {
    display: block;
    padding: 25px 16px 16px 16px;
    height: auto;
    border-radius: 16px;
    max-height: none;

    .fl-txt {
      width: auto;
      font-size: 24px;
      line-height: 1.33;
      margin-top: 0;
      text-align: center;
      padding-bottom: 23px;
      margin-bottom: 16px;
      border-bottom: 1px solid #eaeaea;
    }
  }
`;

export const MapImage = styled.div`
  height: auto;
  padding: 0 59px 0 0;
  margin: 0 auto;

  .MapImageBox {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    max-width: 700px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 0;

    .MapImageBox {
      max-width: none;
    }

    .reset-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(9px);
      border-radius: 40px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 40px;
        height: 40px;
        background-image: url(${`${process.env.PUBLIC_URL}/images/ico-reset.svg`});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      &.is-active {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
`;
export const MapImageBox = styled.div`
  .react-transform-wrapper {
    width: 100% !important;
    height: 100% !important;
  }

  @media (max-width: 768px) {
    .map-img {
      width: 100%;
      height: 100%;
      padding-top: 40px;
      padding-bottom: 80px;
    }

    .reset-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.06);
      backdrop-filter: blur(9px);
      border-radius: 40px;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 40px;
        height: 40px;
        background-image: url(${`${process.env.PUBLIC_URL}/images/ico-reset.svg`});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      &.is-active {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

export const TechSquareTopBox = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 40px 7px 40px;

  @media screen and (max-width: 768px) {
    padding: 0;
    overflow: hidden;
  }
`;
export const TechSquareTopDetail = styled.div`
  .top-img-box {
    position: relative;
  }
  .top-img {
    width: 100%;
    border-radius: 36px;
    margin-bottom: 80px;
  }
  .top-ico {
    position: absolute;
    width: 90px;
    height: 90px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    backdrop-filter: blur(8.494630813598633px);
  }
  .top-title {
    font-size: 42px;
    font-family: 'samsungsharpsans-bold';
    font-weight: 600;
    line-height: 54px;
    margin-bottom: 24px;
  }
  .top-desc {
    color: #1e1e1e;
    line-height: 24px;
    font-family: 'SamsungOne-400';
    max-width: 1160px;
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    padding: 24px 24px 0 24px;
    width: calc(100% - 48px) !important;
    .top-img {
      width: 100%;
      margin-bottom: 32px;
      border-radius: 12px;
    }
    .top-ico {
      width: 48px;
      height: 48px;
    }
    .top-title {
      font-size: 24px;
      margin-bottom: 16px;
      line-height: 32px;
    }
    .top-desc {
      line-height: 20px;
      font-size: 14px;
    }
  }
`;
export const TechSquareBottomBox = styled.div`
  background-color: #f6f6f6;
  padding: 90px 0 110px 0;
  @media screen and (max-width: 768px) {
    padding: 40px 0 60px 0;
  }
`;
export const TechSquareBottomDetail = styled.div`
  max-width: 1200px;
  padding: 0 40px;
  margin: 0 auto;
  .bottom-title {
    font-size: 32px;
    font-family: 'samsungsharpsans-bold';
    font-weight: 600;
    margin-bottom: 32px;
  }
  .bottom-banner {
    width: 100%;
    background-color: #96d452;
    border-radius: 24px;
    min-height: 380px;
    position: relative;
    &.hovered {
      background-color: #84d239;
    }
    .banner-title {
      position: relative;
      z-index: 1;
      padding: 44px 48px;
      font-size: 34px;
      font-family: 'samsungsharpsans-bold';
      font-weight: 600;
    }
    .banner-desc {
      max-width: 657px;
      margin-top: 20px;
      color: rgba(0, 0, 0, 0.8);
      line-height: 24px;
      animation: slide-up 0.5s ease-out; /* 0.5초 동안 ease-out 효과로 애니메이션 실행 */
      opacity: 0; /* 초기 상태에서 투명하게 설정 */
      animation-fill-mode: forwards;
    }
    img {
      position: absolute;
    }
    .bottom-img-1 {
      width: 290px;
      height: auto;
      right: 180px;
      bottom: 52px;
    }
    .bottom-img-2 {
      right: 450px;
      bottom: 48px;
    }
    .bottom-img-3 {
      right: 0;
      bottom: 50px;
    }
    .bottom-rottie {
      position: absolute;
      cursor: pointer;
      display: flex;
      bottom: 48px;
      left: 48px;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      background-color: #00000040;
      border-radius: 50%;
      svg {
        display: block;
        width: 18px !important;
        height: 18px !important;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    .bottom-title {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .bottom-banner {
      border-radius: 16px;
      min-height: 282px;
      .banner-title {
        padding: 20px;
        font-size: 20px;
        font-family: 'SamsungOne-700';
        font-weight: 700;
      }
      .banner-desc {
        max-width: 272px;
        margin-top: 8px;
        color: #000000;
        line-height: 20px;
      }
      .bottom-img-1 {
        width: 138px;
        height: auto;
        right: 87px;
        bottom: 21px;
      }
      .bottom-img-2 {
        width: 40px;
        height: auto;
        right: 220px;
        bottom: 20px;
      }
      .bottom-img-3 {
        width: 94px;
        height: auto;
        right: 0;
        bottom: 20px;
      }
      .bottom-rottie {
        bottom: 20px;
        left: 20px;
        width: 32px;
        height: 32px;
        svg {
          width: 14px !important;
          height: 14px !important;
        }
      }
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(30%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
