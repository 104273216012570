import React from 'react';
import Lottie from 'lottie-react';
import ButtonLink from '../ButtonLink';
import { ActivityMsgContContainer } from './ActivityMsgContElements';
import pickupCompleteLottie from '../../lottie/pickup-complete.json';
import bingoCompleteLottie from '../../lottie/bingo-complete.json';
import codelabCompleteLottie from '../../lottie/codelab-complete.json';

const ActivityMsgCont = (props) => {
  const { msgTitle, msgDesc, buttonLink, buttonText, type } = props;
  const lottieData = {
    pickup: pickupCompleteLottie,
    bingo: bingoCompleteLottie,
    codelab: codelabCompleteLottie,
  };
  return (
    <ActivityMsgContContainer className={type}>
      <div className="text-area">
        <img
          src={`${process.env.PUBLIC_URL}/images/txt-sdc24${
            type !== 'error' ? '-white' : ''
          }.svg`}
          alt="SDC24"
        />
        <p dangerouslySetInnerHTML={{ __html: msgTitle }} />
        {msgDesc && <p>{msgDesc}</p>}
        <ButtonLink color="black" size="medium" url={buttonLink || '/'}>
          {buttonText || 'Go to SDC'}
        </ButtonLink>
      </div>
      {type !== 'error' && (
        <Lottie
          animationData={lottieData[type]}
          loop={false}
          className="motion"
        />
      )}
      <div className="copyright">Samsung Developer Conference 2024</div>
    </ActivityMsgContContainer>
  );
};

export default ActivityMsgCont;
