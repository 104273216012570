/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

const withRouter = (WrappedComponent) => {
  const WithRouterComponent = (props) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    return (
      <WrappedComponent
        {...props}
        params={params}
        location={location}
        navigate={navigate}
        searchParams={[searchParams, setSearchParams]}
      />
    );
  };

  return WithRouterComponent;
};

export default withRouter;
