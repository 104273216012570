// main > speaker list data

export const MainSpeakers = [
  {
    title: 'JH Han',
    description: 'Samsung Electronics',
    personImage: `${process.env.PUBLIC_URL}/images/MainSpeaker_1_23.png`,
    id: '10',
    hoverTitle: 'hoverTitlehoverTitlehoverTitle',
    hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
    subject: 'Keynote',
    blBg: false,
  },
  {
    title: 'Jaeyeon Jung',
    description: 'Samsung Electronics',
    personImage: `${process.env.PUBLIC_URL}/images/MainSpeaker_2_23.png`,
    id: '11',
    hoverTitle:
      'Building meaningful home experiences with enhanced partnerships',
    hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
    subject: 'Keynote',
    blBg: false,
  },
  {
    title: 'Cathy You',
    description: 'Aqara',
    personImage: `${process.env.PUBLIC_URL}/images/MainSpeaker_13_23.png`,
    id: '02',
    hoverTitle:
      'Creating a Seamless, Future-Ready Smart Home with Aqara & Samsung SmartThings',
    hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
    subject: 'Keynote',
    blBg: true,
  },
  {
    title: 'Anil Yadav',
    description: 'Samsung Electronics',
    personImage: `${process.env.PUBLIC_URL}/images/MainSpeaker_8_23.png`,
    id: '01',
    hoverTitle:
      'Bixby: The best, optimal voice platform for the Samsung ecosystem',
    hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
    subject: 'Keynote',
    blBg: false,
  },
  {
    title: 'Shin-Chul Baik',
    description: 'Samsung Electronics',
    personImage: `${process.env.PUBLIC_URL}/images/MainSpeaker_1_23.png`,
    id: '02',
    hoverTitle:
      'Providing a safe, secure & convenient cross-device environment',
    hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
    subject: 'Keynote',
    blBg: true,
  },
  {
    title: 'Hobum Kwon',
    description: 'Samsung Electronics',
    personImage: `${process.env.PUBLIC_URL}/images/MainSpeaker_1_23.png`,
    id: '03',
    hoverTitle: 'Tizen Reboot - for the next 10 years',
    hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
    subject: 'Keynote',
    blBg: true,
  },
  {
    title: 'Bongjun Ko',
    description: 'Samsung Electronics',
    personImage: `${process.env.PUBLIC_URL}/images/MainSpeaker_1_23.png`,
    id: '07',
    hoverTitle:
      'Tizen Screen -\nthe window connecting you to the world in every way',
    hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
    subject: 'Keynote',
    blBg: true,
  },
  {
    title: 'Sally Hyesoon Jeong',
    description: 'Samsung Electronics',
    personImage: `${process.env.PUBLIC_URL}/images/MainSpeaker_5_23.png`,
    id: '06',
    hoverTitle: 'Expanding the Galaxy experience with the new One UI',
    hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
    subject: 'Keynote',
    blBg: true,
  },
  {
    title: 'Hon Pak',
    description: 'Samsung Electronics',
    personImage: `${process.env.PUBLIC_URL}/images/MainSpeaker_12_23.png`,
    id: '08',
    hoverTitle:
      'Building a digital health ecosystem with home-centric connected care',
    hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
    subject: 'Keynote',
    blBg: true,
  },
  {
    title: 'Pattie Maes',
    description: 'MIT',
    personImage: `${process.env.PUBLIC_URL}/images/MainSpeaker_1_23.png`,
    id: '15',
    hoverTitle: 'Groundbreaking Research: Decoding Your Sleep',
    hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
    subject: 'Keynote',
    blBg: false,
  },
  {
    title: 'Nick Holzherr',
    description: 'Whisk, part of Samsung',
    personImage: `${process.env.PUBLIC_URL}/images/MainSpeaker_14_23.png`,
    id: '12',
    hoverTitle: 'Delivering the joy of cooking and eating with Food AI',
    hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
    subject: 'Keynote',
    blBg: true,
  },
];
