import React from 'react';
import ActivityMsgCont from '../../components/ActivityMsgCont';

const ActivityCodelabComplete = () => {
  return (
    <>
      <ActivityMsgCont
        type="codelab"
        msgTitle="Code Lab Task Complete!"
        msgDesc="Don't forget to claim a lucky draw ticket after completing each task
          in the Lab Zone. The lucky draw is exclusive to Lab participants, so
          make sure to join for a chance to win!"
        buttonLink="/activity/codelab"
        buttonText="Go to Code Lab"
      />
    </>
  );
};

export default ActivityCodelabComplete;
