import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import RegisterErrorCont from '../../components/RegisterErrorCont';

const RegConfirm = () => {
  const sendTwitterConversion = () => {
    if (process.env.REACT_APP_TWITTER_EVENT_ID && window.twq) {
      window.twq('event', process.env.REACT_APP_TWITTER_EVENT_ID, {
        value: null, // use this to pass the value of the conversion (e.g. 5.00)
      });
    }
  };

  const sendGoogleTagEvent = () => {
    if (process.env.REACT_APP_MEDIA_GTM_ID && window.gtag) {
      window.gtag('config', process.env.REACT_APP_MEDIA_GTM_ID);
      window.gtag('event', 'conversion', {
        send_to: `${process.env.REACT_APP_MEDIA_GTM_ID}/0C_bCI_dz9wYEKyFpqAB`,
      });
    }
  };

  sendTwitterConversion();
  sendGoogleTagEvent();

  return (
    <>
      <DetailContainer noMargin>
        <RegisterErrorCont
          errorTitle={`Thanks for registering.
          Please check your inbox for a confirmation email.`}
          errorDesc=""
          buttonText="Go to My Page"
          buttonLink="/mypage"
        />
      </DetailContainer>
    </>
  );
};

export default RegConfirm;
