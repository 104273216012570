import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { Get } from '../../utils/Requester';
import { markEventVersion } from '../../utils/Functions';
import * as types from '../ActionTypes';
import { speakerData } from '../../sample/SpeakerData';

export function* getSpeakers() {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield delay(3000);
      yield put({
        type: types.GET_SPEAKERS_LIST_SUCCESS,
        sessionSpeaker: speakerData,
      });
      return;
    }
    const { data, headers, error } = yield call(Get, {
      url: '/api/event/speaker',
    });

    if (error || typeof data === 'string') {
      console.log('error', error);
      yield put({
        type: types.GET_SPEAKERS_LIST_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
      return;
    }

    const { speakerList } = data;

    const sessionSpeaker = speakerList.sort((a, b) =>
      `${a.PresenterFirstName} ${a.PresenterLastName}` >
      `${b.PresenterFirstName} ${b.PresenterLastName}`
        ? 1
        : -1
    );

    if (headers['mark-event-version']) {
      markEventVersion(headers['mark-event-version']);
    }

    yield put({
      type: types.GET_SPEAKERS_LIST_SUCCESS,
      sessionSpeaker,
    });
  } catch (error) {
    if (error) {
      console.log('error', error);
      yield put({ type: types.GET_SPEAKERS_LIST_ERROR, error });
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_SPEAKERS_LIST_REQUEST, getSpeakers);
}
