import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { Get } from '../../utils/Requester';
import * as types from '../ActionTypes';
import { getUserMeetUpData } from './UserSessionSaga';

export function* getMeetupData() {
  try {
    // get meetup item
    const { data, error } = yield call(Get, {
      url: '/api/meetup/items',
    });

    const {
      data: sessionData,
      // headers,
      error: sessionError,
    } = yield call(Get, {
      url: `/api/event/workshop`,
    });

    if (
      error ||
      typeof data === 'string' ||
      sessionError ||
      typeof sessionError === 'string'
    ) {
      console.log('error', error);
      yield put({ type: types.GET_MEETUP_ERROR, error });
      return;
    }

    // login user > check to register type & has to meet up
    const { isLogin } = yield select((state) => state.userSession);
    if (isLogin) {
      const userMeetup = yield call(getUserMeetUpData); // get meetup data
      if (userMeetup) {
        yield put({
          type: types.UPDATE_USER_MEETUP,
          meetup: userMeetup.meetup,
          registerType: userMeetup.type,
          registrationId: userMeetup.registrationId,
        });
      }
    }

    yield put({
      type: types.GET_MEETUP_SUCCESS,
      data: data[0],
      sessionData: sessionData.session,
    });
  } catch (error) {
    console.log('error', error);
    yield put({ type: types.GET_CODELAB_ERROR, error });
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_MEETUP_REQUEST, getMeetupData);
}
