import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import SitemapCont from '../../components/SitemapCont';

const Sitemap = () => {
  return (
    <>
      <DetailContainer>
        <SitemapCont />
      </DetailContainer>
    </>
  );
};

export default Sitemap;
