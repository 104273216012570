import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import EventCLabCont from '../../components/EventSamsungCLabCont';

const EventSamsungCLab = () => {
  return (
    <DetailContainer>
      <EventCLabCont />
    </DetailContainer>
  );
};

export default EventSamsungCLab;
