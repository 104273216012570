import _ from 'lodash';
import React, { useState } from 'react';
import { useActivityData } from '../../hooks/useActivityData';
import { useActivityProfile } from '../../hooks/useActivityProfile';
import { ActivityMainInfo } from '../ActivityMain/ActivityMainElements';
import ActivityMyPoint from '../ActivityMyPoint';
import MyProfile from '../Myprofile';
import PopupPrize from '../PopupPrize';
import {
  ActivityHistory,
  ActivityHistoryContainer,
} from './ActivityPointContElements';

const prizeImage = {
  'Prize - Keychain': 'activity-prizes-keyring.png',
  'Prize - Ecobag': 'activity-prizes-bag.png',
  'Prize - Charging Tray': 'activity-prizes-charger.png',
  'Prize - Tumbler': 'activity-prizes-tumbler.png',
};

const ActivityPointCont = () => {
  const { data, points } = useActivityData();
  const { name, email, profileImage } = useActivityProfile();

  const activityData = _.filter(data, (e, k) => {
    return e.point;
  });

  const [viewState, setViewState] = useState('all');
  const [imgSource, setImgSource] = useState('');
  const [isPrizePopup, setIsPrizePopup] = useState(false);

  const openPopup = (key) => {
    setImgSource(prizeImage[key]);
    setIsPrizePopup(true);
  };
  return (
    <ActivityHistoryContainer>
      <ActivityMainInfo>
        <MyProfile name={name} mail={email} url={profileImage} />
        <ActivityMyPoint point={points} />
      </ActivityMainInfo>
      <ActivityHistory>
        <div className="top">
          <p>Point history</p>
          <div className="control">
            <button
              type="button"
              className={viewState === 'all' ? 'on' : ''}
              onClick={() => setViewState('all')}
            >
              All
            </button>
            <button
              type="button"
              className={viewState === 'save' ? 'on' : ''}
              onClick={() => setViewState('save')}
            >
              Save
            </button>
            <button
              type="button"
              className={viewState === 'use' ? 'on' : ''}
              onClick={() => setViewState('use')}
            >
              Use
            </button>
          </div>
        </div>
        <ul>
          {activityData &&
            activityData
              .filter((e) =>
                viewState === 'save'
                  ? e.point > 0
                  : viewState === 'use'
                  ? e.point < 0
                  : true
              )
              .sort((a, b) => {
                return new Date(b.timestamp) > new Date(a.timestamp) ? 1 : -1;
              })
              .map((item) => (
                <li
                  className={item.point > 0 ? 'save' : 'use'}
                  key={item.title}
                >
                  <p>
                    <span>{item.title}</span>
                    <em>
                      {item.point > 0 && '+'}
                      {item.point} P
                    </em>
                  </p>
                  <p>
                    <span>{item.timestamp}</span>
                    <span>
                      {item.point > 0 ? (
                        'Save'
                      ) : (
                        <button
                          type="button"
                          onClick={() => openPopup(item.title)}
                        >
                          Use
                        </button>
                      )}
                    </span>
                  </p>
                </li>
              ))}
        </ul>
      </ActivityHistory>
      {isPrizePopup && (
        <PopupPrize
          setOpen={setIsPrizePopup}
          imgSource={`${process.env.PUBLIC_URL}/images/activity-prizes/${imgSource}`}
        />
      )}
    </ActivityHistoryContainer>
  );
};

export default ActivityPointCont;
