import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useSelector } from 'react-redux';
import {
  SpeakersContainer,
  SpeakersInner,
  SpeakersWrap,
  SpeakersSection,
  SpeakersTitle,
} from './SpeakersContElements';
import CardSpeaker from '../CardSpeaker';
import Config from '../../Config';

const LoadingItem = () => {
  return [
    [...Array(8)].map((m, idx) => {
      return <CardSpeaker key={`speaker-loading-${idx}`} loading listYn />;
    }),
  ];
};

const SpeakersCont = () => {
  const { sessionSpeaker, isLoading } = useSelector(
    (state) => state.speakersList
  );

  // card interaction
  const tl = useRef();
  const tl2 = useRef();
  // const cardRef = useRef();
  const cardRef2 = useRef();

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      '(min-width: 768px)': function () {
        // const cardArray = cardRef.current
        //   ? gsap.utils.toArray(cardRef.current.children)
        //   : [];
        // cardArray.forEach((el) => {
        //   tl.current = gsap.timeline({
        //     scrollTrigger: {
        //       trigger: el,
        //       start: '0 98%',
        //       scrub: true,
        //       onEnter: () => {
        //         el.classList.add('is-active');
        //       },
        //     },
        //   });
        // });

        const cardArray2 = cardRef2.current
          ? gsap.utils.toArray(cardRef2.current.children)
          : [];
        cardArray2.forEach((el) => {
          tl2.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: '0 100%',
              scrub: true,
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
      '(max-width: 767px)': function () {
        // const cardArray = cardRef.current
        //   ? gsap.utils.toArray(cardRef.current.children)
        //   : [];
        // cardArray.forEach((el) => {
        //   tl.current = gsap.timeline({
        //     scrollTrigger: {
        //       trigger: el,
        //       start: '0 100%',
        //       scrub: true,
        //       onEnter: () => {
        //         el.classList.add('is-active');
        //       },
        //     },
        //   });
        // });

        const cardArray2 = cardRef2.current
          ? gsap.utils.toArray(cardRef2.current.children)
          : [];
        cardArray2.forEach((el) => {
          tl2.current = gsap.timeline({
            scrollTrigger: {
              trigger: el,
              start: 'top 100%',
              scrub: true,
              onEnter: () => {
                el.classList.add('is-active');
              },
            },
          });
        });
      },
    });

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
      // if (tl.current) {
      //   tl.current.kill();
      // }

      if (tl2.current) {
        tl2.current.kill();
      }

      ScrollTrigger.clearMatchMedia();
    };
  }, [isLoading]);

  return (
    <SpeakersContainer>
      <SpeakersInner>
        {/* <SpeakersSection>
          <SpeakersTitle>Keynote Speakers</SpeakersTitle>
          <SpeakersWrap ref={cardRef}>
            {isLoading ? (
              <LoadingItem />
            ) : (
              keynoteSpeaker &&
              keynoteSpeaker.map((item, idx) => (
                <CardSpeaker
                  key={idx}
                  listYn
                  id={item.PresenterID}
                  title={`${item.PresenterFirstName} ${item.PresenterLastName}`}
                  description={`${item.PresenterOrganization}`}
                  personImage={
                    item.PresenterPhotoFileName &&
                    !item.PresenterPhotoFileName.startsWith('http')
                      ? `${Config.CADMIUM_PHOTO_URL}/${item.PresenterPhotoFileName}`
                      : item.PresenterPhotoFileName
                  }
                  // hoverTitle
                  // blBg
                  subject="Keynote"
                  hover
                />
              ))
            )}
          </SpeakersWrap>
        </SpeakersSection> */}
        <SpeakersSection>
          <SpeakersTitle>Session Speakers</SpeakersTitle>
          <SpeakersWrap ref={cardRef2}>
            {isLoading ? (
              <LoadingItem />
            ) : (
              sessionSpeaker &&
              sessionSpeaker.map((item, idx) => (
                <CardSpeaker
                  key={idx}
                  listYn
                  id={item.PresenterID}
                  title={`${item.PresenterFirstName} ${item.PresenterLastName}`}
                  description={`${item.PresenterOrganization}`}
                  personImage={item.PresenterPhotoFileName}
                  hover={false}
                  blBg={item.blBg}
                  StaticIcon
                />
              ))
            )}
          </SpeakersWrap>
        </SpeakersSection>
      </SpeakersInner>
    </SpeakersContainer>
  );
};

export default SpeakersCont;
