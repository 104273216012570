import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DetailContainer from '../../components/DetailContainer';
import DetailHero from '../../components/DetailHero';
import ScheduleCont from '../../components/ScheduleCont';
import ScheduleMap from '../../components/ScheduleMap';
import { GET_SCHEDULE_SESSION_REQUEST } from '../../redux/ActionTypes';
import Loading from '../../components/Loading';

const Schedule = () => {
  // get schedule data
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_SCHEDULE_SESSION_REQUEST });
  }, [dispatch]);

  const { isLoading } = useSelector((state) => state.schedule);

  return (
    <>
      <DetailContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <DetailHero
              bgPc1="bg-schedule-banner-pc-1.svg"
              bgPc2="bg-schedule-banner-pc-2.svg"
              bgMo1="bg-schedule-banner-mo-1.png"
              bgMo2="bg-schedule-banner-mo-2.svg"
              bgColor="#10B77B"
              title="Schedule"
              path="Schedule"
              type="schedule"
            />
            <ScheduleCont />
            <ScheduleMap />
          </>
        )}
      </DetailContainer>
    </>
  );
};

export default Schedule;
