import styled from 'styled-components';

export const ActivityCodeLabList = styled.div`
  position: relative;
  margin: 30px 24px 53px;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -24px;
    top: 263px;
    width: 131px;
    height: 164px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-codelab-list-bg-1.svg`});
  }
  &:after {
    left: auto;
    right: -24px;
    top: 72px;
    width: 96px;
    height: 112px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/activity-codelab-list-bg-2.svg`});
  }
  ul {
    position: relative;
    z-index: 2;
  }
  li {
    position: relative;
    padding: 20px 24px;
    background-color: #fff;
    border-radius: 14px;
    .top {
      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        border-radius: 4px;
        text-align: center;
        font-family: 'SamsungOne-700';
        font-size: 14px;
        line-height: 100%;
        color: #fff;
        background-color: #1e1e1e;
      }
      span + span {
        margin-left: 4px;
        width: auto;
        padding: 0 8px;
        color: #2088fd;
        background-color: rgba(32, 136, 253, 0.2);
      }
    }
    .title {
      margin-top: 16px;
      font-family: 'samsungsharpsans-bold';
      font-size: 16px;
      line-height: 16px;
    }
    .desc {
      margin-top: 4px;
      color: #535353;
    }
    &.completed:after {
      content: 'Task Complete!';
      border-radius: 14px;
      border: 2px solid #fff;
      background: rgba(115, 0, 156, 0.82);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'samsungsharpsans-bold';
      font-size: 24px;
      line-height: 32px;
      color: #fff;
    }
  }
  li + li {
    margin-top: 8px;
  }
  .Button {
    display: flex;
    margin: 40px auto 0;
  }
`;
