import styled from 'styled-components';

export const PopupSurveyWrap = styled.div`
  position: relative;
`;

export const PopupSurveyContainer = styled.div`
  position: fixed;
  right: 32px;
  bottom: 24px;
  width: 520px;
  z-index: 98;

  @media (max-width: 768px) {
    width: calc(100% - 48px);
    right: auto;
    left: 50%;
    bottom: 16px;
    transform: translateX(-50%);
  }
`;

export const PopupSurveyContent = styled.div``;

export const PopupSurveyInner = styled.div`
  padding: 56px 40px 32px 40px;
  background-color: #fff;
  border-radius: 24px;
  text-align: center;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);

  p {
    font-family: 'SamsungOne-700';
    color: #000;
    font-size: 24px;
    line-height: 1.33;
    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  .aLink {
    height: 40px;
    font-size: 14px;
  }

  button {
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
  }

  .btn {
    font-size: 18px;
    height: 56px;
  }

  @media (max-width: 768px) {
    padding: 40px 24px 24px;
    border-radius: 16px;
    p {
      font-size: 20px;
      line-height: 1.3;
      &:last-of-type {
        margin-bottom: 32px;
      }
    }

    button {
      top: 14px;
      right: 14px;
      width: 20px;
      height: 20px;
    }

    .btn {
      min-width: 144px;
      font-size: 16px;
      height: 48px;
      line-height: 1.25;
    }
  }
`;
