import React from 'react';
import ActivityMsgCont from '../../components/ActivityMsgCont';

const OverlapError = () => {
  return (
    <>
      <ActivityMsgCont
        type="error"
        msgTitle="You can’t receive the same product again."
        buttonLink="/activity/point"
        buttonText="Go to Point history"
      />
    </>
  );
};

export default OverlapError;
