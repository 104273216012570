import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import TermPrivacyCont from '../../components/TermsPrivacyCont';
import TermPrivacyCont1 from '../../components/TermsPrivacyCont/version1';
import TermPrivacyCont2 from '../../components/TermsPrivacyCont/version2';
import TermPrivacyContBrazil from '../../components/TermsPrivacyContBrazil';
import TermPrivacyContBrazil1 from '../../components/TermsPrivacyContBrazil/version1';
import TermPrivacyContBrazil2 from '../../components/TermsPrivacyContBrazil/version2';
import TermPrivacyContEu from '../../components/TermsPrivacyContEu';
import TermPrivacyContEu1 from '../../components/TermsPrivacyContEu/version1';
import TermPrivacyContEu2 from '../../components/TermsPrivacyContEu/version2';
import TermPrivacyContKo from '../../components/TermsPrivacyContKo';
import TermPrivacyContKo1 from '../../components/TermsPrivacyContKo/version1';
import TermPrivacyContKo2 from '../../components/TermsPrivacyContKo/version2';
import TermPrivacyContLatin from '../../components/TermsPrivacyContLatin';
import TermPrivacyContLatin1 from '../../components/TermsPrivacyContLatin/version1';
import TermPrivacyContLatin2 from '../../components/TermsPrivacyContLatin/version2';
import TermPrivacyContTurkey from '../../components/TermsPrivacyContTurkey';
import TermPrivacyContTurkey1 from '../../components/TermsPrivacyContTurkey/version1';
import TermPrivacyContTurkey2 from '../../components/TermsPrivacyContTurkey/version2';
import TermsPrivacyContGlobal from '../../components/TermsPrivacyContGlobal';
import TermsPrivacyContGlobal1 from '../../components/TermsPrivacyContGlobal/version1';
import TermsPrivacyContGlobal2 from '../../components/TermsPrivacyContGlobal/version2';

import withRouter from '../../components/Router/withRouter';

function dropdownDateUrl(country) {
  const countryQuery = `/privacy${country ? `?country=${country}` : ''}`;
  return [
    `${countryQuery}`,
    `${countryQuery}&version=1`,
    `${countryQuery}&version=2`,
  ];
}

function dropdownBtnTextDate(country) {
  return country === 'ko'
    ? [
        '최종수정: 2024년 6월 27일',
        '시행일자: 2023년 8월 29일',
        '시행일자: 2022년 9월 14일',
      ]
    : [
        'Effective: June 27, 2024',
        'Oct 29, 2023 ~ June 26, 2024',
        'Sep 29, 2022 ~ Oct 28, 2023',
      ];
}

const Privacy = (props) => {
  const { navigate, location } = props;
  const country = new URLSearchParams(location.search).get('country');
  const version = new URLSearchParams(location.search).get('version');

  const componentMap = {
    global: {
      1: (
        <TermsPrivacyContGlobal1
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      2: (
        <TermsPrivacyContGlobal2
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      default: (
        <TermsPrivacyContGlobal
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
    },
    us: {
      1: (
        <TermPrivacyCont1
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      2: (
        <TermPrivacyCont2
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      default: (
        <TermPrivacyCont
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
    },
    eu: {
      1: (
        <TermPrivacyContEu1
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      2: (
        <TermPrivacyContEu2
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      default: (
        <TermPrivacyContEu
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
    },
    latin_america: {
      1: (
        <TermPrivacyContLatin1
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      2: (
        <TermPrivacyContLatin2
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      default: (
        <TermPrivacyContLatin
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
    },
    br: {
      1: (
        <TermPrivacyContBrazil1
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      2: (
        <TermPrivacyContBrazil2
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      default: (
        <TermPrivacyContBrazil
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
    },
    ko: {
      1: (
        <TermPrivacyContKo1
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      2: (
        <TermPrivacyContKo2
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      default: (
        <TermPrivacyContKo
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
    },
    tr: {
      1: (
        <TermPrivacyContTurkey1
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      2: (
        <TermPrivacyContTurkey2
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
      default: (
        <TermPrivacyContTurkey
          dropdownBtnTextDate={dropdownBtnTextDate}
          dropdownDateUrl={dropdownDateUrl}
        />
      ),
    },
  };

  const renderComponent = () => {
    if (country && componentMap[country]) {
      return componentMap[country][version] || componentMap[country].default;
    }
    return (
      <TermPrivacyCont
        dropdownBtnTextDate={dropdownBtnTextDate}
        dropdownDateUrl={dropdownDateUrl}
      />
    );
  };

  return (
    <>
      <DetailContainer>
        {renderComponent()}
        {/* {country ? (
          {
            global: <TermsPrivacyContGlobal />,
            us: <TermPrivacyCont />,
            eu: <TermPrivacyContEu />,
            latin_america: <TermPrivacyContLatin />,
            br: <TermPrivacyContBrazil />,
            ko: <TermPrivacyContKo />,
            tr: <TermPrivacyContTurkey />,
          }[country]
        ) : (
          <TermPrivacyCont />
        )} */}
      </DetailContainer>
    </>
  );
};

export default withRouter(Privacy);
