// Keynote Page
export const KeynoteData = {
  PresentationTitle: 'Keynote',
  AbstractText:
    '<p>Explore the future of a smarter, safer, and more connected lifestyle at SDC23! Join us for an exclusive worldwide livestream as we reveal the latest cutting-edge innovations from Samsung.</p>',
  PresentationDateTimeStart: '2023-10-05T14:50:00',
  SessionType: 'keynote',
  Presenters: [
    {
      PresenterID: '2454375',
      PresenterFirstName: 'JH',
      PresenterLastName: 'Han',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/main-speaker-1.png`,
      PresenterCustomField3: 'A look into the new AI era with Samsung',
      hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
      subject: 'Keynote',
      blBg: false,
    },
    {
      PresenterID: '2456243',
      PresenterFirstName: 'Daehyun',
      PresenterLastName: 'Kim',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/main-speaker-2.png`,
      PresenterCustomField3: 'Samsung’s strategic vision for AI',
      hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-2-1.svg`,
      subject: 'Keynote',
      blBg: false,
    },
    {
      PresenterID: '2454373',
      PresenterFirstName: 'Sally Hyesoon',
      PresenterLastName: 'Jeong',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/main-speaker-3.png`,
      PresenterCustomField3:
        'New experiences delivered by Galaxy AI / One UI 7Beta',
      hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-3-1.svg`,
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '2456247',
      PresenterFirstName: 'Moon-soo',
      PresenterLastName: 'Kim             ',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/main-speaker-4.png`,
      PresenterCustomField3:
        'Pioneering the market with new AI Display  Experience',
      hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-4-1.svg`,
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '2455678',
      PresenterFirstName: 'Alex Y',
      PresenterLastName: 'Lee',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/main-speaker-5.png`,
      PresenterCustomField3: 'VXT CMS platform with AI Technology',
      hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-5-1.svg`,
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '2456248',
      PresenterFirstName: 'Young Ah',
      PresenterLastName: 'Lee',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/main-speaker-6.png`,
      hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-6-1.svg`,
      PresenterCustomField3:
        'Innovating Home Appliance Experiences through Bespoke AI',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '2454371',
      PresenterFirstName: 'Ho-bum',
      PresenterLastName: 'Kwon',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/main-speaker-7.png`,
      hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-1-1.svg`,
      PresenterCustomField3:
        'Enhancing the Multi-Device Tizen Platform Experience with AI',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '2452266',
      PresenterFirstName: 'Jaeyeon',
      PresenterLastName: 'Jung',
      PresenterOrganization: 'Samsung Electronics',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/main-speaker-8.png`,
      hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-2-1.svg`,
      PresenterCustomField3:
        'SmartThings with AI can make your every day life extraordinary',
      subject: 'Keynote',
      blBg: true,
    },
    {
      PresenterID: '2454372',
      PresenterFirstName: 'Shin-Chul',
      PresenterLastName: 'Baik',
      PresenterOrganization: 'DPC',
      PresenterPhotoFileName: `${process.env.PUBLIC_URL}/images/main-speaker-9.png`,
      PresenterCustomField3: 'AI Ethics & Principles',
      hoverBg: `${process.env.PUBLIC_URL}/images/speaker-hover-bg-3-1.svg`,
      subject: 'Keynote',
      blBg: true,
    },
  ],
};
