import React from 'react';
import ActivityHeader from '../../components/ActivityHeader';
import ActivityCodeLabMain from '../../components/ActivityCodeLabMain';

const ActivityCodeLab = () => {
  return (
    <>
      <ActivityHeader isDropdown />
      <ActivityCodeLabMain />
    </>
  );
};

export default ActivityCodeLab;
