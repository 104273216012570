import React from 'react';
import ActivityHeader from '../../components/ActivityHeader';
import ActivityPointCont from '../../components/ActivityPointCont';

const ActivityBingoMap = () => {
  return (
    <>
      <ActivityHeader type="back" />
      <ActivityPointCont />
    </>
  );
};

export default ActivityBingoMap;
