import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ACTIVITY_REQUEST } from '../redux/ActionTypes';

/**
 * @typedef {{
 *  isLoading: boolean;
 *  error: any;
 *  data: Record<string, any> | null;
 *  points: number;
 * }} ActivityData
 */

/**
 * Hook for getting activity data from the server.
 *
 * @returns {ActivityData} Returns the activity data from the redux store
 */
export const useActivityData = () => {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.activity);

  useEffect(() => {
    dispatch({ type: GET_ACTIVITY_REQUEST });
  }, [dispatch]);

  return selected;
};
