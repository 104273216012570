import React from 'react';
import {
  MapContainer,
  MapInner,
  MapWrap,
  MapText,
  MapTitle,
  MapDesc,
  MapLink,
  MapImage,
} from './ScheduleMapElements';
import Section from '../Section';
import Button from '../Button';

const ScheduleMap = ({ className }) => {
  return (
    <MapContainer className={className}>
      <MapInner>
        <Section className="relatedSession" title="On-site Map">
          <MapWrap>
            <MapText>
              <MapTitle>SDC24 @ San Jose McEnery</MapTitle>
              <MapDesc>
                Know where to go - click below for a map of the event venue.
              </MapDesc>
            </MapText>
            <MapImage>
              <img
                src={`${process.env.PUBLIC_URL}/images/img-location-map.png`}
                alt="map"
              />
            </MapImage>
            <MapLink className={className}>
              <Button
                color="black"
                size="medium"
                onClick={() =>
                  window.open(
                    'https://maps.app.goo.gl/j86dedWzwcFJq2Hz7',
                    '_blank'
                  )
                }
              >
                View Detailed Map
              </Button>
            </MapLink>
          </MapWrap>
        </Section>
      </MapInner>
    </MapContainer>
  );
};

export default ScheduleMap;
