import React from 'react';
import ActivityHeader from '../../components/ActivityHeader';
import ActivityCodeLabPrizesContent from '../../components/ActivityCodeLabPrizesContent';

const ActivityCodeLabPrizes = () => {
  return (
    <>
      <ActivityHeader isDropdown />
      <ActivityCodeLabPrizesContent />
    </>
  );
};

export default ActivityCodeLabPrizes;
