import * as types from '../ActionTypes';

const initialState = {
  isLoading: false,
  error: null,
  data: {
    phase: null, // phase2, phase3, phase4
    // please add initial values for clearance of state structure
    main: {
      watchLive: null, // keynote / mega
      survey: {
        url: null, // survey URL
      },
    },
    keynote: {
      notiTime: null, // time when refresh pop-up is displayed
      liveBadgeStart: null, // time for live badge to be activated
      liveBadgeEnd: null, //  time for live badge to be deactivated
      videoStatus: null, // ready, open, closed, replay, on-demand, error
      url: null, // youtube url
    },
    mega: {
      notiTime: null, // time when refresh pop-up is displayed
      liveBadgeStart: null, // time for live badge to be activated
      liveBadgeEnd: null, //  time for live badge to be deactivated
      videoStatus: null, // ready, open, closed, replay, on-demand, error
      url: null, // youtube url
    },
    'on-demand': {
      videoStatus: null, // ready, open
    },
    keynoteLive: null, // prepare live, live, done
    sessionLive: null,
  },
  gapTime: 0,
};

const MetaData = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_META_LIVE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.GET_META_LIVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: { ...action.data },
        gapTime: action.gapTime,
        error: null,
      };
    case types.GET_META_LIVE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case types.MAIN_STATUS_UPDATE: {
      return {
        ...state,
        keynoteLive: action.data.keynoteLive,
        sessionLive: action.data.sessionLive,
      };
    }
    default:
      return state;
  }
};

export default MetaData;
