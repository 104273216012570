import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Link } from 'react-router-dom';
import {
  NewsLetterCon,
  NewsLetterInner,
  NewsLetterTitle,
  NewsLetterSubtitle,
  NewsLetterInnerContent,
  NewsLetterInput,
  NewsLetterAgree,
  LinkArea,
} from './NewsLetterElement';
import InputText from '../TextInput';
import CheckBox from '../Checkbox';
import Button from '../Button';
import Popup from '../Popup';
import ToastPopup from '../ToastPopup';
import { SUBSCRIBE_REQUEST } from '../../redux/ActionTypes';

const NewsLetter = () => {
  const [input, setInput] = useState('');
  const [checked, setChecked] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setmodalData] = useState({});
  const [click, setClick] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const isLoading = useSelector((state) => state.sessionList.isLoading);

  const onChangeInput = (e) => {
    setInput({ ...input, [e.target.id]: e.target.value });
  };

  const checkHandler = () => {
    setChecked(!checked);
  };

  const emailRegex =
    /^[0-9a-zA-Z]([-_.+]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  const preventHtmlRegex = /[&<>"']/;

  const subscribeResult = useSelector((state) => state.subscribeData);

  useEffect(() => {
    if (subscribeResult.error && !subscribeResult.isLoading && click) {
      if (subscribeResult.error.includes('already')) {
        setModal(true);
        setmodalData({
          title: 'Alert',
          desc: "Looks like you're already subscribed.",
        });
      } else {
        setModal(true);
        setmodalData({
          title: 'Error',
          desc: 'Something went wrong. \n Please try again.',
        });
      }
    } else if (!subscribeResult.error && !subscribeResult.isLoading && click) {
      setModal(true);
      setmodalData({
        title: 'Confirmed',
        desc: 'Thank you for the subscription',
      });
      setDisabled(true);
    }
  }, [subscribeResult]);

  const dispatch = useDispatch();

  const subscribe = () => {
    if (
      checked &&
      input.Email &&
      input.FirstName &&
      input.LastName &&
      !disabled
    ) {
      if (!emailRegex.test(input.Email)) {
        setEmailError(true);
        return;
      }
      if (
        preventHtmlRegex.test(input.FirstName) ||
        preventHtmlRegex.test(input.LastName)
      ) {
        setNameError(true);
        return;
      }
      setEmailError(false);
      dispatch({
        type: SUBSCRIBE_REQUEST,
        data: {
          email: input.Email,
          firstName: input.FirstName,
          lastName: input.LastName,
        },
      });
      setClick(true);
    }
  };

  // fade effect
  const MainNewsRef = useRef();
  const newsTl = useRef();

  const mainRef = useRef();
  const tl = useRef();
  const titleRef = useRef();
  const descRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    tl.current = gsap
      .timeline({
        scrollTrigger: {
          trigger: mainRef.current,
          scrub: 0.5,
          start: '25% 85%',
          end: 'center 70%',
        },
      })
      .fromTo(titleRef.current, { y: 30, opacity: 0 }, { y: 0, opacity: 1 })
      .fromTo(
        descRef.current,
        { y: 20, opacity: 0 },
        { y: 0, opacity: 1 },
        '-=0.05'
      )
      .fromTo(
        MainNewsRef.current,
        { opacity: '0' },
        { opacity: '1', duration: 1 }
      );

    return () => {
      // ScrollTrigger.getAll().forEach((t) => t.kill());
      tl.current.kill();
    };
  }, [isLoading]);

  return (
    <NewsLetterCon ref={mainRef}>
      <NewsLetterInner>
        <NewsLetterTitle ref={titleRef}>
          SDC24 & Developer Newsletter
        </NewsLetterTitle>
        <NewsLetterSubtitle ref={descRef}>
          Subscribe and Sign up to receive Newsletters and event updates first.
        </NewsLetterSubtitle>
        <NewsLetterInnerContent ref={MainNewsRef}>
          <NewsLetterInput>
            <div>
              <InputText
                label="Email Address"
                placeholder="Example@samsung.com"
                id="Email"
                onChangeText={onChangeInput}
                essential="true"
                error={emailError}
                disabled={disabled}
              />
            </div>
            <InputText
              label="First Name"
              placeholder="First Name"
              id="FirstName"
              onChangeText={onChangeInput}
              essential="true"
              disabled={disabled}
            />
            <InputText
              label="Last Name"
              placeholder="Last Name"
              id="LastName"
              onChangeText={onChangeInput}
              essential="true"
              disabled={disabled}
            />
          </NewsLetterInput>
          <NewsLetterAgree>
            <CheckBox
              id="chkAgree"
              checked={checked}
              onChange={checkHandler}
              disabled={disabled}
            />
            <span className="text">
              I agree that Samsung Developers may use the data given by me for
              receiving the newsletter.
            </span>

            <a
              href="https://developer.samsung.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              <span>Detail</span>
            </a>
          </NewsLetterAgree>
          <LinkArea>
            <Button
              color="borderTransparent"
              size="medium"
              className={
                checked &&
                input.Email &&
                input.FirstName &&
                input.LastName &&
                !disabled
                  ? null
                  : 'disabled'
              }
              onClick={subscribe}
            >
              Subscribe
            </Button>
          </LinkArea>
        </NewsLetterInnerContent>
      </NewsLetterInner>
      {/* newsletter popup */}
      {modal ? (
        <Popup
          modal={modal}
          setModal={setModal}
          title={modalData.title}
          desc={modalData.desc}
          btnText="Confirm"
        />
      ) : null}

      {/* newsletter email alert popup */}
      {emailError ? (
        <ToastPopup
          modal={emailError}
          setModal={setEmailError}
          state="warn"
          text="Maybe there's a typo. Please re-type or enter a different email address."
        />
      ) : null}
      {/* newsletter name error alert popup */}
      {nameError ? (
        <ToastPopup
          modal={nameError}
          setModal={setNameError}
          state="error"
          text={`Invalid name. Please remove special characters (such as <, >, &, ", and \') from the name fields.`}
        />
      ) : null}
    </NewsLetterCon>
  );
};

export default NewsLetter;
