import styled from 'styled-components';

export const MapContainer = styled.div`
  padding: 32px 0 24px;
  @media screen and (max-width: 768px) {
    padding: 40px 0 24px;
    display: block;

    &.activity-join {
      background-color: #ffffff;
      padding: 60px 0;
    }
  }
`;

export const MapInner = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const MapWrap = styled.div`
  position: relative;
  display: flex;
  border-radius: 18px;
  background-color: #10b77b;
  align-items: center;
  overflow: hidden;
  padding: 50px;

  .btn-link {
    display: none;
  }

  @media screen and (max-width: 768px) {
    display: block;
    padding: 20px;
    border-radius: 16px;
    min-height: 310px;
    .btn-link {
      display: block;
      position: absolute;
      width: calc(100% - 40px);
      bottom: 20px;
      cursor: pointer;
      a {
        width: 100%;
      }
    }
  }
`;

export const MapText = styled.div`
  position: relative;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const MapTitle = styled.div`
  font-family: 'SamsungOne-700';
  font-size: 32px;
  font-weight: bold;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 1.25;
  }
`;

export const MapDesc = styled.div`
  max-width: 570px;
  font-size: 20px;
  line-height: 1.43;
  color: #ffffff;
  margin: 16px 0 242px 0;
  opacity: 0.8;
  @media screen and (max-width: 768px) {
    margin-top: 12px;
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
`;

export const MapLink = styled.div`
  position: absolute;
  bottom: 0;
  .btn-arrow {
    width: 48px;
    height: 48px;
    background-color: #ffffff20;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #ffffff40;
    }
  }
  .btn-link {
    display: none;
  }
  @media screen and (max-width: 768px) {
    right: 0;
    bottom: initial;
    top: 0;
    button {
      width: 100% !important;
    }
    .btn-link {
      display: block;
    }
    .btn-arrow {
      width: 32px;
      height: 32px;
      img {
        width: 8px;
        height: 14px;
      }
    }
  }
`;

export const MapImage = styled.div`
  img {
    &.breadman {
      position: absolute;
      width: 270px;
      height: auto;
      right: 131px;
      bottom: 50px;
    }
    &.diagram {
      position: absolute;
      right: 0;
      bottom: 68px;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      &.breadman {
        width: 136px;
        height: auto;
        right: 48px;
        top: 162px;
      }
      &.diagram {
        width: 270px;
        top: 162px;
      }
    }
  }
`;
