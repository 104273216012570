import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { Get } from '../../utils/Requester';
import * as types from '../ActionTypes';

const descTimeStamp = [
  '2024-10-03T12:00:00',
  '2024-10-03T12:40:00',
  '2024-10-03T12:50:00',
  '2024-10-03T13:20:00',
  '2024-10-03T14:00:00',
  '2024-10-03T14:40:00',
  '2024-10-03T15:20:00',
  '2024-10-03T15:30:00',
  '2024-10-03T16:00:00',
  '2024-10-03T16:40:00',
  '2024-10-03T17:20:00',
];

const descData = [
  {
    title: 'meet-up',
    desc: 'Meet-up',
    time: [
      '2024-10-03T12:00:00',
      '2024-10-03T12:40:00',
      '2024-10-03T14:00:00',
      '2024-10-03T14:40:00',
      '2024-10-03T15:20:00',
      '2024-10-03T16:40:00',
    ],
    duration: 10,
  },
  {
    title: 'crew-break',
    desc: 'Crew Break Time',
    time: ['2024-10-03T12:50:00', '2024-10-03T15:30:00'],
    duration: 30,
  },
  {
    title: 'preparation',
    desc: 'Preparation',
    time: ['2024-10-03T13:20:00', '2024-10-03T16:00:00', '2024-10-03T17:20:00'],
    duration: 10,
  },
];

const roomOrder = {
  'Tech Session Room 1': 1,
  'Tech Session Room 2': 2,
  'Tech Session Room 3': 3,
};

function handlingScheduleData(time, room, matchDesc, timeValue, roomGroup) {
  const customEndTime = moment(time).add(matchDesc.duration, 'minutes');
  const checkTime = customEndTime.format('YYYY-MM-DDTHH:mm:ss');

  if (!timeValue[time]) {
    timeValue[time] = [];
    timeValue[time].push({
      desc: `${matchDesc.desc} (${moment(time).format(
        'HH:mm'
      )} - ${customEndTime.format('HH:mm')})`,
    });
  }

  if (!roomGroup[room]) {
    roomGroup[room] = [];
  }

  roomGroup[room].push({
    desc: `${matchDesc.desc} (${moment(time).format(
      'HH:mm'
    )} - ${customEndTime.format('HH:mm')})`,
  });

  if (descTimeStamp.includes(checkTime)) {
    const nextMatch = descData.find((item) => item.time.includes(checkTime));
    if (nextMatch) {
      handlingScheduleData(checkTime, room, nextMatch, timeValue, roomGroup);
    }
  }
}

export function* getScheduleData() {
  try {
    const { data, error } = yield call(Get, {
      url: '/api/event/schedule',
    });

    if (error) {
      console.log('error', error);
      yield put({ type: types.GET_SCHEDULE_SESSION_ERROR, error });
      return;
    }

    const { openStage, techSession } = data;

    const timeValue = {};
    const roomGroup = {};

    techSession.forEach((x) => {
      if (!timeValue[x.PresentationDateTimeStart]) {
        timeValue[x.PresentationDateTimeStart] = [];
      }
      if (!roomGroup[x.PresentationRoom]) {
        roomGroup[x.PresentationRoom] = [];
      }

      timeValue[x.PresentationDateTimeStart].push(x);

      // sorting based on room info
      timeValue[x.PresentationDateTimeStart].sort(
        (a, b) => roomOrder[a.PresentationRoom] - roomOrder[b.PresentationRoom]
      );
      roomGroup[x.PresentationRoom].push(x);

      if (descTimeStamp.includes(x.PresentationDateTimeEnd)) {
        const matchDesc = descData.find((item) =>
          item.time.includes(x.PresentationDateTimeEnd)
        );

        if (matchDesc) {
          handlingScheduleData(
            x.PresentationDateTimeEnd,
            x.PresentationRoom,
            matchDesc,
            timeValue,
            roomGroup
          );
        }
      }
    });

    // room sorting: room1, room2, ...
    const roomSchedule = Object.keys(roomGroup)
      .sort((a, b) => roomOrder[a] - roomOrder[b])
      .reduce((acc, key) => {
        acc[key] = roomGroup[key];
        return acc;
      }, {});

    const checkedMainCategories = [];
    const checkedTopics = [];
    const checkedLocations = [];

    if (window.location.search) {
      const query = new URLSearchParams(window.location.search).getAll(
        'filter'
      );

      const searchValue = query[0].split(',');
      searchValue.forEach((x) => {
        if (
          x === 'Device AI' ||
          x === 'SmartThings' ||
          x === 'Responsible AI' ||
          x === 'Advanced Tech' ||
          x === 'Enterprise & Ecosystem'
        ) {
          checkedMainCategories.push(x);
        } else if (
          x === 'Tech Session Room 1' ||
          x === 'Tech Session Room 2' ||
          x === 'Tech Session Room 3' ||
          x === 'Open Stage'
        ) {
          checkedLocations.push(x);
        } else {
          checkedTopics.push(x);
        }
      });
    }

    // all session data
    const filterData = Object.values(timeValue)
      .flat()
      .filter((item) => !item.desc)
      .sort((a, b) => {
        const timeDiff = moment(a.PresentationDateTimeStart).diff(
          moment(b.PresentationDateTimeStart)
        );
        if (timeDiff !== 0) {
          return timeDiff;
        }
        return roomOrder[a.PresentationRoom] - roomOrder[b.PresentationRoom];
      });

    const openStageFilter = openStage;

    yield put({
      type: types.GET_SCHEDULE_SESSION_SUCCESS,
      openStage,
      timeSchedule: timeValue,
      roomSchedule,
      checkedMainCategories,
      checkedTopics,
      checkedLocations,
      filterData: { session: filterData, stage: openStageFilter },
    });
  } catch (error) {
    console.log('error', error);
    yield put({ type: types.GET_SCHEDULE_SESSION_ERROR, error });
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_SCHEDULE_SESSION_REQUEST, getScheduleData);
}
