import React from 'react';
import ActivityHeader from '../../components/ActivityHeader';
import ActivityBingoMain from '../../components/ActivityBingoMain';

const ActivityBingo = () => {
  return (
    <>
      <ActivityHeader isDropdown />
      <ActivityBingoMain />
    </>
  );
};

export default ActivityBingo;
