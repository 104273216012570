import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  TechContainer,
  TechInner,
  TechContent,
  TechTitle,
  TechCardArea,
  TechCardBox,
  TechCardBoxDimmed,
  TechCardBoxBlur,
  TechCardBoxBg,
  TechCardBoxInner,
  TechBoxInfo,
  TechBoxTitle,
  TechBoxDate,
  TechBoxText,
  Button,
} from './EventContElements';
import arrLottie from '../../lottie/plusbtn_white.json';
import arrLottie2 from '../../lottie/plus-close-btn.json';
import ToastPopup from '../ToastPopup';
import EventJoin from '../EventJoin';
import ScheduleMap from '../ScheduleMap';
import PopupTechSquare from '../PopupTechSquare';
import StaticMap from '../StaticMap';

const techSquareCardData = [
  {
    id: 1,
    title: 'Meet-up',
    date: 'October 3',
    desc: 'Engage with industry experts and build meaningful connections with people who share the same enthusiasm for technology and innovation. Join discussions, exchange valuable insights, and expand your network for new opportunities!',
    bg: `${process.env.PUBLIC_URL}/images/img-sdc24-event-01-1.png`,
    url: '/event/meet-up',
    singleLine: false,
    hideBtn: false,
    // theme : 'bright'
  },
  {
    id: 2,
    title: '"Code&Chill" Night Party',
    date: 'October 3',
    desc: 'After a day of learning, networking and coding, join us in celebrating the 10th anniversary of SDC. The SDC Night Party will feature exciting music, unlimited food and drinks, and lucky draws for everyone to enjoy. ',
    bg: `${process.env.PUBLIC_URL}/images/img-sdc24-event-02-2.png`,
    singleLine: false,
    hideBtn: false,
    // theme : 'bright'
  },
];

const mapItems = [
  { label: '1', text: 'Open Stage', color: '#0078FF' },
  { label: '2', text: 'Networking Lounge', color: '#FFAE34' },
  { label: '3', text: 'Code Lab', color: '#CA6CEB' },
  {
    label: '11',
    text: 'Code Lab info Desk',
    color: '#756BF1',
    icon: 'true',
    customIcon: 'true',
  },
  { label: '4', text: 'AI for Platforms', color: '#756BF1' },
  { label: '5', text: 'Enterprise & Ecosystem', color: '#756BF1' },
  { label: '6', text: 'AI for Devices', color: '#756BF1' },
  { label: '7', text: 'Tech Session', color: '#1259AA' },
  { label: '8', text: 'Meeting Room', color: '#8C95C9' },
  { label: '9', text: 'Press Room', color: '#8C95C9' },
  { label: '10', text: 'Info/Giveaway Desk', icon: 'true', border: 'false' },
];

// Card component
const TechCardItem = ({
  item,
  idx,
  setModal,
  setModalFalse,
  hideBtn,
  singleLine,
  url,
}) => {
  const phase = useSelector((state) => state.meta.data.phase);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const navigate = useNavigate();

  // Tech Square popup
  const [openTechPopup, setOpenTechPopup] = useState(false);
  const [openClabPopup, setOpenClabPopup] = useState(false);

  // Arrow lottie button
  const [isHovered, setIsHovered] = useState(false);
  const [shouldUseLottie, setShouldUseLottie] = useState(true);

  // Handle window resize event
  useEffect(() => {
    const handleResize = () => {
      setShouldUseLottie(true);
      setDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    if (!animationRef.current) return;

    if (isClicked) {
      animationRef.current.goToAndStop(0, true);
    } else {
      animationRef.current.play();
    }

    setIsClicked(!isClicked);
  };

  const popupHandler = () => {
    if (item.id === 1) {
      setOpenTechPopup(true);
    } else if (item.id === 2) {
      setOpenClabPopup(true);
    }
  };

  const animationRef = useRef(null); // Lottie 인스턴스를 참조할 ref

  // 애니메이션을 초기화하는 함수
  const resetAnimation = () => {
    if (animationRef.current) {
      animationRef.current.goToAndStop(0, true);
    }
  };

  return (
    <>
      {/* phase2.5에서 팝업 제공 */}
      <TechCardBox
        key={idx}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        className={`${item.theme === 'large' ? 'col-1' : null}`}
      >
        <div className={`${isClicked ? 'clicked' : null} TechSquareBoxLink`}>
          <TechCardBoxDimmed />
          <TechCardBoxBlur className="Blur" />
          <TechCardBoxBg>
            <img className="TechSquareImg" src={item.bg} alt="" />
          </TechCardBoxBg>

          <TechCardBoxInner>
            <TechBoxInfo>
              <TechBoxTitle className={item.theme}>
                {item.title.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index !== item.title.split('\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </TechBoxTitle>
              <TechBoxDate>{item.date}</TechBoxDate>
            </TechBoxInfo>

            <TechBoxText
              className={`TechBoxText ${singleLine ? 'singleLine' : ''}`}
            >
              <p>{item.desc}</p>
            </TechBoxText>
          </TechCardBoxInner>

          <Button
            className={`moreLink ${hideBtn ? 'hideBtn' : ''} ${
              url ? '' : 'empty-url'
            }`}
            onClick={() =>
              isDesktop
                ? navigate(url)
                : isClicked
                ? navigate(url)
                : setIsClicked(false)
            }
          >
            {shouldUseLottie && (
              <Lottie
                lottieRef={animationRef}
                key={isClicked || isHovered ? 'hovered' : 'not-hovered'}
                animationData={url ? arrLottie : arrLottie2}
                loop={false}
                autoplay={isHovered || isClicked}
                onComplete={() => {
                  // Lottie 애니메이션이 끝났을 때 호출되는 함수
                }}
              />
            )}
            {/* {!shouldUseLottie && (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/ico-arr-right-white.svg`}
                    alt="ic"
                  />
                )} */}
          </Button>
        </div>
      </TechCardBox>
      {/* Tech Exhibition popoup */}
      {openTechPopup ? (
        <PopupTechSquare isLinkCont={false} setOpen={setOpenTechPopup} />
      ) : null}
      {/* Samsung C-Lab popoup */}
      {openClabPopup ? (
        <PopupTechSquare isLinkCont setOpen={setOpenClabPopup} />
      ) : null}
    </>
  );
};

const EventCont = () => {
  const phase = useSelector((state) => state.meta.data.phase);
  const [modal, setModal] = useState(false);
  const [modalFalse, setModalFalse] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  return (
    <TechContainer>
      <TechInner>
        <TechContent>
          <TechTitle>Main Event in SDC24</TechTitle>
          <EventJoin hasArrow />
          <div className="line" />
          <TechCardArea>
            {techSquareCardData.map((item, idx, id) => (
              <TechCardItem
                key={idx}
                item={item}
                idx={idx}
                setModal={setModal}
                hideBtn={item.hideBtn}
                singleLine={item.singleLine}
                setModalFalse={setModalFalse}
                url={item.url}
              />
            ))}
          </TechCardArea>
        </TechContent>
        <StaticMap
          title="Event Map"
          imgSrc={`${process.env.PUBLIC_URL}/images/eventMap6.png`}
          listItems={mapItems}
        />
        <ScheduleMap className="event" />
      </TechInner>
    </TechContainer>
  );
};

export default EventCont;
