// Event > samsung-clab Page
export const EventSamsungClabData = [
  {
    title: 'EDINT (Accelerated by Samsung C-Lab)',
    desc: 'Proctormatic is an automated SaaS solution that provides 100% unmanned proctoring by AI proctors for customers seeking high-quality, low-cost online exam services.',
    img: 'https://cdmcd.co/Pn8M5G',
  },
  {
    title: 'OMNIUS.AI (Accelerated by Samsung C-Lab)',
    desc: 'OMNIOUS.AI is a research-focused company leveraging AI to transform the customer experience and augment human creativity.',
    img: 'https://cdmcd.co/9WKZdA',
  },
  {
    title: 'Cochl (Accelerated by Samsung C-Lab)',
    desc: 'Cochl provides a sound AI solution, featuring cutting-edge technology for applications in smart homes, manufacturing, and security.',
    img: 'https://cdmcd.co/8Yrwna',
  },
  {
    title: 'GHOSTPASS (Accelerated by Samsung C-Lab)',
    desc: 'GhostPass provides a decentralized biometric authentication solution, enabling users to securely store and manage their biometric information on their smartphones for seamless identity verification and payment.',
    img: 'https://cdmcd.co/mRMnZG',
  },
];
