import React, { useState, useEffect } from 'react';
import { CheckboxItem, CheckboxInput, Label } from './CheckboxElement';

const CheckBox = ({
  label,
  value,
  id,
  onChangeValue,
  disabled,
  onChange,
  checked,
  className,
}) => {
  // const [checked, setChecked] = useState(false);

  // const checkHandler = () => {
  //   setChecked(!checked);
  // };

  // useEffect(() => {
  //   if (value === true) {
  //     setChecked(true);
  //   }
  // }, [value]);

  return (
    <CheckboxItem className={className}>
      <CheckboxInput
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        // onChange={checkHandler}
        disabled={disabled}
      />
      <Label className={className} htmlFor={id} label={label} checked={checked}>
        {value}
      </Label>
    </CheckboxItem>
  );
};

export default CheckBox;
