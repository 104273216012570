/* eslint-disable import/named */
import React, { useEffect, useRef, useState } from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useSelector } from 'react-redux';
import {
  MainOnsiteEventArea,
  MainOnsiteEventWrap,
} from './MainOnsiteEventElement';
import MainSectionTitle from '../MainSectionTitle';

const MainOnsiteEvent = () => {
  const phase = useSelector((state) => state.meta.data.phase);

  const tl = useRef();
  const mainRef = useRef();
  const imgRef = useRef();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);

  // Handle window resize event
  useEffect(() => {
    const handleResize = () => {
      setDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  gsap.registerPlugin(ScrollTrigger);
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    setHasRendered(true);
  }, []);
  useEffect(() => {
    if (hasRendered) {
      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: mainRef.current,
          scrub: true,
          start: 'top 70%',
          end: 'top center',
          onUpdate: (self) => {
            if (self.progress > 0.1) {
              imgRef.current.classList.add('active');
            } else {
              imgRef.current.classList.remove('active');
            }
          },
        },
      });
    }

    return () => {
      if (tl.current) tl.current.kill();
    };
  }, [hasRendered]);

  return (
    <>
      <MainOnsiteEventWrap
        ref={mainRef}
        className={phase === 'phase4' ? 'is-phase4' : ''}
      >
        <MainSectionTitle
          title="On-site Events"
          desc={<div>Come and join us to experience these events in SDC24</div>}
          linkText="Go to On-site Events"
          buttonUrl="/event"
        />
        <MainOnsiteEventArea ref={imgRef}>
          <img
            src={`${process.env.PUBLIC_URL}/images/main-event-img${
              isDesktop ? '' : '.s'
            }.png`}
            alt="icon"
          />
        </MainOnsiteEventArea>
      </MainOnsiteEventWrap>
    </>
  );
};

export default MainOnsiteEvent;
