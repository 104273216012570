import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const MyProfileWrap = styled.div`
  padding: 24px 0 120px 0;
  @media screen and (max-width: 768px) {
    padding: 16px 0 60px 0;
  }
`;

export const MyProfileWrapInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (max-width: 768px) {
    padding: 0px 24px;
    overflow: hidden;
    gap: 16px;
  }
`;

export const ProfileSlider = styled.div`
  @media screen and (max-width: 768px) {
  }
`;

export const MyProfileDetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 768px) {
    gap: 16px;
  }
`;

export const MyProfileDetail = styled.div`
  position: relative;
  background-color: #f6f6f6;
  border-radius: 24px;
  padding: 32px 40px 12px 40px;

  &.profile_slider {
    width: calc(100% - 80px) !important;
  }

  &.unregister {
    padding: 40px 40px 100px 40px;
  }

  &.e-business {
    padding: 40px 40px 56px 40px;
  }

  &.download {
    display: flex;
    padding: 32px 40px;
    justify-content: space-between;
    align-items: center;
  }

  .btn-edit {
    position: absolute;
    top: 32px;
    right: 40px;
    min-width: 144px;
    min-height: 48px;
    padding: 14px 0;
    background-color: transparent;

    &.update {
      color: #c4c4c4;
      border: 1px solid #c4c4c4;
    }
  }
  .btn-unregister {
    position: absolute;
    top: 32px;
    right: 194px;
    min-width: 144px;
    min-height: 48px;
    padding: 14px 0;
    background-color: transparent;
  }

  table {
    thead {
      tr {
        display: inline-block;
        margin-bottom: 28px;
        text-align: left;
        min-width: 330px;

        &.e-business {
          margin-bottom: 40px;
        }
      }
      th {
        font-size: 24px;
        line-height: 1.34;
        font-family: 'samsungsharpsans-bold';
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #ddd;

        &:nth-child(2) {
          th {
            padding: 28px;
            padding-left: 0;

            &.personal_url {
              padding: 48px 28px 16px 0;
            }
          }
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
      th {
        padding: 20px;
        text-align: left;
        vertical-align: top;
        font-family: 'SamsungOne-700';
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        color: #363636;
        padding-left: 0;

        &.edit {
          padding: 16px 16px 16px 0;
        }
      }
      td {
        padding: 20px;
        font-family: 'SamsungOne-400';
        font-size: 16px;
        line-height: 1.5;
        color: #535353;
        text-align: left;
        span {
          display: block;
          & + span {
            margin-top: 8px;
          }
        }
        &.personal_url {
          padding: 0;
        }
      }
      .confirm-badge {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding-left: 17px;
        span {
          display: flex;
          align-items: center;
          padding: 6px 16px 6px 18px;
          font-family: 'SamsungOne-700';
          font-size: 16px;
          line-height: 24px;
          color: #2088fd;
          background: #fff;
          border-radius: 8px;
          box-sizing: border-box;

          svg {
            margin-right: 4px;
          }
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 10px;
          left: 8px;
          width: 1px;
          height: 16px;
          background-color: #ddd;
        }
      }

      .atten-tag {
        display: inline-block;
        background: #fff;
        max-width: 70px;
        justify-content: space-between;
        align-items: center;
        padding: 6px 16px;
        border-radius: 8px;

        &.virtual {
          max-width: 50px;
        }

        span {
          width: auto;
          text-align: center;
          color: #535353;
          font-family: 'SamsungOne-700';
          font-size: 16px;
          line-height: 1.5;
        }
        span + span {
          margin-top: 0;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 24px 24px 162px;
    background-color: #f6f6f6;
    border-radius: 16px;

    &.is-phase3 {
      padding-bottom: 104px !important;
    }

    &.profile_slider {
      width: calc(100% - 48px) !important;

      &.virtual {
        padding: 24px 24px 168px;
      }

      &.e-business {
        padding: 24px 24px 40px;
      }
    }

    &.download {
      align-items: start;
      flex-direction: column;
      padding: 24px;

      div {
        width: 100%;
        padding-bottom: 16px;
        border-bottom: 1px #ddd solid;
        margin-bottom: 20px;
      }

      button {
        width: 100%;
      }
    }

    &.unregister {
      padding: 24px;
    }

    .btn-edit {
      top: auto;
      left: 50%;
      bottom: 24px;
      width: calc(100% - 48px);
      transform: translateX(-50%);
      margin-left: 0;
    }
    .btn-unregister {
      top: auto;
      left: 50%;
      bottom: 82px;
      width: calc(100% - 48px);
      transform: translateX(-50%);
    }

    .col {
      width: 100%;
      col {
        display: block;
        width: 100%;
      }
    }
    table {
      thead {
        tr {
          display: block;
          font-size: 16px;
          margin-bottom: 16px;

          &.e-business {
            margin-bottom: 16px;
          }
        }
        th {
          font-size: 16px;
          text-align: left;
        }
      }

      tbody {
        tr {
          &:nth-child(2) {
            th {
              padding: 0;

              &.personal_url {
                padding: 0;
              }
            }
          }
        }

        tr {
          display: block;
          border: none;
          &:first-of-type {
            padding-top: 16px;
            border-top: 1px solid #ddd;
          }
          & + tr {
            margin-top: 24px;
          }
        }
        th {
          display: block;
          width: 100%;
          padding: 0;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.43;
          &.personal_url {
            padding: 0;
          }
        }
        td {
          display: block;
          width: 100%;
          padding: 0;
          margin-top: 4px;
          font-size: 14px;
          line-height: 1.43;
          span {
            & + span {
              margin-top: 4px;
            }
          }
        }

        .confirm-badge {
          display: block;
          padding-left: 0;
          span {
            max-width: 185px;
            padding: 6px 12px;
            font-size: 14px;
            line-height: 20px;
            svg {
              width: 16px;
            }
          }
          &:before {
            display: none;
          }
        }

        .atten-tag {
          display: block;
          max-width: 61px;
          margin-top: 10px;
          padding: 6px 12px;
          + .confirm-badge {
            margin-top: 8px;
          }

          &.virtual {
            max-width: 44px;
          }

          span {
            font-size: 14px;
            line-height: 1.43;
          }
        }
      }
    }
  }
`;

export const MyProfilePersonalUrlTh = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'SamsungOne-700';
  font-weight: 700;

  img {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 97px;
    top: 2px;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;

    img {
      width: 16px;
      height: 16px;
      left: 86px;
      top: 2px;
    }
  }
`;

export const MyProfilePersonalUrlTd = styled.div`
  color: #535353;
  font-size: 16px;

  &.error {
    color: #9e9e9e;
    font-weight: 600;
    font-family: 'SamsungOne-600';
  }

  .error-text {
    font-size: 14px;
    color: #e34a5d;
    font-weight: 400;
    font-family: 'SamsungOne-400';
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const RegisterTitle = styled.div`
  color: #000;
  font-family: 'samsungsharpsans-bold';
  font-size: 24px;
  line-height: 1.34;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 1.3;
  }
`;
export const RegisterContent = styled.div`
  p {
    color: #535353;
    text-align: center;
    font-family: 'SamsungOne-400';
    font-size: 16px;
    line-height: 1.5;
    margin-top: 48px;
    padding: 0 12px;
  }
  button {
    background-color: #1e1e1e;
    height: 3.103em;
    padding-left: 2em;
    padding-right: 2em;
    min-width: 170px;
    border-radius: 36px;
    border: none;
    font-family: 'SamsungOne-700';
    cursor: pointer;
    color: #ffffff;
    outline: none;
    transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

    &:hover {
      background-color: #555;
    }

    &:active {
      background-color: #050505;
    }

    @media screen and (max-width: 768px) {
      min-width: 128px;
      height: 2.998em;
      padding: 0 20px;
      font-size: 1.149em;
    }
  }
  .register-btn {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    line-height: 1.35;
    margin-top: 32px;
    font-family: 'SamsungOne-700';
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    p {
      margin-top: 24px;
      font-size: 14px;
      line-height: 1.45;
    }

    .register-btn {
      margin-top: 24px;
      font-size: 16px;
      line-height: 1.3;
      padding: 0 22px;
    }
  }
`;

export const MyProfileQRCode = styled.div`
  display: flex;
  .title {
    color: #000000;
    font-family: 'samsungsharpsans-bold';
    font-size: 24px;
    line-height: 32px;
    flex-shrink: 1;
    width: 30%;
    text-align: left;
  }
  .qr-code {
    width: 200px;
    border-radius: 10px;
    background-color: #c4c4c4;
    padding: 10px 10px 0 10px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;

    .qr-image {
      background: #ffffff;
      padding: 10px;
      border-radius: 4px;
    }

    img {
      border-radius: 4px;
    }
    .name {
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      color: #000;
      text-overflow: ellipsis;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      font-family: 'samsungsharpsans-bold';
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    .title {
      width: 100%;
      font-size: 16px;
    }
    .qr-code {
      margin: 0 auto;
    }
  }
`;

export const DownloadButton = styled.button`
  display: flex;
  width: 172px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background-color: #1e1e1e;
  color: #ffffff;
  font-family: 'SamsungOne-700';
  font-weight: 700;
  line-height: 20px;
`;

export const ModalDownload = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000060;

  .container {
    position: relative;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 24px;
      height: 24px;
    }
    .title {
      font-size: 24px;
      font-family: 'SamsungOne-700';
      font-weight: 700;
      margin-bottom: 24px;
      .desc {
        margin-top: 12px;
        font-size: 20px;
        color: #535353;
        max-width: 640px;
        font-weight: 400;
      }
    }
    .download-img {
      width: 640px;
      height: auto;
      margin-bottom: 32px;
      border-radius: 16px;
    }
    .ticket-box {
      padding: 36px 40px 40px 40px;
      border-radius: 16px;
      width: calc(100% - 80px);
      background-color: #f6f6f6;
      margin-bottom: 32px;
      .ticket-title {
        font-size: 32px;
        font-family: 'samsungsharpsans-bold';
      }
      .ticket-date-box {
        margin-top: 8px;
        padding-bottom: 24px;
        border-bottom: 1px solid #dddddd;
        span {
          font-size: 20px;
          font-family: 'samsungsharpsans-medium';
        }
        .ticket-bar {
          margin: 0 8px;
          display: inline-flex;
          align-items: center;
          width: 1px;
          height: 12px;
          background-color: #dddddd;
        }
      }
      .ticket-grid {
        display: grid;
        margin-top: 24px;
        grid-template-rows: 80px 56px;
        grid-template-columns: 204px 234px auto;
        row-gap: 24px;

        .ticket-info {
          padding-right: 24px;
          &:nth-child(1),
          &:nth-child(2) {
            border-bottom: 1px solid #dddddd;
            padding-bottom: 24px;
          }
          .ticket-cate {
            font-size: 16px;
            color: #535353;
            margin-bottom: 4px;
          }
          .ticket-data {
            font-size: 20px;
            color: #363636;
            font-family: 'SamsungOne-700';
            font-weight: 700;
          }
          &.registration {
            grid-column-start: 2;
            grid-column-end: 4;
          }
        }
      }
    }
    .download-btn {
      border-radius: 36px;
      background-color: #1e1e1e;
      padding: 16px 36px;
      font-size: 18px;
      font-family: 'SamsungOne-700';
      font-weight: 700;
      color: #ffffff;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      padding: 24px;
      border-radius: 16px;
      .title {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .download-img {
        width: 264px;
        border-radius: 8px;
        margin-bottom: 24px;
      }
      .download-btn {
        padding: 14px 20px;
        width: 100%;
        font-size: 16px;
      }
    }
  }
`;

export const ProfileLine = styled.div`
  display: none;
  width: 100%;
  height: 1px;
  background-color: #eaeaea;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
