import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActivityCont from '../../components/ActivityCont';
import withRouter from '../../components/Router/withRouter';
import Loading from '../../components/Loading';

const Activity = (props) => {
  const { navigate, params } = props;
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.sessionDetail);

  return <>{isLoading ? <Loading /> : <ActivityCont />}</>;
};

export default withRouter(Activity);
