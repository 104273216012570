import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { useActivityData } from '../../hooks/useActivityData';
import { useActivityProfile } from '../../hooks/useActivityProfile';
import {
  ActivityBingoCont,
  ActivityContainer,
  ActivityHero,
  ActivityHeroBg,
  ActivityPrecaution,
  ActivityScore,
  ActivityTab,
  HowToPlayPopup,
  HowToPlayStepItem,
} from '../ActivityBingoMain/ActivityBingoElements';
import Button from '../Button';
import { ActivityCodeLabList } from './ActivityCodeLabElements';

export const activityCodelabData = [
  {
    id: 'codelab-1',
    no: '1',
    title: 'SmartThings',
    desc: 'Develop a SmartThings Find-compatible device.',
    point: '500',
  },
  {
    id: 'codelab-2',
    no: '2',
    title: 'SmartThings',
    desc: 'Create 3D objects using Generative AI on SmartThings Map View.',
    point: '500',
  },
  {
    id: 'codelab-3',
    no: '3',
    title: 'SmartThings',
    desc: 'Create a SmartThings Edge Driver for an IOT bulb.',
    point: '500',
  },
  {
    id: 'codelab-4',
    no: '4',
    title: 'SmartThings',
    desc: 'Create your own SmartThings Routine with Generative AI.',
    point: '500',
  },
  {
    id: 'codelab-5',
    no: '5',
    title: 'SmartThings',
    desc: 'Test Edge Driver using SmartThings Test Suite.',
    point: '500',
  },
  {
    id: 'codelab-6',
    no: '6',
    title: 'Health',
    desc: 'Build a health app with steps from Samsung Health and its connected wearables.',
    point: '500',
  },
  {
    id: 'codelab-7',
    no: '7',
    title: 'Health',
    desc: 'Access rich sleep data of Samsung Health measured by Galaxy wearables.',
    point: '500',
  },
  {
    id: 'codelab-8',
    no: '8',
    title: 'Health',
    desc: 'Establish a health research system using Samsung Health Research Stack.',
    point: '500',
  },
  {
    id: 'codelab-9',
    no: '9',
    title: 'Automotive ',
    desc: 'Create an Android Automotive Operating System (AAOS) app with payments via Samsung Checkout.',
    point: '500',
  },
  {
    id: 'codelab-10',
    no: '10',
    title: 'Samsung Wallet',
    desc: 'Utilize Add to Samsung Wallet service for digital cards.',
    point: '500',
  },
  {
    id: 'codelab-11',
    no: '11',
    title: 'Samsung Wallet',
    desc: 'Verify your ID with Samsung Wallet.',
    point: '500',
  },
  {
    id: 'codelab-12',
    no: '12',
    title: 'Samsung Wallet',
    desc: 'Integrate Samsung Pay SDK Flutter Plugin into merchant apps for in-app payment.',
    point: '500',
  },
  {
    id: 'codelab-13',
    no: '13',
    title: 'Samsung Wallet',
    desc: 'Integrate Samsung Pay Web Checkout with merchant sites ',
    point: '500',
  },
];

const ActivityCodeLab = () => {
  const [modal, setModal] = useState(false);
  const [maxLength, setMaxLength] = useState(6);
  const openPopup = () => {
    setModal(true);
  };

  const { data } = useActivityData();
  const { name } = useActivityProfile();
  let sliderRef = useRef(null);

  const settings = {
    dots: true,
    speed: 400,
    centerMode: true,
    infinite: false,
    centerPadding: '0px',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    cssEase: 'ease-in-out',
  };

  const bingoCompleteData = _.filter(data, (e, k) => {
    e.id = k;
    return e.point && k.includes('codelab');
  });

  const checkComplete = (id) => {
    return _.find(bingoCompleteData, (e) => e.id === `sdc24-event-saguid-${id}`)
      ? 'completed'
      : '';
  };

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modal]);

  return (
    <ActivityContainer type="codelab" className="codelab" main>
      <ActivityHero>
        <ActivityHeroBg>
          <span />
          <span />
          <span />
        </ActivityHeroBg>
        <div className="text-area">
          <img
            src={`${process.env.PUBLIC_URL}/images/txt-sdc24-white.svg`}
            alt="SDC24"
          />
          <p>Code Lab</p>
          <p>
            Complete tasks to earn points, which can be redeemed for gifts. For
            each task completed, earn lucky draw ticket to enter our Lucky Draw
            exclusive for Code Lab.
          </p>
          <Button color="black" size="medium" onClick={() => openPopup()}>
            How to play
          </Button>
        </div>
      </ActivityHero>
      <ActivityBingoCont>
        <ActivityTab>
          <Link to="/activity/codelab" className="active">
            Activities
          </Link>
          <Link to="/activity/codelab/map">Map</Link>
          <Link to="/activity/codelab/prizes">Prizes</Link>
        </ActivityTab>
        <ActivityScore className="code-lab">
          <p>{name}’s</p>
          <p>Task Completion</p>
          <div className="progress">
            <p className="progress-text">
              <em>{bingoCompleteData.length}</em> / {activityCodelabData.length}
            </p>
            <div className="progress-bar">
              <div
                className="bar"
                style={{ width: `${(bingoCompleteData.length / 13) * 100}%` }}
              />
            </div>
          </div>
          <p className="text-info">
            Earn one lucky draw ticket for each task <br />
            completed from our Samsung Team.
          </p>
          {/* {bingoCompleteData.length && ( */}
          <p className="desc">
            Collect your lucky draw ticket from our staff at the task locations,
            then head to the Info Desk in the Code Lab zone to submit your
            ticket number and join the raffle at 6:00 PM on the Open Stage.
          </p>
          {/* )} */}
        </ActivityScore>
        <ActivityCodeLabList>
          <ul>
            {activityCodelabData.map((row, idx) =>
              idx < maxLength ? (
                <li key={idx} className={checkComplete(row.id)}>
                  <div className="top">
                    <span>{row.no}</span>
                    <span className="point">{row.point}P</span>
                  </div>
                  <p className="title">{row.title}</p>
                  <p className="desc">{row.desc}</p>
                </li>
              ) : null
            )}
          </ul>
          {maxLength < activityCodelabData.length ? (
            <Button
              color="borderWhite"
              onClick={() =>
                setMaxLength(
                  maxLength < activityCodelabData.length
                    ? maxLength + 6
                    : activityCodelabData.length
                )
              }
            >
              More
            </Button>
          ) : null}
        </ActivityCodeLabList>
        <ActivityPrecaution>
          <p>Precaution for SDC24 Code Lab</p>
          <hr />
          <ul>
            <li>Carefully review the mission details for clear instruction</li>
            <li>
              After completing each mission, you must scan the QR code from our
              staff to confirm completion
            </li>
            <li>Prizes and can be collected at the Giveaway desk.</li>
            <li>
              Prizes are given out on a first-come, first-served basis, and
              availability is limited while supplies last.
            </li>
          </ul>
        </ActivityPrecaution>
      </ActivityBingoCont>
      {modal ? (
        <HowToPlayPopup>
          <div className="inner-wrap">
            <Slider
              ref={(slider) => {
                sliderRef = slider;
              }}
              {...settings}
            >
              <HowToPlayStepItem>
                <img
                  src={`${process.env.PUBLIC_URL}/images/activity-bingo-howtoplay-1.svg`}
                  alt="Sign-in first"
                />
                <p>Sign-in first</p>
                <p>Please sign-in SDC24 to participate.</p>
                <Button color="border" onClick={() => sliderRef.slickNext()}>
                  Next
                </Button>
              </HowToPlayStepItem>
              <HowToPlayStepItem>
                <img
                  src={`${process.env.PUBLIC_URL}/images/activity-bingo-howtoplay-2-2.svg`}
                  alt="View task details"
                />
                <p>View task details</p>
                <p>Finish the tasks to accumulate points.</p>
                <Button color="border" onClick={() => sliderRef.slickNext()}>
                  Next
                </Button>
              </HowToPlayStepItem>
              <HowToPlayStepItem>
                <img
                  src={`${process.env.PUBLIC_URL}/images/activity-bingo-howtoplay-3.svg`}
                  alt="Scan QR code"
                />
                <p>Scan QR code</p>
                <p>
                  Scan QR code from our promoter after completing a task to
                  accumulate points and receive a Code Lab lucky draw ticket.
                </p>
                <Button color="border" onClick={() => sliderRef.slickNext()}>
                  Next
                </Button>
              </HowToPlayStepItem>
              <HowToPlayStepItem>
                <img
                  src={`${process.env.PUBLIC_URL}/images/activity-bingo-howtoplay-4-2.svg`}
                  alt="Redeem for Gifts"
                />
                <p>Redeem for Gifts</p>
                <p>Exchange your points for gifts at the Giveaway desk.</p>
                <Button color="black" onClick={() => setModal(false)}>
                  Okay
                </Button>
              </HowToPlayStepItem>
            </Slider>
          </div>
        </HowToPlayPopup>
      ) : null}
    </ActivityContainer>
  );
};

export default ActivityCodeLab;
