import styled from 'styled-components';

export const NewsLetterCon = styled.div`
  position: relative;
  padding: 120px 40px 148px;
  background-color: #f6f6f6;
  @media screen and (max-width: 768px) {
    padding: 60px 0;
  }
`;

export const NewsLetterInner = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  br {
    display: none;
  }

  @media screen and (max-width: 768px) {
    padding: 0 24px;

    br {
      display: block;
    }
  }
`;

export const NewsLetterTitle = styled.h3`
  display: block;
  margin-bottom: 14px;
  font-family: 'samsungsharpsans-bold';
  font-size: 54px;
  line-height: 1.3;
  text-align: center;
  br {
    display: none;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 1.32;
    br {
      display: block;
    }
  }
`;

export const NewsLetterSubtitle = styled.div`
  margin-bottom: 56px;
  margin-top: 8px;
  font-family: 'SamsungOne-600';
  font-size: 24px;
  line-height: 32px;
  letter-spacing: normal;
  color: #363636;
  text-align: center;
  @media screen and (max-width: 768px) {
    margin-top: 8px;
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 1.57;
  }
`;

export const NewsLetterInnerContent = styled.div`
  padding: 40px;
  background-color: #fff;
  border-radius: 24px;

  @media screen and (max-width: 768px) {
    padding: 0;
    background-color: transparent;
  }
`;

export const NewsLetterInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  > div {
    &:first-of-type {
      width: 45%;
    }
    &:nth-of-type(2) {
      width: 26%;
    }
    &:last-of-type {
      width: 26%;
    }

    + div {
      margin-left: 2.166%;
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 20px;
    > div {
      &:first-of-type {
        width: 100%;
      }
      &:nth-of-type(2) {
        width: 100%;
      }
      &:last-of-type {
        width: 100%;
      }

      + div {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
`;

export const NewsLetterAgree = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.19px;
  color: #7c7c7c;

  .text {
    margin-left: 8px;
  }
  a {
    color: #2088fd;
    margin-left: 4px;
    &:hover {
      color: #2088fd;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 768px) {
    padding-left: 32px;
    font-size: 1em;
    line-height: 1.578;
    br {
      display: block;
    }
    .text {
      margin-left: 0;
    }
    > span:not(.text) {
      position: absolute;
      top: -1px;
      left: 0;
    }
  }
`;

export const LinkArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 32px;
  }
`;
