import React from 'react';
import {
  MyProfileWrap,
  MyProfileWrapInner,
  MyProfileTitle,
  MyProfileTop,
  MyProfileBox,
  MyProfileName,
  MyProfileMail,
  MyProfileImg,
} from './MyprofileElement';

const MyProfile = (props) => {
  const { url, name, mail } = props;

  return (
    <MyProfileWrap className="profile">
      <MyProfileWrapInner>
        <MyProfileTitle>My Profile</MyProfileTitle>
        <MyProfileBox>
          <MyProfileTop>
            <MyProfileImg>{url && <img src={url} alt="" />}</MyProfileImg>
            <div>
              <MyProfileName>{name}</MyProfileName>
              <MyProfileMail>{mail}</MyProfileMail>
            </div>
          </MyProfileTop>
        </MyProfileBox>
      </MyProfileWrapInner>
    </MyProfileWrap>
  );
};

export default MyProfile;
