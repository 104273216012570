import React from 'react';
import DetailContainer from '../../components/DetailContainer';
import RegisterErrorCont from '../../components/RegisterErrorCont';

const RegModification = () => {
  return (
    <>
      <DetailContainer noMargin>
        <RegisterErrorCont
          errorTitle={`Registration modification
          is completed.`}
        />
      </DetailContainer>
    </>
  );
};

export default RegModification;
