/* eslint-disable import/named */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Lottie from 'lottie-react';
import Slider from 'react-slick';
import {
  TransformWrapper,
  TransformComponent,
  useControls,
  useTransformEffect,
} from 'react-zoom-pan-pinch';
import { useSelector } from 'react-redux';
import Button from '../Button';
import {
  TechSquare,
  TechSquareTabCon,
  TechSquareTabConInner,
  CardMoreBtn,
  EventTechSquareMap,
  MapContainer,
  MapWrap,
  MapImage,
  MapImageBox,
  EventTopUtil,
  TechSquareTopBox,
  TechSquareTopDetail,
  TechSquareBottomBox,
  TechSquareBottomDetail,
} from './EventTechContElement';
import EventTechSquareCard from '../EventTechCard';
import { EventTechSquareData } from '../../sample/EventTechSquareData';
import arrLottie from '../../lottie/plusbtn_white.json';

import TechSquareMap from '../TechSquareMap';
import StaticMap from '../StaticMap';
import './style.scss';

function SlideNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

function SlidePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none' }}
      onClick={onClick}
    />
  );
}

const settings = {
  dots: true,
  speed: 800,
  nextArrow: <SlideNextArrow />,
  prevArrow: <SlidePrevArrow />,
  centerMode: true,
  infinite: false,
  centerPadding: '0px',
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: false,
  cssEase: 'ease-in-out',
  responsive: [
    {
      breakpoint: 769,
      settings: {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        dots: true,
        infinite: false,
      },
    },
  ],
};

const EventTechCont = () => {
  // Handle window resize event
  const [isDesktop, setDesktop] = useState(window.innerWidth > 769);
  const [isClicked, setIsClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const animationRef = useRef(null);

  const { isLoading, data } = useSelector((state) => state.metaTechSquare);
  const phase = useSelector((state) => state.meta.data.phase);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 769);
  };

  const handleClick = () => {
    if (!animationRef.current) return;

    if (isClicked) {
      animationRef.current.goToAndStop(0, true);
    } else {
      animationRef.current.play();
    }

    setIsClicked(!isClicked);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  // card interaction
  const tlArray = useRef([]);
  const tl = useRef();
  const cardRef = useRef([]);

  const [activeTab, setActiveTab] = useState(0);
  // more button
  const [count, setCount] = useState(0);
  const [more, setMoreBtn] = useState(false);

  const handleTabChange = (index) => {
    // 이전 탭에 대한 애니메이션을 종료
    if (tlArray.current[activeTab]) {
      tlArray.current[activeTab].kill();
    }
    setActiveTab(index);
  };

  useEffect(() => {
    if (phase === 'phase2') {
      return;
    }
    // tlArray를 빈 타임라인으로 초기화
    tlArray.current = EventTechSquareData.map(() => null);
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.matchMedia({
      '(min-width: 768px)': function () {
        if (cardRef.current) {
          const cardArray = gsap.utils.toArray(cardRef.current.children);
          cardArray.forEach((el) => {
            tl.current = gsap.timeline({
              scrollTrigger: {
                trigger: el,
                start: '0 95%',
                onEnter: () => {
                  el.classList.add('is-active');
                },
              },
            });
          });
        }
      },
      '(max-width: 767px)': function () {
        if (cardRef.current) {
          const cardArray = gsap.utils.toArray(cardRef.current.children);
          cardArray.forEach((el) => {
            tl.current = gsap.timeline({
              scrollTrigger: {
                trigger: el,
                start: '0 100%',
                onEnter: () => {
                  el.classList.add('is-active');
                },
              },
            });
          });
        }
      },
    });

    return () => {
      // 언마운트 시 현재 활성 탭에 대한 애니메이션을 종료
      if (tlArray.current[activeTab]) {
        tlArray.current[activeTab].kill();
      }

      ScrollTrigger.getAll().forEach((t) => t.kill());
      tl.current.kill();

      ScrollTrigger.clearMatchMedia();
    };
  }, [activeTab, isLoading, count, phase]);

  // init count value
  useEffect(() => {
    if (isLoading) {
      return;
    }

    const techData =
      activeTab === 0
        ? data['AI for Platforms']
        : activeTab === 1
        ? data['Enterprise & Ecosystem']
        : activeTab === 2
        ? data['AI for Devices']
        : [];

    if (techData) {
      if (data.length < 7) {
        setCount(data.length);
      } else {
        setCount(6);
      }
    }

    setMoreBtn(false);
  }, [activeTab, data]);

  // more button handler
  const onClick = (e, type) => {
    e.preventDefault();
    if (type === 'devices') {
      setCount(more ? 6 : data['AI for Devices'].length);
    } else if (type === 'enterprise') {
      setCount(more ? 6 : data['Enterprise & Ecosystem'].length);
    } else if (type === 'platform') {
      setCount(more ? 6 : data['AI for Platforms'].length);
    }
    setMoreBtn(!more);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const handleSquareClick = (id) => {
    if (id > 3) return;
    setActiveTab(id - 1);
  };

  const mapItems = [
    { label: '1', text: 'Open Stage', color: '#0078ff' },
    { label: '2', text: 'Networking Lounge', color: '#ffae34' },
    { label: '3', text: 'Code Lab', color: '#ca6ceb' },
    { label: '4', text: 'AI for Platforms', color: '#756bf1' },
    { label: '5', text: 'Enterprise & Ecosystem', color: '#756bf1' },
    { label: '6', text: 'AI for Devices', color: '#756bf1' },
    { label: '7', text: 'Tech Session', color: '#1259aa' },
    { label: '8', text: 'Meeting Room', color: '#8c95c9' },
    { label: '9', text: 'Press Room', border: 'false', color: '#8c95c9' },
    { label: '10', text: 'Info/Giveaway Desk', icon: 'true', border: 'false' },
  ];

  const mapItems02 = [
    { label: '1', text: 'Enterprise & Ecosystem', color: '#756BF1' },
    { label: '2', text: 'AI for Devices', color: '#756BF1' },
    { label: '3', text: 'Info/Giveaway Desk', icon: 'true', border: 'false' },
  ];

  return (
    <>
      <TechSquareTopBox className="techsquare-slide">
        <Slider {...settings}>
          <TechSquareTopDetail>
            <img
              className="top-img"
              src={`${process.env.PUBLIC_URL}/images/img-techsquare-11.png`}
              alt="top-img-1"
            />
            <div className="top-title">Tech Square</div>
            <div className="top-desc">
              Explore Tech Square, an exhibition space showcasing a variety of
              innovations. With the help of experts, get hands-on experience and
              see how technology comes to life. Explore Samsung's latest
              advancements in software, services, and platforms, designed to
              deliver personalized user experiences.
            </div>
          </TechSquareTopDetail>
          <TechSquareTopDetail>
            <div className="top-img-box">
              <img
                className="top-img"
                src={`${process.env.PUBLIC_URL}/images/EventClab-img-04.png`}
                alt="top-img-2"
              />
            </div>
            <div className="top-title">Samsung C-Lab</div>
            <div className="top-desc">
              C-Lab Inside is a corporate venture program that encourages and
              nurtures innovative ideas from Samsung employees. It was
              established in 2012, to discover new ideas and to disperse a
              creative corporate culture throughout the company. C-Lab Inside
              projects that have proven marketability will either transfer to an
              in-house division or turned into spin-off companies supported by
              Samsung Electronics.
            </div>
          </TechSquareTopDetail>
        </Slider>
      </TechSquareTopBox>
      {/* <StaticMap
        type=""
        title="Event Map"
        imgSrc={`${process.env.PUBLIC_URL}/images/eventMap01.png`}
        listItems={mapItems}
      />
      <StaticMap
        type="type02"
        title="Event Map"
        imgSrc={`${process.env.PUBLIC_URL}/images/eventMap02.png`}
        listItems={mapItems02}
      /> */}
      <TechSquareMap onSquareClick={handleSquareClick} />
      {phase !== 'phase2' && (
        <TechSquare>
          <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
            <TabList>
              <Tab>
                AI for&nbsp;
                <br className="hide-pc" />
                Platforms
              </Tab>
              <Tab>
                Enterprise&nbsp;
                <br className="hide-pc" />& Ecosystem
              </Tab>
              <Tab>
                AI for&nbsp;
                <br className="hide-pc" />
                Devices
              </Tab>
            </TabList>
            {/* Platform Innovation > object Key: Platform */}
            <TabPanel>
              <TechSquareTabCon>
                <TechSquareTabConInner ref={cardRef}>
                  {(data['AI for Platforms'] || [{}, {}, {}, {}, {}, {}]).map(
                    (item, idx) => (
                      <EventTechSquareCard
                        key={idx}
                        slide={item.slide}
                        listYn
                        video={item.video}
                        title={item.title}
                        desc={item.desc}
                        popupDesc={item.popupDesc}
                        image={item.image}
                        images={item.images}
                        videoUrl={item.videoUrl}
                        // 로딩중일 경우 true로 전송
                        loading={isLoading}
                        hideCard={idx + 1 > count}
                        // videos={item.videos}
                      />
                    )
                  )}
                </TechSquareTabConInner>
                {data['AI for Platforms'] &&
                  data['AI for Platforms'].length > 6 && (
                    <CardMoreBtn>
                      <Button
                        color="white"
                        outline="outline"
                        size="medium"
                        className="moreBtn"
                        onClick={(e) => onClick(e, 'platform')}
                      >
                        {more ? 'Less' : 'More'}
                      </Button>
                    </CardMoreBtn>
                  )}
              </TechSquareTabCon>
            </TabPanel>
            {/* Home & Health Experience > object Key: Home & Health Experience */}
            <TabPanel>
              <TechSquareTabCon>
                <TechSquareTabConInner ref={cardRef}>
                  {(
                    data['Enterprise & Ecosystem'] || [{}, {}, {}, {}, {}, {}]
                  ).map((item, idx) => (
                    <EventTechSquareCard
                      key={idx}
                      slide={item.slide}
                      listYn
                      video={item.video}
                      title={item.title}
                      desc={item.desc}
                      popupDesc={item.popupDesc}
                      image={item.image}
                      images={item.images}
                      videoUrl={item.videoUrl}
                      // 로딩중일 경우 true로 전송
                      loading={isLoading}
                      hideCard={idx + 1 > count}
                      // videos={item.videos}
                    />
                  ))}
                </TechSquareTabConInner>
                {data['Enterprise & Ecosystem'] &&
                  data['Enterprise & Ecosystem'].length > 6 && (
                    <CardMoreBtn>
                      <Button
                        color="white"
                        outline="outline"
                        size="medium"
                        className="moreBtn"
                        onClick={(e) => onClick(e, 'enterprise')}
                      >
                        {more ? 'Less' : 'More'}
                      </Button>
                    </CardMoreBtn>
                  )}
              </TechSquareTabCon>
            </TabPanel>
            {/* Mobile & Screen Experience > object key: Mobile & Screen Experience */}
            <TabPanel>
              <TechSquareTabCon>
                <TechSquareTabConInner ref={cardRef}>
                  {(data['AI for Devices'] || [{}, {}, {}, {}, {}, {}]).map(
                    (item, idx) => (
                      <EventTechSquareCard
                        key={idx}
                        slide={item.slide}
                        listYn
                        video={item.video}
                        title={item.title}
                        desc={item.desc}
                        popupDesc={item.popupDesc}
                        image={item.image}
                        images={item.images}
                        videoUrl={item.videoUrl}
                        // 로딩중일 경우 true로 전송
                        loading={isLoading}
                        hideCard={idx + 1 > count}
                        // videos={item.videos}
                      />
                    )
                  )}
                </TechSquareTabConInner>
                {data['AI for Devices'] &&
                  data['AI for Devices'].length > 6 && (
                    <CardMoreBtn>
                      <Button
                        color="white"
                        outline="outline"
                        size="medium"
                        className="moreBtn"
                        onClick={(e) => onClick(e, 'devices')}
                      >
                        {more ? 'Less' : 'More'}
                      </Button>
                    </CardMoreBtn>
                  )}
              </TechSquareTabCon>
            </TabPanel>
          </Tabs>
        </TechSquare>
      )}
      {phase !== 'phase2' && (
        <TechSquareBottomBox>
          <TechSquareBottomDetail
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="bottom-title">Join Onsite Events</div>
            <div
              className={`bottom-banner ${
                isClicked || isHovered ? 'hovered' : ''
              }`}
            >
              <div className="banner-title">
                Samsung C-Lab
                {(isClicked || isHovered) && (
                  <div className="banner-desc">
                    After a day of learning, networking and coding, join us in
                    celebrating the 10th anniversary of SDC. The SDC Night
                    Party.
                  </div>
                )}
              </div>
              <img
                className="bottom-img-1"
                src={`${process.env.PUBLIC_URL}/images/bg-techsquare-assets-1.png`}
                alt="bottom-img-1"
              />
              <img
                className="bottom-img-2"
                src={`${process.env.PUBLIC_URL}/images/bg-techsquare-assets-${
                  isClicked || isHovered ? '4' : '2'
                }.svg`}
                alt="bottom-img-2"
              />
              <img
                className="bottom-img-3"
                src={`${process.env.PUBLIC_URL}/images/bg-techsquare-assets-${
                  isClicked || isHovered ? '5' : '3'
                }.svg`}
                alt="bottom-img-3"
              />
              <div className="bottom-rottie">
                <Lottie
                  lottieRef={animationRef}
                  key={isClicked || isHovered ? 'hovered' : 'not-hovered'}
                  animationData={arrLottie}
                  loop={false}
                  autoplay={isHovered || isClicked}
                  onClick={() =>
                    isClicked || isHovered
                      ? navigate('/event/samsung-clab')
                      : {}
                  }
                />
              </div>
            </div>
          </TechSquareBottomDetail>
        </TechSquareBottomBox>
      )}
    </>
  );
};

export default EventTechCont;
