import React from 'react';
import ActivityMsgCont from '../../components/ActivityMsgCont';

const ActivityPointError = () => {
  return (
    <>
      <ActivityMsgCont
        type="error"
        msgTitle="This doesn't seem to have enough points to receive a prize, please check again!"
        buttonLink="/activity/point"
        buttonText="Go to Point history"
      />
    </>
  );
};

export default ActivityPointError;
