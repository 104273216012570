import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../ActionTypes';
import { Get } from '../../utils/Requester';
import { EventTechSquareData } from '../../sample/EventTechSquareData';

export function* getMetaTechSquare() {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({
        type: types.GET_META_TECH_SQUARE_SUCCESS,
        data: {
          Platform: EventTechSquareData,
          'Home & Health Experience': EventTechSquareData,
          'Mobile & Screen Experience': EventTechSquareData,
          Sustainability: EventTechSquareData,
        },
      });
      return;
    }
    const { data, error } = yield call(Get, {
      url: `/api/event/tech-square`,
    });

    if (error || typeof data === 'string') {
      console.error('Meta', error);
      yield put({
        type: types.GET_META_TECH_SQUARE_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
    }

    const techSquareData = {};
    Object.keys(data)
      .filter((y) => y !== 'currentTime')
      .forEach((x) => {
        const techData = data[x];
        const techCards = Object.keys(techData).map((tech) => {
          const techCard = techData[tech];
          let images = (techCard?.images || []).filter((img) => img !== '');
          const thumbnail = [];

          if (techCard.videos && techCard.videos.length > 0) {
            techCard.videos = techCard.videos.map((video, index) => {
              let videoId = '';

              const url = new URL(video);
              const args = new URLSearchParams(url.search);
              videoId =
                video.indexOf('www.youtube.com') > -1
                  ? new URLSearchParams(args).get('v')
                  : video.split('/').pop().replace(url?.search, '');
              // get thumbnail
              if (videoId) {
                thumbnail.push(
                  `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
                );
              }

              const timeQuery = new URLSearchParams(args).get('t');

              return `https://www.youtube.com/embed/${videoId}${
                timeQuery ? `?start=${timeQuery.replace('s', '')}` : ''
              }`;
            });
            // youtube thumbnail + image
            images = thumbnail.concat(images);
          }

          return {
            slide:
              techCard.videos &&
              techCard.videos.length > 0 &&
              images.length === 1
                ? true
                : images.length > 1,
            title: techCard.title,
            desc: techCard.description,
            video: techCard.videos && techCard.videos.length > 0,
            videoUrl: techCard.videos,
            image: images.length === 1 ? images[0] : null,
            images,
          };
        });
        techSquareData[x] = techCards;
      });
    yield put({
      type: types.GET_META_TECH_SQUARE_SUCCESS,
      data: techSquareData,
    });
  } catch (error) {
    if (error) {
      console.error('TechSqquare', error);
      yield put({
        type: types.GET_META_TECH_SQUARE_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_META_TECH_SQUARE_REQUEST, getMetaTechSquare);
}
