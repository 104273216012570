import _ from 'lodash';
import React, { useState } from 'react';
import { useActivityData } from '../../hooks/useActivityData';
import { useActivityProfile } from '../../hooks/useActivityProfile';
import { ActivityMainInfo } from '../ActivityMain/ActivityMainElements';
import ActivityMyRaffleTicket from '../ActivityMyRaffleTicket';
import {
  ActivityHistory,
  ActivityHistoryContainer,
} from '../ActivityPointCont/ActivityPointContElements';
import MyProfile from '../Myprofile';
import PopupRaffleTicket from '../PopupRaffleTicket';

const ActivityTicketCont = () => {
  const { data } = useActivityData();
  const { name, email, profileImage } = useActivityProfile();

  const activityData = _.filter(data, (e, k) => {
    return !e.point;
  });

  const [viewState, setViewState] = useState('all');
  const [isTicketPopup, setIsTicketPopup] = useState(false);

  const getCurrntNum = () => {
    return activityData.filter((e) => e.status === 'open').length;
  };

  const getUseNum = () => {
    return activityData
      .filter((e) => e.amount)
      .reduce((acc, curr) => {
        return acc + curr.amount;
      }, 0);
  };

  const openPopup = (key) => {
    setIsTicketPopup(true);
  };
  return (
    <ActivityHistoryContainer>
      <ActivityMainInfo>
        <MyProfile name={name} mail={email} url={profileImage} />
        <ActivityMyRaffleTicket current={getCurrntNum()} use={getUseNum()} />
      </ActivityMainInfo>
      <ActivityHistory>
        <div className="top">
          <p>Ticket history</p>
          <div className="control">
            <button
              type="button"
              className={viewState === 'all' ? 'on' : ''}
              onClick={() => setViewState('all')}
            >
              All
            </button>
            <button
              type="button"
              className={viewState === 'save' ? 'on' : ''}
              onClick={() => setViewState('save')}
            >
              Save
            </button>
            <button
              type="button"
              className={viewState === 'use' ? 'on' : ''}
              onClick={() => setViewState('use')}
            >
              Use
            </button>
          </div>
        </div>
        <ul>
          {activityData &&
            activityData
              .filter((e) =>
                viewState === 'save'
                  ? e.status
                  : viewState === 'use'
                  ? e.amount
                  : true
              )
              .sort((a, b) => {
                return new Date(b.timestamp) > new Date(a.timestamp) ? 1 : -1;
              })
              .map((item, idx) => (
                <li className={item.amount ? 'use' : 'save'} key={idx}>
                  <p>
                    <span>{item.title || 'Bingo'}</span>
                    <em>{item.amount ? `-${item.amount}` : '+1'}</em>
                  </p>
                  <p>
                    <span>{item.timestamp}</span>
                    <span>
                      {!item.amount ? (
                        'Save'
                      ) : (
                        <button
                          type="button"
                          onClick={() => openPopup(item.title)}
                        >
                          Use
                        </button>
                      )}
                    </span>
                  </p>
                </li>
              ))}
        </ul>
      </ActivityHistory>
      {isTicketPopup && <PopupRaffleTicket setOpen={setIsTicketPopup} />}
    </ActivityHistoryContainer>
  );
};

export default ActivityTicketCont;
