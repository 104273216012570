import React from 'react';
import ActivityMsgCont from '../../components/ActivityMsgCont';

const ActivityScanError = () => {
  return (
    <>
      <ActivityMsgCont
        type="error"
        msgTitle="Oops! Looks like there was a problem. Maybe you already scanned this QR code?"
        buttonLink="/activity/point"
        buttonText="Go to Point history"
      />
    </>
  );
};

export default ActivityScanError;
