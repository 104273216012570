import styled, { css } from 'styled-components';

export const MainOnsiteEventWrap = styled.div`
  padding: 0 40px 60px;
  background-color: #fff;

  &.is-phase4 {
    background-color: #f6f6f6;
    padding-bottom: 100px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 24px 60px;

    &.is-phase4 {
      padding-bottom: 60px;
    }

    .MainSectionTitleArea {
      padding-bottom: 36px;
    }
  }
`;

export const MainOnsiteEventArea = styled.div`
  position: relative;
  max-width: 1200px;
  height: 572px;
  margin: 0 auto;
  border-radius: 24px;
  background: #0f42cf;
  overflow: hidden;
  transform: translateY(260px);
  transition: 350ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.active {
    transform: translateY(0);
  }

  @media screen and (max-width: 768px) {
    height: 268px;
    border-radius: 12px;
  }
`;
