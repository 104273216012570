import React from 'react';

const BingoItem = ({ fill }) => {
  // 👇️ paste SVG into a component
  // take fill and stroke colors as props
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_6015_110620)">
        <path
          d="M67.3462 84.991L67.3461 84.9911C55.8436 86.6696 44.1594 86.6696 32.6569 84.9911L32.6568 84.991C27.8424 84.2896 23.57 82.0004 20.3646 78.6695C17.1632 75.3384 15.0343 70.9712 14.5079 66.1141L14.5079 66.1138C13.3317 55.2957 13.5257 44.3719 15.0819 33.6023L15.0822 33.5998L15.2079 32.7087C15.208 32.7083 15.208 32.7079 15.2081 32.7075C16.6346 22.8322 24.6675 15.2545 34.57 14.4349L34.5707 14.4348L35.6921 14.3411C35.6921 14.3411 35.6922 14.341 35.6923 14.341C45.2169 13.553 54.786 13.553 64.3105 14.341C64.3107 14.341 64.3108 14.3411 64.3109 14.3411L65.428 14.4348L65.4289 14.4349C75.3357 15.2545 83.3649 22.8327 84.791 32.7087L84.7911 32.7096L84.9211 33.602C86.4732 44.3718 86.6673 55.2957 85.4951 66.1141C84.9686 70.9711 82.8399 75.3382 79.6347 78.6691L79.6343 78.6695C76.4331 82.0002 72.1608 84.2896 67.3462 84.991Z"
          fill={fill}
          stroke="white"
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_6015_110620"
          x="5"
          y="9"
          width="90"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0745098 0 0 0 0 0.0509804 0 0 0 0 0.321569 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6015_110620"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6015_110620"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BingoItem;
