import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
  }
`;

export const RegisterBtnContainer = styled.div`
  position: fixed;
  bottom: 14px;
  right: 22px;
  z-index: 90;
  opacity: 1;
  transition: 0.3s;

  @media screen and (max-width: 768px) {
    bottom: 6px;
    right: 6px;
  }

  .RegisterLink {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    font-family: 'samsungsharpsans-bold';
    font-size: 20px;
    color: #fff;
    border-radius: 32px;
    svg g g:nth-of-type(2) {
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    }

    @media screen and (min-width: 769px) {
      &:hover {
        svg g g:nth-of-type(4) g g:nth-of-type(4) {
          animation: bounce 1.4s;
          animation-iteration-count: 3;
        }
      }

      @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
          transform: translateX(0);
        }
        40% {
          transform: translateX(-8px);
        }
        60% {
          transform: translateX(-4px);
        }
      }
    }

    @media screen and (max-width: 768px) {
      width: 115px;
      height: 115px;
    }
  }
`;
