import styled from 'styled-components';

export const MeetupContContainer = styled.div`
  margin: 102px auto 0;
  max-width: 1280px;

  @media (max-width: 768px) {
    margin: 56px auto 0;
  }
`;
export const MeetupContTop = styled.div`
  padding: 80px 40px 60px;
  .btn-back {
    color: #000000;
    position: relative;
    padding-left: 32px;
    font-family: 'samsungOne-700';
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 24px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url(${`${process.env.PUBLIC_URL}/images/ico-arrow-previous.svg`});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  @media (max-width: 768px) {
    padding: 24px 24px 40px 24px;
    .btn-back {
      padding-left: 24px;
      margin-bottom: 12px;
      font-size: 16px;
      &:before {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
export const MeetupBanner = styled.div`
  background-color: #2088fd;
  border-radius: 36px;
  position: relative;
  max-height: 380px;
  overflow: hidden;
  margin-bottom: 56px;
  &:after {
    content: '';
    display: block;
    padding-bottom: 49.14%;
  }
  .banner-box {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .banner-text {
      font-size: 54px;
      font-family: 'samsungsharpsans-bold';
      color: #ffffff;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .banner-time {
      font-size: 20px;
      font-family: 'SamsungOne-400';
      color: #ffffff;
      font-weight: 400;
    }
  }
  img {
    position: absolute;
  }
  .assets-1 {
    left: 0;
    bottom: 0;
  }
  .assets-2 {
    bottom: 9px;
    left: 180px;
  }
  .assets-3 {
    top: 0;
    left: 720px;
  }
  .assets-4 {
    top: 0;
    left: 945px;
  }
  .assets-5 {
    right: 10px;
    bottom: 0;
    width: 380px;
  }

  @media (max-width: 768px) {
    margin-bottom: 24px;
    border-radius: 12px;
    max-height: 220px;
    &:after {
      padding-bottom: 70.51%;
    }
    .banner-box {
      .banner-text {
        font-size: 20px;
        margin-bottom: 2px;
      }
      .banner-time {
        font-size: 14px;
      }
    }
    .assets-1 {
      left: 0;
      bottom: -40px;
      width: 80px;
    }
    .assets-2 {
      display: none;
    }
    .assets-3 {
      width: 99px;
      right: 110px;
      left: initial;
    }
    .assets-4 {
      width: 130px;
      right: -30px;
      left: initial;
    }
    .assets-5 {
      right: -20px;
      bottom: -5px;
      width: 180px;
    }
  }
`;
export const MeetupTitle = styled.div`
  font-size: 42px;
  font-family: 'samsungsharpsans-bold';
  font-weight: 600;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 12px;
  }
`;
export const MeetupDesc = styled.div`
  font-size: 16px;
  color: #1e1e1e;
  font-family: 'SamsungOne-400';
  font-weight: 400;
  line-height: 24px;
  max-width: 1160px;
  @media (max-width: 768px) {
    font-size: 14px;
    &.bottom {
      padding: 0 24px;
    }
  }
`;
export const MeetupContBottom = styled.div`
  padding: 40px 40px 120px;
  .react-tabs__tab-list {
    display: flex;
    width: 100%;
    margin-bottom: 48px;
    overflow: auto;
  }
  .react-tabs__tab {
    width: 50%;
    padding: 20px 0;
    font-family: 'samsungsharpsans-medium';
    font-size: 18px;
    line-height: 1.44;
    color: #9e9e9e;
    border-bottom: 2px solid #efefef;
    text-align: center;
    cursor: pointer;
    &--selected {
      font-family: 'samsungsharpsans-bold';
      color: #2088fd;
      border-bottom: 2px solid #2088fd;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    .react-tabs__tab {
      width: 50%;
      padding: 14px 13px 13px;
      font-size: 16px;
      white-space: nowrap;
    }
    .react-tabs__tab-list {
      width: calc(100% - 48px);
      padding: 0 24px;
      margin-bottom: 36px;
      ::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
`;
export const TabPanelBox = styled.div`
  @media screen and (max-width: 768px) {
  }
`;

export const MeetupIntroduce = styled.div`
  padding: 52px 40px 62px 40px;
  border-radius: 24px;
  background-color: #f6f6f6;
  margin: 48px 0 24px 0;
  display: flex;
  gap: 40px;
  .img-introduce {
    width: 282px;
    height: 362px;
    border-radius: 21px;
    background-color: #ffffff;
  }
  .introduce-info {
    width: 100%;
    .introduce-title {
      font-size: 18px;
      color: #7c7c7c;
      font-weight: 600;
      font-family: 'SamsungOne-600';
      margin-bottom: 14px;
    }
    .introduce-name {
      font-size: 32px;
      color: #000000;
      font-weight: 600;
      font-family: 'samsungsharpsans-bold';
      margin-bottom: 4px;
    }
    .introduce-job {
      font-size: 18px;
      color: #1e1e1e;
      font-weight: 700;
      font-family: 'SamsungOne-700';
      margin-bottom: 16px;
    }
    .introduce-desc {
      font-size: 14px;
      line-height: 20px;
      color: #1e1e1e;
      font-family: 'SamsungOne-400';
      padding-bottom: 32px;
      border-bottom: 1px solid #efefef;
      margin-bottom: 32px;
      line-height: 20px;
    }
    .introduce-stage {
      .introduce-box {
        display: flex;
        gap: 16px;
      }
      .stage-info {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .stage-badge {
          display: flex;
          align-items: center;
          color: #10b77b;
          gap: 4px;
          font-weight: 700;
          font-family: 'SamsungOne-700';
          img {
            width: 22px;
            height: 22px;
          }
        }
        .stage-time {
          color: #7c7c7c;
          font-weight: 600;
          font-family: 'SamsungOne-600';
          margin-right: 16px;

          &:before {
            content: '';
            display: inline-block;
            width: 1px;
            height: 10px;
            background-color: rgba(124, 124, 124, 0.3);
            margin: 0 8px;
          }
        }
      }
      .stage-subject {
        font-size: 24px;
        font-weight: 600;
        font-family: 'samsungsharpsans-bold';
        margin-bottom: 20px;
      }
      .stage-tags {
        display: flex;
        gap: 8px;
        .tag {
          border: 1.4px solid #7c7c7c;
          border-radius: 18px;
          padding: 8px 16px;
          color: #7c7c7c;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    border-radius: 0;
    margin-top: 32px;
    gap: 32px;
    padding: 32px 24px 60px 24px;
    .img-introduce {
      width: 100%;
      max-width: 100%;
      height: auto;
      border-radius: 16px;
      object-fit: cover;
    }
    .introduce-info {
      .introduce-title {
        font-size: 16px;
        margin-bottom: 20px;
      }
      .introduce-name {
        font-size: 24px;
        margin-bottom: 6px;
        &.theme {
          font-size: 20px;
        }
      }
      .introduce-job {
        font-size: 16px;
        margin-bottom: 20px;
      }
      .introduce-desc {
        padding-bottom: 0;
        border-bottom: 0;
        margin-bottom: 36px;
        line-height: 24px;
      }
      .introduce-stage {
        .introduce-box {
          flex-direction: column;
          gap: 2px;
          margin-bottom: 8px;
        }
        .stage-info {
          margin-bottom: 0;
          line-height: 20px;
        }
        background-color: #ffffff;
        border-radius: 16px;
        padding: 24px;
        .stage-subject {
          font-size: 18px;
          margin-bottom: 24px;
        }
      }
    }
  }
`;

export const EventMapWrap = styled.div`
  background-color: #f6f6f6;
  padding: 52px 40px 40px 40px;
  border-radius: 24px;
  margin: 24px 0 0 0;
  & > div {
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    background-color: #ffffff;
    padding: 0;
    margin: 12px 0 60px 0;
    & > div {
      padding: 48px 24px 60px;
    }
  }
`;
export const MeetupBottomBox = styled.div`
  background-color: #f6f6f6;
  padding: 1px 0;

  @media screen and (min-width: 769px) {
    padding: 0;
    background-color: #ffffff;
  }
`;

export const MeetupBottomBannerWrap = styled.div`
  @media screen and (max-width: 768px) {
    background-color: #f6f6f6;
    padding: 60px 0;
  }
`;
export const MeetupBottomBanner = styled.div`
  background-color: #10b77b;
  border-radius: 36px;
  position: relative;
  max-height: 340px;
  overflow: hidden;
  margin-top: 72px;
  &:after {
    content: '';
    display: block;
    padding-bottom: 120.83%;
  }
  &.join {
    background-color: #1259aa;
    max-height: 214px;
    .bottom-banner-box {
      padding: 54px 48px;
    }
  }
  img {
    position: absolute;
  }
  .assets-6 {
    right: 0;
  }
  .assets-7 {
    bottom: 0;
    right: 320px;
  }
  .assets-8 {
    width: 400px;
    bottom: 0;
    right: 130px;
  }
  .assets-11 {
    width: 300px;
    bottom: 0;
    right: 100px;
  }
  .assets-12 {
    right: 0;
    bottom: 0;
  }
  .bottom-banner-box {
    padding: 71px 56px;
    height: 100%;
    .bottom-banner-title {
      font-size: 32px;
      font-family: 'samsungsharpsans-bold';
      color: #ffffff;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .bottom-banner-desc {
      font-size: 16px;
      font-weight: 500;
      font-family: 'SamsungOne-500';
      opacity: 0.8;
      color: #ffffff;
      max-width: 570px;
    }
    .btn-register {
      padding: 16px 36px;
      background-color: #1e1e1e;
      color: #ffffff;
      font-family: 'samsungOne-700';
      font-weight: 700;
      border-radius: 36px;
      font-size: 18px;
    }
  }
  @media screen and (max-width: 768px) {
    position: relative;
    margin: 0 24px;
    max-height: 377px;
    border-radius: 16px;
    &.join {
      border-radius: 16px;
      max-height: 275px;
      &:after {
        content: '';
        display: block;
        padding-bottom: 113.4%;
      }
      .bottom-banner-box {
        padding: 24px;
      }
    }
    .assets-6 {
      width: 126px;
      top: 117px;
    }
    .assets-7 {
      bottom: 40px;
      left: 0;
    }
    .assets-8 {
      width: 200px;
      bottom: 70px;
      right: 70px;
    }
    .assets-11 {
      width: 250px;
      right: 116px;
      // left: -50px;
    }
    .assets-12 {
      width: 128px;
    }
    .bottom-banner-box {
      padding: 30px 24px;
      .bottom-banner-title {
        font-size: 20px;
        margin-bottom: 10px;
      }
      .bottom-banner-desc {
        font-size: 14px;
      }
    }
  }
`;

export const TabPanelTitle = styled.div`
  font-size: 32px;
  font-family: 'samsungsharpsans-bold';
  font-weight: 600;
  margin-bottom: 14px;

  @media screen and (max-width: 768px) {
    padding: 0 24px;
    font-size: 20px;
    margin-bottom: 12px;
  }
`;

export const TabPanelButton = styled.button`
  padding: 16px 36px;
  background-color: #1e1e1e;
  color: #ffffff;
  font-family: 'samsungOne-700';
  font-weight: 700;
  border-radius: 36px;
  font-size: 18px;
  margin: 36px 0 100px 0;
  &.disabled {
    background-color: #dddddd;
    color: #c4c4c4;
    point-events: none;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin: 40px 24px 60px 24px;
    &.register {
      position: absolute;
      bottom: 24px;
      left: 0;
      margin-bottom: 0;
      width: calc(100% - 48px);
    }
  }
`;

export const MeetupTheme = styled.div`
  margin-top: 32px;
  .introduce-theme {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px rgba(0, 0, 0, 0.08) solid;
    padding-bottom: 26px;
    margin-bottom: 26px;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .introduce-theme-time {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 18px;
      color: #7c7c7c;
      font-weight: 600;
      font-family: 'SamsungOne-600';
      margin-bottom: 8px;
      img {
        width: 14px;
        height: 14px;
      }
    }
    .introduce-theme-title {
      max-width: 600px;
      font-size: 24px;
      font-weight: 600;
      font-family: 'samsungsharpsans-bold';
    }
    .introduce-theme-cate {
      width: 200px;
      .introduce-theme-badge {
        padding: 2px 8px;
        color: #ffffff;
        font-family: 'samsungOne-700';
        font-weight: 700;
        border-radius: 6px;
        background-color: #756bf1;
      }
      .introduce-theme-text {
        margin-top: 10px;
        font-size: 18px;
        font-family: 'samsungOne-700';
        font-weight: 700;
        color: #756bf1;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 24px;
    background-color: #ffffff;
    padding: 32px;
    border-radius: 12px;
    .introduce-theme {
      padding-bottom: 32px;
      margin-bottom: 32px;
      flex-direction: column;
      .introduce-theme-time {
        font-size: 14px;
        margin-bottom: 4px;
      }
      .introduce-theme-title {
        font-size: 18px;
        margin-bottom: 16px;
      }
      .introduce-theme-cate {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        .introduce-theme-text {
          font-size: 16px;
          margin-top: 0;
        }
      }
    }
  }
`;
export const MeetupModalBox = styled.div`
  position: fixed;
  inset: 0;
  z-index: 99;
  background-color: #00000060;
`;
export const MeetupModalCont = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 40px;
  @media screen and (max-width: 768px) {
    padding: 24px;
    width: 264px;
  }
`;
export const MeetupModalReserve = styled.div`
  .modal-title {
    font-size: 24px;
    font-family: 'samsungOne-700';
    font-weight: 700;
    margin-bottom: 24px;
  }
  .modal-reserve-box {
    padding: 40px;
    border-radius: 16px;
    background-color: #f6f6f6;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 32px;
    .modal-reserve-item {
      min-width: 180px;
      .modal-reserve-title {
        color: #535353;
        font-family: 'samsungOne-600';
        font-weight: 600;
        opacity: 0.8;
        margin-bottom: 4px;
      }
      .modal-reserve-text {
        font-size: 20px;
        font-family: 'samsungOne-700';
        font-weight: 700;
        padding-right: 24px;
        white-space: nowrap;
        &.line {
          border-bottom: 1px solid #ddd;
          padding-bottom: 24px;
          margin-bottom: 24px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .modal-title {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .modal-reserve-box {
      padding: 24px;
      border-radius: 8px;
      grid-template-columns: 1fr;
      margin-bottom: 24px;
      .modal-reserve-item {
        .modal-reserve-title {
          font-size: 14px;
          margin-bottom: 4px;
        }
        .modal-reserve-text {
          font-size: 16px;
          padding-right: 0;
          border-bottom: 1px solid #ddd;
          padding-bottom: 16px;
          margin-bottom: 16px;
          &.line {
            padding-bottom: 16px;
            margin-bottom: 16px;
          }
          &.last {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;
export const MeetupModalConfirm = styled.div`
  display: flex;
  flex-direction: column;
  justift-content: center;
  align-items: center;
  .modal-title {
    margin-bottom: 4px;
    font-size: 24px;
    font-family: 'samsungOne-700';
    font-weight: 700;
    margin-bottom: 24px;
  }
  .modal-desc {
    width: 640px;
    color: #535353;
    font-size: 20px;
    text-align: center;
    margin-bottom: 32px;
    white-space: pre-wrap;
  }
  @media screen and (max-width: 768px) {
    .modal-title {
      font-size: 20px;
      margin-bottom: 12px;
    }
    .modal-desc {
      width: 264px;
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
`;
export const MeetupModalButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 768px) {
    gap: 8px;
  }
`;
export const MeetupModalButton = styled.button`
  display: flex;
  width: 172px;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  background-color: #1e1e1e;
  white-space: nowrap;
  color: #ffffff;
  font-size: 18px;
  font-family: 'samsungOne-700';
  font-weight: 700;
  &.cancel {
    background-color: #ffffff;
    color: #1e1e1e;
    border: 1.4px solid #1e1e1e;
  }
  @media screen and (max-width: 768px) {
    width: 128px;
    padding: 14px 20px;
    font-size: 16px;
  }
`;
