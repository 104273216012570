import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  SpeakerDetailWrap,
  SpeakerDetailInner,
  SpeakerDetailInfo,
  SpeakerDetailTitle,
  SpeakerDetailSubTitle,
  SpeakerDetailDesc,
  SpeakerDetailImage,
  SpeakerDetailMoreWrap,
  SpeakerDetailMore,
  GotoPrevious,
} from './SpeakerDetailContElement';

const SpeakerDetailCont = (props) => {
  const { title, subTitle, desc, img } = props;

  const [more, setMore] = useState(false);
  const textHandler = () => {
    setMore(!more);
  };

  const descRef = useRef(null);
  const [btnBlock, setBtnBlock] = useState(false);

  const descHandler = useCallback(() => {
    if (descRef.current) {
      setBtnBlock(false);
      if (window.innerWidth > 769) {
        if (descRef.current.scrollHeight > 65) {
          // 3줄 넘길 경우
          setBtnBlock(true); // 말줄임, more 버튼 활성화
        }
      } else if (descRef.current.scrollHeight > 64) {
        // 3줄 넘길 경우
        setBtnBlock(true); // 말줄임, more 버튼 활성화
      }
    }
  }, [descRef, btnBlock]);

  useEffect(() => {
    descHandler();

    window.addEventListener('resize', descHandler);
    return () => window.removeEventListener('resize', descHandler);
  }, []);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <SpeakerDetailWrap>
      <button type="button" onClick={goBack}>
        <GotoPrevious>Back</GotoPrevious>
      </button>
      <SpeakerDetailInner>
        <SpeakerDetailInfo>
          <SpeakerDetailTitle>{title}</SpeakerDetailTitle>
          <SpeakerDetailSubTitle>{subTitle}</SpeakerDetailSubTitle>
          <SpeakerDetailDesc
            className={more ? 'is-appended' : btnBlock ? 'is-more' : ''}
          >
            <div ref={descRef}> {desc.replace(/&lt;br&gt;/g, '')}</div>
          </SpeakerDetailDesc>
          <SpeakerDetailMoreWrap className={btnBlock ? 'block' : ''}>
            <SpeakerDetailMore
              onClick={textHandler}
              className={more ? 'is-appended' : ''}
            >
              {more ? 'Less' : 'More'}
            </SpeakerDetailMore>
          </SpeakerDetailMoreWrap>
        </SpeakerDetailInfo>
        <SpeakerDetailImage
          style={{
            backgroundImage:
              img === ''
                ? `url(${`${process.env.PUBLIC_URL}/images/MainSpeaker_empty_23_B.png`})`
                : `url('${img}')`,
          }}
        />
      </SpeakerDetailInner>
    </SpeakerDetailWrap>
  );
};

export default SpeakerDetailCont;
