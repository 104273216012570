import * as types from '../ActionTypes';

const initialState = {
  isLoading: false,
  keynoteData: null,
  sessionList: [],
  mainSession: [],
  filterData: [],
  filterChecked: false,
  error: null,
  sessionLoading: false,
  roomStatus: [],
};

const SessionList = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SESSION_LIST_REQUEST:
      return {
        ...state,
        sessionLoading: !action.goback,
        error: null,
      };
    case types.GET_SESSION_LIST_SUCCESS:
      return {
        ...state,
        sessionLoading: false,
        ...action.data,
      };
    case types.GET_SESSION_LIST_ERROR:
      return {
        ...state,
        sessionLoading: false,
        error: action.error,
      };
    case types.GET_MAIN_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        keynoteData: null,
        sessionList: [],
        mainSession: [],
        error: null,
      };
    case types.GET_MAIN_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    case types.GET_MAIN_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case types.GET_KEYNOTE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.GET_KEYNOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        keynoteData: { ...action.data.keynoteData },
        keynoteCount: action.data.keynoteCount,
      };
    case types.UPDATE_KEYNOTE_COUNT:
      return {
        ...state,
        keynoteCount: action.keynoteCount,
      };
    case types.GET_KEYNOTE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case types.GET_SESSION_FILTER_REQUEST:
      return {
        ...state,
        sessionLoading: true,
      };
    case types.GET_SESSION_FILTER_SUCCESS:
      return {
        ...state,
        sessionLoading: false,
        ...action.data,
      };
    case types.GET_SESSION_FILTER_ERROR:
      return {
        ...state,
        sessionLoading: false,
        error: action.error,
      };

    case types.SESSION_ROOM_STATUS_UPDATE:
      return {
        ...state,
        megaRoomStatus: action.roomStatus,
      };

    default:
      return state;
  }
};

export default SessionList;
