import React from 'react';
import ActivityHeader from '../../components/ActivityHeader';
import ActivityTicketCont from '../../components/ActivityTicketCont';

const ActivityBingoMap = () => {
  return (
    <>
      <ActivityHeader type="back" />
      <ActivityTicketCont />
    </>
  );
};

export default ActivityBingoMap;
