import * as types from '../ActionTypes';

const initialState = {
  phase3: false,
  keynote: 'ready',
  liveRoom1: 'ready',
  liveRoom2: 'ready',
  megaSession: 'ready',
  vod: 'ready',
  error: null,
  isLoading: false,
  room1URL: null,
  room2URL: null,
  megaURL: null,
  gapTime: null,
  surveyPopup: false,
  surveyURL: null,
};

const UtilReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SESSION_ROOM_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case types.GET_SESSION_ROOM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.data,
      };
    }

    case types.GET_SESSION_ROOM_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    // case types.MAIN_STATUS_UPDATE: {
    //   return {
    //     ...state,
    //     ...action.data,
    //   };
    // }
    default:
      return state;
  }
};

export default UtilReducer;
