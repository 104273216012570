/* eslint-disable */

import moment from 'moment';
import * as types from '../ActionTypes';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  points: 0,
  tickets: 0,
};

const ActivityReducer = (state = initialState, action) => {
  let points = 0;
  let tickets = 0;

  switch (action.type) {
    case types.GET_ACTIVITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
        tickets: 0,
        points: 0,
      };
    case types.GET_ACTIVITY_SUCCESS:
      for (const key in action.data) {
        // Converting timestamp to local format
        action.data[key].timestamp = moment
          .unix(action.data[key].timestamp)
          .format('YYYY-MM-DD HH:mm:ss');

        // Count total points and claimed tickets
        if ('point' in action.data[key]) {
          points += action.data[key].point;
        } else if (
          'status' in action.data[key] &&
          action.data[key].status === 'closed'
        ) {
          tickets += 1;
        }
      }

      return {
        ...state,
        isLoading: false,
        data: action.data,
        tickets,
        points,
      };
    case types.GET_ACTIVITY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default ActivityReducer;
