import { call, put, takeLatest } from 'redux-saga/effects';
import { Get } from '../../utils/Requester';
import * as types from '../ActionTypes';

export function* getBingoData() {
  try {
    const { data, error } = yield call(Get, {
      url: '/api/sdc/activities',
    });

    if (error || typeof data === 'string') {
      console.log('error', error);
      yield put({ type: types.GET_ACTIVITY_ERROR, error });
      return;
    }

    yield put({
      type: types.GET_ACTIVITY_SUCCESS,
      data,
    });
  } catch (err) {
    console.log('error', err);
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_ACTIVITY_REQUEST, getBingoData);
}
