import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import DetailContainer from '../../components/DetailContainer';
import { getGaClientId } from '../../utils/Functions';
import { Get } from '../../utils/Requester';

const RegisterInvited = styled.div`
  position: relative;
  min-height: 100vh;
  background: #f2f2f2;
  padding-bottom: 100px;
  box-sizing: border-box;
  color: #252525;
  .head {
    height: 90px;
    background-color: #fff;
  }
  .head .inner {
    position: relative;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 0 24px;
    box-sizing: border-box;
  }
  .head span {
    display: block;
    position: absolute;
    left: 24px;
    top: 36px;
    width: 61px;
    height: 16px;
    font-size: 0;
    line-height: 0;
    background-image: url(${`${process.env.PUBLIC_URL}/images/invited/logo.png`});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .content {
    position: relative;
    height: calc(100vh - 190px);
    min-height: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  .content .inner-wrap {
    width: 640px;
    padding: 72px 120px;
    background: #fff;
    border-radius: 26px;
    box-sizing: border-box;
    text-align: center;
  }
  .content p:first-child {
    display: block;
    margin: 0 auto;
    width: 80px;
    height: 80px;
    font-size: 0;
    line-height: 0;
    text-indent: -10000px;
    background-image: url(${`${process.env.PUBLIC_URL}/images/invited/sdc24.png`});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .content p {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
  .content .sdc24 + p {
    max-width: 340px;
    margin: 30px auto 0;
  }
  .content .desc {
    margin-top: 69px;
    font-size: 16px;
    line-height: 23px;
    color: #7c7c7c;
  }
  .content .btn-confirm {
    display: inline-block;
    margin-top: 30px;
    width: 360px;
    height: 44px;
    line-height: 44px;
    font-size: 17px;
    font-weight: bold;
    border-radius: 22px;
    background-color: #0381fe;
    color: #fff;
  }
  .content .copyright {
    position: absolute;
    width: 100%;
    left: 50%;
    bottom: -50px;
    text-align: center;
    transform: translateX(-50%);
    font-family: 'samsungSharpSans-bold';
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: #c4c4c4;
  }
  @media screen and (max-width: 768px) {
    background: #fff;
    .head {
      height: 56px;
    }
    .head .inner {
      max-width: 100%;
    }
    .head span {
      top: 20px;
    }
    .content {
      height: calc(100vh - 156px);
      min-height: 550px;
    }
    .content .inner-wrap {
      width: 100%;
      margin: 47px auto 0;
      padding: 0 24px;
      border-radius: 0;
    }
    .content .sdc24 + p {
      max-width: 320px;
      margin-top: 24px;
    }
    .content .desc {
      margin-top: 93px;
    }
    .content .btn-confirm {
      margin-top: 24px;
      width: 100%;
      max-width: 312px;
      height: 44px;
      line-height: 44px;
      font-size: 17px;
      font-weight: bold;
      border-radius: 22px;
      background-color: #0381fe;
      color: #fff;
    }
    .content .copyright {
      font-size: 14px;
      bottom: -70px;
    }
  }
`;

const RegistrationInvited = () => {
  const [popup, setPopup] = useState(null);
  // const navigate = useNavigate();
  const location = useLocation();
  const openPopup = () => {
    const left = window.outerWidth / 2 - 260;
    const newPopup = window.open(
      `/logout?redirectURL=/login?popup=1`,
      'Samsung Developer Conference',
      `toolbar=no,scrollbars=yes,resizable=no,top=50,left=${left},width=640,height=720`
    );
    setPopup(newPopup);
  };

  useEffect(() => {
    if (!popup) return;

    const checkUrlChange = () => {
      const queryParams = location.search;
      const gaCid = getGaClientId();
      if (popup.closed) {
        console.log('Popup window closed.');
        clearInterval(urlCheckInterval);
        window.location.href = `/login/check-sso?success=${encodeURIComponent(
          `/registration?fd=${process.env.REACT_APP_PARTNER_INVITATION_TOKEN}${
            queryParams ? `&${queryParams.slice(1)}` : ''
          }${gaCid ? `&_gl=${gaCid}` : ''}`
        )}&fail=${encodeURIComponent(
          window.location.pathname +
            window.location.search +
            window.location.hash
        )}`;
      }
    };
    const urlCheckInterval = setInterval(checkUrlChange, 1000);

    return () => clearInterval(urlCheckInterval);
  }, [popup]);

  return (
    <>
      <DetailContainer noMargin>
        <RegisterInvited>
          <div className="head">
            <div className="inner">
              <span>SDC24</span>
            </div>
          </div>
          <div className="content">
            <div className="inner-wrap">
              <p className="sdc24">SDC24</p>
              <p>You have been invited to SDC24 as a partner.</p>
              <p>
                <br />
                If you want to participate in the event as a partner, please
                sign in or sign up for the Samsung Account with the email you
                were invited to and register for the event.
              </p>
              <p className="desc">It's connected to Samsung Account</p>
              {/* TODO: [SW:06/21/2024] Hardcoded for GA testing */}
              <button onClick={openPopup} className="btn-confirm">
                OK
              </button>
            </div>
            <p className="copyright">Samsung Developer Conference 2024</p>
          </div>
        </RegisterInvited>
      </DetailContainer>
    </>
  );
};

export default RegistrationInvited;
