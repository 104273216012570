import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { useActivityData } from '../../hooks/useActivityData';
import { useActivityProfile } from '../../hooks/useActivityProfile';
import Button from '../Button';
import PopupBingoActivity from '../PopupBingoActivity';
import {
  ActivityBingoBoard,
  ActivityBingoCont,
  ActivityBingoItem,
  ActivityBingoLine,
  ActivityContainer,
  ActivityHero,
  ActivityHeroBg,
  ActivityPrecaution,
  ActivityScore,
  ActivityTab,
  HowToPlayPopup,
  HowToPlayStepItem,
} from './ActivityBingoElements';
import BingoItem from './BingoItem';

export const bingoData = [
  {
    id: 'bingo-1-1',
    title: 'Enterprise & <br />Ecosystem 1',
    location: '<span class="n4">Enterprise & Ecosystem</span>',
    desc: `
    Learn ‘Samsung Health SDK Suite’ from Samsung experts 
    and receive a digital stamp from our staff. <br />
    <br />
    Go to Enterprise & Ecosystem booth for mission.  <br />
    Check the map for mission location
    `,
    point: 200,
    type: 'type-1',
    color: '#0066FF',
  },
  {
    id: 'bingo-1-2',
    title: 'Open Stage <br /> Session',
    location: '<span class="n1">Open Stage</span>',
    desc: `
    Attend an Open Stage Session and ask our staff nearby to complete the mission.<br />
    <br />
    Go to Open Stage booth for mission.<br />
    Check the map for mission location
    `,
    point: 200,
    type: 'type-4',
    color: '#0066FF',
  },
  {
    id: 'bingo-1-3',
    title: 'Tech Session',
    location: `
    <span class="n3">AI for Platforms</span>
    <span class="n6">Session Rooms</span>
    `,
    desc: `
    Attend a Tech Session and scan QR after each session.<br />
    <br />
    Go to Session Rooms for mission.  <br />
    Check the map for mission location
    `,
    point: 200,
    type: 'type-4',
    color: '#0066FF',
  },
  {
    id: 'bingo-1-4',
    title: 'Enterprise & <br />Ecosystem 2',
    location: '<span class="n4">Enterprise & Ecosystem</span>',
    desc: `
    Learn ‘Harman Automotive Product Demo’ from Samsung experts and receive a digital stamp from our staff.<br />
    <br />
    Go to Enterprise & Ecosystem for mission.<br />  
    Check the map for mission location
    `,
    point: 200,
    type: 'type-1',
    color: '#0066FF',
  },
  {
    id: 'bingo-2-1',
    title: 'AI for <br /> Devices 1',
    location: '<span class="n5">AI for Devices</span>',
    desc: `
    Learn ‘On-Device Cleaning Solution’ from Samsung experts and receive a digital stamp from our staff.<br />
    <br />
    Go to AI for Devices booth for mission.<br /> 
    Check the map for mission location
    `,
    point: 200,
    type: 'type-1',
    color: '#756BF1',
  },
  {
    id: 'bingo-2-2',
    title: 'Meet-up',
    location: `
    <span class="n2">Networking Lounge</span>
    <span class="n4">Enterprise & Ecosystem</span>
    <span class="n5">AI for Devices</span>
    <span class="n6">Session Rooms</span>
    `,
    desc: `
    Network with other attendees at networking area after sessions at the Meet-up area and get a digital stamp from our staff.<br />
    <br />
    Go to Networking Area Session Room, meeting room, Tech Square for mission.<br /> 
    Check the map for mission location
    `,
    point: 400,
    type: 'type-1',
    color: '#CA6CEB',
  },
  {
    id: 'bingo-2-3',
    title: 'SDC24 <br />Message <br />Wall',
    location: '<span class="n7">Message Wall</span>',
    desc: `
    Take photo and be part in completing SDC24 message wall.<br />
    <br />
    Go to Message Wall for mission.<br />
    Check the map for mission location
    `,
    point: 200,
    type: 'type-6',
    color: '#CA6CEB',
  },
  {
    id: 'bingo-2-4',
    title: 'AI for <br /> Devices 2',
    location: '<span class="n5">AI for Devices</span>',
    desc: `
    Learn ‘Create Your Own Image with AI’ from Samsung experts and receive a digital stamp from our staff.<br />
    <br />
    Go to AI for Devices booth for mission.<br /> 
    Check the map for mission location
    `,
    point: 200,
    type: 'type-1',
    color: '#756BF1',
  },
  {
    id: 'bingo-3-1',
    title: 'AI for <br /> Platforms 1',
    location: '<span class="n3">AI for Platforms</span>',
    desc: `
    Learn ‘Daily Health Care in the Comfort of Your Home’ from Samsung experts and receive a digital stamp from our staff.<br />
    <br />
    Go to AI for Platforms for mission.<br /> 
    Check the map for mission location
    `,
    point: 200,
    type: 'type-1',
    color: '#756BF1',
  },
  {
    id: 'bingo-3-2',
    title: 'SDC24 <br />NPS Survey',
    linkURL: '/survey',
    desc: `
    Complete our SDC24 NPS survey from the link below.
    `,
    point: 400,
    type: 'type-2',
    color: '#CA6CEB',
  },
  {
    id: 'bingo-3-3',
    title: 'Share <br />Your Pic',
    location: '<span class="info">Info/Giveaway Desk</span>',
    desc: `
    Upload SDC24 photo with #SDC24, #joinsdc24 and show it to our Staff at Info/Giveaway Desk<br />
    <br />
    Check the map for mission location
    `,
    point: 400,
    type: 'type-6',
    color: '#CA6CEB',
  },
  {
    id: 'bingo-3-4',
    title: 'AI for <br /> Platforms 2',
    location: '<span class="n3">AI for Platforms</span>',
    desc: `
    Learn ‘Home Insight Powered by SmartThings AI’ from Samsung experts and receive a digital stamp from our staff<br />
    <br />
    Go to AI for Platforms booth for mission.<br />  
    Check the map for mission location
    `,
    point: 200,
    type: 'type-1',
    color: '#756BF1',
  },
  {
    id: 'bingo-4-1',
    title: 'Visit C-Lab booth',
    location: '<span class="n4">Enterprise & Ecosystem</span>',
    desc: `
    Learn what’s in C-Lab booth from Samsung experts and receive a digital stamp from our staff.<br />
    <br />
    Go to Enterprise & Ecosystem booth for mission.<br />  
    Check the map for mission location
    `,
    point: 200,
    type: 'type-1',
    color: '#10B77B',
  },
  {
    id: 'bingo-4-2',
    title: 'Samsung Dev <br />Support <br />Survey',
    linkURL:
      'https://samsungdevelopers.my.site.com/survey/runtimeApp.app?invitationId=0KiQP000000PHLp&surveyName=samsung_dev_support_survey&UUID=7d7e7456-0f51-4358-a881-4f1380acd175',
    desc: `
    Complete our Dev Support survey from the link below
    `,
    point: 300,
    type: 'type-2',
    color: '#10B77B',
  },
  {
    id: 'bingo-4-3',
    title: 'Galaxy <br />Photo <br />Studio',
    location: '<span class="n8">Gamer’s Lounge</span>',
    desc: `
    Take a selfie with our Galaxy Z Flip6 and take home a lasting memory.<br />
    <br />
    Go to Gamer’s Lounge for mission.<br />  
    Check the map for mission location
    `,
    point: 200,
    type: 'type-6',
    color: '#10B77B',
  },
  {
    id: 'bingo-4-4',
    title: 'Samsung<br />Developer <br />Newsletter',
    linkURL: 'https://developer.samsung.com/newsletter/subscribe?source=SDC24',
    desc: `
    Subscribe to Samsung developer’s newsletter with the link below.
    `,
    point: 100,
    type: 'type-3',
    color: '#10B77B',
  },
];

const ActivityBingo = () => {
  const [modal, setModal] = useState(false);
  const openPopup = () => {
    setModal(true);
  };

  const { data } = useActivityData();
  const { name } = useActivityProfile();

  let sliderRef = useRef(null);

  const settings = {
    dots: true,
    speed: 400,
    centerMode: true,
    infinite: false,
    centerPadding: '0px',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    cssEase: 'ease-in-out',
  };

  const bingoCompleteData = _.filter(data, (e, k) => {
    e.id = k;
    return e.point && k.includes('bingo');
  });

  const bingoLineData = _.filter(data, (e, k) => {
    e.id = k;
    return k.includes('bingo-line');
  });

  const [isActivityPopup, setIsActivityPopup] = useState(false);
  const [selectedBingData, setSelectedBingData] = useState(null);

  const checkComplete = (id) => {
    return _.find(bingoCompleteData, (e) => e.id === id || e.id.includes(id))
      ? 'completed'
      : '';
  };

  const bingoItemClickHandler = (row) => {
    setSelectedBingData(row);
    setIsActivityPopup(true);
    // if (checkComplete(row.id) !== 'completed') {
    //   setSelectedBingData(row);
    //   setIsActivityPopup(true);
    // }
  };

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modal]);

  return (
    <ActivityContainer type="bingo" className="bingo" main>
      <ActivityHero>
        <ActivityHeroBg>
          <span />
          <span />
          <span />
        </ActivityHeroBg>
        <div className="text-area">
          <img
            src={`${process.env.PUBLIC_URL}/images/txt-sdc24-white.svg`}
            alt="SDC24"
          />
          <p>Bingo Game</p>
          <p>
            Complete SDC24 Bingo missions to earn points, which you can redeem
            for gifts. For each Bingo line you complete, you'll receive lucky
            draw ticket to enter our Lucky Draw.
          </p>
          <Button color="black" size="medium" onClick={() => openPopup()}>
            How to play
          </Button>
        </div>
      </ActivityHero>
      <ActivityBingoCont>
        <ActivityTab>
          <Link to="/activity/bingo" className="active">
            Activities
          </Link>
          <Link to="/activity/bingo/map">Map</Link>
          <Link to="/activity/bingo/prizes">Prizes</Link>
        </ActivityTab>
        <ActivityScore>
          <p>{name}’s</p>
          <p>Bingo Line</p>
          <div className="progress">
            <p className="progress-text">
              <em>{bingoLineData.length}</em> / 10 lines
            </p>
            <div className="progress-bar">
              <div
                className="bar"
                style={{ width: `${(bingoLineData.length / 10) * 100}%` }}
              />
            </div>
          </div>
          <p className="text-info">
            Earn one lucky draw ticket for every bingo line completed. You can
            receive your lucky draw ticket at the info / giveaway desk.
          </p>
          <div className={`ticket ${bingoLineData.length ? 'on' : ''}`}>
            <p>lucky draw ticket</p>
            <em>{bingoLineData.length}</em>
          </div>
        </ActivityScore>
        <ActivityBingoBoard>
          <ActivityBingoLine>
            {bingoLineData.map((row, idx) => (
              <hr
                key={idx}
                className={row.id.substring(
                  row.id.indexOf('line'),
                  row.id.length
                )}
              />
            ))}
          </ActivityBingoLine>
          {bingoData.map((row, idx) => (
            <ActivityBingoItem
              key={row.id}
              className={checkComplete(row.id)}
              onClick={() => bingoItemClickHandler(row)}
            >
              <div className="bg">
                <BingoItem fill={row.color} />
              </div>
              <p
                className="title"
                dangerouslySetInnerHTML={{ __html: row.title }}
              />
              <p className="num">{idx + 1}</p>
            </ActivityBingoItem>
          ))}
        </ActivityBingoBoard>
        <ActivityPrecaution>
          <p>Precaution for Bingo</p>
          <hr />
          <ul>
            <li>Carefully review the mission details for clear instruction</li>
            <li>
              Bingo prizes and Lucky Draw tickets can be collected at the
              Giveaway desk.
            </li>
            <li>
              After completing each experience mission, you must scan the QR
              code from our staff to confirm completion
            </li>
            <li>
              If you fail to scan the QR code right away, you will not be able
              to scan it later and will need to repeat the mission.
            </li>
            <li>
              Prizes are given out on a first-come, first-served basis, and
              availability is limited while supplies last.
            </li>
          </ul>
        </ActivityPrecaution>
      </ActivityBingoCont>
      {modal ? (
        <HowToPlayPopup>
          <div className="inner-wrap">
            <Slider
              ref={(slider) => {
                sliderRef = slider;
              }}
              {...settings}
            >
              <HowToPlayStepItem>
                <img
                  src={`${process.env.PUBLIC_URL}/images/activity-bingo-howtoplay-1.svg`}
                  alt="Sign-in first"
                />
                <p>Sign-in first</p>
                <p>Please sign-in SDC24 to participate.</p>
                <Button color="border" onClick={() => sliderRef.slickNext()}>
                  Next
                </Button>
              </HowToPlayStepItem>
              <HowToPlayStepItem>
                <img
                  src={`${process.env.PUBLIC_URL}/images/activity-bingo-howtoplay-2-1.v2.svg`}
                  alt="Visit the mission spot"
                />
                <p>Visit the mission spot</p>
                <p>Visit the designated mission spot to fulfill the mission.</p>
                <Button color="border" onClick={() => sliderRef.slickNext()}>
                  Next
                </Button>
              </HowToPlayStepItem>
              <HowToPlayStepItem>
                <img
                  src={`${process.env.PUBLIC_URL}/images/activity-bingo-howtoplay-3.svg`}
                  alt="Scan QR code"
                />
                <p>Scan QR code</p>
                <p>
                  Scan QR code from our promoter after completing the mission to
                  accumulate points.
                </p>
                <Button color="border" onClick={() => sliderRef.slickNext()}>
                  Next
                </Button>
              </HowToPlayStepItem>
              <HowToPlayStepItem>
                <img
                  src={`${process.env.PUBLIC_URL}/images/activity-bingo-howtoplay-4.svg`}
                  alt="Redeem for Gifts"
                />
                <p>Redeem for Gifts</p>
                <p>
                  Exchange your points and lines for gifts at the Giveaway desk.
                </p>
                <Button color="black" onClick={() => setModal(false)}>
                  Okay
                </Button>
              </HowToPlayStepItem>
            </Slider>
          </div>
        </HowToPlayPopup>
      ) : null}
      {isActivityPopup && (
        <PopupBingoActivity
          setOpen={setIsActivityPopup}
          id={selectedBingData.id}
          type={selectedBingData.type}
          title={selectedBingData.title}
          location={selectedBingData.location}
          linkURL={selectedBingData.linkURL}
          desc={selectedBingData.desc}
          point={selectedBingData.point}
        />
      )}
    </ActivityContainer>
  );
};

export default ActivityBingo;
