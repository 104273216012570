import React from 'react';
import ActivityMsgCont from '../../components/ActivityMsgCont';

const ActivityPickupTicketComplete = () => {
  return (
    <>
      <ActivityMsgCont
        type="pickup"
        msgTitle="Thank you for using Lucky Draw tickets. Go pick up your tickets!"
        buttonLink="/activity/ticket"
        buttonText="Go to Ticket history"
      />
    </>
  );
};

export default ActivityPickupTicketComplete;
