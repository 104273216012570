import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DetailContainer from '../../components/DetailContainer';
import EventTechCont from '../../components/EventTechCont';
import DetailHero from '../../components/DetailHero';
import { GET_META_TECH_SQUARE_REQUEST } from '../../redux/ActionTypes';

const EventTechSquare = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_META_TECH_SQUARE_REQUEST });
  }, [dispatch]);

  return (
    <DetailContainer>
      <DetailHero
        bgPc1="bg-techsquare-banner-pc-1.svg"
        bgPc2="bg-techsquare-banner-pc-2.svg"
        bgMo1="bg-techsquare-banner-mo-1.png"
        bgMo2="bg-techsquare-banner-mo-2.svg"
        title="Tech Square"
        type="tech-square"
        path="Tech Square"
        bgColor="#2088FD"
      />
      <EventTechCont />
    </DetailContainer>
  );
};

export default EventTechSquare;
