import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import Slider from 'react-slick';
import {
  KeynoteContContainer,
  KeynoteContInner,
  KeynoteContVideo,
  KeynoteContVideoInner,
  KeynoteContVideoBox,
  KeynoteContSoon,
  KeynoteContText,
  KeynoteContTitle,
  KeynoteBtnBox,
  KeynoteContMark,
  KeynoteContDesc,
  KeynoteContLink,
  KeynoteContTag,
  KeynoteContLike,
  KeynoteContType,
  KeynoteMore,
  SessionDesc,
  KeynotebtnWrap,
  KeynoteTimeStampWrap,
  BookmarkTooltip,
  KeynoteContLikeTooltip,
  KeynoteTimeStamp,
  KeynoteTimeStampTitle,
  KeynoteTimeStampItem,
  KeynoteTimeStampImg,
  KeynoteBadge,
  KeynoteBadgeWrap,
  KeynoteContUtil,
} from './KeynoteContElements';
import {
  SessionRoomLink,
  SessionEventInfo,
} from '../sessionDetailTopCont/SessionDetailTopContElement';
import ToastPopup from '../ToastPopup';
import Popup from '../Popup';
// import PopupCalendar from '../PopupCalendar';
import Button from '../Button';
import SessionCalendar from '../PopupCalendar/SessionCalendar';
import {
  ADD_BOOKMARK_REQUEST,
  ADD_RESERVATION_REQUEST,
  DELETE_BOOKMARK_REQUEST,
  DELETE_RESERVATION_REQUEST,
  LIKE_SESSION_REQUEST,
  RESET_BOOKMARK_MOTION,
  RESET_LIKE_MOTION,
  RESET_RESERVATION_MOTION,
  UNLIKE_SESSION_REQUEST,
} from '../../redux/ActionTypes';
import './style.scss';
import { SESSION_CLOSED, SESSION_OPEN, SESSION_READY } from '../../data/status';

// bookmark
const BookmarkToggleItem = ({
  sessionData,
  setModal,
  setModalFalse,
  tooltipOpen,
  setTooltipOpen,
}) => {
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state) => state.userSession);
  const { data, motion, targetId, error } = useSelector(
    (state) => state.bookmarkList
  );

  const bookmarkStatus = data
    ? data.filter((x) => x.PresentationID === sessionData?.PresentationID)
    : [];

  const [Bookmark, setBookmark] = useState(bookmarkStatus.length > 0);

  const onClickHandler = () => {
    if (isLogin && sessionData) {
      // login user
      setBookmark(!Bookmark); // update ui
      if (Bookmark === false) {
        dispatch({
          type: ADD_BOOKMARK_REQUEST,
          sessionData,
          actionPage:
            sessionData.SessionType.toLowerCase() === 'keynote'
              ? 'keynote'
              : '',
        });
      } else {
        dispatch({
          type: DELETE_BOOKMARK_REQUEST,
          sessionId: sessionData?.PresentationID,
          actionPage:
            sessionData.SessionType.toLowerCase() === 'keynote'
              ? 'keynote'
              : '',
        });
      }
    } else {
      // not login user, display tooltip
      setTooltipOpen(true);
    }
  };

  // tooltip
  useEffect(() => {
    const timer = setTimeout(() => {
      setTooltipOpen(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [tooltipOpen, setTooltipOpen]);

  useEffect(() => {
    if (data && motion && targetId) {
      if (targetId === sessionData.PresentationID) {
        const sessionStatus = data.filter(
          (x) => x.PresentationID === sessionData.PresentationID
        );
        if (sessionStatus.length === 0) {
          // delete bookmark
          setModal(false);
          setModalFalse(true);
          setBookmark(false); // ui update state
        } else if (sessionStatus.length === 1) {
          // add bookmark
          setModalFalse(false);
          setModal(true);
          setBookmark(true); // ui update state
        }
        dispatch({ type: RESET_BOOKMARK_MOTION });
      }
    }
  }, [data, motion]);

  // when error for bookmark action, revert ui state
  useEffect(() => {
    if (error && targetId === sessionData.PresentationID) {
      if (Bookmark !== bookmarkStatus.length > 0) {
        setBookmark(bookmarkStatus.length > 0);
      }
    }
  }, [error, targetId]);

  useLayoutEffect(() => {
    if (Bookmark !== bookmarkStatus.length > 0) {
      setBookmark(bookmarkStatus.length > 0);
    }
  }, [data]);

  return (
    <KeynoteContMark
      className={Bookmark ? 'Bookmark-checked' : ''}
      onClick={onClickHandler}
    />
  );
};

// reservation
const ReserveToggleItem = ({
  sessionData,
  setLoginModal,
  setRegisterModal,
  calendarHandler,
  setReservationState,
  setRemoveModal,
}) => {
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state) => state.userSession);
  const sessionType = sessionData.SessionType;
  const { isReservation, registerType, motion } = useSelector(
    (state) => state.reservationData
  );

  const [isReserve, setReserve] = useState(isReservation);
  useLayoutEffect(() => {
    setReserve(isReservation);
  }, [isReservation]);

  const onClickHandler = () => {
    if (isLogin && sessionData) {
      if (
        !registerType ||
        (registerType === 'online' && sessionType.toLowerCase() !== 'mega')
      ) {
        // show reservation popup
        setRegisterModal(true);
      } else if (isReserve === false) {
        // added reservation data
        dispatch({
          type: ADD_RESERVATION_REQUEST,
          sessionData,
        });
      } else {
        setRemoveModal(true);
      }
    } else {
      setLoginModal(true);
    }
  };

  useEffect(() => {
    if (motion === 'add') {
      const showPopup = localStorage.getItem('confirmPopup');
      if (showPopup) {
        setReservationState(true);
      } else {
        calendarHandler(); // show calendar popup
      }
    }
    if (motion) {
      // reset motion value
      dispatch({ type: RESET_RESERVATION_MOTION });
    }
  }, [motion]);

  return registerType === 'online' && sessionType.toLowerCase() !== 'mega' ? (
    <div />
  ) : isReserve ? (
    <Button
      className="btn-calendar"
      size="medium"
      color="white"
      outline="outline"
      onClick={onClickHandler}
    >
      Cancel Reservation
    </Button>
  ) : (
    <Button className="btn-calendar" size="medium" onClick={onClickHandler}>
      Reserve Session
    </Button>
  );
};

function SampleNextArrow(nextProps) {
  const { className, style, onClick } = nextProps;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'red' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(prevProps) {
  const { className, style, onClick } = prevProps;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'green' }}
      onClick={onClick}
    />
  );
}

const KeynoteCont = (props) => {
  const {
    tag,
    title,
    session,
    desc,
    type,
    startDate,
    released,
    url,
    sessionData,
    sessionStatus,
    /* replay 시 플래그값 사용  */
    uploadedAfter,
    room,
    uploadedBefore,
    beforeLive,
    onDemand,
    emergency,
    notiTime,
    live,
    infoTime,
    infoPlace,
    status,
    time,
    noPlayable,
    setNoPlayable,
    chapterData,
  } = props;

  const dispatch = useDispatch();
  const phase = useSelector((state) => state.meta.data.phase);
  const gapTime = useSelector((state) => state.meta.gapTime);
  const { isLiked, motion } = useSelector((state) => state.sessionLikeStatus);
  const [modal, setModal] = useState(false);
  const [modalFalse, setModalFalse] = useState(false);
  const [like, setLike] = useState(isLiked);
  const [notiModal, setNotiModal] = useState(false);

  // reservation status
  const [reservationState, setReservationState] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);

  // reservation hide button
  const [isReady, setReady] = useState(true);

  const notiModalTimeOut = useRef(null);
  const reservationTimeOut = useRef(null);
  const likeHandler = () => {
    // setLike(!like);
    if (like) {
      dispatch({
        type: UNLIKE_SESSION_REQUEST,
        sessionId: sessionData.PresentationID,
      });
    } else {
      dispatch({
        type: LIKE_SESSION_REQUEST,
        sessionData,
      });
    }
  };

  // reservation button show / hide
  // useLayoutEffect(() => {
  //   if (!startDate) {
  //     return;
  //   }
  //   const d =
  //     gapTime === 0 ? moment.utc().valueOf() : moment.utc().valueOf() - gapTime;

  //   const currentTime = moment
  //     .tz(d, 'America/Los_Angeles')
  //     .format('YYYY-MM-DD[T]HH:mm:ss');

  //   if (moment(currentTime).isBefore(startDate)) {
  //     const diff = moment(startDate).diff(currentTime);
  //     reservationTimeOut.current = setTimeout(() => {
  //       setReady(false);
  //     }, diff);
  //   } else if (moment(currentTime).isAfter(startDate)) {
  //     setReady(false);
  //   }

  //   return () => {
  //     clearTimeout(reservationTimeOut.current);
  //   };
  // }, [startDate]);

  // refresh popup
  useLayoutEffect(() => {
    if (!notiTime || !sessionStatus || sessionStatus !== SESSION_READY) {
      return;
    }
    const d =
      gapTime === 0 ? moment.utc().valueOf() : moment.utc().valueOf() - gapTime;

    const currentTime = moment
      .tz(d, 'America/Los_Angeles')
      .format('YYYY-MM-DD[T]HH:mm:ss');
    if (
      moment(currentTime).isBefore(notiTime) &&
      sessionStatus === SESSION_READY
    ) {
      const diff = moment(notiTime).diff(currentTime);

      notiModalTimeOut.current = setTimeout(() => {
        setNotiModal(true);
      }, diff);
    }
    return () => {
      clearTimeout(notiModalTimeOut.current);
    };
  }, [notiTime, sessionStatus]);

  useLayoutEffect(() => {
    if (like !== isLiked) {
      setLike(isLiked);
    }
  }, [isLiked]);

  useEffect(() => {
    if (motion) {
      setLike(!like);
      dispatch({ type: RESET_LIKE_MOTION });
    }
  }, [isLiked, motion]);

  const deleteReservationItem = () => {
    setRemoveModal(false);
    dispatch({
      type: DELETE_RESERVATION_REQUEST,
      sessionId: sessionData.PresentationID,
    });
  };

  const [more, setMore] = useState(false);
  const textHandler = () => {
    setMore(!more);
  };

  // Bookmark tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const closeTooltip = () => {
    setTooltipOpen(false);
  };

  // Bookmark, Like Counting
  const { keynoteCount } = useSelector((state) => state.sessionList);
  // console.log('keynoteCount', keynoteCount);

  const { bookmarkCount, likeCount } = useSelector(
    (state) => state.sessionDetail
  );

  function millify(n) {
    const millnames = ['', 'K', 'M'];
    const millidx = Math.max(
      0,
      Math.min(millnames.length - 1, Math.floor(Math.log10(Math.abs(n)) / 3))
    );

    let formatNumber = (n / 10 ** (3 * millidx)).toFixed(1);
    if (formatNumber.endsWith('.0')) {
      formatNumber = formatNumber.slice(0, -2);
    }

    return `${formatNumber}${millnames[millidx]}`;
  }

  // Calendar
  const [addCalendar, setAddCalendar] = useState(false);
  const calendarHandler = () => {
    setAddCalendar(true);
    document.body.style.overflow = 'hidden';
  };

  // const [descHeight, setDescHeight] = useState(0);
  const SessionDescRef = useRef(null);
  const [btnBlock, setBtnBlock] = useState(false);

  useEffect(() => {
    const checkAndSetButtonBlock = () => {
      if (SessionDescRef.current) {
        const { scrollHeight } = SessionDescRef.current;
        setBtnBlock(scrollHeight > 72);
      }
    };

    // check when loading for the first time and when changing window size.
    checkAndSetButtonBlock();
    window.addEventListener('resize', checkAndSetButtonBlock);

    // Register the Cleanup function to remove the listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', checkAndSetButtonBlock);
    };
  }, []);

  const playerRef = useRef(null);

  const [nextSlide, setNextSlide] = useState(0);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: 0,
    beforeChange: (current, next) => {
      setNextSlide(next);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeId, setActiveId] = useState();
  const [isPlaying, setPlaying] = useState(false);

  const goToTime = (second) => {
    const { length } = chapterData;
    for (let i = 0; i < length; i++) {
      const val = chapterData;

      if (second < val[length - 1].time) {
        // 첫번째 타임스탬프 이전일 경우
        if (second < val[0].time) {
          setActiveId(0);
        } else if (second > val[i].time && second < val[i + 1].time) {
          setActiveId(i + 1);
        }
        // 마지막 타임스탬프일 경우
      } else {
        setActiveId(length);
      }
    }
  };

  return (
    <KeynoteContContainer
      className={released ? 'KeynoteCont is-released' : 'KeynoteCont'}
    >
      <KeynoteContInner className="KeynoteContInner">
        {session ? (
          phase === 'phase2' ? (
            <KeynoteContVideo className="KeynoteContVideo">
              <img
                className="react-player"
                src={`${process.env.PUBLIC_URL}/images/video-keynote-comingsoon.gif`}
                alt="coming-soon"
              />
              <KeynoteContSoon className="has-dim">
                <div className="title">Coming Soon</div>
                <div className="time">{`${moment(startDate).format(
                  'ddd, MMM D, YYYY hh:mm A'
                )} PT`}</div>
              </KeynoteContSoon>
            </KeynoteContVideo>
          ) : !uploadedBefore && onDemand && onDemand.length > 0 ? (
            <KeynoteContVideo className="KeynoteContVideo is-ondemand">
              <ReactPlayer
                ref={playerRef}
                className="react-player"
                url={url}
                width="100%"
                height="100%"
                controls
                playing={isPlaying}
                onPlay={() => {
                  setPlaying(true);
                }}
              />
            </KeynoteContVideo>
          ) : (
            // : phase === 'phase4' ? (
            //   <KeynoteContVideo className="KeynoteContVideo dim">
            //     <img
            //       className="react-player"
            //       src={`${process.env.PUBLIC_URL}/images/img-session-live.png`}
            //       alt="coming-soon"
            //     />
            //     <KeynoteContSoon className="play-btn">
            //       <img
            //         src={`${process.env.PUBLIC_URL}/images/ico-session-play.svg`}
            //         alt="ico-play"
            //       />
            //     </KeynoteContSoon>
            //   </KeynoteContVideo>
            // )
            <KeynoteContVideo className="KeynoteContVideo">
              <img
                className="react-player"
                src={`${process.env.PUBLIC_URL}/images/video-keynote-comingsoon.gif`}
                alt="coming-soon"
              />
              <KeynoteContSoon className="has-dim">
                <div className="title">
                  The On-demand session <br />
                  will be uploaded soon.
                </div>
              </KeynoteContSoon>
            </KeynoteContVideo>
          )
        ) : beforeLive ? (
          <KeynoteContVideo className="KeynoteContVideo">
            <img
              className="react-player"
              src={`${process.env.PUBLIC_URL}/images/video-keynote-comingsoon.gif`}
              alt="coming-soon"
            />
            <KeynoteContSoon className="has-dim">
              <div className="title">Coming Soon</div>
              <div className="time">{`${moment(startDate).format(
                'ddd, MMM D, YYYY hh:mm A'
              )} PT`}</div>
            </KeynoteContSoon>
          </KeynoteContVideo>
        ) : released ? (
          // <KeynoteContVideo className="KeynoteContVideo is-live">
          //   <div className="KeynoteBg">
          //     <img
          //       src={`${process.env.PUBLIC_URL}/images/keynote_live_bg.png`}
          //       alt="session is live"
          //     />
          //   </div>
          //   <KeynoteContSoon>
          //     <div className="title">
          //       The session is live.
          //       <br /> You can watch it in the live room.
          //     </div>
          //     <SessionRoomLink
          //       className="room"
          //       dangerouslySetInnerHTML={{ __html: room }}
          //     />
          //   </KeynoteContSoon>
          // </KeynoteContVideo>
          <>
            <KeynoteContVideoBox />
            <KeynoteContVideo
              className="KeynoteContVideo is-released"
              released={released}
            >
              <KeynoteContVideoInner>
                <ReactPlayer
                  ref={playerRef}
                  className="react-player"
                  playing={sessionStatus === SESSION_OPEN}
                  url={url}
                  width="100%"
                  height="100%"
                  controls
                  onProgress={(prog) => {
                    chapterData && goToTime(prog.playedSeconds);
                  }}
                  onError={(e) => {
                    setNoPlayable(true);
                    console.warn('keynote youtube error', e);
                  }}
                  autoPlay={sessionStatus === SESSION_OPEN}
                  muted={sessionStatus === SESSION_OPEN}
                />
              </KeynoteContVideoInner>
            </KeynoteContVideo>
          </>
        ) : uploadedBefore ? (
          // type === 'Mega' ? (
          //   <KeynoteContVideo className="KeynoteContVideo">
          //     <div className="KeynoteBg">
          //       <img
          //         src={`${process.env.PUBLIC_URL}/images/keynote_live_bg.png`}
          //         alt="session is live"
          //       />
          //     </div>
          //     <KeynoteContSoon>
          //       <div className="title">
          //         The live session has ended. <br />
          //         The on-demand session will be <br />
          //         uploaded soon.
          //       </div>
          //     </KeynoteContSoon>
          //   </KeynoteContVideo>
          // ) : (
          //   <KeynoteContVideo className="KeynoteContVideo is-beforeLive">
          //     <img
          //       className="react-player"
          //       src={`${process.env.PUBLIC_URL}/images/video-keynote-comingsoon.gif`}
          //       alt="coming-soon"
          //     />
          //     <KeynoteContSoon className="has-dim">
          //       <div className="title">
          //         The On-demand session <br />
          //         will be uploaded soon.
          //       </div>
          //     </KeynoteContSoon>
          //   </KeynoteContVideo>
          // )

          <>
            <KeynoteContVideoBox />
            <KeynoteContVideo
              className="KeynoteContVideo is-released"
              released={released}
            >
              <KeynoteContVideoInner>
                <div className="KeynoteBg">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/keynote_uploadBefore_bg.png`}
                    alt="session is live"
                  />
                </div>
                <KeynoteContSoon>
                  <div className="title">
                    Keynote replay will be <br />
                    uploaded soon.
                  </div>
                </KeynoteContSoon>
              </KeynoteContVideoInner>
            </KeynoteContVideo>
          </>
        ) : uploadedAfter ? (
          // <KeynoteContVideo className="KeynoteContVideo is-beforeLive">
          //   <img
          //     className="react-player"
          //     src={`${process.env.PUBLIC_URL}/images/video-keynote-comingsoon.gif`}
          //     alt="coming-soon"
          //   />
          //   <KeynoteContSoon className="has-dim">
          //     <div className="title">
          //       The On-demand session <br />
          //       will be uploaded soon.
          //     </div>
          //   </KeynoteContSoon>
          // </KeynoteContVideo>

          <>
            <KeynoteContVideoBox />
            <KeynoteContVideo
              className="KeynoteContVideo is-released"
              released={released}
            >
              <Link to={url}>
                <KeynoteContVideoInner>
                  <div className="KeynoteBg">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/keynote_uploadBefore_bg.png`}
                      alt="session is live"
                    />
                  </div>
                  <KeynoteContSoon>
                    <div>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/ico-play-btn-90.svg`}
                        alt="video-btn"
                        className="play-btn"
                      />
                    </div>
                  </KeynoteContSoon>
                </KeynoteContVideoInner>
              </Link>
            </KeynoteContVideo>
          </>
        ) : onDemand ? (
          <KeynoteContVideo className="KeynoteContVideo is-ondemand">
            <ReactPlayer
              ref={playerRef}
              className="react-player"
              url={url}
              width="100%"
              height="100%"
              controls
              playing={isPlaying}
              onPlay={() => {
                setPlaying(true);
              }}
              // phase 4 feature
              onProgress={(prog) => {
                if (phase === 'phase4' && chapterData) {
                  // support only for keynote sessions
                  goToTime(prog.playedSeconds);
                }
              }}
            />
          </KeynoteContVideo>
        ) : emergency ? (
          <>
            <KeynoteContVideoBox />
            <KeynoteContVideo
              className="KeynoteContVideo is-released"
              released={released}
            >
              <KeynoteContVideoInner>
                <div className="KeynoteBg">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Emergency.png`}
                    alt="session is live"
                  />
                </div>
                <KeynoteContSoon>
                  <div className="title">
                    Stay tuned!
                    <br /> We’ll be back in just a moment!
                  </div>
                </KeynoteContSoon>
              </KeynoteContVideoInner>
            </KeynoteContVideo>
          </>
        ) : noPlayable ? (
          <>
            <KeynoteContVideoBox />
            <KeynoteContVideo
              className="KeynoteContVideo is-released"
              released={released}
            >
              <KeynoteContVideoInner>
                <div className="KeynoteBg">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Emergency.png`}
                    alt="session is live"
                  />
                </div>
                <KeynoteContSoon>
                  <div className="title">
                    Unable to play the video. <br />
                    Please refresh this page or <br />
                    visit our official channel <br />
                    on YouTube/@SamsungDevelopers
                  </div>
                </KeynoteContSoon>
              </KeynoteContVideoInner>
            </KeynoteContVideo>
          </>
        ) : (
          <KeynoteContVideo className="KeynoteContVideo">
            <img
              className="react-player"
              src={`${process.env.PUBLIC_URL}/images/video-keynote-comingsoon.gif`}
              alt="coming-soon"
            />
            <KeynoteContSoon className="has-dim">
              <div className="title">Coming Soon</div>
              <div className="time">{`${moment(startDate).format(
                'ddd, MMM D, YYYY hh:mm A'
              )} PT`}</div>
            </KeynoteContSoon>
          </KeynoteContVideo>
        )}
        {/* Title Area */}
        <KeynoteContTitle
          className={
            session
              ? 'KeynoteContTitle is-session'
              : phase === 'phase3' && released
              ? 'KeynoteContTitle phase35'
              : released && !session
              ? 'KeynoteContTitle is-released keynote'
              : 'KeynoteContTitle keynote'
          }
        >
          {session ? (
            <SessionEventInfo>
              <div className="info time">{infoTime}</div>
              <div className="info place">{infoPlace}</div>
            </SessionEventInfo>
          ) : null}
          <div className="title">{title}</div>
          <div className="btn">
            {/* Like button in sessionDetail page */}
            {session ? (
              <KeynoteBtnBox>
                <KeynoteContLike
                  onClick={likeHandler}
                  className={like ? 'is-active' : ''}
                />
                <span className="util-btn-num">{millify(likeCount)}</span>
              </KeynoteBtnBox>
            ) : null}
            <KeynoteBtnBox>
              <BookmarkToggleItem
                setModal={setModal}
                setModalFalse={setModalFalse}
                tooltipOpen={tooltipOpen}
                setTooltipOpen={setTooltipOpen}
                sessionData={sessionData}
              />
              <span className="util-btn-num">
                {session ? (
                  <>{millify(bookmarkCount)}</>
                ) : (
                  <>{millify(keynoteCount)}</>
                )}
              </span>
              {/* Bookmark tooltip */}
              <BookmarkTooltip
                className={
                  tooltipOpen ? 'bookmark_tooltip is-open' : 'bookmark_tooltip'
                }
              >
                {'Bookmarks are available after signing in. '}
                <a href={`/login?redirectURL=${window.location.pathname}`}>
                  Sign in
                </a>
                <button
                  className="close_btn"
                  onClick={closeTooltip}
                  type="button"
                  aria-label="closeButton"
                />
              </BookmarkTooltip>
            </KeynoteBtnBox>
          </div>
          {/* Use in sessionDetail page */}
          <div className="tag_wrap">
            {/* {type ? (
              <KeynoteContType className={released ? 'released' : ''}>
                {type === 'Mega' && live && (
                  <span className="liveTag">Live</span>
                )}
                <span className="type">
                  {type
                    .replace('Mega', 'Mega Session')
                    .replace('Tech', 'Tech Session')}
                </span>
              </KeynoteContType>
            ) : null} */}
            {tag &&
              tag.map((item, idx) => (
                <KeynoteContTag className="KeynoteContTag" key={idx}>
                  {item}
                  {/* {item.replace('Contents & Service', 'Contents&Service')} */}
                </KeynoteContTag>
              ))}
          </div>
        </KeynoteContTitle>
        {/* keynote Live badge */}
        {/* 라이브 오픈 후, vod 업로드 이전 */}
        {!session && released && !uploadedAfter && live && (
          <KeynoteBadgeWrap>
            <KeynoteBadge>
              <div className="inner">
                <span className="tag">LIVE</span>
              </div>
            </KeynoteBadge>
          </KeynoteBadgeWrap>
        )}
        {session ? (
          <>
            {/* On the session detail page, use the Sessiondesc component instead of the KeynoteContDesc */}
            <SessionDesc
              ref={SessionDescRef}
              // className={more ? 'SessionDesc is-appended' : 'SessionDesc'}
              className={
                more
                  ? 'SessionDesc is-appended'
                  : btnBlock
                  ? 'SessionDesc is-more'
                  : 'SessionDesc'
              }
              dangerouslySetInnerHTML={{ __html: desc }}
            />
            {/* More button in sessionDetail page */}
            <KeynotebtnWrap className={btnBlock ? 'block' : ''}>
              <KeynoteMore
                onClick={textHandler}
                className={more ? 'is-appended' : ''}
              >
                {more ? 'Less' : 'More'}
              </KeynoteMore>
            </KeynotebtnWrap>
          </>
        ) : null}
        <KeynoteContText
          className={
            session && phase !== 'phase4'
              ? 'KeynoteContText is-session'
              : 'KeynoteContText'
          }
        >
          <KeynoteContDesc
            className={`KeynoteContDesc ${live ? 'live' : null}`}
            dangerouslySetInnerHTML={{ __html: desc }}
          />

          <KeynoteContUtil>
            {phase === 'phase4' ? (
              ''
            ) : !session &&
              (((sessionStatus === SESSION_READY || !sessionStatus) &&
                phase === 'phase3') ||
                phase === 'phase2') ? (
              <KeynoteContLink
                className="KeynoteContLink"
                href="https://www.youtube.com/watch?v=ax71_UYDk3Y"
                target="_blank"
              >
                Watch last Year's Keynote
              </KeynoteContLink>
            ) : null}
            {session && phase !== 'phase4' && (
              <>
                <ReserveToggleItem
                  sessionData={sessionData}
                  setLoginModal={setLoginModal}
                  setRegisterModal={setRegisterModal}
                  calendarHandler={calendarHandler}
                  setReservationState={setReservationState}
                  setRemoveModal={setRemoveModal}
                />
                {addCalendar ? (
                  <SessionCalendar
                    setOpen={setAddCalendar}
                    data={sessionData}
                  />
                ) : null}
              </>
            )}
            {/* {phase === 'phase2' && session ? (
              <>
                <ReserveToggleItem
                  sessionData={sessionData}
                  setLoginModal={setLoginModal}
                  setRegisterModal={setRegisterModal}
                  calendarHandler={calendarHandler}
                  setReservationState={setReservationState}
                  setRemoveModal={setRemoveModal}
                />
                {addCalendar ? (
                  <SessionCalendar
                    setOpen={setAddCalendar}
                    data={sessionData}
                  />
                ) : null}
              </>
            ) : session && phase === 'phase3' ? (
              isReady ? (
                <>
                  <ReserveToggleItem
                    sessionData={sessionData}
                    setLoginModal={setLoginModal}
                    setRegisterModal={setRegisterModal}
                    calendarHandler={calendarHandler}
                    setReservationState={setReservationState}
                    setRemoveModal={setRemoveModal}
                  />
                  {addCalendar ? (
                    <SessionCalendar
                      setOpen={setAddCalendar}
                      data={sessionData}
                    />
                  ) : null}
                </>
              ) : (
                <div />
              )
            ) : (
              <div />
            )} */}
          </KeynoteContUtil>

          {!chapterData || phase !== 'phase4' ? (
            ''
          ) : (
            <>
              <KeynoteTimeStampTitle
                className={`${session ? '' : 'is-keynote'}`}
              >
                Chapter
              </KeynoteTimeStampTitle>
              <KeynoteTimeStamp>
                <KeynoteTimeStampWrap
                  className={
                    nextSlide !== 0 ? 'timestampe not-first' : 'timestampe'
                  }
                >
                  <Slider {...settings}>
                    {chapterData.map((val, idx) => (
                      <KeynoteTimeStampItem
                        key={idx}
                        onClick={() => {
                          setActiveId(val.id);
                          goToTime(val.time);
                        }}
                        className={activeId === val.id ? 'active' : ''}
                      >
                        <button
                          onClick={() => {
                            playerRef.current.seekTo(val.time);
                            setPlaying(true);
                          }}
                          type="button"
                        >
                          <KeynoteTimeStampImg className="thumb">
                            <img src={val.thumbnail} alt="thumbnail" />
                          </KeynoteTimeStampImg>
                          <div className="title">{val.title}</div>
                          <div className="txt">From {val.text}</div>
                        </button>
                      </KeynoteTimeStampItem>
                    ))}
                  </Slider>
                </KeynoteTimeStampWrap>
              </KeynoteTimeStamp>
            </>
          )}
        </KeynoteContText>
      </KeynoteContInner>
      {/* toast popup */}
      {/* added bookmark popup */}
      <ToastPopup
        modal={modal}
        setModal={setModal}
        state="success"
        text="This session is kept as a bookmark."
      />
      {/* bookmark delete popup */}
      <ToastPopup
        modal={modalFalse}
        setModal={setModalFalse}
        state="success"
        text="This session is no longer bookmarked."
      />
      {notiModal ? (
        <Popup
          modal={notiModal}
          setModal={setNotiModal}
          notify="Please refresh this page"
          btnText="OK"
          reload
        />
      ) : null}
      {/* Reservation Toast popup */}
      <ToastPopup
        modal={reservationState}
        setModal={setReservationState}
        state="success"
        text="Session reservation is complete."
      />
      {/* reservation modal */}
      {session && removeModal ? (
        <Popup
          modal={removeModal}
          // setModal={setRemoveModal}
          onClick={() => deleteReservationItem()}
          title="Confirmed"
          desc={
            <>
              The session will be removed from your reserved sessions list.
              <br />
              To continue, click "Confirm".
            </>
          }
          btnText="Confirm"
        />
      ) : null}
      {session && registerModal ? (
        <Popup
          modal={registerModal}
          setModal={setRegisterModal}
          title="Registration Required"
          desc="You must register before you can reserve a session."
          linkText="Go to Registration"
          link="/register"
          linkBlock
          btnText="Confirm"
        />
      ) : null}
      {session && loginModal ? (
        <Popup
          modal={loginModal}
          setModal={setLoginModal}
          title="Sign In Required"
          desc="You must be signed in to do that."
          linkText="Sign in to Samsung Account"
          link={`/login?redirectURL=${window.location.pathname}`}
          linkBlock
          btnText="Confirm"
        />
      ) : null}
    </KeynoteContContainer>
  );
};

export default KeynoteCont;
