import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SessionDetailTop from '../../components/sessionDetailTopCont';
import SessionDetailCont from '../../components/SessionDetailCont';
import { GET_SESSION_DETAIL_REQUEST } from '../../redux/ActionTypes';
import withRouter from '../../components/Router/withRouter';
import Loading from '../../components/Loading';

const SessionDetail = (props) => {
  const { navigate, params } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_SESSION_DETAIL_REQUEST, sessionId: params.id });
  }, [dispatch, params.id]);

  const { isLoading, data, error } = useSelector(
    (state) => state.sessionDetail
  );

  useEffect(() => {
    if (error && navigate) {
      if (error === 'Not Found') {
        navigate('/page_not_found');
      } else {
        navigate('/internal_server_error');
      }
    }
  }, [error]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {data && (
            <>
              <SessionDetailTop />
              <SessionDetailCont />
            </>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(SessionDetail);
