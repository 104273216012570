import { all } from 'redux-saga/effects';
import ActivitySaga from './ActivitySaga';
import CodelabSaga from './CodelabSaga';
import KeynoteData from './KeynoteDataSaga';
import MeetupDataSaga from './MeetUpSaga';
import MetaSaga from './MetaSaga';
import MetaTechSquareSaga from './MetaTechSquareSaga';
import MyPageSaga from './MyPageSaga';
import ReservationSaga from './ReservationSaga';
import ScheduleDataSaga from './ScheduleDataSaga';
import SessionDetail from './SessionDetailSaga';
import SessionFilter from './SessionFilterSaga';
import SessionList from './SessionListSaga';
import SpeakerDetail from './SpeakerDetailSaga';
import SpeakerList from './SpeakersListSaga';
import PostSubscribe from './SubscribeSaga';
import TermsDownload from './TermsDownSaga';
import UserBookmarkSaga from './UserBookmarkSaga';
import UserLikeSaga from './UserLikeSaga';
import UserSession from './UserSessionSaga';
import UtilSaga from './UtilSaga';

export default function* rootSaga() {
  yield all([
    SessionList(),
    UserSession(),
    SessionDetail(),
    KeynoteData(),
    SpeakerList(),
    SpeakerDetail(),
    SessionFilter(),
    PostSubscribe(),
    UserBookmarkSaga(),
    MyPageSaga(),
    UserLikeSaga(),
    ReservationSaga(),
    TermsDownload(),
    UtilSaga(),
    MetaSaga(),
    MetaTechSquareSaga(),
    CodelabSaga(),
    ScheduleDataSaga(),
    MeetupDataSaga(),
    ActivitySaga(),
  ]);
}
